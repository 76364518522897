/*
 *   File : summary.js
 *   Author : https://evoqins.com
 *   Description : kyc summary page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';

//custom component
import { PrimaryButton } from '../../../Component/Buttons';
import { VerifyEmailModal } from '../../../Component/Modal';

//custom style
import styles from "../../../Style/Component/kyc.module.scss";

// Assets
import KYCProfile from "../../../Assets/Images/KYC/kyc-profile.svg";
import KYCError from "../../../Assets/Images/KYC/kyc-err.svg";
import KYCChecklist from "../../../Assets/Images/KYC/checklist.svg";

const Summary = (props) => {

    //get progress data
    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS_DATA);
    const [kycError, setKycError] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [kycStep, setKycStep] = useState(null);

    useEffect(() => {
        // _getKYCStatus();
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    // Checking kyc errors
    useEffect(() => {

        if (PROGRESS_DATA.step_detail.length !== 0) {

            let kyc_errors = PROGRESS_DATA.step_detail.filter(item => item.error === true).map((item) => {
                return { error: item.message };
            }).flatMap((item => {
                return [...item.error]
            }));

            setKycError(kyc_errors);
        }

        setKycStep(PROGRESS_DATA.current_step);

    }, [PROGRESS_DATA]);

    // handle open email verify modal
    useEffect(() => {
        if (showEmailModal === true) {
            let verify_modal = new Modal(document.getElementById('verify-email-modal'));
            verify_modal.show();
        };
    }, [showEmailModal]);

    //Email verify modal
    const _handleOpenVerify = useCallback(() => {

        // When again navigating from pan page with pan verification failed
        // After editing pan detail
        if (props.isPanFailed) {
            props.onUpdateStep(2);
            return;
        }

        if (PROGRESS_DATA.kyc_data.is_email_verified === false) {
            setShowEmailModal(true);
        } else {
            // 1. PROGRESS_DATA.step_detail[0] = Pan related
            // 2. If we get any error in this step we need to navigate to pan page
            // 3. Other wise continue the flow 1 => 2 => 3 ....
            if (PROGRESS_DATA.step_detail[0].error == true) {
                // 0 - will be pan verifying step
                props.onUpdateStep(0);
            } else {
                // 2 - will be personal detail step
                props.onUpdateStep(2);
            };
        }

    }, [PROGRESS_DATA.kyc_data.is_email_verified, props.onUpdateStep]);


    return (
        <>
            <div className='px-0 pb-5'>
                {
                    kycError.length !== 0 ?

                        <div className="col-md-10 col-12 mt-4">
                            {/* KYC Errors */}
                            <div className='e-bg-snow p-3 e-border-radius-16'>
                                <div className='d-flex gap-2 mb-4'>
                                    <img src={KYCError}
                                        width={48}
                                        height={48}
                                        className='object-fit-contain e-border-radius-10 e-bg-white p-1'
                                        draggable={false}
                                        alt='profile' />
                                    <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-28px'>
                                        Reason for rejection
                                    </p>
                                </div>
                                <div className='d-flex gap-4 flex-column'>
                                    {kycError.map((item, key) => (
                                        <div key={key} className='e-display-flex gap-0-8'>
                                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-700 line-height-24px e-bg-misty-rose e-border-radius-6 px-2'>
                                                {key + 1}
                                            </p>
                                            <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px'>
                                                {item}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        :

                        <div className='col-md-10 col-12'>
                            <h3 className='e-font-16-res line-height-22px-res mb-4 pt-4 e-text-raisin-black e-font-18 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                Things to keep in mind when opening an account
                            </h3>
                            {/* investment steps */}
                            <div className='e-bg-lavender p-3 e-border-radius-16'>
                                <div className='e-display-flex gap-0-8 mb-4'>
                                    <img src={KYCProfile}
                                        draggable={false}
                                        alt='profile' />
                                    <p className='e-font-14-res line-height-22px-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-28px'>
                                        Invest in {PROGRESS_DATA.step_detail.length} simple steps
                                    </p>
                                </div>
                                <div className='e-display-flex gap-0-24 e-flex-wrap gap-3'>
                                    {PROGRESS_DATA.step_detail.map((data, key) => (
                                        <div key={key} className='e-display-flex gap-0-8'>
                                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-700 line-height-24px e-bg-lavender-blue e-border-radius-6 px-2'>
                                                {data.step}
                                            </p>
                                            <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px'>
                                                {data.step_name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* checklist */}
                            <div className='mt-4 e-bg-anti-flash-white e-border-radius-16 p-3'>
                                <div className='e-display-flex gap-0-8'>
                                    <img src={KYCChecklist}
                                        draggable={false}
                                        alt='checklist' />
                                    <h6 className='e-font-14-res line-height-22px-res mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                        Checklist
                                    </h6>
                                </div>
                                <p className='mb-0 pt-3 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                                    Details below are needed for a smooth account opening process.
                                </p>

                                <div className={`${styles.e_list_wrapper} pt-3 `}>
                                    {PROGRESS_DATA.check_list.map((data, key) => (
                                        <ul key={key} className='mb-0'>
                                            <li className='pb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                {data}
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        </div>
                }
                {/* custom button */}
                <div className='padding-32px-top'>
                    <PrimaryButton name={kycStep > 1 ? "Edit & Update" : "Continue"}
                        className="px-4 padding-12px-top padding-12px-bottom"
                        onPress={_handleOpenVerify} />
                </div>
            </div >

            {/* verify email and otp modal */}
            {
                showEmailModal === true &&
                <VerifyEmailModal onUpdateStep={props.onUpdateStep}
                    onCloseModal={() => setShowEmailModal(false)} />
            }
        </>
    )
}

export default Summary;