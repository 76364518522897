/*
 *   File : order.js
 *   Author : https://evoqins.com
 *   Description : Order listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


//custom components
import { OrderCard } from '../../Component/Cards';
import { BreadCrumbs, CustomTab, EmptyScreen } from '../../Component/Other';
import { Footer } from '../../Component/Footer';
import { APIService } from '../../Service';
import { OrderFilterModal } from '../../Component/Modal';
import { Pagination } from '../../Component/Pagination';
import OverLay from '../../Component/Other/overlay';


// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyOrder from "../../Assets//Images/EmptyScreen/no-order.webp";
import NextIcon from "../../Assets/Images/right-black-icon.svg";

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",

    },
    {
        label: "Orders",
    }
];

const ITEMS_PER_PAGE = 10;

const ORDERS_TAB = [
    {
        id: 2,
        label: "Goals"
    },
    {
        id: 3,
        label: "Basket"
    },
    {
        id: 1,
        label: "Funds"
    },

];

const OrderListing = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(2);
    const [orderData, setOrderData] = useState([]);
    const [filterData, setFilterData] = useState("");
    const [selectedPaymentFilter, setSelectedPaymentFilter] = useState([null]);
    const [selectedOrderFilter, setSelectedOrderFilter] = useState([null]);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterDataLoading, setFilterDataLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [selectedMemberID, setSelectedMemberID] = useState(null);

    // handle get filter data and 
    useEffect(() => {
        // API call
        setDataLoading(true);
        _getFilterData();
    }, []);

    // handle update filter data when page reloads
    useEffect(() => {

        window.addEventListener("click", (e) => {
            if (!e.target.closest("#order-filter-modal")) {
                setToggleFilter(false);
            };
        });

        window.addEventListener("load", () => {
            setSelectedPaymentFilter(location.state !== null ? location.state.filter_by_payment : ([null]));
            setSelectedOrderFilter(location.state !== null ? location.state.filter_by_order : ([null]));
            setTabIndex(location.state !== null ? location.state.tab_index : 2);
            setSelectedMemberID(location.state !== null ? location.state.member_id : null)
        });

    }, []);

    useEffect(() => {

        if (filterData) {
            setFilterDataLoading(true);
            _getOrderList();
        };

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        const selected_filters_count = [...selectedOrderFilter, ...selectedPaymentFilter].filter((item) => item !== null).length;
        setAppliedFilters(selected_filters_count);

    }, [selectedOrderFilter, selectedPaymentFilter, tabIndex, pageNumber, selectedMemberID, filterData]);

    // Saving selected filters in location state
    useEffect(() => {

        navigate("/orders", {
            state: {
                "filter_by_payment": selectedPaymentFilter,
                "filter_by_order": selectedOrderFilter,
                "member_id": selectedMemberID,
                "tab_index": tabIndex,
            },
            replace: true
        });

    }, [selectedPaymentFilter, selectedOrderFilter, tabIndex, selectedMemberID]);


    //  handle body scroll when filter modal opened
    useEffect(() => {
        if (toggleFilter === true) {
            // document.body.style.overflow = "hidden";
            document.body.classList.add("body-no-scroll");
            document.querySelector("#order-filter-modal").scrollIntoView({ top: 0, behavior: "smooth" });
        } else {
            document.body.classList.remove("body-no-scroll");
        }

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            if (toggleFilter === true) {
                setToggleFilter(false);
            };
        });

    }, [toggleFilter]);

    // Setting active tab and filter - when coming from payment modal
    useEffect(() => {
        if (location.state !== null && location.state.order_tab) {
            setTabIndex(location.state.order_tab);
        };

        if (location.state !== null && location.state.filter_type) {
            setSelectedPaymentFilter([location.state.filter_type]);
        };

        if (location.state !== null && location.state.member_id) {
            setSelectedMemberID(location.state.member_id);
        };

    }, [location]);


    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
        setPageNumber(1);
        setFilterDataLoading(true);
        setSelectedPaymentFilter([null]);
        setSelectedOrderFilter([null]);
    };

    // handle member select
    const _handleMemberSelect = (member_id) => {
        setSelectedMemberID(member_id);
        setPageNumber(1);
        setSelectedPaymentFilter([null]);
        setSelectedOrderFilter([null]);
    };

    //handle toggle filter modal
    const _handleToggleFilter = () => {
        setToggleFilter(status => !status);
    };


    //handle filter submit
    const _handleFilterSubmit = (selected_payment, selected_order, status) => {
        if (status === true) {
            setSelectedPaymentFilter(selected_payment);
            setSelectedOrderFilter(selected_order);
        };

        setToggleFilter(false);
    };

    // handle order listing pagination change
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setPageNumber(selected_page);
    };


    /* ===============================================
                     API CALLS
  ================================================ */

    //API- Post order list 
    const _getOrderList = () => {
        let is_post = true;
        let url = 'order/list-order';
        let data = {
            "investment_type": tabIndex,
            "page_num": pageNumber,
            "payment_status": selectedPaymentFilter,
            "type": selectedOrderFilter,
            "family_detail_id": selectedMemberID
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.orders);
                setTotalPage(Math.ceil(response.data.total_orders / ITEMS_PER_PAGE));
            } else {
                setOrderData([]);
            };
            setFilterDataLoading(false);
            setDataLoading(false);
        });
    };

    //API- Get order filter data 
    const _getFilterData = () => {
        let is_post = true;
        let url = 'order/filter-data';
        let data = {
            "family_detail_id": selectedMemberID
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
            } else {
                setFilterData("");
            };
        });
    };

    return (
        <>
            <div className="min-h-100vh pb-4 pb-sm-5 flex-column row e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px pb-5">
                {/* breadcrumbs */}
                <BreadCrumbs data={BREADCRUMBS} />

                {
                    dataLoading === true ?

                        <div className="text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                        :
                        <div className='col-lg-11 px-0 padding-34px-top pb-4 pb-sm-5'>
                            <div className="d-flex e-border-bottom-2px-bright-gray">
                                {/* tab component */}
                                {
                                    ORDERS_TAB.map((data, key) => (
                                        <CustomTab key={key}
                                            tabData={data}
                                            tabIndex={tabIndex}
                                            handleChange={_handleTabChange} />
                                    ))
                                }
                            </div>

                            {/* Family select */}
                            {
                                filterData.family !== null &&
                                <div div className="my-4 pt-3 d-flex flex-wrap gap-0-10 row-gap-3">
                                    {
                                        filterData.family.map((member, i) => (
                                            <span key={i}
                                                className={`${selectedMemberID == member.id ? "e-bg-lavender-blue border-transparent" : "e-border-1px-light-gray"}  cursor-pointer d-flex align-items-center justify-content-center px-sm-4 px-3 padding-8px-tb-res padding-15px-tb e-border-radius-32 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400`}
                                                onClick={() => _handleMemberSelect(member.id)}>
                                                {member.name}
                                            </span>
                                        ))
                                    }
                                </div>
                            }


                            <div className='row mt-4 align-items-center'>
                                <div className='col'>
                                    <h4 className='e-font-14-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                        Recent orders
                                    </h4>
                                </div>
                                {/* filter funds  */}

                                <div className='col text-end position-relative'
                                    id="order-filter-modal">
                                    <div className="position-relative px-sm-4 px-3 cursor-pointer gap-0-12px e-res-gap-0-8 e-inline-flex padding-10px-bottom padding-10px-top e-bg-anti-flash-white  e-border-radius-32"
                                        onClick={_handleToggleFilter}   >
                                        <p className="e-font-14-res mb-0 e-text-dark-liver e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Filter by

                                        </p>

                                        <img src={require("../../Assets/Images/down-arrow.svg").default}
                                            draggable={false}
                                            alt="arrow" />

                                        {
                                            appliedFilters !== 0 &&
                                            <span className="d-flex align-items-center justify-content-center width-24px h-24 e-font-10 e-bg-egyptian-blue e-text-white e-alt-font-poppins e-font-weight-400 e-border-radius-50per position-absolute top-0 start-50 ms-4 mt-1 translate-middle">
                                                {appliedFilters}
                                            </span>
                                        }
                                    </div>

                                    {
                                        toggleFilter &&
                                        <>
                                            <OverLay isOpen={toggleFilter} onClose={_handleToggleFilter} />
                                            <div className="position-absolute  w-374px right-8px top-50px z-index-9 p-4 e-border-radius-24 e-bg-white e-box-shadow-filter-modal e-order-filter-modal">
                                                <OrderFilterModal data={filterData}
                                                    selectedPaymentType={selectedPaymentFilter}
                                                    selectedOrderType={selectedOrderFilter}
                                                    onSubmit={_handleFilterSubmit} />
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>

                            {/* section  */}
                            {
                                filterDataLoading ?
                                    <div className="text-center mt-5" >
                                        <img src={Loader}
                                            draggable="false"
                                            alt="loader" />
                                    </div>
                                    :
                                    <div className='row pt-4'>
                                        {
                                            orderData.length !== 0 ? (
                                                orderData.map((data, key) => (
                                                    <div key={key} className='col-xl-4 col-sm-6 col-12 mb-4' >
                                                        <OrderCard data={data} />
                                                    </div>
                                                ))
                                            ) : (

                                                <EmptyScreen
                                                    img={EmptyOrder}
                                                    title="No order yet !"
                                                    description="Currently, there are no orders to show. Start exploring our products." />
                                            )
                                        }
                                        {
                                            orderData.length !== 0 && totalPage > 1 &&
                                            <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 " >
                                                <Pagination
                                                    initialPage={pageNumber - 1}
                                                    nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                    prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    totalPage={totalPage}
                                                    onChange={_handlePagination}
                                                    showFirstLastPages={true} />
                                            </div >
                                        }
                                    </div>
                            }
                        </div>
                }

            </div >

            {/* footer */}
            < Footer />
        </>
    )
}

export default OrderListing;


