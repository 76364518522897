/*
 *   File : breadcrumbs.js
 *   Author : https://evoqins.com
 *   Description : breadcrumb component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';

//assets
import RightArrow from "../../Assets/Images/right-black-small.svg";

const BreadCrumbs = (props) => {

    const navigate = useNavigate();

    return (
        <div className="px-0 e-display-flex e-flex-wrap">
            {props.data.map((data, key) => (
                <div key={key} className='e-inline-flex'>
                    {key != props.data.length - 1 ?
                        <>
                            <a className="e-breadcrumb-name text-decoration-hover-underline cursor-pointer text-decoration-none e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px"
                                onClick={() => navigate(data.path)} >
                                {data.label}
                            </a>
                            <img src={RightArrow}
                                draggable="false"
                                className="px-xl-2 px-lg-2 px-md-2 px-sm-2 px-0"
                                alt="arrow" />
                        </>
                        :
                        <span className="e-breadcrumb-name text-decoration-none e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                            {data.label}
                        </span>
                    }
                </div>
            ))}
        </div>
    )
}
export default BreadCrumbs;