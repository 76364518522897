/*
 *   File : order-filter.js
 *   Author : https://evoqins.com
 *   Description : Order Filter modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//custom component
import { PrimaryButton } from '../Buttons';

//assets
import Close from "../../Assets/Images/close-btn.svg";

const OrderFilterModal = (props) => {

    const [selectedPayment, setSelectedPayment] = useState(props.selectedPaymentType);
    const [selectedOrder, setSelectedOrder] = useState(props.selectedOrderType);

    useEffect(() => {
        setSelectedPayment(props.selectedPaymentType);
        setSelectedOrder(props.selectedOrderType);
    }, [props.selectedPaymentType, props.selectedOrderType]);

    //handle click for filter by AMC
    const _handlePaymentFilter = (id) => {

        let updated_payment;

        if (selectedPayment.includes(id) && id !== null) {
            updated_payment = selectedPayment.filter((item) => item !== id);
        } else {
            if (id == null || (props.data.payment_status.length - 2 === selectedPayment.length)) {
                updated_payment = [null];
            } else {
                updated_payment = selectedPayment.filter((item) => item !== null);
                updated_payment = [...updated_payment, id];
            };
        };

        setSelectedPayment(updated_payment);
    };

    //handle click for category filter
    const _handleOrderFilter = (id) => {

        let updated_order;

        if (selectedOrder.includes(id) && id !== null) {
            updated_order = selectedOrder.filter((item) => item !== id);
        } else {
            if (id == null || (props.data.order_type.length - 2 === selectedOrder.length)) {
                updated_order = [null];
            } else {
                updated_order = selectedOrder.filter((item) => item !== null);
                updated_order = [...updated_order, id];
            };
        };

        setSelectedOrder(updated_order);
    };

    //Handle submit filter
    const _handleSubmitFilter = (status) => {
        if (status === false) {
            props.onSubmit(props.selectedPaymentType, props.selectedOrderType, false);
        } else {
            props.onSubmit(selectedPayment, selectedOrder, true);
        };
    };

    return (
        <div>
            <div className="row pb-3">
                <div className="col-8 text-start">
                    <h6 className="mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-32px">
                        Filter by
                    </h6>
                </div>
                <div className="col-4 text-end">
                    <img src={Close}
                        draggable="false"
                        className="cursor-pointer"
                        alt="close"
                        onClick={() => _handleSubmitFilter(false)} />
                </div>
            </div>

            {/* filter by Sort by payment status  */}
            <div className='text-start' >
                <p className="mb-0 padding-12px-bottom e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                    Sort by payment status
                </p>
                <div className="e-flex-wrap d-flex ">
                    {props.data.payment_status.map((item, key) => (
                        <p key={key} className={`${selectedPayment.includes(item.id) ? 'e-text-egyptian-blue e-bg-lavender e-font-weight-500' : 'e-bg-anti-flash-white e-font-weight-400 e-text-dark-liver'}  mb-3 margin-12px-right px-3 py-1 cursor-pointer e-border-radius-16  e-font-14 e-alt-font-poppins  line-height-24px`}
                            onClick={() => _handlePaymentFilter(item.id, item)}>
                            {item.label}
                        </p>
                    ))}
                </div>
            </div>

            {/* Filter by Order type  */}
            <div className="text-start">
                <p className="mb-0 padding-12px-bottom e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                    Order type
                </p>
                <div className="e-flex-wrap d-flex ">
                    {props.data.order_type.map((item, key) => (
                        <p key={key}
                            className={`${selectedOrder.includes(item.id) ? 'e-text-egyptian-blue e-bg-lavender e-font-weight-500' : 'e-bg-anti-flash-white cursor-pointer e-font-weight-400 e-text-dark-liver'}  mb-3 margin-12px-right px-3 py-1  e-border-radius-16  e-font-14 e-alt-font-poppins  line-height-24px`}
                            onClick={() => _handleOrderFilter(item.id)}>
                            {item.label}
                        </p>
                    ))}
                </div>
            </div>
            {/* buttons */}
            <div className="row">
                <div className="col-6">
                    <PrimaryButton name="Cancel"
                        className="padding-12px-top padding-12px-bottom w-100 w-100 e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                        onPress={() => _handleSubmitFilter(false)} />
                </div>
                <div className="col-6">
                    <PrimaryButton name="Apply"
                        className="padding-12px-top padding-12px-bottom w-100 e-font-16"
                        onPress={_handleSubmitFilter} />
                </div>
            </div>
        </div>
    );
};

export default OrderFilterModal;