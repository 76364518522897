/*
 *   File : settings.js
 *   Author : https://evoqins.com
 *   Description : settings page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import OneSignal from 'react-onesignal';

//custom component
import { OTPInput } from '../../Component/Form-Elements';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';

//custom styles
import styles from "../../Style/Component/profile.module.scss";

const Settings = () => {

    const otpInputRef = useRef(null);
    const newRef = useRef(null);
    const confirmRef = useRef(null);

    const [currentPinValue, setCurrentPinValue] = useState("");
    const [currentPinError, setCurrentPinError] = useState("");
    const [newPinValue, setNewPinValue] = useState("");
    const [confirmPinValue, setConfirmPinValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(0);
    const [focusedInputNew, setFocusedInputNew] = useState(0);
    const [focusedInputConfirm, setFocusedInputConfirm] = useState(0);
    const [confirmError, setConfirmError] = useState("");
    const [enableNotification, setEnableNotification] = useState(true);
    const initialRender = useRef(true);


    useEffect(() => {

        if (initialRender.current === false) {
            if (enableNotification === true) {
                OneSignal.User.PushSubscription.optIn();
                toast.dismiss();
                toast.success("Push notification enabled", { className: "e-toast" });
            } else {
                OneSignal.User.PushSubscription.optOut();
                toast.dismiss();
                toast.success("Push notification disabled", { className: "e-toast" });
            };
        }

    }, [enableNotification]);

    useEffect(() => {

        const status = OneSignal.User.PushSubscription.optedIn;
        setEnableNotification(status ? true : false);


        // // will be called when user changes push notification status
        OneSignal.User.PushSubscription.addEventListener("change", () => {
            const status = OneSignal.User.PushSubscription.optedIn;
            setEnableNotification(status ? true : false);
        });

    }, []);


    //handle current pin
    const _handleCurrentPin = (value) => {
        setCurrentPinValue(value);
        setCurrentPinError("");
    };

    //handle new pin 
    const _handleNewPin = (value) => {
        setNewPinValue(value);
        setConfirmError("");
    };

    //handle confirm pin 
    const _handleConfirmPin = (value) => {
        setConfirmPinValue(value);
        setConfirmError("");
    };

    //handle update new pin
    const _handleUpdatePIN = () => {

        if (currentPinValue.length === 4) {
            if (newPinValue !== confirmPinValue) {
                setConfirmError("PIN does not match");
            } else {
                setConfirmError("");
                _updatePIN();
            }
        } else {
            setCurrentPinError("Enter 4 digit current PIN");
        }
    }

    //API- Post Update PIN 
    const _updatePIN = () => {
        let is_post = true;
        let url = 'profile/update-pin';
        let data = {
            "current_pin": currentPinValue,
            "new_pin": newPinValue
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setCurrentPinValue("");
                setNewPinValue("");
                setConfirmPinValue("");

                toast.dismiss();
                toast.error("PIN Updated", {
                    type: "success",
                    className: "e-toast",

                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }
        });
    }


    return (
        <>
            <div className='e-bg-cultured e-border-radius-16 p-4'>
                <h4 className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                    Change PIN
                </h4>

                {/* current pin */}
                <div className="pt-3">
                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                        Enter your 4 digit PIN*
                    </label>
                    <div className="h-64">
                        <OTPInput id="pin"
                            ref={otpInputRef}
                            value={currentPinValue}
                            spellCheck="false"
                            focused_input={focusedInput}
                            error={currentPinError}
                            autoFocus={true}
                            onFocus={() => setFocusedInput(2)}
                            onBlur={() => setFocusedInput(0)}
                            onChange={_handleCurrentPin} />
                    </div>
                </div>

                {/* set new pin */}
                <div className="pt-1">
                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                        Set new 4 digit PIN*
                    </label>
                    <OTPInput id="pin"
                        value={newPinValue}
                        ref={newRef}
                        spellCheck="false"
                        focused_input={focusedInputNew}
                        onFocus={() => setFocusedInputNew(2)}
                        onBlur={() => setFocusedInputNew(0)}
                        onChange={_handleNewPin} />
                </div>

                {/* confirm new pin  */}
                <>
                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                        Confirm your 4 digit PIN*
                    </label>
                    <div className="h-64">
                        <OTPInput id="pin"
                            ref={confirmRef}
                            value={confirmPinValue}
                            spellCheck="false"
                            focused_input={focusedInputConfirm}
                            error={confirmError}
                            onFocus={() => setFocusedInputConfirm(2)}
                            onBlur={() => setFocusedInputConfirm(0)}
                            onChange={_handleConfirmPin} />
                    </div>
                </>

                <div className='pt-4 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6'>
                    <PrimaryButton name="Update PIN"
                        className={`${currentPinValue.length !== 4 || newPinValue.length !== 4 || confirmPinValue.length !== 4 ? `e-pointer-events-none e-bg-bright-gray` : ""}
                         w-100 padding-12px-top  padding-12px-bottom line-height-28px`}
                        onPress={_handleUpdatePIN} />
                </div>
            </div>

            {/* push notification */}
            <div className='pt-4'>
                <h4 className='mb-2 pb-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                    Push notification settings
                </h4>
                <div className='e-bg-cultured e-border-radius-16 p-3'>
                    <div className='e-display-flex justify-content-space-between'>
                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Pause all
                        </p>
                        <label className={`${styles.e_pushnotification} `}
                        // onClick={_handleTogglePushNotification}
                        >
                            <input type="checkbox"
                                checked={enableNotification}
                                onChange={(event) => {
                                    setEnableNotification(event.target.checked)
                                    initialRender.current = false;
                                }} />
                            <span className={`${styles.e_slider}`}></span>
                        </label>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Settings;