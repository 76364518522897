/*
 *   File : basket-detail.js
 *   Author : https://evoqins.com
 *   Description : Basket detail page for selected basket
 *   Integrations : react-toastify, bootstrap
 *   Version : 1.0.0
*/

import { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";


// Components
import { Footer } from "../../Component/Footer";
import { BreadCrumbs } from "../../Component/Other";
import { PrimaryButton } from "../../Component/Buttons";
import { SectionTitle } from "../../Component/Title";
import { Icon } from "../../Component/Icon";
import { APIService } from "../../Service";
import { BasketInvestModal, PaymentStatusModal, WhyInvestInBasket } from "../../Component/Modal";
import { _basketSummary, _getProfile } from "../../Helper";

//Assets
import Loader from "../../Assets/Images/coloured_loader.svg";

// Custom date 
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "Baskets",
        path: "/baskets",
    },
];

function BasketDetail() {

    const userData = useSelector(store => store.Reducer.USER_DATA);
    const location = useLocation();
    const navigate = useNavigate();
    const is_risk_assessment_done = useRef("");
    const available_investment_types = useRef({});

    const [basketId, setBasketId] = useState("")
    const [basketData, setBasketData] = useState();
    const [basketSummary, setBasketSummary] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [breadcrumbs, setBreadcrumbs] = useState(BREADCRUMBS);

    // Invest 
    const [investmentType, setInvestmentType] = useState(null);
    const [showInvestModal, setShowInvestModal] = useState();
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showWhyInvestBasketModal, setShowWhyInvestBasketModal] = useState(false);
    const [paymentModalType, setPaymentModalType] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

        if (location.state !== null) {
            setBasketId(location.state.basket_id);
        } else {
            navigate("/baskets");
        };

        _getProfile().then(response => {
            if (response.status_code === 200) {
                is_risk_assessment_done.current = response.data.risk_assessment_done;
            }
        })

    }, [location.state]);

    useEffect(() => {
        if (basketId !== "") {
            _getBasketDetails();
        }
    }, [basketId]);

    useEffect(() => {
        if (showInvestModal == true) {
            new Modal(document.getElementById('invest-basket-modal')).show();;
        };
    }, [showInvestModal]);

    useEffect(() => {
        if (showWhyInvestBasketModal == true) {
            new Modal(document.getElementById('why-invest-in-basket-modal')).show();;
        };
    }, [showWhyInvestBasketModal]);

    useEffect(() => {
        if (showPaymentModal == true) {
            new Modal(document.querySelector("#payment-status-modal")).show();
        }
    }, [showPaymentModal]);

    // Navigating to fund detail page
    const _handleOnFundClick = (id) => {
        let path = location.pathname === "/explore" ? "fund-details" : "/explore/fund-details";
        navigate(path, {
            state: {
                fund_id: id,
            }
        });
    };

    // Invest Basket
    const _handleInvestBasket = () => {

        if (is_risk_assessment_done.current === false) {
            toast.dismiss();
            toast.error("Please complete your risk profile to invest", {
                className: "e-toast",
            });

            return;
        }

        if (userData !== null && (userData.signup_progress_status == "INVESTED" || userData.signup_progress_status == "ACCOUNT_CREATED")) {

            // API call to get basket summary
            const param = {
                "basket_id": basketId,
            }

            _basketSummary(param).then(status => {
                if (status.status_code === 200) {
                    setBasketSummary(status.data);
                    setInvestmentType(available_investment_types.current.sip ? 1 : 2);
                    setShowInvestModal(true);
                } else {
                    toast.dismiss();
                    toast.error(status.message, { className: "e-toast", });
                }
            });

        } else {
            toast.dismiss();
            toast.error("Complete KYC to start with investment", {
                className: "e-toast",

            });
        };
    };

    const _handleCloseInvestModal = (type) => {

        // Closing modal
        var button = document.getElementById("basket-invest-modal-close");
        button.click();

        setShowInvestModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };
    };

    // API - Basket Details
    const _getBasketDetails = () => {
        let is_post = true;
        let url = 'baskets/detail';
        let data = {
            "basket_id": basketId,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBasketData(response.data);
                setBreadcrumbs([...BREADCRUMBS, { label: response.data.name }]);
                available_investment_types.current = {
                    sip: response.data.sip_enabled,
                    lumpsum: response.data.lumpsum_enabled,
                };
            } else {
                setBasketData([]);
            }
            setIsDataLoading(false);
        });
    };



    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion d-flex flex-column px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={breadcrumbs} />
                {isDataLoading ? (
                    <div className="min-h-100vh text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>

                ) : (
                    <div className="col-lg-11 p-0">
                        <div className="e-bg-magnolia p-3 p-sm-4 my-4 e-border-radius-16 ">
                            <div className="d-flex gap-2 align-items-center justify-content-between e-flex-wrap">
                                <div className="d-flex">
                                    <img className="img-fluid"
                                        width={48}
                                        height={48}
                                        src={basketData.icon}
                                        draggable="false"
                                        alt="book" />
                                    <div className="padding-12px-left d-flex flex-column gap-1">
                                        <h3 className="mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                            {basketData.name}
                                        </h3>
                                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-18px">
                                            {basketData.description}
                                        </p>
                                    </div>
                                </div>
                                <PrimaryButton name="Invest now"
                                    className="line-height-20px-res padding-12px-lr-res e-font-12-res padding-6px-tb-res padding-32px-lr padding-12px-tb mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                                    onPress={_handleInvestBasket} />
                            </div>
                        </div>

                        <SectionTitle title="Fund details" className={"p-0"} />

                        {/* Fund Details */}
                        <div className="row flex-column flex-md-row mt-4 p-0">
                            <div className="col-md-5 col-12 mb-sm-4  mb-md-0">
                                <div className="d-flex flex-wrap e-bg-magnolia e-border-radius-16 p-3 p-sm-4">
                                    <div className="col-12 d-flex mb-4">
                                        {
                                            basketData.lumpsum_enabled &&
                                            <div className="col-6 col-sm-8">
                                                <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                    Min investment
                                                </p>
                                                <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                    ₹{basketData.min_investment.min_invest.toLocaleString("en-IN")}
                                                </p>
                                            </div>
                                        }
                                        {
                                            basketData.sip_enabled &&
                                            <div className="col-6 col-sm-8">
                                                <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                    Min SIP
                                                </p>
                                                <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                    ₹{basketData.min_investment.min_invest_sip.toLocaleString("en-IN")}
                                                </p>
                                            </div>
                                        }
                                        {
                                            basketData.lumpsum_enabled === true && basketData.sip_enabled === true ? "" :
                                                <div className="col-6 col-sm-4">
                                                    <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                        Ideal for
                                                    </p>
                                                    <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                        {basketData.ideal_for}
                                                    </p>
                                                </div>
                                        }
                                    </div>
                                    <div className="col-6 col-sm-8">
                                        <img src={basketData.risk_icon}
                                            width={54}
                                            draggable="false"
                                            alt="risk img" />
                                    </div>
                                    <div className="col-6 col-sm-4">
                                        <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                            Avg return
                                        </p>
                                        <p className={`${basketData.returns.returns_percentage >= 0 ? "e-text-ufo-green" : "e-text-red"} mb-0 e-text-ufo-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                                            {basketData.returns.returns_percentage_text}
                                            <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400">
                                                ({basketData.returns.tenure_text})
                                            </span>
                                        </p>
                                    </div>

                                    {
                                        basketData.lumpsum_enabled === true && basketData.sip_enabled === true &&
                                        <div className="col-6 col-sm-4 mt-4">
                                            <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                Ideal for
                                            </p>
                                            <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                {basketData.ideal_for}
                                            </p>
                                        </div>
                                    }

                                </div>
                                <p className="margin-12px-top e-text-davys-grey e-font-12 e-alt-font-poppins e-font-weight-400 line-height-16px" >
                                    {basketData.returns_text}
                                </p>
                            </div>

                            {/* Why invest */}
                            <div className="col-md-7 col-12 mt-1 mt-md-0">
                                <div className="basket-why-invest-bg p-3 p-sm-4 mb-4 mt-4 mt-sm-0 e-border-radius-16">
                                    <h2 className="e-font-16-res e-text-white e-alt-font-poppins e-font-22 e-font-weight-600 line-height-26px mb-0 mb-sm-2">
                                        {basketData.why_invest.title}
                                    </h2>
                                    <p className="e-font-14-res e-text-lotion e-alt-font-poppins e-font-16 e-font-weight-500 line-height-24px mb-2 mb-sm-4">
                                        {basketData.why_invest.description}
                                    </p>
                                    <PrimaryButton name="View more"
                                        onPress={() => setShowWhyInvestBasketModal(true)}
                                        className="line-height-20px-res e-font-12-res padding-6px-tb-res padding-12px-tb padding-12px-lr-res padding-32px-lr e-bg-lotion e-font-16 e-text-charleston-green " />
                                </div>
                            </div>
                        </div>

                        {/* Fund allocations */}
                        <div className="row p-0 mb-5 ">
                            <div className="order-sm-1 order-2 mb-4 col-md-7 col-sm-12 col-12">
                                <SectionTitle title="Mutual funds & allocations" className={"pt-4 p-sm-0"} />
                                <div className="col e-over-flow-auto e-manage-scrollbar e-bg-cultured e-border-radius-16 p-3 p-sm-4 mt-4">
                                    <div className="e-width-540px-res row pe-md-4 pe-xl-0">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between mb-3 mb-sm-4">
                                                <span className="col-8 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                    Fund Name
                                                </span>
                                                <span className="col-2 text-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                    Allocation
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            {basketData.scheme_allocation.map((item, key) => (
                                                <div className={`${key == basketData.scheme_allocation.length - 1 ? "" : "mb-3"} d-flex justify-content-between align-items-center`}
                                                    key={key}>
                                                    <span className="e-font-14-res col-8 cursor-pointer e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px letter-spacing-neg-2"
                                                        onClick={() => _handleOnFundClick(item.mf_id)}>
                                                        {item.scheme_name}
                                                    </span>
                                                    <span className="e-font-14-res col-2  text-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px letter-spacing-neg-2">
                                                        {item.percentage}%
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-sm-2 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <SectionTitle title="Market cap breakdown chart" className={"p-0"} />
                                <div className="e-bg-cultured e-border-radius-16 p-3 p-sm-4 mt-4">
                                    <p className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                        *The estimated returns are determined by analyzing the historical returns of the mutual funds.
                                    </p>
                                    <div className="d-flex justify-content-between">
                                        <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                            0%
                                        </span>
                                        <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                            100%
                                        </span>
                                    </div>
                                    {/* Market cap breakdown */}
                                    <div className="height-40px-res progress-stacked e-border-radius-12 h-48 mt-2 mb-4">
                                        {basketData.asset_allocation.map((item, key) => (
                                            <div style={{ width: `${item.percentage}%` }}
                                                className="progress h-100"
                                                role="progressbar"
                                                key={key} >
                                                <div style={{ backgroundColor: item.color }}
                                                    className="progress-bar"></div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="d-flex flex-column gap-3 gap-sm-4">
                                        <div className="d-flex">
                                            <span className="col-6 col-md-7 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                Name
                                            </span>
                                            <span className="col-6 col-md-2 text-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                Allocation
                                            </span>
                                        </div>

                                        {basketData.asset_allocation.map((item, key) => (
                                            <div className="d-flex align-items-center" key={key}>
                                                <span className="d-flex align-items-center gap-2 e-font-14-res col-6 col-md-7  e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    <Icon icon="bullet"
                                                        color={item.color}
                                                        size={"1rem"} />
                                                    {item.asset_class}
                                                </span>
                                                <span className="e-font-14-res col-6 col-md-2 text-center align-self-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    {item.percentage}%
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
                }
                {
                    showInvestModal &&
                    <BasketInvestModal basketSummary={basketSummary}
                        availableInvestmentTypes={available_investment_types.current}
                        basketId={basketId}
                        investmentType={investmentType}
                        onCloseModal={_handleCloseInvestModal}
                    />
                }
                {
                    showPaymentModal &&
                    <PaymentStatusModal type={paymentModalType}
                        investmentType="Basket"
                        onClose={(() => setShowPaymentModal(false))} />
                }
                {
                    showWhyInvestBasketModal &&
                    <WhyInvestInBasket data={basketData.why_invest}
                        onClose={(() => setShowWhyInvestBasketModal(false))} />
                }
            </div >
            <Footer />
        </>
    );
};

export default memo(BasketDetail);