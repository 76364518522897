/*
 *   File : signup.js
 *   Author : https://evoqins.com
 *   Description : SignUp page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { isValidPhoneNumber } from "react-phone-number-input";


//custom components
import APIService from "../../Service/service";
import { CustomPhoneInput, CustomTextInput, OTPInput } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import { Header } from "../../Component/Navigation";
import { Footer } from "../../Component/Footer";
import Store from "../../Store/store";
import { Icon } from "../../Component/Icon";

//custom styles
import styles from "../../Style/Component/login.module.scss";

const SignUp = () => {

    const inputRef = useRef(null); // Enter PIN
    const confirmRef = useRef(null); // Re-Enter PIN
    const navigate = useNavigate();
    const { state } = useLocation();

    // Updated Sign up flow
    // Step 1 => Taking user name email and phone
    // Step 2 => OTP verification 
    // Step 3 => Set pin 

    const [currentStep, setCurrentStep] = useState(1);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(state && state.phone_number ? state.phone_number : "");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");


    // OTP States
    const mobileOTPInputRef = useRef(null);
    const emailOTPInputRef = useRef(null);
    const [mobileOTP, setMobileOTP] = useState("");
    const [emailOTP, setEmailOTP] = useState("");
    const [focusMobileOTPInput, setFocusMobileOTPInput] = useState(2);
    const [focusEmailOTPInput, setFocusEmailOTPInput] = useState(0);

    // Set PIN
    const [focusedInputNew, setFocusedInputNew] = useState(2);
    const [focusedInputConfirm, setFocusedInputConfirm] = useState(0);
    const [newPinValue, setNewPinValue] = useState("");
    const [confirmNewPinValue, setConfirmNewPinValue] = useState("");
    const [pinError, setPinError] = useState("");

    // Others
    const [timer, setTimer] = useState(0);
    const [selectedCountryCode, setSelectedCountryCode] = useState(state && state.country_code ? state.country_code : "+91");
    const [selectedCountryLocale, setSelectedCountryLocale] = useState(state && state.country_locale ? state.country_locale : "IN");
    const [isIndianUser, setIsIndianUser] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP
    const [refresToken, setRefreshToken] = useState(null);    // refresh token


    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    //for timer
    useEffect(() => {
        // Status 2 => OTP verification , Resend OTP
        if (currentStep === 2 && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [currentStep, timer]);

    useEffect(() => {
        if (state != null) {
            if (state.phone_number) {
                setPhoneNumber(state.phone_number);
            }

            if (state.country_code) {
                setSelectedCountryCode(state.country_code);
            }

            if (state.country_locale) {
                setSelectedCountryLocale(state.country_locale);
            }

            if (state.email) {
                setEmail(state.email);
            }
        }

        // When user leaves the page in set pin step
        // And comeback and try to login without setting pin
        if (state !== null && state.auth_configured === false) {
            setCurrentStep(3);
        }

        if (state !== null && state.refresh_token) {
            setRefreshToken(state.refresh_token);
        }

    }, [state]);

    // Signup when user click enter
    useEffect(() => {

        const _handleOnEnter = (e) => {
            if (e.key === 'Enter') {
                if (currentStep === 1) {
                    _handleGetSignUpOtp();
                } else if (currentStep === 2) {
                    _handleVerifyOtp();
                } else {
                    _handleVerifyPin();
                }
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };

    }, [currentStep, name, phoneNumber, email, mobileOTP, emailOTP, newPinValue, confirmNewPinValue]);

    useEffect(() => {
        setPinError("");
    }, [newPinValue, confirmNewPinValue]);

    //handle change name
    const _handleNameChange = (value) => {
        setName(value);
        setNameError("");
    };

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    // handle phone number change
    const _handlePhoneNumberChange = (value, selected_country_code, country_locale) => {
        setPhoneNumber(value);
        setPhoneNumberError("");
        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }

        if (country_locale) {
            setSelectedCountryLocale(country_locale);
        }
    };

    //handle submit otp
    const _handleChangeMobileOTP = (value) => {
        setMobileOTP(value);
        if (value.length == 4) {
            // mobileOTPInputRef.current.blur();
            setFocusEmailOTPInput(2);
        }
    };

    //handle submit otp
    const _handleChangeEmailOTP = (value) => {
        setEmailOTP(value)
    };

    //handle get Signup OTP
    const _handleGetSignUpOtp = () => {

        let validation = true;

        // valid phone number and email pattern 
        let valid_phone = /^[6-9]\d{9}$/;
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        // Validating phone
        if (selectedCountryCode == "+91") {

            // removing country code
            let phone_number = Number(phoneNumber.replace(selectedCountryCode, ""));

            if (!valid_phone.test(phone_number)) {
                setPhoneNumberError("Please enter a valid 10-digit phone number");
                validation = false;
            }
        } else {
            setIsIndianUser(false);
            let is_valid_number = isValidPhoneNumber(String(phoneNumber));
            if (is_valid_number === false) {
                setPhoneNumberError("Please enter a valid number");
                validation = false;
            }
        }

        // Validating email
        if (!validate_email.test(email)) {
            setEmailError("Please enter a valid email id");
            validation = false;
        }

        if (validation === true) {
            setLoading(true);
            setTimer(30);
            // API call
            _getOTP();
        }
    };

    // handle resend otp
    const _handleResendOTP = () => {
        let resend = true;
        setDisableResendOTP(true);
        // API call
        _getOTP(resend);
    };

    //handle new pin
    const _handleNewPin = (value) => {
        setNewPinValue(value);
        if (value.length == 4) {
            setFocusedInputConfirm(2);
            inputRef.current.blur();
        }
    };

    //handle confirm new pin
    const _handleConfirmNewPin = (value) => {
        setConfirmNewPinValue(value);
    };

    //handle for login otp verify
    const _handleVerifyOtp = () => {
        _verifyOTP();
    };

    //handle back to login or register page
    const _handleBack = () => {
        setCurrentStep((current_step) => current_step - 1);
        setTimer(0);
    };


    // create account pin
    const _handleVerifyPin = () => {
        if (newPinValue !== confirmNewPinValue) {
            setPinError("PIN does not match");
            setLoading(false);
        } else {
            setPinError("");
            setLoading(true);
            _setPin();
        }
    };



    // ================================ //
    //============= API CALLS ========= //

    // API - get sign-in otp
    const _getOTP = (resend) => {

        let is_post = true;
        let url = 'auth/get-signin-otp';
        let data = {
            "name": name,
            "country_code": selectedCountryCode,
            "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
            "email": email,
            "type": resend === true ? "RESEND" : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setCurrentStep(2);
                setMobileOTP("");
                setEmailOTP("");
                setTimer(30);

                if (response.data.mobile_otp) {
                    setFocusMobileOTPInput(2);
                } else {
                    setFocusEmailOTPInput(2);
                }


                if (resend === true) {
                    toast.dismiss();
                    toast.success("OTP sent successfully", {
                        className: "e-toast",
                    });
                }
            } else {
                if (response.status_code === 400 && response?.data?.flow === "LOGIN") {

                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                        className: "e-toast",

                    });

                    if (state.phone_number) {
                        navigate("/login", {
                            state: {
                                email: email,

                            }
                        });
                    } else {
                        navigate("/login", {
                            state: {
                                phone_number: phoneNumber,
                                country_code: selectedCountryCode,
                                country_locale: selectedCountryLocale
                            }
                        });
                    }


                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                        className: "e-toast",

                    });
                }

            }
            setLoading(false);
            setDisableResendOTP(false);
        });
    };

    // API - Verify otp
    const _verifyOTP = () => {

        setLoading(true);

        let is_post = true;
        let url = 'auth/verify-otp';
        let data = {
            "type": "SIGNUP",

        };

        if (isIndianUser) {
            data = {
                ...data,
                "country_code": selectedCountryCode,
                "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
                "phone_otp": mobileOTP,
                "email": email,
                "email_otp": emailOTP,
            }
        } else {
            data = {
                ...data,
                "email": email,
                "email_otp": emailOTP,
            }
        }



        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRefreshToken(response.data.refresh_token); //update refresh token
                setCurrentStep(3);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }

            setLoading(false);
        });
    };

    // API - Set new pin
    const _setPin = () => {
        // let refresh_token = null;
        let is_post = true;
        let url = 'auth/set-pin';
        let data = {
            "pin": newPinValue
        };
        let token = refresToken;
        APIService(is_post, url, data, token).then((response) => {
            if (response.status_code === 200) {

                Cookies.set('refresh_token', response.data.refresh_token, { domain: process.env.REACT_APP_DOMAIN, path: '/', expires: 30 }); //todo

                Store.dispatch({
                    type: 'UPDATE_ACCESS_TOKEN',
                    payload: response.data.access_token
                })

                navigate("/unlock", {
                    state: {
                        refresh_token: token,
                        user_name: name
                    }
                });

                setRefreshToken(null);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }

            setLoading(false);
        });
    };

    return (
        <>
            <section className="e-login-gradient">
                {/* header  */}
                <Header />
                <div className="container px-4">
                    {/* signup  */}
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="e-padding-block-40px-res padding-76px-top padding-150px-bottom position-relative">
                                <div className={`${styles.e_login_wrapper} e-border-radius-32`}>
                                    <div className="row">
                                        <div className="col-md-5 me-5 d-md-block d-none mt-auto">
                                            <h3 className="mb-0 e-text-charleston-green e-font-24 e-alt-font-poppins e-font-weight-600 line-height-40px letter-spacing-neg-2per">
                                                Invest with <br />
                                                <span className="e-font-32" >
                                                    Confidence
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12 pe-sm-0">
                                            <div className="gradient-vr-line ps-md-4">
                                                {/* signup section  */}
                                                {
                                                    // Sign up method
                                                    currentStep === 1 ?
                                                        <div>
                                                            <Icon icon="back-icon"
                                                                className="cursor-pointer mb-3"
                                                                size={32}
                                                                onClick={() => navigate("/login")} />
                                                            <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                Welcome
                                                            </h3>
                                                            <p className="mb-0 padding-20px-bottom e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                                Start your wealth creation journey by
                                                                creating your account
                                                            </p>
                                                            <div className="padding-20px-bottom">
                                                                <CustomTextInput label="Name(As per PAN card)"
                                                                    placeholder="Joseph Thomas"
                                                                    className={name ? `e-text-input-padding w-100 ` : `e-text-input-padding w-100`}
                                                                    type="text"
                                                                    style="name"
                                                                    value={name}
                                                                    require
                                                                    errorMsg={nameError}
                                                                    handleChange={_handleNameChange} />
                                                            </div>
                                                            <label className={`e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                                                                Phone Number
                                                                <span className='e-text-sunset-orange'>*</span>
                                                            </label>
                                                            <div className="">
                                                                <CustomPhoneInput
                                                                    placeholder="9876543210"
                                                                    className={phoneNumber ? `e-number-input-padding w-100 ` : `e-number-input-padding w-100`}
                                                                    type="text"
                                                                    value={phoneNumber}
                                                                    selectedCountryCode={selectedCountryCode}
                                                                    selectedCountryLocale={selectedCountryLocale}
                                                                    require
                                                                    error={phoneNumberError}
                                                                    handleChange={_handlePhoneNumberChange} />
                                                            </div>
                                                            <div className='h-100px'>
                                                                <CustomTextInput label="Email"
                                                                    placeholder="josephthomas@gmail.com"
                                                                    type="text"
                                                                    value={email}
                                                                    require
                                                                    errorMsg={emailError}
                                                                    className="w-100 ps-3 padding-14px-top padding-14px-bottom"
                                                                    handleChange={_handleEmailChange} />
                                                            </div>

                                                            <PrimaryButton name="Continue"
                                                                loading={loading}
                                                                disabled={loading}
                                                                className={`${(phoneNumber && email.length !== 0 && name.length !== 0) ? "" : "e-bg-bright-gray e-cursor-none pe-none"} padding-12px-top padding-12px-bottom e-font-16 mt-3 w-100`}
                                                                onPress={_handleGetSignUpOtp} />

                                                        </div>
                                                        :

                                                        // OTP verification
                                                        currentStep === 2 ?
                                                            <div>
                                                                <Icon icon="back-icon"
                                                                    className="cursor-pointer mb-3"
                                                                    size={32}
                                                                    onClick={_handleBack} />

                                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                    OTP Verification
                                                                </h3>
                                                                <p className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                    Enter the 4-digit OTP received on your email {isIndianUser && " and mobile number"}
                                                                </p>

                                                                {/* Phone OTP */}
                                                                {/* Showing OTP input to indian users only */}
                                                                {
                                                                    isIndianUser &&
                                                                    <div>
                                                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                            Enter your Mobile OTP*
                                                                        </label>
                                                                        <OTPInput ref={mobileOTPInputRef}
                                                                            id="mobile-otp"
                                                                            value={mobileOTP}
                                                                            spellcheck="false"
                                                                            focused_input={focusMobileOTPInput}
                                                                            onFocus={() => setFocusMobileOTPInput(2)}
                                                                            onBlur={() => setFocusMobileOTPInput(0)}
                                                                            onChange={_handleChangeMobileOTP} />
                                                                    </div>
                                                                }

                                                                {/* Email OTP */}
                                                                <div>
                                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                        Enter your Email OTP*
                                                                    </label>
                                                                    <OTPInput ref={emailOTPInputRef}
                                                                        id="email-otp"
                                                                        value={emailOTP}
                                                                        spellcheck="false"
                                                                        focused_input={focusEmailOTPInput}
                                                                        onFocus={() => setFocusEmailOTPInput(2)}
                                                                        onBlur={() => setFocusEmailOTPInput(0)}
                                                                        onChange={_handleChangeEmailOTP} />
                                                                </div>

                                                                {/* Resend OTP */}
                                                                <div className='e-inline-flex flex-wrap gap-2 pt-2 e-text-charleston-green e-font-14 e-alt-font-poppins line-height-18px'>
                                                                    <span className="e-font-weight-500 ">
                                                                        Didn't get the OTP?
                                                                    </span>
                                                                    <button className={`${timer !== 0 ? "e-disable e-text-dark-liver" : "e-text-egyptian-blue"} px-0 d-flex gap-1 border-0 e-bg-transparent e-font-weight-500`}
                                                                        disabled={disableResendOTP}
                                                                        onClick={_handleResendOTP} >
                                                                        Resend OTP
                                                                        {timer !== 0 &&
                                                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-weight-500'>
                                                                                in {timer} {timer == 1 ? "second" : "seconds"}
                                                                            </p>
                                                                        }
                                                                    </button>
                                                                </div>

                                                                <div className='mt-4'>
                                                                    {(isIndianUser && mobileOTP.length === 4 && emailOTP.length === 4) || (isIndianUser === false && emailOTP.length === 4) ?
                                                                        <PrimaryButton
                                                                            name={"Continue"}
                                                                            loading={loading}
                                                                            disabled={loading}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-font-16"
                                                                            onPress={_verifyOTP} />
                                                                        :
                                                                        <PrimaryButton
                                                                            name="Continue"
                                                                            disabled={true}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-bg-bright-gray e-font-16 e-cursor-none" />
                                                                    }
                                                                    {/* <p className="mb-2 pt-2 e-text-slate-gray e-font-12 e-alt-font-poppins e-font-weight-400 line-height-22px">
                                                                                I agree to the
                                                                                <a href="https://indexfundssahihai.com/terms"
                                                                                    target="_blank"
                                                                                    className="px-1 text-decoration-none text-decoration-hover-underline">
                                                                                    terms and conditions
                                                                                </a>
                                                                                and
                                                                                <a href="https://indexfundssahihai.com/privacy-policy"
                                                                                    target="_blank"
                                                                                    className="px-1 text-decoration-none text-decoration-hover-underline">
                                                                                    privacy policy
                                                                                </a>
                                                                                by verifying the OTP.
                                                                            </p> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            // create security pin 
                                                            <div>
                                                                <Icon icon="back-icon"
                                                                    className="cursor-pointer mb-3"
                                                                    size={32}
                                                                    onClick={_handleBack} />
                                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                    Create PIN
                                                                </h3>
                                                                <p className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                    Set a 4 digit PIN to keep our investments
                                                                    safe & secure
                                                                </p>
                                                                <>
                                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                        Enter PIN
                                                                    </label>
                                                                    <OTPInput id="newPin"
                                                                        ref={inputRef}
                                                                        value={newPinValue}
                                                                        spellcheck="false"
                                                                        focused_input={focusedInputNew}
                                                                        onFocus={() => setFocusedInputNew(2)}
                                                                        onBlur={() => setFocusedInputNew(0)}
                                                                        onChange={_handleNewPin} />
                                                                </>
                                                                <div className="">
                                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                        Re-enter PIN
                                                                    </label>
                                                                    <div className="h-72">
                                                                        <OTPInput
                                                                            id="confirmNewPin"
                                                                            ref={confirmRef}
                                                                            value={confirmNewPinValue}
                                                                            spellcheck="false"
                                                                            focused_input={focusedInputConfirm}
                                                                            error={pinError}
                                                                            onFocus={() => setFocusedInputConfirm(2)}
                                                                            onBlur={() => setFocusedInputConfirm(0)}
                                                                            onChange={_handleConfirmNewPin} />
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2">
                                                                    <PrimaryButton
                                                                        name={`Get strated`}
                                                                        loading={loading}
                                                                        disabled={loading}
                                                                        className={newPinValue.length === 4 && confirmNewPinValue.length === 4 ?
                                                                            `w-100 padding-12px-top padding-12px-bottom e-font-16` :
                                                                            `e-bg-bright-gray e-cursor-none w-100 padding-12px-top padding-12px-bottom e-font-16`}
                                                                        onPress={_handleVerifyPin}
                                                                    />
                                                                </div>
                                                            </div>
                                                }
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* footer section  */}
            <div div className="e-bg-anti-flash-white" >
                <Footer auth={true} />
            </div >
        </>
    )
}
export default SignUp;