/*
 *   File : primary.js
 *   Author : https://evoqins.com
 *   Description : CTA component
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Assets
import Loader from "../../Assets/Images/btn-loader.gif";

function PrimaryButton(props) {
    return (
        <button className={`${props.className} ${props.disabled == true ? "cursor-default opacity-7" : "cursor-pointer"}  e-border-radius-24 e-bg-egyptian-blue border-transparent e-text-white e-alt-font-poppins e-font-weight-600 line-height-24px e-btn-custom-wrapper no-outline`}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-cancel={props.cancel}
            data-bs-dismiss={props.dismiss}
            disabled={props.disabled}
            id={props.id}
            onClick={(e) => props.onPress(e)}>
            {
                props.loading ? (
                    <img src={Loader}
                        width={22}
                        height={22}
                        className='object-fit-contain'
                        draggable="false" />
                ) : (props.name)
            }
        </button>
    )
};

PrimaryButton.defaultProps = {
    onPress: () => { },
    className: null,
    id: null,
    name: null,
    icon: null
}

export default PrimaryButton;

