/*
 *   File : verify-email.js
 *   Author : https://evoqins.com
 *   Description : verify email and otp modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryButton } from '../Buttons';
import { CustomTextInput, OTPInput } from '../Form-Elements';
import { APIService } from '../../Service';
import { _getSignupProgress } from '../../Helper';

//styles
import styles from "../../Style/Component/modal.module.scss";

//Assets
import CloseBtn from "../../Assets/Images/close-btn.svg";

const VerifyEmailModal = (props) => {

    const inputRef = useRef(null);

    const [email, setEmail] = useState(props.email);
    const [emailError, setEmailError] = useState("");

    const [verifyToggle, setVerifyToggle] = useState(false);

    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");

    const [focusedInput, setFocusedInput] = useState(0);
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP

    //for timer
    useEffect(() => {
        if (verifyToggle === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [verifyToggle, timer]);

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    //handle verify otp
    const _handleGetOTP = () => {
        // email validation 
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (validate_email.test(email)) {
            setTimer(30);
            setOtpValue("");
            setOtpError("");
            setLoading(true);
            _getOTP();
        } else {
            setEmailError("Please enter a valid email id");
        }
    }

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // handle OTP resend
    const _handleOTPResend = () => {
        setDisableResendOTP(true);
        let resend = true;
        _getOTP(resend);
    };

    //handle modal close
    const _handleEmailClose = () => {
        //handle modal close
        var button = document.getElementById("verify-email-modal-close");
        button.click();
    };


    //handle verify otp
    const _handleVerifyOTP = () => {
        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
        } else {
            setLoading(true);
            _verifyOTP();
        }
    };

    //API- get OTP
    const _getOTP = (resend) => {

        let is_post = true;
        let url = 'kyc/send-otp';
        let data = {
            "email": email,
            "type": resend ? "RESEND" : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setVerifyToggle(true);
                setOtpError("");
                setTimer(30);
                setOtpValue("");
                if (resend === true) {
                    toast.dismiss();
                    toast.success("OTP sent successfully to " + email, {
                        className: "e-toast",
                    });
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }
            setLoading(false);
            setDisableResendOTP(false);
        });
    }

    //API- verify OTP
    const _verifyOTP = () => {
        let is_post = true;
        let url = 'kyc/verify-email-otp';
        let data = {
            "email_otp": otpValue,
            "email": email,
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _getSignupProgress();
                toast.dismiss();
                toast.success("Email verified successfully", {
                    className: "e-toast",
                });
                props.onUpdateStep(2);
                //handle modal close
                _handleEmailClose();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }

            setLoading(false);
        });
    };

    return (
        <div className={`modal fade ${styles.e_verify_modal}`}
            id="verify-email-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="verifyEmailModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered ${styles.e_modal_dialog}`}>
                <div className="modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow overflow-hidden">
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Verify email
                        </h6>

                        <img src={CloseBtn}
                            draggable={false}
                            id="verify-email-modal-close"
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onCloseModal} />
                    </div>
                    <div className="modal-body pb-4 px-4 ">
                        {verifyToggle === false ?
                            <>
                                <p className='e-kyc-email-des mb-2  e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px '>
                                    Email verification is required to receive updates on the order status.
                                </p>
                                <div className='h-100px'>
                                    <CustomTextInput label="Enter email*"
                                        placeholder="josephthomas@gmail.com"
                                        type="text"
                                        value={email}
                                        errorMsg={emailError}
                                        className="w-100 ps-3 padding-14px-top padding-14px-bottom"
                                        handleChange={_handleEmailChange} />
                                </div>
                                {/* custom buttons */}
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <PrimaryButton name="Back"
                                            className="w-100 e-font-16 e-bg-lotion e-border-bright-gray e-text-charleston-green padding-12px-top padding-12px-bottom"
                                            dismiss="modal"
                                            onPress={props.onCloseModal} />
                                    </div>
                                    <div className='col-6'>
                                        <PrimaryButton name="Verify OTP"
                                            loading={loading}
                                            disabled={loading}
                                            className="w-100 e-font-16 padding-12px-top padding-12px-bottom"
                                            onPress={_handleGetOTP} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='e-bg-cultured px-4 pt-3 pb-4 e-border-radius-16'>
                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        OTP received on Email ID*
                                    </label>
                                    <div className='h-64'>
                                        <OTPInput ref={inputRef}
                                            id="otp"
                                            autoFocus={true}
                                            value={otpValue}
                                            spellCheck="false"
                                            focused_input={focusedInput}
                                            error={otpError}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                    </div>
                                    {/* timer */}
                                    <div className='e-inline-flex pt-1'>
                                        <button className={`${timer !== 0 && " e-disable"} ps-0 border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                            disabled={disableResendOTP}
                                            onClick={_handleOTPResend} >
                                            Re-send OTP
                                        </button>
                                        {timer !== 0 &&
                                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                in {timer} {timer == 1 ? "second" : "seconds"}
                                            </p>
                                        }
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>
                                {/* custom buttons */}
                                <div className='row pt-4'>
                                    <div className='col-6'>
                                        <PrimaryButton name="Back"
                                            className="w-100 e-bg-lotion e-border-bright-gray e-text-charleston-green padding-12px-top padding-12px-bottom"
                                            onPress={() => setVerifyToggle(false)} />
                                    </div>
                                    <div className='col-6'>
                                        <PrimaryButton name="Continue"
                                            loading={loading}
                                            disabled={loading}
                                            className="w-100 padding-12px-top padding-12px-bottom"
                                            onPress={_handleVerifyOTP} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailModal;