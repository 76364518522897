/*
 *   File : notifications.js
 *   Author : https://evoqins.com
 *   Description : Notification page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Footer } from '../../Component/Footer';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';
import { Icon } from '../../Component/Icon';
import { BreadCrumbs, EmptyScreen } from "../../Component/Other";
import { Pagination } from '../../Component/Pagination';
import { _getNotificationCount } from '../../Helper';

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyNotification from "../../Assets/Images/EmptyScreen/no-notification.webp";
import NextIcon from "../../Assets/Images/right-black-icon.svg";


// Custom data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "Notifications",
    },
];

const ITEMS_PER_PAGE = 15;

const Notifications = () => {

    const navigate = useNavigate();
    const [allNotification, setAllNotification] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    // const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        _notificationRead();

    }, []);

    useEffect(() => {
        _getAllNotification();
    }, [pageNumber]);

    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setPageNumber(selected_page);
    };

    const _handleNotificationClick = (type, order_id, is_goal) => {

        _notificationRead();

        if (type == 1) {
            navigate("/orders/order-detail", {
                state: {
                    order_id: order_id,
                    is_goal: is_goal,
                }
            });
        };
    };


    // API - Get all notification
    const _getAllNotification = () => {
        let is_post = true;
        let url = "notification/list";
        let data = {
            "page_num": pageNumber
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setAllNotification(response.data);
                // setTotalPage(Math.ceil(response.data.total_notifications / ITEMS_PER_PAGE));
            } else {
                setAllNotification([]);
            }
            setIsDataLoading(false);
        });
    };

    // API- Read all notification
    const _notificationRead = () => {
        let is_post = true;
        let url = "notification/read";
        let data = {
            "notification_id": null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status_code === 200) {
                    _getNotificationCount();
                };
            } else {

            }
        });
    };

    return (
        <>
            <div className={`min-h-100vh row flex-column e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top  e-border-radius-TL-BL-24px`}>
                <BreadCrumbs data={BREADCRUMBS} />



                {
                    isDataLoading ? (
                        <div className="min-h-100vh  text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                    ) : (
                        <div className='col-lg-11 p-0'>

                            {
                                allNotification.length > 0 ? (
                                    <div className='pt-4 padding-68px-bottom px-0'>
                                        <h3 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            All notifications
                                        </h3>

                                        <div className='row'>
                                            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-10 col-12 '>
                                                {
                                                    allNotification.map((item, key) => (
                                                        <div key={key}>
                                                            <h6 className='e-font-14-res my-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                                {item.title}
                                                            </h6>

                                                            {/* List of Notifications */}
                                                            <div className='e-border-radius-16 e-bg-cultured '>
                                                                {
                                                                    item.data.map((data, key) => (
                                                                        <div className={`${item.data.length - 1 !== key ? "e-border-bottom-1px-light-gray-opa-05" : ""} 
                                                                ${data.notification_type === 1 && data.is_general === false ? "cursor-pointer" : ""} hover-arrow-wrapper  box-shadow-none e-display-flex gap-3 p-3`}
                                                                            onClick={() => _handleNotificationClick(data.notification_type, data.extra_data, data.is_goal)}
                                                                            key={key}>
                                                                            <div className='position-relative align-self-start'>
                                                                                <img src={data.image}
                                                                                    draggable={false}
                                                                                    width={47}
                                                                                    height={47}
                                                                                    className='object-fit-contain e-bg-white p-1 e-border-radius-10'
                                                                                    alt='icon' />
                                                                                {
                                                                                    data.is_read == false &&
                                                                                    <img src={require("../../Assets/Images/Notification/active.svg").default}
                                                                                        draggable={false}
                                                                                        className='position-absolute right-0px bottom-4px'
                                                                                        alt='icon' />
                                                                                }
                                                                            </div>
                                                                            <div className='w-100'>
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <p className='e-font-14-res line-height-20px-res mb-2 mb-sm-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                                                        {data.title}
                                                                                    </p>
                                                                                    {
                                                                                        data.is_general === false &&
                                                                                        <Icon icon="right-black-icon" className="hover-arrow transition w-32px width-24px-res " />
                                                                                    }
                                                                                </div>
                                                                                <p className='e-font-12-res line-height-18px-res mb-0 e-text-onyx e-font-14 e-primary-font-inter e-font-weight-400 line-height-24px'>
                                                                                    {data.message}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {/* {
                                                totalPage > 1 &&
                                                <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 " >
                                                    <Pagination
                                                        initialPage={pageNumber - 1}
                                                        nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                        prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                        itemsPerPage={ITEMS_PER_PAGE}
                                                        totalPage={totalPage}
                                                        onChange={_handlePagination}
                                                        showFirstLastPages={true} />
                                                </div >
                                            } */}
                                            </div>
                                            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-10 col-12  padding-56px-top text-center'>
                                                <div className='e-bg-nyanza p-3 e-border-radius-16'>
                                                    <img src={require("../../Assets/Images/Notification/money.svg").default}
                                                        draggable={false}
                                                        alt='money' />

                                                    <h6 className='mb-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-28px'>
                                                        Looking to grow your wealth effortlessly
                                                    </h6>

                                                    <p className='offset-1 col-10 mb-4 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        There is nothing to show right now
                                                    </p>

                                                    <div className='offset-2 col-8'>
                                                        <PrimaryButton name="Invest now" onPress={() => navigate("/explore")}
                                                            className="py-2 w-100 e-primary-button-hover transition" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                ) : (

                                    <EmptyScreen
                                        img={EmptyNotification}
                                        title="No notification yet !"
                                        description="Stay tuned for latest updates and notifications." />
                                )}
                        </div>
                    )
                }
                {/* footer  */}
            </div>
            < Footer />
        </>
    )
}

export default Notifications;