/*
 *   File : date-range-picker.js
 *   Author : https://evoqins.com
 *   Description : Date picker component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { Fragment, useEffect, useState } from 'react'
import { addDays, endOfDay, isSameDay, startOfDay, subDays, subMonths } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Icon } from '../Icon';
import { DateRangePicker } from 'react-date-range';

function CustomDateRangePicker({ initialRange, onUpdate, cancel, minDate, maxDate, leftAlign }) {

    const [selectionRange, setSelectionRange] = useState(initialRange);


    useEffect(() => {
        const react_calendar = document.querySelector("#range-picker");
        react_calendar.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    const _handleSelect = (ranges) => {
        setSelectionRange([ranges.selection]);
    };

    const _handleCancel = () => {
        cancel();
    }

    const _handleUpdate = () => {
        onUpdate(selectionRange);
    }

    // Modify the labels of the defaultStaticRanges
    const customStaticRanges = [
        {
            label: "Last 1 Week",
            range: () => ({
                startDate: startOfDay(subDays(new Date(), 6)), // 6 days ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
        {
            label: "Last 1 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 1)), // 1 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        }, {
            label: "Last 3 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 3)), // 3 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
        {
            label: "Last 6 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 6)), // 6 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
    ]

    const customNavigatorRenderer = (focusedDate, changeShownDate, props) => {
        const { showMonthArrow } = props;

        return (
            <div className="w-100 position-absolute e-custom-date-navigator">
                {
                    showMonthArrow ? (
                        <button
                            type="button"
                            className="e-left-icon"
                            onClick={() => changeShownDate(-1, 'monthOffset')}>
                            <i>
                                <Icon icon="left-black-icon"
                                    size={16} />
                            </i>
                        </button>
                    ) : null
                }
                {/* Add your custom month and year picker here */}
                {showMonthArrow ? (
                    <button
                        type="button"
                        className="e-right-icon"
                        onClick={() => changeShownDate(1, 'monthOffset')}
                    >
                        <i>
                            <Icon icon="right-black-icon"
                                size={16} />
                        </i>
                    </button>
                ) : null}
            </div>
        );
    };


    return (
        <Fragment>
            <div id='range-picker'
                className={`position-absolute z-index-3 mt-3`}>
                <div id=''
                    className={`mb-4 bg-white border-radius-8px e-filter-date-picker ${leftAlign === true && 'left-0'}`}>
                    <div id='range-picker-inner-wrapper'>


                        <DateRangePicker
                            showSelectionPreview={false}
                            showDateDisplay={false}
                            moveRangeOnFirstSelection={false}
                            showMonthAndYearPickers={false}
                            months={2}
                            weekdayDisplayFormat="EEEEEE"
                            monthDisplayFormat="MMMM yyyy"
                            inputRanges={[]}
                            ranges={selectionRange}
                            direction="horizontal"
                            staticRanges={customStaticRanges}
                            minDate={minDate}
                            maxDate={maxDate}
                            navigatorRenderer={customNavigatorRenderer}
                            onChange={_handleSelect} />

                        <div className="d-flex gap-3 justify-content-end p-3 border-top-1px border-bright-gray">
                            <SecondaryButton className="py-2 px-4 px-sm-5 e-text-charleston-green e-border-bright-gray"
                                name="Clear"
                                onPress={_handleCancel} />
                            <PrimaryButton className="px-4 px-sm-5 py-2 border-radius-8px"
                                name="Select"
                                onPress={_handleUpdate} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

CustomDateRangePicker.defaultProps = {
    leftAlign: false
};

export default CustomDateRangePicker;