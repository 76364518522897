/*
 *   File : area.js
 *   Author : https://evoqins.com
 *   Description : Area chart line component
 *   Integrations : chart.js
 *   Version : 1.0.0
*/

import { useState, useEffect, memo, useRef } from "react";
import Chart, { Tooltip } from "chart.js/auto";

// Importing Images
import DataPoint from "../../Assets/Images/data-point.svg";

// custom data point img
const CUSTOM_DATA_POINT = new Image();
CUSTOM_DATA_POINT.src = DataPoint;
CUSTOM_DATA_POINT.style.zIndex = 3;

// Setting position of tooltip
Tooltip.positioners.right = (elements) => {
    if (elements.length != 0) {
        return {
            x: elements[0].element.x + 15,
            y: elements[0].element.y - 5,
        }
    }
    return {
        x: 0,
        y: 0,
    }

};

function AreaChart(props) {

    const [data, setData] = useState(props.data);
    const chartRef = useRef(null);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    // Custom border in axis
    const custom_border = {
        id: "custom_border",
        afterDatasetsDraw: (chart) => {
            const { ctx, chartArea: { top, bottom, left, right } } = chart;
            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = "#C6C6C8";
            ctx.moveTo(left, top);
            ctx.lineTo(left, bottom);
            ctx.lineTo(right, bottom);
            ctx.stroke();
        }
    };

    // Custom plugins for chart
    const tooltip_line = {
        id: "tooltip_line",
        beforeDatasetsDraw: chart => {

            if (chart.tooltip._active && chart.tooltip._active.length) {

                const ctx = chart.ctx;
                const active_point = chart.tooltip._active[0];
                ctx.save();

                ctx.lineWidth = 1;
                ctx.strokeStyle = "#1C359F";

                // verticle line
                ctx.beginPath();
                ctx.setLineDash([6, 6]);
                ctx.moveTo(active_point.element.x, chart.chartArea.top);
                ctx.lineTo(active_point.element.x, chart.chartArea.bottom);
                ctx.stroke();

                // horizontal line
                // ctx.beginPath();
                // ctx.moveTo(chart.chartArea.left, active_point.element.y);
                // ctx.lineTo(chart.chartArea.right, active_point.element.y);
                // ctx.stroke();

                ctx.restore();

            }
        }
    };

    // Trigger recent data point on load
    const _triggerDataPointOnLoad = (chart, data_point) => {
        chart.setActiveElements([{ datasetIndex: 0, index: data_point }]);
        chart.tooltip.setActiveElements([{ datasetIndex: 0, index: data_point }]);
        chart.update();
    };

    // Triggering tooltip 
    const _triggerDataPointOnLeave = (chart, data_point) => {

        var meta = chart.getDatasetMeta(0);
        var rect = chart.canvas.getBoundingClientRect();
        var point = meta.data[Math.ceil(data_point)].getCenterPoint();

        var evt = new MouseEvent('mousemove', {
            clientX: rect.left + point.x,
            clientY: rect.top + point.y
        });

        if (chart.getActiveElements().length > 0) {
            chart.setActiveElements([]);
        } else {
            chart.setActiveElements([
                {
                    datasetIndex: 0,
                    index: Math.ceil(data_point),
                }
            ]);
        }
        chart.update();
        chart.canvas.dispatchEvent(evt);
    };

    // Padding
    const custom_padding = {
        id: "custom_padding",
        afterDraw: (chart) => {
            let { ctx, chartArea } = chart;

            // Specify padding values (in pixels)
            const padding = {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            };

            // Adjust chart area with padding
            chartArea = {
                ...chartArea,
                top: chartArea.top + padding.top,
                right: chartArea.right - padding.right,
                bottom: chartArea.bottom - padding.bottom,
                left: chartArea.left + padding.left
            };

            // Ensure that the chart's background is clear
            ctx.save();
            ctx.fillStyle = 'rgba(255, 255, 255, 0)'; // Transparent background
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
            ctx.restore();
        }
    };


    useEffect(() => {

        if ((chartRef && chartRef.current) === false) return;

        const ctx = chartRef.current.getContext('2d');

        Chart.register(tooltip_line, custom_border, custom_padding);

        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data.labels,
                datasets: [{
                    label: "NAV",
                    data: data.datasets,
                    tension: 0.35, // for curved line
                    fill: true,
                }]
            },
            options: {
                layout: {
                    padding: {
                        // left: 50,
                        // right: 50
                    }
                },
                maintainAspectRatio: false,
                interaction: {
                    mode: 'index'
                },
                animation: false,
                hover: {
                    intersect: false,
                },
                scales: {

                    x: {
                        border: { dash: [6, 6] },
                        offset: true,
                        ticks: {
                            display: false,
                            maxTicksLimit: 6,
                        },
                        grid: {
                            drawTicks: false,
                        }

                    },
                    y: {
                        display: false,
                        min: 0,
                        // For adding space from top
                        max: Number(Math.max(...data.datasets)) + (Number(Math.max(...data.datasets)) * (20 / 100)),
                    },
                },
                elements: {
                    line: {
                        borderColor: '#1C359F',
                        borderWidth: 2,
                        backgroundColor: (context) => {

                            if (!context.chart.chartArea) return;
                            const { ctx, data, chartArea: { top, bottom } } = context.chart;
                            const gradient_bg = ctx.createLinearGradient(0, top, 0, bottom);
                            gradient_bg.addColorStop(0.7, "#1c349f5b");
                            gradient_bg.addColorStop(1, "#1c349f13");
                            return gradient_bg;
                        },

                    },
                    point: {
                        radius: 0,
                        pointStyle: CUSTOM_DATA_POINT,
                    }
                },
                plugins: {
                    chartAreaBorder: {
                        borderColor: 'red',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        borderDashOffset: 2,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: false,
                        intersect: false,
                        // custom one connected with function "Tooltip.positioners.right"
                        position: "right",
                        backgroundColor: "#1B223F",
                        caretSize: 0,
                        caretPadding: 10,
                        cornerRadius: 6,
                        xAlign: "left",
                        yAlign: "bottom",
                        padding: {
                            top: 6,
                            bottom: 4,
                            left: 16,
                            right: 16,
                        },
                        bodyFont: {
                            family: "Inter, sans- serif ",
                            size: 12,
                            weight: 400,
                        },
                        callbacks: {
                            title: function () {
                                return null
                            },
                            label: context => {
                                return (
                                    `${context.dataset.label} ₹${context.raw} (${context.label})`
                                )
                            },
                        }
                    },
                },
            }
        });

        const data_point = data.datasets.length - 1;
        _triggerDataPointOnLoad(chart, data_point);

        const _handleMouseOutEvent = (event) => {
            const canvas_position = chart.canvas.getBoundingClientRect();
            const mouse_x = event.clientX - canvas_position.left;
            const data_x = chart.scales.x.getValueForPixel(mouse_x);
            const data_point = data_x < 0 ? 0
                : data_x >= data.datasets.length ?
                    data.datasets.length - 1 :
                    data_x;

            // Triggering data point
            _triggerDataPointOnLeave(chart, data_point);


        };

        chart.canvas.addEventListener("mouseout", _handleMouseOutEvent);

        return () => {
            chart.canvas.removeEventListener("mouseout", _handleMouseOutEvent);
            chart.destroy();
        };


    }, [data]);

    return (
        <canvas ref={chartRef} id="canvas-id" />
    );
}

export default memo(AreaChart);