/*
 *   File :add-bank.js
 *   Author : https://evoqins.com
 *   Description : Add bank page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';

//custom component
import { CustomTextInput, FilePicker, RadioGroup } from '../../../Component/Form-Elements';
import { PrimaryButton } from '../../../Component/Buttons';
import { toast } from 'react-toastify';
import { FindIFSCModal, SearchBankModal, SearchBranchModal, SelectionSuggestModal } from '../../../Component/Modal';
import { APIService, awsService } from '../../../Service';
import { _checkFileSize, _getSignupProgress } from '../../../Helper';


//Assets
import Loader from "../../../Assets/Images/loader.gif";

const AddBank = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.Reducer.GENERAL_DATA);
    const [generalData, setGeneralData] = useState(GENERAL_DATA);

    //get progress data form redux
    const PROGRESS_DATA = useSelector((store) => store.Reducer.SIGNUP_PROGRESS_DATA);

    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [reConfirmAccountNumber, setReConfirmAccountNumber] = useState("");
    const [reConfirmAccountNumberError, setReConfirmAccountNumberError] = useState("");
    const [holderName, setHolderName] = useState("");
    const [holderNameError, setHolderNameError] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");
    const [accountType, setAccountType] = useState("");

    const [bankProofFile, setBankProofFile] = useState(null);
    const [bankProofFileError, setBankProofFileError] = useState("");
    const [bankProofViewUrl, setBankProofViewUrl] = useState(null);

    const [loading, setLoading] = useState(false);
    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [initialStatus, setInitialStatus] = useState(true);

    const [ifscModal, setIfscModal] = useState(false);

    const [bankSearchModal, setBankSearchModal] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});

    const [branchSearchModal, setBranchSearchModal] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState({});

    const [bankList, setBankList] = useState([]);
    const [bankDataSearch, setBankDataSearch] = useState("");
    const [bankDataLoading, setBankDataLoading] = useState(true);

    const [branchList, setBranchList] = useState([]);
    const [branchSearch, setBranchSearch] = useState("");
    const [branchDataLoading, setBranchDataLoading] = useState(true);

    const [isFileUploading, setIsFileUploading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        setBankDataLoading(false);
        setBranchDataLoading(false);
    }, []);

    useEffect(() => {

        if (Object.hasOwn(PROGRESS_DATA.kyc_data.bank_details, "account_number")) {

            setAccountNumber(PROGRESS_DATA.kyc_data.bank_details.account_number);
            setReConfirmAccountNumber(PROGRESS_DATA.kyc_data.bank_details.account_number);
            setHolderName(PROGRESS_DATA.kyc_data.bank_details.account_holder_name);
            setIfsc(PROGRESS_DATA.kyc_data.bank_details.ifsc);

            if (PROGRESS_DATA.kyc_data.bank_details.account_type_id !== null) {
                setAccountType(PROGRESS_DATA.kyc_data.bank_details.account_type_id);
            }

        } else {
            setAccountType(generalData.bank_account_types[0].id);
        }


        if (PROGRESS_DATA.kyc_data.bank_proof_url !== null) {
            let bank_url = PROGRESS_DATA.kyc_data.bank_proof_url;
            let updated_bank = bank_url.split("/").pop().split("?")[0];

            setBankProofFile(updated_bank);
            setBankProofViewUrl(PROGRESS_DATA.kyc_data.bank_proof_url);
        }

        setInitialStatus(false);

    }, [PROGRESS_DATA]);


    useEffect(() => {
        if (GENERAL_DATA) {
            setGeneralData(GENERAL_DATA);
        }
    }, [GENERAL_DATA]);

    useEffect(() => {
        if (bankSearchModal === true) {
            _getBank();
            setBankDataLoading(true);
        }
    }, [bankDataSearch, bankSearchModal]);

    useEffect(() => {
        if (branchSearchModal === true) {
            _getBranch();
            setBranchDataLoading(true);
        }
    }, [branchSearch, branchSearchModal]);

    useEffect(() => {

        if (showSuggestModal === true) {
            let proof_modal = new Modal(document.getElementById('select-account-proof-modal'));
            proof_modal.show();
        };

    }, [showSuggestModal]);

    useEffect(() => {
        if (ifscModal === true) {
            let ifsc_modal = new Modal(document.getElementById('find-ifsc-modal'));
            ifsc_modal.show();
        };
    }, [ifscModal]);

    useEffect(() => {
        if (bankSearchModal === true) {
            let bank_modal = new Modal(document.getElementById('bank-search-modal'));
            bank_modal.show();
        };
    }, [bankSearchModal]);

    useEffect(() => {
        if (branchSearchModal === true) {
            let branch_modal = new Modal(document.getElementById('branch-search-modal'));
            branch_modal.show();
        };
    }, [branchSearchModal]);

    useEffect(() => {
        setAccountNumberError("");
    }, [accountNumber]);

    useEffect(() => {
        setHolderNameError("");
    }, [holderName]);

    useEffect(() => {
        setIfscError("");
    }, [ifsc]);

    useEffect(() => {
        setBankProofFileError("");
    }, [bankProofFile]);

    //handle account type
    const _handleAccountType = (id) => {
        setAccountType(id)
    };

    // handle open find ifsc modal 
    const _handleIfscModal = () => {
        setIfscModal(true);
    };

    // handle open bank search modal 
    const _handleBankSearchModal = () => {
        setBankSearchModal(true);
    };

    // handle select bank 
    const _handleSelectBank = (selected) => {
        setSelectedBank(selected);
    };

    // handle open branch search modal 
    const _handleBranchSearchModal = () => {
        setBranchSearchModal(true);
    };

    // handle select branch 
    const _handleSelectBranch = (selected) => {
        setSelectedBranch(selected);
    };

    // handle ifsc 
    const _handleFind = (data) => {
        setIfsc(data.ifsc);
    };

    const _handleReconfirmACNumber = (e) => {
        if (e.length < 19) {
            setReConfirmAccountNumber(e);
            setReConfirmAccountNumberError("");
        }
    };

    // upload bank proof front image 
    const _handleUploadBankProof = (file) => {

        if (file.target.files.length !== 0) {

            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setBankProofFileError("Maximum file size should be 5mb only");
                return;
            }


            _getPresignedUrl(file);
        }
    }

    //handle submit add bank
    const _handleSubmitAddBank = () => {

        let errors = [];

        if (accountNumber.length === 0) {
            setAccountNumberError("Provide your bank account number");
            errors.push("account-number");
        }

        if (reConfirmAccountNumber.length === 0) {
            setReConfirmAccountNumberError("Please confirm your account number");
            errors.push("confirm-account-number");
        } else {
            if (accountNumber !== reConfirmAccountNumber) {
                setReConfirmAccountNumberError("Account number is not matching");
                errors.push("confirm-account-number");
            }
        }


        if (holderName.length === 0) {
            setHolderNameError("Provide your full name");
            errors.push("account-holder");
        }
        if (ifsc.length === 0) {
            setIfscError("Please provide your bank's IFSC for secure bank transactions");
            errors.push("ifsc-code");
        }

        // ONLY FOR NON KYC USER
        if (PROGRESS_DATA.kyc_user === false) {

            if (bankProofFile === null) {
                setBankProofFileError("Please upload the required document");
                errors.push("bank-account-proof-file");
            }

            // NON KYC USER
            if (accountNumber && holderName && ifsc && bankProofFile) {
                setLoading(true);
                _addBank();

            }
        } else {
            // KYC USER
            if (accountNumber && holderName && ifsc) {
                setLoading(true);
                _addBank();
            }
        }

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

    }

    //get presigned url
    async function _getPresignedUrl(file) {

        setIsFileUploading(true);

        let is_post = true;
        let url = 'file/upload';
        let data = {
            "type": file.target.files[0].type,
            "file": file.target.files[0].name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            let aws_URL = '';
            if (response.status_code == 200) {
                aws_URL = response.data.upload_info.url;
                let view_url = response.data.view_info
                let file_param = new File(
                    [file.target.files[0]],
                    file.target.files[0].name,
                    { type: file.target.files[0].type }
                )
                try {
                    let awsResponse = await awsService(response.data.upload_info.fields, file_param, aws_URL);
                    if (awsResponse.status == 'ok') {
                        setBankProofViewUrl(view_url);
                        setBankProofFile(file.target.files[0].name);
                    } else {
                        toast.dismiss();
                        toast.error(awsResponse.message, { className: "e-toast" });
                    }
                } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, { className: "e-toast" });
                }
            }

            setIsFileUploading(false);
        } catch (err) {
            setIsFileUploading(false);
        }
    };

    //API- Get bank list
    const _getBank = () => {
        let is_post = true;
        let url = 'general/banks';
        let data = {
            "query": bankDataSearch.length !== 0 ? bankDataSearch : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBankList(response.data);
            } else {
                setBankList([]);
            }
            setBankDataLoading(false);
        });
    };

    //API- Get branch list
    const _getBranch = () => {
        let is_post = true;
        let url = 'general/branches';
        let data = {
            "bank_id": selectedBank.id,
            "query": branchSearch.length !== 0 ? branchSearch : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBranchList(response.data);
            } else {
                setBranchList([]);
            }
            setBranchDataLoading(false);
        });
    };

    //API- Add bank
    const _addBank = () => {

        if (PROGRESS_DATA.kyc_user === false && bankProofViewUrl === null) {
            setLoading(false);
            return;
        };

        let is_post = true;
        let url = 'kyc/save-bank';
        let data = {
            "account_number": accountNumber,
            "account_holder_name": holderName,
            "account_type": accountType,
            "ifsc": ifsc,
            "bank_proof_url": bankProofViewUrl
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _getSignupProgress();
                toast.dismiss();
                toast.success("Bank details saved successfully", {
                    className: "e-toast",
                });
                props.onUpdateStep(6);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setLoading(false);
        });
    };

    return (
        <>
            {
                initialStatus == false ? (
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <div className='padding-26px-top'>
                                <img src={require("../../../Assets/Images/left-arrow.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={() => props.onUpdateStep(4)} />
                            </div>
                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-egyptian-blue e-border-radius-16 top-0'
                                    style={{ width: `${(100 / PROGRESS_DATA.step_detail.length) * 4}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                    Step {PROGRESS_DATA.kyc_user === true ? '4' : '5'} of {PROGRESS_DATA.step_detail.length}
                                </p>
                            </div>

                            {/* Bank account details */}
                            <div className='pt-3'>
                                <h3 className='mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    Bank account details
                                </h3>
                                {/* Account number */}
                                <div id="account-number" className='h-90 mb-2'>
                                    <CustomTextInput label="Account number*"
                                        placeholder="99980107428"
                                        type="password"
                                        style="number"
                                        maxLength={18}
                                        value={accountNumber}
                                        errorMsg={accountNumberError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={(e) => {
                                            if (e.length <= 18) {
                                                setAccountNumber(e);
                                            }
                                        }} />
                                </div>
                                <div id="confirm-account-number" className='h-90 mb-2'>
                                    <CustomTextInput label="Confirm account number*"
                                        placeholder="99980107428"
                                        type="password"
                                        style="number"
                                        maxLength={18}
                                        value={reConfirmAccountNumber}
                                        errorMsg={reConfirmAccountNumberError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={_handleReconfirmACNumber} />
                                </div>

                                {/* holder name */}
                                <div id="account-holder" className='h-90 mb-2'>
                                    <CustomTextInput label="Account holder name*"
                                        placeholder="Jhon Doe"
                                        type="text"
                                        value={holderName}
                                        errorMsg={holderNameError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={(e) => {
                                            setHolderName(e);
                                        }} />
                                </div>

                                {/* Account type select */}
                                <div className='pt-2 pb-4'>
                                    <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Account type*
                                    </h6>
                                    <div className='d-flex gap-3 e-flex-wrap'>
                                        <RadioGroup data={generalData.bank_account_types}
                                            selected={accountType}
                                            handleSelect={_handleAccountType} />
                                    </div>
                                </div>

                                {/* IFSC code */}
                                <div id="ifsc-code" className='h-90'>
                                    <CustomTextInput label="IFSC code*"
                                        placeholder="SBIN1234"
                                        type="text"
                                        value={ifsc}
                                        errorMsg={ifscError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={(e) => {
                                            if (e.length < 12) {
                                                setIfsc(e);
                                            }
                                        }} />
                                </div>

                                {/* find ifsc  */}
                                <p className='mb-4 mb-sm-0 margin-8px-neg-top text-end'>
                                    <span className='e-text-indigo e-font-14 e-alt-font-poppins e-font-weight-600 line-height-14px e-font-14-res cursor-pointer text-decoration-hover-underline'
                                        onClick={_handleIfscModal}>
                                        Find IFSC code
                                    </span>
                                </p>

                                {PROGRESS_DATA.kyc_user === false &&
                                    <>

                                        {/* upload bank account proof*/}
                                        <div id="bank-account-proof-file" className='pt-1'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-18px e-font-14 mb-2">
                                                Cancelled cheque image*(max.5mb)
                                            </label>
                                            {/* uploaded bank proof */}
                                            {bankProofFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='e-display-flex justify-content-space-between'>
                                                        <p className='e-font-12-res mb-0 padding-12px-top padding-12px-bottom e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            {bankProofFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/KYC/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => setBankProofFile(null)} />
                                                    </div>
                                                </div>
                                            }
                                            {/* bank proof file upload */}
                                            <FilePicker id="bank-proof"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                currentFile={bankProofFile}
                                                error={bankProofFileError}
                                                selectFile={_handleUploadBankProof} />
                                        </div>
                                    </>
                                }

                                {/* button */}
                                <div className='padding-56px-top'>
                                    <PrimaryButton name={"Save & Continue"}
                                        loading={loading}
                                        className={`${isFileUploading && "pe-none"} w-40 padding-12px-top padding-12px-bottom`}
                                        disabled={loading}
                                        onPress={_handleSubmitAddBank} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="min-h-100vh text-center mt-5 pt-5" >
                        <img src={Loader}
                            className='object-fit-contain w-20'
                            draggable="false"
                            alt="loader" />
                    </div>
                )
            }


            {/* find ifsc modal  */}
            {ifscModal === true &&
                <FindIFSCModal selectedBank={selectedBank}
                    selectedBranch={selectedBranch}
                    bankSearchModal={_handleBankSearchModal}
                    branchSearchModal={_handleBranchSearchModal}
                    onModalClose={() => {
                        setIfscModal(false);
                        setSelectedBank({});
                        setSelectedBranch({});
                    }}
                    handleFind={_handleFind} />
            }

            {/* bank search modal  */}
            {bankSearchModal === true &&
                <SearchBankModal data={bankList}
                    selectedBank={selectedBank}
                    bankLoading={bankDataLoading}
                    searchValue={bankDataSearch}
                    handleClearSearch={() => setBankDataSearch("")}
                    handleSearch={(value) => setBankDataSearch(value)}
                    handleSelectedBank={_handleSelectBank}
                    onModalClose={() => {
                        setBankSearchModal(false);
                        setBankDataSearch("");
                    }} />
            }

            {/* branch search modal  */}
            {branchSearchModal === true &&
                <SearchBranchModal data={branchList}
                    selectedBranch={selectedBranch}
                    branchLoading={branchDataLoading}
                    searchValue={branchSearch}
                    handleClearSearch={() => setBranchSearch("")}
                    handleSearch={(value) => setBranchSearch(value)}
                    handleSelectedBranch={_handleSelectBranch}
                    onModalClose={() => {
                        setBranchSearchModal(false);
                        setBranchSearch("");
                    }} />
            }
        </>
    )
}

export default AddBank;