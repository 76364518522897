/*
 *   File :  calculated-riskscore.js
 *   Author : https://evoqins.com
 *   Description :  calculated risk score page
 *   Integrations : react-gauge-component
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GaugeComponent from 'react-gauge-component';
import { Modal } from 'bootstrap';

//custom component
import { SectionTitle } from '../../../Component/Title'
import { PrimaryButton, SecondaryButton } from '../../../Component/Buttons';
import { Icon } from '../../../Component/Icon';
import { FirstInvestmentModal } from '../../../Component/Modal';
import Store from '../../../Store/store';

// COLORS
import Colors from "../../../Style/color.scss"

const CalculatedRiskScore = (props) => {

    const navigate = useNavigate();
    const USER_DATA = Store.getState().Reducer.USER_DATA;  //fetch data from redux
    
    const [investmentModal, setInvestmentModal] = useState(false);
    const [basketsData, setBasketsData] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (props.basketData.length !== null) {
            setBasketsData(props.basketData);
        }
    }, [props.basketData]);

    useEffect(() => {
        if (investmentModal === true) {
            let works_modal = new Modal(document.getElementById('first-investment-modal'));
            works_modal.show();
        }
    }, [investmentModal]);

    // handle open first investment modal 
    const _handleInvestmentModal = () => {
        setInvestmentModal(true);
    };

    return (
        <div className='pe-lg-5 me-lg-5'>
            {/* title */}
            <SectionTitle title="Recommended risk" />

            <p className='e-font-14-res line-height-22px-res mb-0 pt-3 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px'>
                Risk profilers assess your need, capability, and willingness to
                take risks based on your financial goals and the potential for higher returns.
            </p>

            <div className='row flex-column flex-md-row pt-4 h-100'>
                <div className="col-xl-5 col-lg-5 col-md-6 ">
                    <div className='d-flex flex-column h-100'>
                        <SectionTitle title="Your risk score" className={"p-0"} />
                        {/* gauge chart component  */}
                        <div className=' mt-4 e-border-radius-16 e-bg-cultured  p-4 pb-5 text-center flex-grow-1'>
                            <div className="position-relative">
                                <GaugeComponent type="semicircle"
                                    arc={{
                                        cornerRadius: 16,
                                        colorArray: [Colors.iguana_green, Colors.orange_yellow, Colors.fuzzy_wuzzy_red],
                                        padding: 0.02,
                                        width: 0.17,
                                        subArcs:
                                            [
                                                { limit: 50 },
                                                { limit: 70 },
                                                { limit: 80 },
                                            ]
                                    }}
                                    pointer={{ type: "blob", animationDelay: 0 }}
                                    value={props.data.risk_score.score * 10} />
                                <p className="mb-0 margin-top-neg-56px  e-text-charleston-green e-font-52 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                    {props.data.risk_score.score}
                                </p>
                                <p className="mb-0 pt-4 mt-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-30px">
                                    {props.data.risk_score.score_text}
                                </p>
                                {/* <div className="padding-36px-top">
                                <PrimaryButton name="Edit"
                                    onPress={() => props.onUpdateProfile(1)}
                                    className="px-5 py-2 e-font-14 e-primary-button-hover transition" />
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6">
                    <div className="mt-4 mt-md-0 d-flex flex-column h-100">
                        <SectionTitle title="Allocation based on your risk score" className={"p-0"} />
                        <div className="e-bg-cultured e-border-radius-16 p-3 p-sm-4 mt-4 flex-grow-1">
                            <div className="d-flex justify-content-between">
                                <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                    0%
                                </span>
                                <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                    100%
                                </span>
                            </div>
                            {/* Market cap breakdown */}
                            <div className="height-40px-res progress-stacked e-border-radius-12 h-48 mt-2 mb-4">
                                {props.data.graph_data.allocation.map((item, key) => (
                                    <div style={{ width: `${item.value}%` }}
                                        className="progress h-100"
                                        role="progressbar"
                                        key={key} >
                                        <div style={{ backgroundColor: item.color }}
                                            className="progress-bar"></div>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex flex-column gap-3 gap-sm-4">
                                <div className="d-flex">
                                    <span className="col-6 col-md-9 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                        Name
                                    </span>
                                    <span className="col-6 col-md-2 text-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                        Allocation
                                    </span>
                                </div>

                                {
                                    props.data.graph_data.allocation.map((item, key) => (
                                        <div className="d-flex align-items-center" key={key}>
                                            <span className="e-font-14-res col-6 col-md-9 d-flex align-items-center gap-2 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                <Icon icon="bullet"
                                                    color={item.color}
                                                    size={"1rem"} />
                                                {item.label}
                                            </span>
                                            <span className="e-font-14-res col-6 col-md-2 text-center align-self-center e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                {item.value}%
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* custom buttons */}
            <div className='row e-flex-wrap pt-xl-5 pt-lg-5 pt-md-5 pt-sm-4 pt-4 gap-0'>
                {basketsData.length !== 0 &&
                    <>
                        {USER_DATA.signup_progress_status === "INVESTED" ?
                            <div className="col-xl-5 col-lg-5 col-md-6 d-flex flex-wrap gap-xl-3 gap-lg-3 gap-md-3 gap-sm-3 gap-3">
                                <div className="flex-fill">
                                    <SecondaryButton name="Explore funds"
                                        className="mb-2 w-100 padding-12px-top padding-12px-bottom e-border-bright-gray"
                                        onPress={() => navigate("/explore")} />
                                </div>

                                <div className="flex-fill">
                                    <PrimaryButton name="Explore goals"
                                        className="mb-2 w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={() => navigate("/goals")} />
                                </div>
                            </div>
                            :
                            <div className="col-xl-7 col-lg-8 col-md-9 d-flex flex-wrap gap-xl-3 gap-lg-3 gap-md-3 gap-sm-3 gap-3">
                                <div className="flex-fill">
                                    <SecondaryButton name="Skip and explore funds"
                                        className="mb-0 w-100 padding-12px-top padding-12px-bottom e-border-bright-gray"
                                        onPress={() => navigate("/explore")} />
                                </div>

                                <div className="flex-fill">
                                    <PrimaryButton name="Let’s start your first investment"
                                        className="mb-2 w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleInvestmentModal} />
                                </div>
                            </div>
                        }
                    </>
                }
            </div>

            {/* first investment modal  */}
            {investmentModal === true &&
                <FirstInvestmentModal basketData={basketsData}
                    onCloseModal={() => setInvestmentModal(false)} />
            }
        </div>
    )
}

export default CalculatedRiskScore;