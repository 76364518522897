/*
 *   File : nri.js
 *   Author : https://evoqins.com
 *   Description : NRI user warning modal
 *   Integrations : NA
 *   Version : 1.0.0
 *   Date : 30-05-2024
*/

import { memo } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { PrimaryButton } from '../Buttons';

// Style
import Styles from "../../Style/Component/modal.module.scss";

function NRIWarningModal(props) {

    const navigate = useNavigate();

    const _handleNavigateToHome = () => {
        const close_modal = document.querySelector("#nri-warning-modal-close");
        close_modal.click();
        navigate("/");
    };

    return (
        <div className="modal fade"
            id="nri-warning-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="NRI warning modal label"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`${Styles.nri_warning_modal_bg} modal-content e-border-radius-24 e-addbank-box-shadow padding-32px-all padding-16px-all-res`}>
                    <div className="modal-header p-0 border-0 position-relative">
                        <h6 className='col-11 col-sm-9 pe-4 mx-auto text-center e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                            Account opening is restricted for Non-KYC users
                        </h6>
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='nri-warning-modal-close'
                            draggable={false}
                            className='cursor-pointer position-absolute end-0 top-50 translate-middle-y'
                            data-bs-dismiss="modal"
                            aria-label="close"
                            alt='close btn'
                            onClick={props.onClose} />
                    </div>
                    <div className="modal-body pt-3 pt-sm-4 pb-0 px-0">
                        <p className='text-center mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                            Unfortunately, you can't open an investment account with us since the regular has not started accepting NRI non-KYC users online. For more information, please contact our support at
                        </p>
                        <div className="text-center">
                            <a href="mailto:help@indexfundssahihai.com"
                                target="_blank"
                                className="e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px">
                                help@indexfundssahihai.com
                            </a>
                        </div>
                        <div className="margin-top-32px">
                            <PrimaryButton name="View Home"
                                className="py-2 w-100"
                                onPress={_handleNavigateToHome} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(NRIWarningModal);