/*
 *   File : active-sip.js
 *   Author : https://evoqins.com
 *   Description : Active SIP card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';

//styles
import styles from "../../Style/Component/card.module.scss";

//custom component
import { PrimaryButton, SecondaryButton } from '../Buttons';

const ActiveSIPCard = (props) => {

    return (
        <>
            <div className={`${styles.e_sip_card_wrapper} transition cursor-pointer e-bg-anti-flash-white-light e-border-radius-16  h-100`}   >
                <div className="d-flex gap-3 p-3">
                    <div className="">
                        <img src={props.data.icon}
                            width={48}
                            height={48}
                            className="object-fit-contain e-border-radius-10 e-bg-white p-1"
                            draggable="false"
                            alt="fund" />
                    </div>
                    <div className="">
                        <h3 className="e-ellipse mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                            {props.data.name}
                        </h3>
                    </div>
                </div>

                <div className='e-display-flex justify-content-space-between px-3 pb-3'>
                    <div>
                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            Created on
                        </p>
                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            {props.data.created}
                        </p>
                    </div>
                    <div>
                        <div className="vr p-0"></div>
                    </div>
                    <div>
                        <p className='mb-0 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Next SIP date
                        </p>
                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            {props.data.next_sip_date}
                        </p>
                    </div>
                </div>

                {/* calculated */}
                <div className={styles.e_border_bottom}></div>

                <div className="e-display-flex justify-content-space-between p-3">
                    <p className="mb-1 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px">
                        Amount
                    </p>
                    <p className="mb-1 e-primary-font-inter e-text-charleston-green e-font-16 e-font-weight-600 line-height-24px">
                        ₹ {props.data.amount}
                    </p>
                </div>
                <div className='row p-3'>
                    <div className='col-6'>
                        <SecondaryButton name="Cancel SIP"
                            className="padding-10px-top padding-10px-bottom  w-100"
                            onPress={() => props.onCancel(props.data.sip_id)} />
                    </div>
                    <div className='col-6'>
                        <PrimaryButton name="Edit SIP"
                            className="padding-10px-top padding-10px-bottom  w-100"
                            onPress={() => props.handleEdit(props.data)} />
                    </div>
                </div>
            </div>


        </>
    )
}

export default ActiveSIPCard;