/*
 *   File : date-picker.js
 *   Author : https://evoqins.com
 *   Description : Date picker component
 *   Integrations : react-calendar
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { format } from "date-fns";
import 'react-calendar/dist/Calendar.css';

// custom component 
import { OverLay } from '../Other';

//custom style
import styles from "../../Style/Component/calender.module.scss";

// Assets
import CalenderIcon from "../../Assets/Images/KYC/calendar.svg";

const DatePicker = (props) => {

    const calendarRef = useRef(null);

    const [value, setValue] = useState(new Date());
    const [showValue, setShowValue] = useState("");
    const [showCalender, setShowCalender] = useState(false);

    useEffect(() => {
        if (props.value) {
            setValue(format(props.value, "MM/dd/yyyy"));
            setShowValue(format(props.value, "dd MM yyyy").replaceAll(" ", "-"));
        } else {
            if (props.activeDate) {
                setValue(props.activeDate);
            } else {
                setShowValue("");
                setValue(new Date());
            }

        };

    }, [props.value]);


    useEffect(() => {

        if (showCalender === true) {
            const react_calendar = document.querySelector("#calender-wrapper");
            react_calendar.scrollIntoView({ behavior: "smooth", block: "end" });

            document.body.style.overflow = "hidden";

        } else {
            document.body.style.overflow = 'unset';
        }

    }, [showCalender]);

    //handle onchange date
    const _handleOnChangeDate = (date) => {

        setValue(date);
        setShowValue(format(date, "dd MM yyyy").replaceAll(" ", "-"));
        props.onChange(date);
        setShowCalender(false);
    };

    const _handleShowCalender = () => {
        setShowCalender((showCalender) => !showCalender);
    };

    // format day according to the design
    function formatWeekDay(locale, date) {
        const day_format = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        const day_index = date.getDay();
        const formatted_day = day_format[day_index];
        return formatted_day;
    };

    return (
        <div className='position-relative' ref={calendarRef}>

            <div id='calender' className={`padding-11px-tb padding-8px-tb-res  ${styles.e_input_wrapper} ${showCalender === true ? "e-border-otp-active" : "e-border-1px-charleston-green"} ${props.error && "border-red-1px"} position-relative`}
                onClick={_handleShowCalender}>
                {showCalender === false ?
                    <img src={CalenderIcon}
                        draggable={false}
                        className='top-6px-res position-absolute right-16px top-50 translate-middle-y'
                        alt='calender' />
                    :
                    <img src={require("../../Assets/Images/close-btn.svg").default}
                        draggable={false}
                        className='top-6px-res position-absolute right-16px top-50 translate-middle-y'
                        alt='calender' />
                }

                {/* Displayed value */}
                <p className={`${showValue.length === 0 ? "opacity-75 e-font-14 e-font-weight-400 " : "e-font-16 "} e-font-14-res px-3 line-height-22px e-text-dark-charcoal e-alt-font-poppins  mb-0 `}>
                    {showValue.length !== 0 ? showValue : "DD-MM-YYYY"}&nbsp;
                </p>

            </div>
            {props.error &&
                <div className='position-absolute d-flex align-items-center e-error left-0'>
                    <p className={`e-text-red e-font-12 mb-0`}>{props.error}</p>
                </div>
            }

            {/* Background overlay */}
            {
                showCalender === true &&
                <>
                    <OverLay isOpen={showCalender} onClose={_handleShowCalender} overLay={false} />
                    <div id='calender-wrapper' className={`${styles.e_calender} e-calender position-absolute pb-4`}>
                        <Calendar value={value}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            nextLabel=">"
                            next2Label={null}
                            prevLabel="<"
                            prev2Label={null}
                            showNeighboringMonth={false}
                            // navigationLabel={({ date, label, locale, view }) => { return label.split(' ')[0] }}
                            locale="en-IN"
                            formatShortWeekday={formatWeekDay}
                            onChange={_handleOnChangeDate} />
                    </div>
                </>
            }
        </div>
    )
}

export default DatePicker;