/*
 *   File : bank.js
 *   Author : https://evoqins.com
 *   Description : dank detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

//custom component
import { AddBankModal, BankStatusModal } from '../../Component/Modal';
import { useSelector } from 'react-redux';

const Bank = (props) => {

    // get data from redux     
    let account_data = null;
    account_data = useSelector((store) => store.Reducer.USER_DATA);
    // get general data
    let GENERAL_DATA = useSelector((store) => store.Reducer.GENERAL_DATA);

    const [basicDetail, setBasicDetail] = useState({})
    const [accountDetails, setAccountDetails] = useState({});
    const [showBankModal, setShowBankModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);

    // Edit access to user
    const [editAccess, setEditAccess] = useState(props.editAccess);

    useEffect(() => {
        if (account_data) {
            setAccountDetails(account_data.bank);
        }
    }, [account_data, accountDetails]);

    useEffect(() => {

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setShowBankModal(false);
            setShowStatusModal(false);
        });

    }, [showBankModal, showStatusModal]);

    useEffect(() => {
        if (GENERAL_DATA !== null) {
            setBasicDetail(GENERAL_DATA);
        }
    }, [GENERAL_DATA, basicDetail]);

    useEffect(() => {
        setEditAccess(props.editAccess);
    }, [props.editAccess]);

    useEffect(() => {

        if (showBankModal == true) {
            let edit_modal = new Modal(document.getElementById('addBankModal'));
            edit_modal.show();
            setShowStatusModal(false);
        };

    }, [showBankModal]);

    useEffect(() => {

        if (showStatusModal == true) {
            // open status modal 
            let status_modal = new Modal(document.getElementById('bankStatusModal'));
            status_modal.show();
        };

    }, [showStatusModal]);

    //handle edit modal
    const _handleEditModal = () => {
        setShowBankModal(true);
    };

    const _handleBankModalClose = (status) => {
        setShowBankModal(false);
        if (status === "SUCCESS") {
            setShowStatusModal(true);
        };
    };

    return (

        <div className='col-12 col-lg-9 col-xl-7'>
            <div>
                <h3 className='mb-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                    Bank account details
                </h3>
                <div className='d-flex flex-column gap-3 e-bg-cultured e-border-radius-16 p-4'>
                    <div className='d-flex justify-content-space-between'>
                        <div>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Bank name
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                {accountDetails ? accountDetails.bank_name : '-'}
                            </p>
                        </div>
                        {
                            editAccess === true &&
                            <span className='e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px text-decoration-hover-underline cursor-pointer'
                                onClick={_handleEditModal} >
                                Edit
                            </span>
                        }
                    </div>

                    <div className='e-manage-bank d-flex flex-wrap g-5 justify-content-between'>
                        <div className='col-6 col-lg-5'>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Account holder
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                {accountDetails ? accountDetails.account_holder_name : '-'}
                            </p>
                        </div>
                        <div className='col-6 col-lg-7'>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                IFSC
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                {accountDetails ? accountDetails.ifsc : '-'}
                            </p>
                        </div>
                    </div>

                    <div className='e-manage-bank d-flex  flex-wrap justify-content-between'>
                        <div className='col-6 col-lg-5'>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Account type
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                {accountDetails ? accountDetails.account_type : '-'}
                            </p>
                        </div>
                        <div className='col-6 col-lg-7'>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Account number
                            </p>
                            <div className='d-flex'>
                                <span className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    {accountDetails ? accountDetails.masked_account_number : '-'}
                                </span>
                                {accountDetails &&
                                    <img src={require("../../Assets/Images/Profile/tick.svg").default}
                                        draggable={false}
                                        className='ms-1'
                                        alt='tick' />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='e-manage-bank d-flex  flex-wrap justify-content-between'>
                        <div className='col-6 col-lg-7'>
                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Auto pay ID
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                {accountDetails ? accountDetails.autopay_id : '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* add bank modal */}
            {
                showBankModal &&
                <AddBankModal
                    data={basicDetail}
                    accountData={accountDetails}
                    onCloseModal={_handleBankModalClose} />
            }

            {/*  bank status modal */}
            {
                showStatusModal &&
                < BankStatusModal onCloseModal={() => {
                    setShowStatusModal(false)
                }} />
            }
        </div>
    )
}

export default Bank;