/*
 *   File : edit-phone.js
 *   Author : https://evoqins.com
 *   Description : Edit phone modal component
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/


import React, { memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

// Components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomPhoneInput, OTPInput } from "../Form-Elements";
import { APIService } from "../../Service";

//Assets
import CloseBtn from "../../Assets/Images/close-btn.svg";

function EditPhoneNumberModal(props) {

    const otpOldPhoneRef = useRef(null); // old phone number otp
    const otpNewPhoneRef = useRef(null); // new phone number otp

    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [newPhoneNumberError, setNewPhoneNumberError] = useState("");

    const [focusOldPhoneOtp, setFocusOldPhoneOtp] = useState(2);
    const [oldPhoneOtp, setOldPhoneOtp] = useState("");
    const [oldPhoneOtpErr, setOldPhoneOtpErr] = useState("");

    const [focusNewPhoneOtp, setFocusNewPhoneOtp] = useState(0);
    const [newPhoneOtp, setNewPhoneOtp] = useState("");
    const [newPhoneOtpErr, setNewPhoneOtpErr] = useState("");

    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [toggleOtp, setToggleOtp] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default to 91 (india)

    //for otp timer
    useEffect(() => {

        if (toggleOtp === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }

    }, [timer, toggleOtp]);

    const _handleChangePhoneNumber = (value, selected_country_code) => {
        setNewPhoneNumber(value);
        setNewPhoneNumberError("");
        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }
    };

    const _handleSendOtp = (type) => {

        if (newPhoneNumber && newPhoneNumber.length < 10) {
            setNewPhoneNumberError("Please enter a valid 10-digit phone number");
            return;
        };

        if (type === "Edit") {
            setToggleOtp(false);
        } else {
            _sendOTP();
        }

    };

    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        _sendOTP("re-send");
    };

    const _handleUpdatePhoneNumber = () => {
        if (oldPhoneOtp.length == 4 && newPhoneOtp.length == 4 && (oldPhoneOtp == newPhoneOtp)) {
            _verifyOTP();
            setLoading(true);
        } else {

            if (oldPhoneOtp.length === 0) {
                setOldPhoneOtpErr("Please enter the OTP");
            } else if (oldPhoneOtp.length !== 4) {
                setOldPhoneOtpErr("Please enter the full OTP");
            }

            if (newPhoneOtp.length == 0) {
                setNewPhoneOtpErr("Please enter the OTP");
            } else if (newPhoneOtp.length !== 4) {
                setNewPhoneOtpErr("Please enter the full OTP");
            }

            if (oldPhoneOtp.length === 4 && newPhoneOtp.length === 4 && (oldPhoneOtp !== newPhoneOtp)) {
                toast.dismiss();
                toast.error("Current phone number OTP does not match with new phone number OTP", {
                    className: "e-toast",
                });
            }
        }
    };

    const _handleCurrentPhoneOTP = (value) => {
        setOldPhoneOtp(value);
        setOldPhoneOtpErr("");
        if (value.length == 4) {
            setFocusNewPhoneOtp(2);
            otpOldPhoneRef.current.blur();
        }
    };

    const _handleNewPhoneOTP = (value) => {
        setNewPhoneOtp(value);
        setNewPhoneOtpErr("");
    };

    // API to send OTP
    const _sendOTP = (type) => {

        // removing country code
        let phone_number = Number(newPhoneNumber.replace(selectedCountryCode, ""));

        let is_post = true;
        let url = 'profile/change-mobile';
        let data = {
            "phone_number": phone_number,
            "country_code": "+91",
            "type": type === "re-send" ? "RESEND" : null,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully to new and old mobile numbers", {
                    className: "e-toast",
                });

                setToggleOtp(true);
                setTimer(30);
                setFocusOldPhoneOtp(2);
                setOldPhoneOtp("");
                setNewPhoneOtp("");
                setOldPhoneOtpErr("");
                setNewPhoneOtpErr("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            };

            setDisableResendOTP(false);

        });

    };

    // API to verify OTP
    const _verifyOTP = () => {

        // removing country code
        let phone_number = Number(newPhoneNumber.replace(selectedCountryCode, ""));

        let is_post = true;
        let url = 'profile/verify-mobile-otp';
        let data = {
            "phone_number": phone_number,
            "current_mobile_otp": oldPhoneOtp,
            "new_mobile_otp": newPhoneOtp,
            "country_code": "+91"
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                // Closing modal
                document.querySelector("#edit-phone-modal-close").click();
                // Opening success modal
                props.onSuccess();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            };

            setLoading(false);

        });
    };


    return (
        <div className="modal fade e-manage-modal"
            id="edit-phone-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="EditPhoneModal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="min-h90vh modal-content e-bg-lotion e-border-radius-24">
                    <div className="modal-header border-0 justify-content-space-between py-3 px-3 px-sm-4 ">
                        <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Edit phone number
                        </h6>
                        <img src={CloseBtn}
                            id='edit-phone-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onClose}
                        />
                    </div>

                    {/* Body */}
                    <div className="modal-body pb-2 px-3 px-sm-4 pt-0 d-flex flex-column">
                        {/* Phone Input */}
                        <div className="mb-4 pb-1">
                            <CustomPhoneInput label="Phone number"
                                placeholder="9876543210"
                                className={"e-number-input-padding w-100"}
                                type="text"
                                value={newPhoneNumber}
                                selectedCountryCode={selectedCountryCode}
                                require
                                error={newPhoneNumberError}
                                disableCountrySelect
                                handleChange={_handleChangePhoneNumber}
                                handleSendOtp={() => _handleSendOtp(toggleOtp == true ? "Edit" : "Send OTP")}
                                textLink={true}
                                textLabel={toggleOtp == true ? "Edit" : "Send OTP"}
                                disabled={toggleOtp == true ? true : false}
                            />
                        </div>

                        {/* otp section */}
                        {
                            toggleOtp === true &&
                            <>

                                <div className='p-3 p-sm-4 e-border-radius-16 e-bg-cultured'>
                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        OTP received on current phone number*
                                    </label>
                                    <OTPInput ref={otpOldPhoneRef}
                                        id="old-number-otp"
                                        value={oldPhoneOtp}
                                        spellCheck="false"
                                        error={oldPhoneOtpErr}
                                        focused_input={focusOldPhoneOtp}
                                        onFocus={() => setFocusOldPhoneOtp(2)}
                                        onBlur={() => setFocusOldPhoneOtp(0)}
                                        onChange={_handleCurrentPhoneOTP} />

                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        OTP received on new phone number*
                                    </label>
                                    <OTPInput ref={otpNewPhoneRef}
                                        id="new-number-otp"
                                        value={newPhoneOtp}
                                        spellCheck="false"
                                        error={newPhoneOtpErr}
                                        focused_input={focusNewPhoneOtp}
                                        onFocus={() => setFocusNewPhoneOtp(2)}
                                        onBlur={() => setFocusNewPhoneOtp(0)}
                                        onChange={_handleNewPhoneOTP} />

                                    <div className={`e-inline-flex`} >
                                        <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                            disabled={disableResendOTP}
                                            onClick={_handleResendOTP} >
                                            Re-send OTP
                                        </button>
                                        {
                                            timer !== 0 &&
                                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                in {timer} {timer == 1 ? "second" : "seconds"}
                                            </p>
                                        }
                                    </div>

                                    <p className='mb-0 mt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                <div className='d-flex gap-3 padding-32px-top mt-auto mb-2'>
                                    <SecondaryButton name="Cancel"
                                        dismiss="modal"
                                        className="padding-10px-top padding-10px-bottom  w-100 e-border-bright-gray"
                                        onPress={props.onClose} />
                                    <PrimaryButton name="Update"
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleUpdatePhoneNumber} />
                                </div>
                            </>

                        }



                    </div>
                </div>
            </div>
        </div >
    )
}

export default memo(EditPhoneNumberModal);