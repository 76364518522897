/*
 *   File : login.js
 *   Author : https://evoqins.com
 *   Description : login page
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

//custom components
import { OTPInput, CustomPhoneInput, RadioGroup, CustomTextInput } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import APIService from "../../Service/service";
import { Footer } from "../../Component/Footer";
import { Header } from "../../Component/Navigation";
import { Icon } from "../../Component/Icon"
import Store from "../../Store/store";

//custom styles
import styles from "../../Style/Component/login.module.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import { _getProfile } from "../../Helper";

// Custom data
const SIGNIN_METHODS = [
    {
        id: 1,
        display_name: "Phone",
        icon: "signup-phone-icon"
    },
    {
        id: 2,
        display_name: "Email",
        icon: "singup-mail-icon"
    },
];


const Login = () => {

    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { pathname, state } = useLocation();

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedSignInMethod, setSelectedSignInMethod] = useState(1);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(state && state.phone_number ? state.phone_number : "");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(2);
    const [timer, setTimer] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState(state && state.country_code ? state.country_code : "+91");
    const [selectedCountryLocale, setSelectedCountryLocale] = useState(state && state.country_locale ? state.country_locale : "IN");

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        // navigate(pathname, { replace: true });
    }, [pathname]);

    //for timer
    useEffect(() => {
        if (timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        };
    }, [timer]);

    // When redirecting from signup flow
    useEffect(() => {
        if (state !== null) {
            if (state.phone_number) {
                setPhoneNumber(state.phone_number);
                setSelectedSignInMethod(1);
            }

            if (state.email) {
                setEmail(state.email);
                setSelectedSignInMethod(2);
            }

            if (state.country_code) {
                setSelectedCountryCode(state.country_code);
            }

            if (state.country_locale) {
                setSelectedCountryLocale(state.country_locale);
            }

        }
    }, [state]);

    // handling Enter key 
    useEffect(() => {

        const _handleOnEnter = (e) => {
            if (e.key === 'Enter') {
                if (currentStep == 1) {
                    _handleGetSignInOtp();
                } else {
                    _handleVerifyOtp();
                }
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };

    }, [currentStep, phoneNumber, otpValue, email]);

    // handle Signin method select
    const _handleSignInMethodSelect = (id) => {
        setSelectedSignInMethod(id);
    };

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    // handle phone number change
    const _handlePhoneNumberChange = (value, selected_country_code, country_locale) => {
        setPhoneNumber(value);
        setPhoneNumberError("");

        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }

        if (country_locale) {
            setSelectedCountryLocale(country_locale);
        }
    };

    //handle back
    const _handleBack = () => {
        setCurrentStep((cur_step) => cur_step - 1);
        setTimer(0);
    };

    // handle RESEND OTP 
    const _handleResendOTP = () => {
        let resend = true;
        setDisableResendOTP(true);
        _getOTP(resend);
    };

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        if (loading !== true) {
            setOtpValue(value);
        };
    };

    //handle get otp signup
    const _handleGetSignInOtp = () => {

        let validation = true;

        // valid phone number and email pattern 
        let valid_phone = /^[6-9]\d{9}$/;
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (selectedSignInMethod == 1) {

            // removing country code
            let phone_number = Number(phoneNumber.replace(selectedCountryCode, ""));

            // Validating phone
            if (selectedCountryCode == "+91") {
                if (!valid_phone.test(phone_number)) {
                    setPhoneNumberError("Please enter a valid 10-digit phone number");
                    validation = false;
                }
            } else {
                let is_valid_number = isValidPhoneNumber(String(phoneNumber));
                if (is_valid_number === false) {
                    setPhoneNumberError("Please enter a valid number");
                    validation = false;
                }
            }

        } else {

            // Validating email
            if (!validate_email.test(email)) {
                setEmailError("Please enter a valid email id");
                validation = false;
            }

        }

        if (validation === true) {
            setLoading(true);
            // API call
            _getOTP();
        }
    };

    //handle for login otp verify
    const _handleVerifyOtp = () => {
        if (otpValue.length !== 4) return;
        setLoading(true);
        // API call
        _verifyOTP();
    };



    // ================================ //
    //============= API CALLS ========= //

    // API - get login otp 
    const _getOTP = (resend) => {

        let is_post = true;
        let url = 'auth/get-login-otp';
        let data = {
            "type": "LOGIN",
            "auth_type": selectedSignInMethod == 1 ? "MOBILE" : "EMAIL",
        };

        if (selectedSignInMethod == 1) {
            data = {
                ...data,
                "country_code": selectedCountryCode,
                "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
            }
        } else {
            data = {
                ...data,
                "email": email
            }
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCurrentStep(2); // OTP page
                setTimer(30);
                setOtpValue("");
                setFocusedInput(2);

                if (resend === true) {
                    toast.dismiss();
                    toast.success(`OTP sent successfully to registered ${selectedSignInMethod == 1 ? " Mobile" : " Email"}`, {
                        className: "e-toast",
                    });
                }

            } else {

                if (response.status_code === 400 && response?.data?.flow === "SIGNUP") {
                    navigate("/signup", {
                        state: {
                            phone_number: phoneNumber,
                            email: email,
                            country_code: selectedCountryCode,
                            country_locale: selectedCountryLocale
                        }
                    });
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        className: "e-toast",
                    });
                }
            }

            setLoading(false);
            setDisableResendOTP(false);
        });
    };


    // API - Verify otp
    const _verifyOTP = () => {

        let is_post = true;
        let url = 'auth/verify-otp';
        let data = {
            "type": "LOGIN",
        };

        if (selectedSignInMethod === 1) {
            data = {
                ...data,
                "country_code": selectedCountryCode,
                "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
                "phone_otp": otpValue,
            }
        } else {
            data = {
                ...data,
                "email": email,
                "email_otp": otpValue,
            }
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                if (response.data.is_auth_configured === false) {

                    navigate("/signup", {
                        state: {
                            auth_configured: false,
                            refresh_token: response.data.refresh_token
                        },
                        replace: true
                    });

                } else {

                    Cookies.set('refresh_token', response.data.refresh_token, { domain: process.env.REACT_APP_DOMAIN, path: '/', expires: 30 });

                    Store.dispatch({
                        type: 'UPDATE_REFRESH_TOKEN',
                        payload: response.data.refresh_token
                    });

                    navigate("/unlock", {
                        replace: true
                    });

                }

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }
            setLoading(false);
        });
    };


    return (
        <>
            <section className="e-login-gradient">
                {/* header  */}
                <Header />

                <div className="container px-4">
                    {/* login  */}
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="e-padding-block-40px-res padding-76px-top padding-150px-bottom position-relative">
                                <div className={`${styles.e_login_wrapper} e-border-radius-32`}>
                                    <div className="row">
                                        <div className="col-md-5 me-5 d-md-block d-none mt-auto">
                                            <h3 className="mb-4 e-text-charleston-green e-font-24 e-alt-font-poppins e-font-weight-600 line-height-40px letter-spacing-neg-2per">
                                                Invest with <br />
                                                <span className="e-font-32" >
                                                    Confidence
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12 pe-sm-0">
                                            <div className="gradient-vr-line ps-md-4">

                                                {
                                                    // step-1 =>  Login  method select
                                                    currentStep === 1 ?
                                                        <div>
                                                            <h3 className="d-flex gap-12px align-items-center e-text-dark-charcoal e-alt-font-poppins e-font-weight-400 e-font-14 line-height-22px e-text-charleston-green ">
                                                                Login or signup with
                                                                {/* gradient line */}
                                                                <span className="gradient-border-line flex-fill"></span>
                                                            </h3>

                                                            <div className="d-flex gap-3 my-4 my-sm-3">
                                                                <RadioGroup
                                                                    type={3}
                                                                    data={SIGNIN_METHODS}
                                                                    selected={selectedSignInMethod}
                                                                    handleSelect={_handleSignInMethodSelect} />
                                                            </div>

                                                            {
                                                                selectedSignInMethod == 2 ?
                                                                    <div className='h-100px margin-3px-bottom'>
                                                                        <CustomTextInput label="Email"
                                                                            placeholder="josephthomas@gmail.com"
                                                                            type="text"
                                                                            value={email}
                                                                            require
                                                                            errorMsg={emailError}
                                                                            className="w-100 ps-3 padding-14px-top padding-14px-bottom"
                                                                            handleChange={_handleEmailChange} />
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="margin-2px-bottom margin-10px-bottom-res ">
                                                                            <CustomPhoneInput label="Phone Number"
                                                                                placeholder="9876543210"
                                                                                className={phoneNumber ? `e-number-input-padding w-100 ` : `e-number-input-padding w-100`}
                                                                                type="text"
                                                                                value={phoneNumber}
                                                                                selectedCountryCode={selectedCountryCode}
                                                                                selectedCountryLocale={selectedCountryLocale}
                                                                                require
                                                                                error={phoneNumber && phoneNumberError}
                                                                                handleChange={_handlePhoneNumberChange} />
                                                                        </div>
                                                                    </>
                                                            }

                                                            <PrimaryButton name={`Get OTP`}
                                                                loading={loading}
                                                                disabled={loading}
                                                                className={`${((selectedSignInMethod == 2 && email.length !== 0) || (selectedSignInMethod == 1 && phoneNumber)) ? "" : "e-bg-bright-gray e-cursor-none"} w-100 padding-12px-top padding-12px-bottom e-font-16 mt-2`}
                                                                onPress={_handleGetSignInOtp} />

                                                            <div className="mt-3">
                                                                <span className="invisible">
                                                                    or
                                                                </span>
                                                                <div className="invisible my-3 d-flex justify-content-center align-items-center">

                                                                    <Icon icon="back-icon"
                                                                        className="cursor-pointer"
                                                                        size={32}
                                                                        onClick={_handleBack} />
                                                                    <Icon icon="back-icon"
                                                                        className="cursor-pointer"
                                                                        size={32}
                                                                        onClick={_handleBack} />

                                                                </div>

                                                                <p className="text-center e-text-dark-liver-70per e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px">
                                                                    By continuing, I agree with
                                                                    <a href="https://indexfundssahihai.com/terms"
                                                                        target="_blank"
                                                                        className="px-1 hover-blue-link">
                                                                        T&C
                                                                    </a>
                                                                    {","}
                                                                    <a href="https://indexfundssahihai.com/privacy-policy"
                                                                        target="_blank"
                                                                        className="px-1 hover-blue-link">
                                                                        Privacy Policy
                                                                    </a>
                                                                </p>
                                                            </div>

                                                        </div>
                                                        :

                                                        // OTP verification
                                                        currentStep === 2 ?
                                                            <div className="py-sm-4 my-sm-2">
                                                                <Icon icon="back-icon"
                                                                    className="cursor-pointer mb-3"
                                                                    size={32}
                                                                    onClick={_handleBack} />

                                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                    {
                                                                        selectedSignInMethod == 1 ? "OTP Verification" : "Verify email"
                                                                    }
                                                                </h3>

                                                                {
                                                                    String(phoneNumber).startsWith("+91") ?
                                                                        <div className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px mb-4'>
                                                                            Enter the 4-digit OTP received on your {selectedSignInMethod == 1 ? " Mobile number " : " Email "}
                                                                            <span className="d-inline-flex gap-1 e-text-chinese-black e-font-weight-600">
                                                                                {selectedSignInMethod == 1 ? ` ${phoneNumber}` : ` ${email}`}
                                                                                <Icon icon="edit-data-icon"
                                                                                    onClick={() => setCurrentStep(1)}
                                                                                    size={20} />
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        <div className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px mb-4'>
                                                                            Enter the 4-digit OTP received on your Email
                                                                            {
                                                                                email &&
                                                                                <span className="d-inline-flex gap-1 e-text-chinese-black e-font-weight-600">
                                                                                    {email}
                                                                                    <Icon icon="edit-data-icon"
                                                                                        onClick={() => setCurrentStep(1)}
                                                                                        size={20} />
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                }

                                                                <OTPInput ref={inputRef}
                                                                    id="otp"
                                                                    value={otpValue}
                                                                    spellcheck="false"
                                                                    focused_input={focusedInput}
                                                                    onFocus={() => setFocusedInput(2)}
                                                                    onBlur={() => setFocusedInput(0)}
                                                                    onChange={_handleChangeOTP} />

                                                                <div className='e-inline-flex flex-wrap pt-2 e-text-charleston-green gap-2 e-font-14 e-alt-font-poppins line-height-18px'>
                                                                    <span className="e-font-weight-500 ">
                                                                        Didn't get the OTP?
                                                                    </span>
                                                                    <button className={`${timer !== 0 ? "e-disable e-text-dark-liver" : "e-text-egyptian-blue"} px-0 d-flex gap-1 border-0 e-bg-transparent e-font-weight-500`}
                                                                        disabled={disableResendOTP}
                                                                        onClick={_handleResendOTP} >
                                                                        Resend OTP
                                                                        {timer !== 0 &&
                                                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-weight-500'>
                                                                                in {timer} {timer == 1 ? "second" : "seconds"}
                                                                            </p>
                                                                        }
                                                                    </button>

                                                                </div>

                                                                <div className='mt-4'>
                                                                    {otpValue.length >= 4 ?
                                                                        <PrimaryButton
                                                                            name={"Continue"}
                                                                            loading={loading}
                                                                            disabled={loading}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-font-16"
                                                                            onPress={_handleVerifyOtp} />
                                                                        :
                                                                        <PrimaryButton
                                                                            name="Continue"
                                                                            disabled={true}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-bg-bright-gray e-font-16 e-cursor-none" />
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* footer section  */}
            <div className="e-bg-anti-flash-white">
                <Footer auth={true} />
            </div>
        </>
    )
}
export default Login;