/*
 *   File : personal-detail.js
 *   Author : https://evoqins.com
 *   Description : Personal detail page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import { differenceInYears, format, isValid, subYears } from 'date-fns';

//custom component
import { CustomAmountInput, CustomTextInput, DatePicker, FilePicker, RadioGroup } from '../../../Component/Form-Elements';
import { SelectionSuggestModal, VerifyEmailModal } from '../../../Component/Modal';
import { PrimaryButton } from '../../../Component/Buttons';
import { APIService, awsService } from '../../../Service';
import { _checkFileSize, _formatDateFormat, _getSignupProgress } from '../../../Helper';


//Assets
import Inactive from "../../../Assets/Images/check-box.svg";
import ActiveCheckbox from "../../../Assets/Images/check-box-active.svg";
import Loader from "../../../Assets/Images/loader.gif";
import LeftArrow from "../../../Assets/Images/left-arrow.svg";

const CITIZEN = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

const DATE_TYPE = [
    {
        id: 1,
        display_name: "Calendar"
    },
    {
        id: 2,
        display_name: "Custom date"
    },
];

const TAX = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];



const PersonalDetail = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.Reducer.GENERAL_DATA);
    const [generalData, setGeneralData] = useState(GENERAL_DATA);

    //get progress data form redux
    const PROGRESS_DATA = useSelector((store) => store.Reducer.SIGNUP_PROGRESS_DATA);

    const [progressData, setProgressData] = useState(PROGRESS_DATA);

    const [email, setEmail] = useState("");

    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [occupationError, setOccupationError] = useState("");

    const [selectedSource, setSelectedSource] = useState([]);
    const [incomeSourceError, setIncomeSourceError] = useState("");

    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countryError, setCountryError] = useState("");

    const [taxPayingCountry, setTaxPayingCountry] = useState([]);
    const [taxPayingCountryError, setTaxPayingCountryError] = useState("");

    const [taxProof, setTaxProof] = useState([]);
    const [taxProofError, setTaxProofError] = useState("");

    const [taxReferenceNumber, setTaxReferenceNumber] = useState("");
    const [taxReferenceNumberError, setTaxReferenceNumberError] = useState("");

    const [dob, setDob] = useState("");
    const [dobError, setDobError] = useState("");

    const [genderSelect, setGenderSelect] = useState("");
    const [martialSelect, setMartialSelect] = useState("");
    const [citizenSelect, setCitizenSelect] = useState(1);
    const [dateTypeSelect, setDateTypeSelect] = useState(1);
    const [taxResidentSelect, setTaxResidentStatus] = useState(2);
    const [income, setIncome] = useState("");
    const [incomeError, setIncomeError] = useState("");
    const [selectTerms, setSelectTerms] = useState(true);
    const [countryData, setCountryData] = useState("");

    const [panFile, setPanFile] = useState(null);
    const [panFileError, setPanFileError] = useState("");
    const [panViewUrl, setPanViewUrl] = useState(null);

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageViewUrl, setImageViewUrl] = useState(null);

    const [signatureFile, setSignatureFile] = useState(null);
    const [signatureFileError, setSignatureFileError] = useState("");
    const [signatureViewUrl, setSignatureViewUrl] = useState(null);

    const [fatherName, setFatherName] = useState("");
    const [fatherNameError, setFatherNameError] = useState("");

    const [motherName, setMotherName] = useState("");
    const [motherNameError, setMotherNameError] = useState("");

    // Loaders
    const [initialStatus, setInitialStatus] = useState(true);
    const [loading, setLoading] = useState(false); // Save details btn
    const [isFileUploading, setIsFileUploading] = useState(false);

    // Modals
    const [showCountryModal, setShowCountryModal] = useState(false);
    const [showOccupationModal, setShowOccupationModal] = useState(false);
    const [showSourceOfIncomeModal, setShowSourceOfIncomeModal] = useState(false);
    const [showTaxPayingCountryModal, setShowTaxPayingCountryModal] = useState(false);
    const [showTaxProofModal, setShowTaxProofModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    useEffect(() => {

        _getCountry();

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    useEffect(() => {

        if (progressData.kyc_data.email !== null) {
            setEmail(progressData.kyc_data.email);
        };

        if (progressData.kyc_data.dob !== null) {
            setDob(_formatDateFormat(progressData.kyc_data.dob));
        };

        if (progressData.kyc_data.father_name !== null) {
            setFatherName(progressData.kyc_data.father_name);
        };

        if (progressData.kyc_data.mother_name !== null) {
            setMotherName(progressData.kyc_data.mother_name);
        };

        if (progressData.kyc_data.gender_id !== null) {
            setGenderSelect(progressData.kyc_data.gender_id);
        } else {
            setGenderSelect(generalData.genders[0].id);
        };

        if (progressData.kyc_data.marital_status_id !== null) {
            setMartialSelect(progressData.kyc_data.marital_status_id);
        } else {
            setMartialSelect(generalData.marital_statuses[0].id);
        };

        if (progressData.kyc_data.is_indian_citizen == true || progressData.kyc_data.is_indian_citizen == null) {
            setCitizenSelect(1);
        } else {
            setCitizenSelect(2);
        };

        if (progressData.kyc_data.is_indian_citizen === false) {
            if (progressData.kyc_data.country_code !== null && progressData.kyc_data.country) {
                setSelectedCountry({ id: progressData.kyc_data.country_code, name: progressData.kyc_data.country });
            };
        }

        if (progressData.kyc_data.is_indian_tax_payer === true) {
            setTaxResidentStatus(2);
        } else {
            setTaxResidentStatus(1);
        };

        if (progressData.kyc_data.tax_country && progressData.kyc_data.tax_country_id) {
            setTaxPayingCountry({ id: progressData.kyc_data.tax_country_id, name: progressData.kyc_data.tax_country })
        };

        if (progressData.kyc_data.reference_proof_id && progressData.kyc_data.reference_proof) {
            setTaxProof({ id: progressData.kyc_data.reference_proof_id, display_name: progressData.kyc_data.reference_proof })
        }

        if (progressData.kyc_data.tax_reference_number) {
            setTaxReferenceNumber(progressData.kyc_data.tax_reference_number);
        };

        if (progressData.kyc_data.occupation_id !== null && progressData.kyc_data.occupation !== null) {
            setSelectedOccupation({ id: progressData.kyc_data.occupation_id, display_name: progressData.kyc_data.occupation });
        };

        if (progressData.kyc_data.income_source_id !== null && progressData.kyc_data.income_source) {
            setSelectedSource({ id: progressData.kyc_data.income_source_id, display_name: progressData.kyc_data.income_source });
        };

        if (progressData.kyc_data.anual_income > 0) {
            setIncome(progressData.kyc_data.anual_income);
        };

        // uploaded file 
        if (progressData.kyc_data.identity_proof_url !== null) {
            let pan_url = progressData.kyc_data.identity_proof_url;
            let updated_name = pan_url.split("/").pop().split("?")[0];

            setPanFile(updated_name);
            setPanViewUrl(progressData.kyc_data.identity_proof_url);
        };

        if (progressData.kyc_data.photo_proof_url !== null) {
            let image_url = progressData.kyc_data.photo_proof_url;
            let updated_image = image_url.split("/").pop().split("?")[0];

            setImage(updated_image);
            setImageViewUrl(progressData.kyc_data.photo_proof_url);
        }

        if (progressData.kyc_data.signature_proof_url !== null) {
            let signature_url = progressData.kyc_data.signature_proof_url;
            let updated_signature = signature_url.split("/").pop().split("?")[0];

            setSignatureFile(updated_signature);
            setSignatureViewUrl(progressData.kyc_data.signature_proof_url);
        };

        setInitialStatus(false);

    }, [progressData]);

    useEffect(() => {
        setGeneralData(GENERAL_DATA);
    }, [GENERAL_DATA]);

    useEffect(() => {
        setProgressData(PROGRESS_DATA);
    }, [PROGRESS_DATA]);

    //Modals
    // handle open edit email modal
    useEffect(() => {
        if (showEmailModal === true) {
            let email_modal = new Modal(document.getElementById('verify-email-modal'));
            email_modal.show();
        };
    }, [showEmailModal]);

    useEffect(() => {
        setDobError("");

        if (dateTypeSelect === 2) {


            if (dob && dob.length === 10) {
                let formatted_date = _formatDateFormat(dob);
                if (isValid(formatted_date) === false) {
                    setDobError("Please enter a valid date");
                } else if (differenceInYears(new Date(), formatted_date) < 18) {
                    setDobError("Invalid date. Your age should be greater than 18yrs");
                }
            }
        };

    }, [dob]);

    useEffect(() => {
        setOccupationError("");
    }, [selectedOccupation]);

    useEffect(() => {
        setIncomeSourceError("");
    }, [selectedSource]);

    useEffect(() => {
        setCountryError("");
    }, [selectedCountry]);

    useEffect(() => {
        setPanFileError("");
    }, [panFile]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setSignatureFileError("");
    }, [signatureFile]);

    useEffect(() => {
        setFatherNameError("");
    }, [fatherName]);

    useEffect(() => {
        setMotherNameError("");
    }, [motherName]);

    //  Opening Modal
    useEffect(() => {

        if (showTaxPayingCountryModal === true) {
            let country_modal = new Modal(document.getElementById('select-tax-paying-country-modal'));
            country_modal.show();
        };

    }, [showTaxPayingCountryModal]);

    useEffect(() => {

        if (showTaxProofModal === true) {
            let tax_proof_modal = new Modal(document.getElementById('select-tax-proof-modal'));
            tax_proof_modal.show();
        };

    }, [showTaxProofModal]);

    useEffect(() => {

        if (showCountryModal === true) {
            let country_modal = new Modal(document.getElementById('select-country-modal'));
            country_modal.show();
        };

    }, [showCountryModal]);

    useEffect(() => {

        if (showSourceOfIncomeModal === true) {
            let source_modal = new Modal(document.getElementById('select-source-modal'));
            source_modal.show();
        };

    }, [showSourceOfIncomeModal]);

    useEffect(() => {

        if (showOccupationModal === true) {
            let occupation_modal = new Modal(document.getElementById('select-occupation-modal'));
            occupation_modal.show();
        };

    }, [showOccupationModal]);


    //handle change date
    const _handleDateChange = (date) => {
        setDob(date);
    };

    const _handleCustomDateChange = (value) => {
        setDob(value);
    };

    //handle select occupation
    const _handleSelectOccupation = (value) => {
        setSelectedOccupation(value);
    };

    //handle select income source
    const _handleSelectSource = (value) => {
        setSelectedSource(value);
    };

    //handle select country
    const _handleSelectCountry = (value) => {
        setSelectedCountry(value);
    };

    const _handleSelectTaxPayingCountry = (value) => {
        setTaxPayingCountry(value);
        setTaxPayingCountryError("");
        setShowTaxPayingCountryModal(false);
    };

    const _handleSelectTaxProof = (value) => {
        setTaxProof(value);
        setTaxProofError("");
    };

    const _handleChangeTaxReference = (value) => {
        setTaxReferenceNumber(value)
        setTaxReferenceNumberError("");
    };


    //handle gender select
    const _handleGenderSelect = (id) => {
        setGenderSelect(id);
    };

    //handle date type select
    const _handleDateTypeSelect = (id) => {
        setDob("");
        setDobError("");
        setDateTypeSelect(id);
    };

    //handle the martial status
    const _handleMartialSelect = (id) => {
        setMartialSelect(id);
    };

    //handle citizenship
    const _handleCitizenSelect = (id) => {
        setCitizenSelect(id);
    };

    //handle tax
    const _handleTaxSelect = (id) => {
        setTaxResidentStatus(id);
    };

    //handle INcome
    const _handleIncome = (value) => {
        setIncome(value);
        setIncomeError("");
    };

    //handle select terms and condition
    const _handleSelectTerms = () => {
        setSelectTerms((selectTerms) => !selectTerms);
    };

    //handle pan file upload
    const _handleUploadPAN = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setPanFileError("Maximum file size should be 5mb only");
                return;
            }

            _getPresignedUrl(file, 1);
        }

    };

    //handle upload photo 
    const _handleUploadImage = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setImageError("Maximum file size should be 5mb only");
                return;
            }
            _getPresignedUrl(file, 2);
        }

    };

    //handle upload signature
    const _handleUploadSignature = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setSignatureFileError("Maximum file size should be 5mb only");
                return;
            }
            _getPresignedUrl(file, 3);
        }

    };

    //handle submit details
    const _handleSubmit = () => {

        let errors = [];
        let validation = true;

        if (dob.length === 0) {
            setDobError("Please enter your date of birth");
            errors.push("dob");
            validation = false;
        } else {
            if (dateTypeSelect === 2) {
                let formatted_date = _formatDateFormat(dob);
                if (dob.length !== 10) {
                    setDobError("Please enter a valid date (DD-MM-YYYY)");
                    errors.push("dob");
                    validation = false;
                } else if (isValid(formatted_date) === false) {
                    setDobError("Please enter a valid date");
                    errors.push("dob");
                    validation = false;
                } else if (differenceInYears(new Date(), formatted_date) < 18) {
                    setDobError("Invalid date. Your age should be greater than 18yrs");
                    errors.push("dob");
                    validation = false;
                }
            }
        }

        // ONLY FOR NON KYC USER
        if (progressData.kyc_user === false) {

            if (fatherName.length === 0) {
                setFatherNameError("Please provide your father's name");
                errors.push("father-name");
                validation = false;
            }

            if (motherName.length === 0) {
                setMotherNameError("Please provide your mother's name");
                errors.push("mother-name");
                validation = false;
            }
        };

        if (selectedOccupation.length === 0) {
            setOccupationError("Please specify your occupation type");
            errors.push("occupation");
            validation = false;
        };

        if (selectedSource.length === 0) {
            setIncomeSourceError("Please specify your source of income");
            errors.push("source-of-income");
            validation = false;
        };

        if (income === 0 || income.length === 0) {
            setIncomeError("Please provide your annual income");
            errors.push("income");
            validation = false;
        };

        if (citizenSelect === 2) {
            if (selectedCountry.length === 0) {
                setCountryError("Please select your country");
                errors.push("country");
                validation = false;
            }
        };


        if (taxResidentSelect === 1) {
            if (taxPayingCountry.length === 0) {
                setTaxPayingCountryError("Please select your tax paying country");
                errors.push("tax-paying-country");
                validation = false;
            };

            if (taxProof.length === 0) {
                setTaxProofError("Please select tax proof");
                errors.push("tax-proof");
                validation = false;
            }

            if (taxReferenceNumber.length === 0) {
                setTaxReferenceNumberError("Please enter tax reference number");
                errors.push("tax-reference");
                validation = false;
            }
        };

        // ONLY FOR NON KYC USER
        if (progressData.kyc_user === false) {

            if (panFile === null) {
                setPanFileError("Please upload a photo of your PAN card");
                errors.push("pan-file");
                validation = false;
            }

            if (image === null) {
                setImageError("Please upload a clear image of your photograph");
                errors.push("photo");
                validation = false;
            }

            if (signatureFile === null) {
                setSignatureFileError("Please upload a clear image of your signature");
                errors.push("sign");
                validation = false;
            }
        };


        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        if (validation === true && selectTerms) {
            setLoading(true);
            _savePersonalData();
        };

    };

    //get presigned url
    async function _getPresignedUrl(file, type) {

        setIsFileUploading(true);

        let file_name = file.target.files[0].name;

        let is_post = true;
        let url = 'file/upload';
        let data = {
            "type": file.target.files[0].type,
            "file": file_name,
            "is_public": true
        };

        try {
            let response = await APIService(is_post, url, data);
            let aws_URL = '';
            if (response.status_code == 200) {

                aws_URL = response.data.upload_info.url;
                let view_url = response.data.view_info;

                let file_param = new File(
                    [file.target.files[0]],
                    file.target.files[0].name,
                    { type: file.target.files[0].type }
                );

                try {
                    let awsResponse = await awsService(response.data.upload_info.fields, file_param, aws_URL);

                    if (awsResponse.status == 'ok') {

                        if (type === 1) {
                            setPanViewUrl(view_url);
                            setPanFile(file_name);
                        }
                        if (type === 2) {
                            setImageViewUrl(view_url);
                            setImage(file_name);
                        }
                        if (type === 3) {
                            setSignatureViewUrl(view_url);
                            setSignatureFile(file_name);
                        }

                    } else {
                        toast.dismiss();
                        toast.error(awsResponse.message, { className: "e-toast" });
                    }
                } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, { className: "e-toast" });
                }
            }

            setIsFileUploading(false);

        } catch (err) {

            setIsFileUploading(false);
        }
    };

    //API- Post save personal data 
    const _savePersonalData = () => {

        if (progressData.kyc_user === false && (imageViewUrl === null || panViewUrl === null || signatureViewUrl === null)) {
            setLoading(false);
            return;
        };

        let is_post = true;
        let url = 'kyc/save-personal-data';
        let data = {
            "dob": dateTypeSelect === 1 ? format(dob, "dd-MM-yyyy") : dob,
            "gender_id": genderSelect,
            "is_indian_citizen": citizenSelect === 1 ? true : false,
            "country_id": citizenSelect === 1 ? null : selectedCountry.id,
            "is_indian_tax_payer": taxResidentSelect === 2 ? true : false,
            "reference_proof_id": taxResidentSelect === 1 ? taxProof.id : null,
            "tax_reference_number": taxResidentSelect === 1 ? taxReferenceNumber : null,
            "tax_country_id": taxResidentSelect === 1 ? taxPayingCountry.id : null,
            "occupation_id": selectedOccupation.id,
            "income_source_id": selectedSource.id,
            "annual_income": income,
            "marital_status_id": martialSelect,
            "father_name": fatherName,
            "mother_name": motherName,
            "photo_proof_url": imageViewUrl,
            "signature_proof_url": signatureViewUrl,
            "identity_proof_url": panViewUrl

        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                //Updating redux 
                _getSignupProgress().then((response) => {

                    if (response.status_code === 200) {
                        props.onUpdateStep(3);
                        toast.dismiss();
                        toast.success("Personal details saved successfully", {
                            className: "e-toast",
                        });
                    } else {
                        toast.dismiss();
                        toast.error(response.message, {
                            className: "e-toast",
                        });
                    }

                    setLoading(false);

                });

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });

                setLoading(false);
            }

        });
    };

    //API- Get countries
    const _getCountry = () => {
        let is_post = false;
        let url = 'general/countries';
        let data = null;
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryData(response.data);
            } else {
                setCountryData("");
                toast.dismiss();
                toast.error("We are facing issue in fetching country data. Please try later", { className: "e-toast" });
            };


        });
    };

    return (
        <>
            {
                initialStatus === false ?
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>

                            <div className='padding-26px-top'>
                                <img src={LeftArrow}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={() => props.onUpdateStep(1)} />
                            </div>

                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-egyptian-blue e-border-radius-16 top-0'
                                    style={{ width: `${(100 / progressData.step_detail.length) * 2}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                    Step 2 of {progressData.step_detail.length}
                                </p>
                            </div>

                            {/* Personal details */}
                            <div className='d-flex gap-4 flex-column pt-3'>
                                <h3 className='e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    Personal details
                                </h3>

                                <div className='position-relative'>
                                    <CustomTextInput label="Email*"
                                        placeholder="josephthomas@gmail.com"
                                        type="text"
                                        value={email}
                                        readOnly
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom pe-5"
                                        labelStyle="e-font-weight-500" />
                                    <p className='position-absolute bottom-14px-res bottom-10px right-16px text-decoration-hover-underline mb-0 cursor-pointer e-text-indigo e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px'
                                        onClick={() => setShowEmailModal(true)}>
                                        Edit
                                    </p>
                                </div>

                                {/* Date picker */}
                                <div>
                                    <h6 id='dob'
                                        className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Date of birth*
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3 mb-3 pt-1'>
                                        <RadioGroup data={DATE_TYPE}
                                            selected={dateTypeSelect}
                                            handleSelect={_handleDateTypeSelect} />
                                    </div>
                                    <div className='h-44'>


                                        {
                                            dateTypeSelect === 1 ?
                                                <DatePicker value={dob}
                                                    minDate={subYears(new Date(), 100)}
                                                    maxDate={subYears(new Date(), 18)}
                                                    error={dobError}
                                                    onChange={_handleDateChange} />
                                                :
                                                <CustomTextInput
                                                    placeholder="DD-MM-YYYY"
                                                    type="text"
                                                    style="date"
                                                    value={dob}
                                                    maxLength={10}
                                                    errorMsg={dobError}
                                                    className="w-100 px-3 padding-11px-tb"
                                                    labelStyle="e-font-weight-500"
                                                    handleChange={_handleCustomDateChange} />
                                        }
                                    </div>

                                </div>

                                {/* Aadhaar number */}
                                {
                                    progressData.kyc_user === false &&
                                    <>

                                        {/*Father name */}
                                        <div id='father-name'>
                                            <CustomTextInput label="Father name*"
                                                placeholder="John doe"
                                                type="text"
                                                value={fatherName}
                                                errorMsg={fatherNameError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => {
                                                    setFatherName(e);
                                                }} />
                                        </div>

                                        {/* Mother name */}
                                        <div id='mother-name'>
                                            <CustomTextInput label="Mother name*"
                                                placeholder="Marry John"
                                                type="text"
                                                value={motherName}
                                                errorMsg={motherNameError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => {
                                                    setMotherName(e);
                                                }} />
                                        </div>

                                    </>
                                }



                                {/* gender  */}
                                <div>
                                    <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Your gender*
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={generalData.genders}
                                            selected={genderSelect}
                                            handleSelect={_handleGenderSelect} />
                                    </div>
                                </div>

                                {/* martial status */}
                                <div>
                                    <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Your martial status*
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={generalData.marital_statuses}
                                            selected={martialSelect}
                                            handleSelect={_handleMartialSelect} />
                                    </div>
                                </div>
                            </div>

                            {/* Nationality, Tax and Source */}
                            <div className='mt-4 d-flex flex-column gap-2'>
                                <h3 className='mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    Nationality, Tax and Source
                                </h3>

                                {/* citizen */}
                                <div className='mb-2'>
                                    <h6 className='e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Are you an Indian citizen?
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={CITIZEN}
                                            selected={citizenSelect}
                                            handleSelect={_handleCitizenSelect} />
                                    </div>
                                </div>

                                {/* Country select */}
                                {
                                    citizenSelect === 2 &&
                                    <div className='position-relative h-90'
                                        id='country'>
                                        <CustomTextInput label="Country*"
                                            placeholder="Select country"
                                            dropdown={true}
                                            type="text"
                                            value={selectedCountry.name}
                                            errorMsg={countryError}
                                            readOnly
                                            disabled={countryData ? false : true}
                                            className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                            labelStyle="e-font-weight-500"
                                            onClick={() => setShowCountryModal(true)} />
                                    </div>
                                }

                                {/* tax detail */}
                                <div className='mb-2'>
                                    <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Are you a tax-paying resident in any other country?
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={TAX}
                                            selected={taxResidentSelect}
                                            handleSelect={_handleTaxSelect} />
                                    </div>
                                </div>

                                {
                                    taxResidentSelect == 1 &&
                                    <>
                                        <div className='position-relative h-90'
                                            id='tax-paying-country'>
                                            <CustomTextInput label="Tax paying country*"
                                                placeholder="Select country"
                                                dropdown={true}
                                                type="text"
                                                value={taxPayingCountry.name}
                                                errorMsg={taxPayingCountryError}
                                                readOnly
                                                disabled={countryData ? false : true}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                onClick={() => setShowTaxPayingCountryModal(true)} />
                                        </div>
                                        <div id='tax-proof'
                                            className='h-90 position-relative'>
                                            <CustomTextInput label="Tax proof*"
                                                dropdown={true}
                                                placeholder="Select tax proof"
                                                type="text"
                                                value={taxProof.display_name}
                                                errorMsg={taxProofError}
                                                readOnly
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                onClick={() => setShowTaxProofModal(true)} />
                                        </div>
                                        <div id='tax-reference'
                                            className='h-90 position-relative'>
                                            <CustomTextInput label="Tax reference number*"
                                                placeholder="FHDC28738"
                                                type="text"
                                                value={taxReferenceNumber}
                                                errorMsg={taxReferenceNumberError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                handleChange={_handleChangeTaxReference} />
                                        </div>
                                    </>
                                }

                                {/* Occupation select */}
                                <div id='occupation'
                                    className='h-90 position-relative'>
                                    <CustomTextInput label="Occupation*"
                                        dropdown={true}
                                        placeholder="Select occupation"
                                        type="text"
                                        value={selectedOccupation.display_name}
                                        errorMsg={occupationError}
                                        readOnly
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                        labelStyle="e-font-weight-500"
                                        onClick={() => setShowOccupationModal(true)} />
                                </div>

                                {/* source of Income */}
                                <div id='source-of-income'
                                    className='h-90 position-relative'>
                                    <CustomTextInput label="Source of income*"
                                        dropdown={true}
                                        placeholder="Select source of income"
                                        type="text"
                                        value={selectedSource.display_name}
                                        errorMsg={incomeSourceError}
                                        readOnly
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer "
                                        labelStyle="e-font-weight-500"
                                        onClick={() => setShowSourceOfIncomeModal(true)} />
                                </div>

                                {/* Annual income */}
                                <div id='income'>
                                    <CustomAmountInput label='Annual income*'
                                        placeholder={0}
                                        value={income}
                                        errorMsg={incomeError}
                                        maxLength={8}
                                        labelStyle="e-font-weight-500"
                                        handleChange={(value) => _handleIncome(value)} />
                                </div>

                                {
                                    progressData.kyc_user === false &&
                                    <>
                                        {/* Upload PAN*/}
                                        <div id='pan-file'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-18px e-font-14 mb-2">
                                                Upload PAN image*(max.5mb)
                                            </label>

                                            {/* uploaded pan */}
                                            {panFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='e-display-flex justify-content-space-between'>
                                                        <p className='e-font-12-res mb-0 padding-12px-top padding-12px-bottom e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            {panFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/KYC/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => setPanFile(null)} />
                                                    </div>
                                                </div>
                                            }

                                            <FilePicker id="pan"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                error={panFileError}
                                                currentFile={panFile}
                                                selectFile={_handleUploadPAN} />
                                        </div>

                                        {/* upload your photo */}
                                        <div id='photo'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-18px e-font-14 mb-2">
                                                Upload your photo*(max.5mb)
                                            </label>
                                            {/* uploaded image */}
                                            {image &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='e-display-flex justify-content-space-between'>
                                                        <p className='e-font-12-res mb-0 padding-12px-top padding-12px-bottom e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            {image}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/KYC/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => setImage(null)} />
                                                    </div>
                                                </div>
                                            }
                                            <FilePicker id="your-image"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                currentFile={image}
                                                error={imageError}
                                                selectFile={_handleUploadImage} />
                                        </div>

                                        {/* upload signature file */}
                                        <div id='sign'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-18px e-font-14 mb-2">
                                                Upload your signature*(max.5mb)
                                            </label>

                                            {signatureFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='e-display-flex justify-content-space-between'>
                                                        <p className='e-font-12-res mb-0 pe-2 pe-sm-0 no-scrollbar  overflow-scroll padding-12px-top padding-12px-bottom e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            {signatureFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/KYC/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => setSignatureFile(null)} />
                                                    </div>
                                                </div>
                                            }

                                            <FilePicker id="signature"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                error={signatureFileError}
                                                currentFile={signatureFile}
                                                selectFile={_handleUploadSignature} />
                                        </div>
                                    </>
                                }


                            </div>

                            {/* terms and conditions */}
                            <div className='pt-4 mt-sm-2'>
                                <div className='d-flex align-items-start gap-0-12px'>
                                    <img src={selectTerms ? ActiveCheckbox : Inactive}
                                        draggable={false}
                                        className='cursor-pointer'
                                        alt='checkbox'
                                        onClick={_handleSelectTerms} />
                                    <div >


                                        <p className='mb-0  e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                            I agree to the Index Funds Sahi Hai
                                            <a href="https://indexfundssahihai.com/terms"
                                                target="_blank"
                                                className='px-1 e-text-egyptian-blue cursor-pointer text-decoration-hover-underline'>
                                                terms and conditions.
                                            </a>
                                            Also, I assure that I will read KIM & SID of funds before investments.
                                        </p>
                                        <p className={`${selectTerms === false && "opacity-100"} opacity-0 mb-0 e-error-message e-font-12`}>
                                            please select terms and conditions.
                                        </p>

                                    </div>
                                </div>

                            </div>

                            {/* button */}
                            <div className='pt-4 mt-2'>
                                <PrimaryButton name={"Save & Continue"}
                                    loading={loading}
                                    disabled={loading}
                                    className={`${isFileUploading && "pe-none"} w-40 padding-12px-top padding-12px-bottom`}
                                    onPress={_handleSubmit} />
                            </div>
                        </div>
                    </div >
                    :
                    <div className="min-h-100vh text-center mt-5 pt-5" >
                        <img src={Loader}
                            className='object-fit-contain w-20'
                            draggable="false"
                            alt="loader" />
                    </div>
            }


            {/* Country select modal */}
            {
                showCountryModal === true &&
                <SelectionSuggestModal title="Choose a country"
                    data={countryData.countries.filter((item) => item.id !== 251)}
                    type={3}
                    id='select-country-modal'
                    selectedOption={selectedCountry}
                    onSelectOption={_handleSelectCountry}
                    onModalClose={() => setShowCountryModal(false)} />
            }

            {/* occupation modal*/}
            {
                showOccupationModal === true &&
                <SelectionSuggestModal title="Choose an occupation"
                    data={generalData.occupation}
                    type={1}
                    id='select-occupation-modal'
                    selectedOption={selectedOccupation}
                    onSelectOption={_handleSelectOccupation}
                    onModalClose={() => setShowOccupationModal(false)} />
            }

            {/* source of income select */}
            {
                showSourceOfIncomeModal &&
                <SelectionSuggestModal title="Choose a source of income"
                    data={generalData.source_of_wealths}
                    type={2}
                    id='select-source-modal'
                    selectedOption={selectedSource}
                    onSelectOption={_handleSelectSource}
                    onModalClose={() => setShowSourceOfIncomeModal(false)} />
            }


            {/* Tax paying country select modal */}
            {
                showTaxPayingCountryModal === true &&
                <SelectionSuggestModal title="Choose a tax paying country"
                    data={countryData.countries.filter((item) => item.id !== 251)}
                    type={4}
                    id='select-tax-paying-country-modal'
                    selectedOption={taxPayingCountry}
                    onSelectOption={_handleSelectTaxPayingCountry}
                    onModalClose={() => setShowTaxPayingCountryModal(false)} />
            }

            {/* Tax proof modal*/}
            {
                showTaxProofModal === true &&
                <SelectionSuggestModal title="Choose a tax proof"
                    data={generalData.fatca_verification}
                    type={5}
                    id='select-tax-proof-modal'
                    selectedOption={taxProof}
                    onSelectOption={_handleSelectTaxProof}
                    onModalClose={() => setShowTaxProofModal(false)} />
            }


            {/* verify email and otp modal */}
            {
                showEmailModal === true &&
                <VerifyEmailModal email={email}
                    onUpdateStep={props.onUpdateStep}
                    onCloseModal={() => setShowEmailModal(false)} />
            }
        </>
    )
}

export default PersonalDetail;