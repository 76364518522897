/*
 *   File : category.js
 *   Author : https://evoqins.com
 *   Description : Index fund category card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

//styles
import styles from "../../Style/Component/card.module.scss";

//assets
import Arrow from "../../Assets/Images/right-black-icon.svg";

const CategoryCard = (props) => {
    return (
        <div style={{ background: `url(${props.cardData.icon}) no-repeat`, backgroundSize: "100% 100%" }}
            className={`${styles.e_category_card} my-3 d-flex align-items-center  cursor-pointer transition`}
            onClick={props.onClick}>
            <div className="w-235px h-112px d-flex align-items-center justify-content-center" style={{ marginLeft: "38%" }}>
                <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px">
                    {props.cardData.category_code}
                </p>
                <img src={Arrow}
                    draggable="false"
                    className={`${styles.e_right_arrow} transition width-20px-res`}
                    alt="arrow" />
            </div>
        </div>
    )
}
export default CategoryCard;