/*
 *   File : account-opening.js
 *   Author : https://evoqins.com
 *   Description : Account opening navigation modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";
import { useNavigate } from "react-router-dom";


// Component
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Assets
import AccountOpeningImage from "../../Assets/Images/Home/account-opening-image.webp";

//Styles
import styles from "../../Style/Component/modal.module.scss";

function AccountOpeningModal(props) {

    const navigate = useNavigate();

    const _handleOnNavigate = () => {
        navigate("/kyc");
        props.handleModalClose();
    };


    return (
        <div className="modal fade p-0 m-0"
            id="account-opening-modal"
            tabIndex="-1"
            aria-labelledby="Account Opening"
            data-bs-backdrop="static"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered min-h90vh">
                <div className={`modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow`}>
                    <div className={` ${styles.account_opening_modal_bg} modal-body p-4 e-border-radius-24`}>

                        <h6 className="mb-3 col-10 col-sm-6 mx-auto text-center e-font-18-res text-center e-text-charleston-green e-alt-font-poppins e-font-20 e-font-weight-600 line-height-30px">
                            Open an Investment Account Now
                        </h6>
                        <img className="d-block img-fluid col-6 mx-auto mb-4"
                            draggable={false}
                            src={AccountOpeningImage}
                            alt="payment status image" />
                        <p className="margin-32px-bottom text-center e-text-onyx e-alt-font-poppins e-font-14 e-font-weight-400 line-height-22px">
                            It will take less than 5 minutes to create your investment account with Index Funds Sahi Hai. Create your account and start your investment instantly                        </p>

                        <div className="d-flex flex-column flex-sm-row gap-2 w-100">
                            <SecondaryButton name={"Skip for now"}
                                dismiss={"modal"}
                                className="w-100 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                onPress={props.handleModalClose}
                            />
                            <PrimaryButton name={"Open an Account"}
                                dismiss={"modal"}
                                className="padding-12px-tb w-100"
                                onPress={_handleOnNavigate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(AccountOpeningModal);