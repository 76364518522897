/*
 *   File : footer.js
 *   Author : https://evoqins.com
 *   Description : Footer component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//custom style
import styles from "../../Style/Component/footer.module.scss";

//Assets
import Logo from "../../Assets/Images/index-fund-logo.svg";
import Facebook from "../../Assets/Images/Login/facebook.svg";
import Twitter from "../../Assets/Images/Login/twittwe.svg";
import Youtube from "../../Assets/Images/Login/youtube.svg";
import Instagram from "../../Assets/Images/Login/instagram.svg";
import LinkdIN from "../../Assets/Images/Login/linkdin.svg";
import Telegram from "../../Assets/Images/Login/telagram.svg";

// Custom data 
const FOOTER_LINKS = [
    {
        id: 1,
        label: "Home",
        path: "/"
    },
    {
        id: 2,
        label: "Contact",
        path: "/contact"
    },
    {
        id: 3,
        label: "FAQs",
        path: "/faq"
    },
];

const SOCIAL_LINKS = [
    {
        id: 1,
        img_src: Facebook,
        alt: "index fund facebook link",
        link: "/"
    },
    {
        id: 2,
        img_src: Twitter,
        alt: "index fund facebook link",
        link: "/"
    },
    {
        id: 3,
        img_src: Youtube,
        alt: "index fund youtube link",
        link: "/"
    },
    {
        id: 4,
        img_src: Instagram,
        alt: "index fund instagram link",
        link: ""
    },
    {
        id: 5,
        img_src: LinkdIN,
        alt: "index fund linkedin link",
        link: "/"
    },
    {
        id: 6,
        img_src: Telegram,
        alt: "index fund telegram link",
        link: "/"
    },
]

const Footer = (props) => {

    let location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const current_year = new Date().getFullYear();

    const _handleScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };

    //handle footer navigate 
    const _handleNavigate = (type) => {
        if (type.id === 2) {
            window.open("https://indexfundssahihai.com/contact-us", '_blank');
        } else {
            if (type.path === "/faq") {
                window.open("https://indexfundssahihai.com/faq", "_blank");
            } else {
                navigate(type.path);
            }
        }
    };

    return (
        <>
            {props.auth === true ?

                <div className="container-fluid">
                    <div className="row mx-sm-5 ps-4 px-sm-4 padding-42px-top">
                        <div className="col-md-auto col-sm-12 pb-md-0 pb-4 px-0">
                            <img src={Logo}
                                className="transform-scale-hover cursor-pointer"
                                draggable="false"
                                alt="logo"
                                onClick={_handleScroll} />
                            <p className="mt-4 d-flex flex-column e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                <span className="e-font-14"> Index funds distribution Pvt LTD</span>
                                <span className="e-font-14">102, Pranav Chs Ltd, Eksar Road,</span>
                                <span className="e-font-14"> Opp Sushrut Hospital, Borivali</span>
                                <span className="e-font-14"> West Mumbai, Maharashtra 400091</span>
                            </p>

                            {/* Will be enabled in future */}
                            {/* <div className={`${styles.e_social_wrapper} pt-4 d-flex gap-12px`}>
                                {
                                    SOCIAL_LINKS.map((item, key) => (
                                        <a href={item.link}
                                            key={key}
                                            className="text-decoration-none">
                                            <img src={item.img_src}
                                                draggable="false"
                                                alt={item.alt} />
                                        </a>
                                    ))
                                }
                            </div> */}
                        </div>
                        <div className="col-auto ms-md-auto me-sm-5 ps-0">
                            <p className="mb-3 mb-sm-4 e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                Company
                            </p>
                            <div>
                                <a href="https://indexfundssahihai.com/about-us"
                                    target="_blank"
                                    className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                    About
                                </a>
                            </div>
                            <div className="pt-3 pt-sm-4">
                                {/* <a
                                    href="https://indexfundssahihai.com/contact-us"
                                    target="_blank"
                                    className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                    Blog
                                </a> */}
                                <a
                                    href="https://indexfundssahihai.com/contact-us"
                                    target="_blank"
                                    className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                    Support
                                </a>
                            </div>
                        </div>
                        <div className="col-auto pe-0">
                            <p className="mb-3 mb-sm-4 e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                Other links
                            </p>
                            <div className="d-flex flex-column gap-3">
                                {/* <div>
                                    <a
                                        href="https://indexfundssahihai.com/contact-us"
                                        target="_blank"
                                        className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        Support
                                    </a>
                                </div> */}
                                <div>
                                    <span
                                        onClick={() => window.open("https://indexfundssahihai.com/faq", "_blank")}
                                        className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        FAQ
                                    </span>
                                </div>
                                <div>
                                    <a href="https://indexfundssahihai.com/privacy-policy"
                                        target="_blank"
                                        className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        Privacy
                                    </a>
                                </div>
                                <div>
                                    <a href="https://indexfundssahihai.com/terms"
                                        target="_blank"
                                        className="text-decoration-none text-decoration-hover-underline cursor-pointer e-text-rich-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        Terms & Conditions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row px-4 px-sm-auto  padding-32px-bottom padding-res-8px-bottom" >
                        <p className="px-0 mb-0 e-text-yankees-blue e-font-12 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            *Disclaimer lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                        </p>
                    </div> */}
                    <hr className="col e-text-azureish-white opacity-100 my-0 mx-sm-5 ps-4 px-sm-4 mt-3" />
                    <div className="row mx-sm-5 ps-4 px-sm-4 pt-xl-4 pt-lg-4 pt-md-3 pt-sm-2 pt-2 mt-1 mt-sm-2 pb-4">
                        <div className="col-sm-6 col-12 px-0">
                            <p className="mb-0 pb-1 e-text-black e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                <span>©{current_year} </span>
                                All right reserved by Index funds distribution Pvt LTD
                            </p>
                        </div>
                        <div className="col-sm-6 col-12 text-sm-end text-start px-0 mt-2 mt-sm-0">
                            <p className="mb-0 pb-1 e-text-black e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                Design & developed by
                                <a href="https://evoqins.com/"
                                    target="_blank"
                                    className={`${styles.e_developed_by} ms-1 cursor-pointer e-text-black text-decoration-none`}>
                                    Evoqins
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div className="e-display-flex justify-content-center p-4">
                    {
                        location.pathname !== "/faq" && FOOTER_LINKS.map((item, key) => (
                            <React.Fragment key={key}>
                                <span id="footer-links"
                                    className="e-font-14-res text-decoration-hover-underline cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px"
                                    onClick={() => _handleNavigate(item)}>
                                    {item.label}
                                </span>
                                {
                                    FOOTER_LINKS.length - 1 !== key &&
                                    <span className="vr mx-3 e-text-light-silver" />
                                }
                            </React.Fragment>
                        ))
                    }
                    {/* <a className="text-decoration-hover-underline cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px">
                        Home
                    </a>
                    <span className="vr mx-3 e-text-light-silver" />
                    <a className="text-decoration-hover-underline cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px">
                        Contact
                    </a>
                    <span className="vr mx-3 e-text-light-silver" />
                    <a className="text-decoration-hover-underline cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px">
                        FAQs
                    </a> */}
                </div>
            }


        </>
    )
}
export default Footer;