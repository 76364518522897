/*
 *   File : calculate-corpus.js
 *   Author : https://evoqins.com
 *   Description : Calculate corpus modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';

//custom component
import { PrimaryButton } from '../Buttons';
import { CustomAmountInput, CustomSlider } from '../Form-Elements';
import { _amountInWords, _formatToRupees } from '../../Helper';
import { APIService } from '../../Service';
import { toast } from 'react-toastify';

const CalculateCorpusModal = (props) => {


    const [data, setData] = useState(props.data);

    const [currentAge, setCurrentAge] = useState(props.data.admin_configured.meta_data.current_age.default);
    const [retireAge, setRetireAge] = useState(props.data.admin_configured.meta_data.retirement_age.default);

    const [currentExpense, setCurrentExpense] = useState("");
    const [currentExpenseError, setCurrentExpenseError] = useState("");
    const [calculatedCorpus, setCalculatedCorpus] = useState("");

    const [calculate, setCalculate] = useState(false);


    useEffect(() => {
        if (props.data) {
            setData(props.data);
            setCurrentAge(props.data.admin_configured.meta_data.current_age.default);
            setRetireAge(props.data.admin_configured.meta_data.retirement_age.default);
        }
    }, [props.data]);

    useEffect(() => {
        setCurrentExpense(0);
    }, []);

    //handle current age slider
    const _handleCurrentAge = (value) => {
        setCurrentAge(value);
    }

    //handle retire age slider
    const _handleRetireAge = (value) => {
        setRetireAge(value);
    }

    //handle expense
    const _handleCurrentExpense = (value) => {
        setCurrentExpense(value);
        setCurrentExpenseError("");
    };

    //calculate 
    const _handleCalculate = () => {
        if (currentExpense === 0) {
            setCurrentExpenseError("Please enter your monthly expenses")
            return;
        }

        let is_post = true;
        let url = 'goal/calculate-corpus';
        let data = {
            "current_age": currentAge,
            "retirement_age": retireAge,
            "monthly_expenses": currentExpense,
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {

                setCalculatedCorpus(response.data);
                setCalculate(true);
            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }
        });


    }

    const _handleReflectRetirementCorpus = () => {
        setCalculate(false);
        props.onClose(calculatedCorpus.corpus_amount);
    };

    return (
        <div className="modal fade e-manage-modal"
            id="calculate-corpus-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="calculateCorpusModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-md">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    {calculate === false ?
                        <>
                            <div className="modal-header justify-content-space-between pt-4 px-4 pb-3 border-0">
                                <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Calculate corpus
                                </h6>
                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    draggable={false}
                                    id="verify-email-modal-close"
                                    className='cursor-pointer w-30px width-24px-res'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={props.onClose} />
                            </div>
                            <div className="modal-body px-4 pb-4 pt-0">

                                <div className='e-bg-cultured e-border-radius-16 p-3'>
                                    <div className='e-display-flex gap-0-8'>
                                        <img src={data?.admin_configured?.icon}
                                            draggable={false}
                                            width={48}
                                            alt='goal-image' />
                                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            Life goal : {data?.admin_configured?.short_name}
                                        </p>
                                    </div>
                                </div>

                                <div className='e-bg-cultured e-border-radius-16 p-3 mt-4'>

                                    {/* current age slider  */}
                                    <div className="d-flex gap-1 gap-sm-2 justify-content-space-between align-items-center">
                                        <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-22px">
                                            What is your current age?
                                        </h4>
                                        <p className="text-nowrap e-font-14-res mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                            {currentAge} Yrs
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <CustomSlider min={data.admin_configured.meta_data.current_age.min_age}
                                            max={data.admin_configured.meta_data.current_age.max_age}
                                            step={1}
                                            defaultValue={currentAge}
                                            handleChange={(value) => _handleCurrentAge(value)} />
                                        <div className="d-flex justify-content-space-between pt-4">
                                            <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                {data.admin_configured.meta_data.current_age.min_age} Yrs
                                            </p>
                                            <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                {data.admin_configured.meta_data.current_age.max_age} Yrs
                                            </p>
                                        </div>
                                    </div>

                                    {/* retire age slider  */}
                                    <div className='mt-4'>
                                        <div className="d-flex gap-1 gap-sm-2 justify-content-space-between align-items-center">
                                            <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-22px">
                                                At what age do you want to retire?
                                            </h4>
                                            <p className="text-nowrap e-font-14-res mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                {retireAge} Yrs
                                            </p>
                                        </div>
                                        <div className="pt-4">
                                            <CustomSlider min={data.admin_configured.meta_data.retirement_age.min}
                                                max={data.admin_configured.meta_data.retirement_age.max}
                                                step={1}
                                                defaultValue={retireAge}
                                                handleChange={(value) => _handleRetireAge(value)} />
                                            <div className="d-flex justify-content-space-between padding-12px-top mt-2">
                                                <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    {data.admin_configured.meta_data.retirement_age.min} Yrs
                                                </p>
                                                <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    {data.admin_configured.meta_data.retirement_age.max} Yrs
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-4">
                                        <CustomAmountInput label='Your current monthly expenses?'
                                            labelStyle="e-font-weight-500"
                                            value={currentExpense}
                                            maxValue = {999999}
                                            errorMsg={currentExpenseError}
                                            handleChange={(value) => { _handleCurrentExpense(value) }} />
                                    </div>
                                </div>

                                <div className='padding-32px-top'>
                                    <PrimaryButton name="Calculate"
                                        className="w-100 padding-12px-top padding-12px-bottom"
                                        onPress={_handleCalculate} />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0">
                                <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    My corpus amount
                                </h6>
                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    draggable={false}
                                    id="verify-email-modal-close"
                                    className='cursor-pointer w-30px width-24px-res'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={props.onClose} />
                            </div>
                            <div className="modal-body px-4 pb-4 pt-3">

                                <div className='e-bg-cultured e-border-radius-16 p-3'>
                                    <div className='e-display-flex gap-0-8'>
                                        <img src={data?.admin_configured?.icon}
                                            draggable={false}
                                            width={48}
                                            alt='goal-image' />
                                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            Life goal : {data?.admin_configured?.short_name}
                                        </p>
                                    </div>
                                    <div className='pt-4'>
                                        <p className='e-font-14-res line-height-20px-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px '>
                                            Recommended retirement corpus
                                        </p>
                                        <p className='e-font-12-res line-height-20px-res mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                            Based on your current expenses and age, you will need the following amount to ensure a peaceful retirement life.
                                        </p>
                                        <p className='mb-0'>
                                        </p>

                                        <p className="e-font-20-res mb-0 padding-12px-top e-text-egyptian-blue e-primary-font-inter e-font-26 e-font-weight-600 line-height-40px">
                                            {calculatedCorpus.corpus_amount_text}
                                        </p>

                                    </div>


                                </div>

                                <div className='mt-4 pt-1 mt-sm-4 pt-sm-2 '>
                                    <PrimaryButton name="Reflect retirement corpus"
                                        className="w-100 padding-12px-top padding-12px-bottom"
                                        dismiss="modal"
                                        onPress={_handleReflectRetirementCorpus}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default CalculateCorpusModal;