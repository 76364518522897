/*
 *   File : kyc-status.js
 *   Author : https://evoqins.com
 *   Description :  kyc status card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//custom component
import { PrimaryButton } from '../Buttons';

const KYCStatusCard = (props) => {

    const [label, setLabel] = useState('');
    const [buttonLabel, setButtonLabel] = useState('');
    const [labelColor, setLabelColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [btnBackgroundColor, setBtnBackgroundColor] = useState('');
    const [icon, setIcon] = useState("");
    const [type, setType] = useState(props.type);

    useEffect(() => {
        if (props.type !== undefined) {
            switch (props.type) {
                case 1:
                    setLabel('You are halfway to your first investment.');
                    setButtonLabel('Continue');
                    setLabelColor("e-text-dark-tangerine");
                    setBackgroundColor("e-bg-floral-white");
                    setBtnBackgroundColor("e-bg-dark-tangerine");
                    setIcon(require("../../Assets/Images/Home/continue.svg").default);
                    break;

                case 2:
                    setLabel('Your application is now ready to E-sign');
                    setButtonLabel('E-sign now');
                    setLabelColor("e-text-medium-orchid");
                    setBackgroundColor("e-bg-anti-flash-white-dark");
                    setBtnBackgroundColor("e-bg-medium-orchid");
                    setIcon(require("../../Assets/Images/Home/e-sign.svg").default);
                    break;
                case 3:
                    setLabel('KYC verification request got rejected');
                    setButtonLabel('Update');
                    setLabelColor("e-text-sunset-orange");
                    setBackgroundColor("e-bg-snow");
                    setBtnBackgroundColor("e-bg-sunset-orange");
                    setIcon(require("../../Assets/Images/Home/rejected.svg").default);
                    break;
                case 4:
                    setLabel('Your request is under review. As soon as the status changes, you will be notified');
                    setButtonLabel('Explore now');
                    setLabelColor("e-text-egyptian-blue");
                    setBackgroundColor("e-bg-alice-blue");
                    setBtnBackgroundColor("e-bg-egyptian-blue");
                    setIcon(require("../../Assets/Images/Home/requested.svg").default);
                    break;
                case 5:
                    setLabel(`Let's start ${'\n'}your first investment`);
                    setButtonLabel('Explore now');
                    setLabelColor("e-text-ufo-green");
                    setBackgroundColor("e-bg-honeydew");
                    setBtnBackgroundColor("e-bg-ufo-green");
                    setIcon(require("../../Assets/Images/Home/explore-now.svg").default);
                    break;
                default:
                    setLabel('');
                    setButtonLabel('');
                    setLabelColor('');
                    setBackgroundColor('');
                    setBtnBackgroundColor('');
                    setIcon('');
                    break;
            }
        }
        setType(props.type);
    }, [props.type]);

    //handle navigate
    const _handleNavigate = () => [
        props.onPress(type)
    ]

    return (
        <div className={`${backgroundColor}  p-3 e-border-radius-16`}>
            <div className="e-display-flex justify-content-space-between flex-wrap row-gap-1 column-gap-2">
                <div className="d-flex e-alignflex-start gap-0-8 mb-2">
                    <img src={icon}
                        draggable={false}
                        className='pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-1'
                        alt="icon" />
                    <p className={`${labelColor} e-font-14-res mb-0 e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                        {label}
                    </p>
                </div>

                <PrimaryButton name={`${buttonLabel}`}
                    // loading={props.loader}
                    disabled={props.loader}
                    className={`${btnBackgroundColor} py-sm-2 py-1 px-sm-3 px-2`}
                    onPress={_handleNavigate} />
            </div>
        </div>
    )
}

export default KYCStatusCard;