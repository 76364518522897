/*
 *   File : calculate-risk-score.js
 *   Author : https://evoqins.com
 *   Description : user investment risk calculate page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useState } from "react";
import { useNavigate } from "react-router";

// Components
import { Footer } from "../../Component/Footer";
import { BreadCrumbs } from "../../Component/Other";
import { PersonalInfo, CalculatedRiskScore, RiskAppetite, SelfEvaluation } from "./Helper";
import { _getProfile } from "../../Helper";


// COLORS
import Colors from "../../Style/color.scss"

// Custom data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Risk Profiler",
    },
];


function CalculateRiskScore() {

    const navigate = useNavigate();
    const [questionTab, setQuestionTab] = useState(1);
    const [scoreData, setScoreData] = useState({});

    const [basketData, setBasketData] = useState([]);

    //handle update step
    const _handleUpdateRiskProfile = (id, data) => {
        setQuestionTab(id);
        if (data) {
            setScoreData(data);
            setBasketData(data.basket);
        }
    }

    const _handleQuestionTab = () => {
        if (questionTab > 1) {
            setQuestionTab(questionTab - 1)
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 d-flex flex-column e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top  e-border-radius-TL-BL-24px" >
                {/* breadcrumbs */}
                <BreadCrumbs data={BREADCRUMBS} />
                <div className={`mb-5 p-0 pt-2 mt-4 ${questionTab !== 4 ? `col-lg-6 col-md-8 col-sm-10 col-12` : `col-12`}`}>

                    {
                        questionTab >= 1 && questionTab < 4 &&
                        <img src={require("../../Assets/Images/left-arrow.svg").default}
                            draggable={false}
                            className='cursor-pointer mb-4'
                            alt='arrow'
                            onClick={_handleQuestionTab} />
                    }

                    {/* Risk profiler steps indicator */}
                    {
                        questionTab !== 4 && <>
                            <div className="progress-stacked e-border-radius-12 h-6">
                                <div style={{ width: questionTab === 1 ? '33%' : questionTab === 2 ? '66%' : '100%' }}
                                    className="progress h-100">
                                    <div style={{ backgroundColor: Colors.egyptian_blue }}
                                        className="progress-bar"></div>
                                </div>

                            </div>
                            <p className="mb-3 pt-2 e-text-egyptian-blue e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px text-end">
                                Step {questionTab} of 3
                            </p>
                        </>
                    }
                    <div>
                        {
                            questionTab === 1 ?
                                <PersonalInfo onUpdateProfile={_handleUpdateRiskProfile} />
                                :
                                questionTab === 2 ?
                                    <RiskAppetite onUpdateProfile={_handleUpdateRiskProfile} />
                                    :
                                    questionTab === 3 ?
                                        <SelfEvaluation onUpdateProfile={_handleUpdateRiskProfile} />
                                        :
                                        questionTab === 4 ?
                                            <CalculatedRiskScore data={scoreData}
                                                basketData={basketData}
                                                onUpdateProfile={_handleUpdateRiskProfile} />
                                            :
                                            null

                        }
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default CalculateRiskScore;