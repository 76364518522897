/*
 *   File : fund.js
 *   Author : https://evoqins.com
 *   Description : Fund explore card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useLocation, useNavigate } from "react-router-dom";

// Component
import Chip from "../Other/chip";

//styles
import styles from "../../Style/Component/card.module.scss";

//assets
import RightIcon from "../../Assets/Images/right-black-icon.svg";

const FundCard = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const _handleOnClick = (id) => {
        let path = location.pathname === "/explore" ? "fund-details" : "/explore/fund-details";
        navigate(path, {
            state: {
                fund_id: id,
            }
        })
    };

    return (
        <div className={`${styles.e_fund_card_wrapper} transition cursor-pointer e-bg-anti-flash-white-light e-border-radius-16 h-100`}
            onClick={() => _handleOnClick(props.data.mf_id)}>
            <div className={`${styles.e_fund_card} d-flex gap-3 p-3`}>
                <div className="">
                    <img src={props.data.image}
                        width={48}
                        height={48}
                        className="object-fit-contain e-border-radius-10 e-bg-white p-1"
                        draggable="false"
                        alt="fund" />
                </div>
                <div className="">
                    <div className="d-flex justify-content-space-between">
                        <h3 className="e-ellipse mb-md-1 mb-sm-2 mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                            {props.data.scheme_name}
                        </h3>
                        <div>
                            <img src={RightIcon}
                                draggable="false"
                                className={`${styles.e_right_arrow} transition`}
                                alt="arrow" />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap gap-2 mt-1">
                        <Chip className={"py-1 px-3 e-font-10 line-height-14px"}
                            name={props.data.asset_category} />

                        {
                            props.data.option_name !== null &&
                            <Chip className={"py-1 px-3 e-font-10 line-height-14px"}
                                name={props.data.option_name} />
                        }
                    </div>
                </div>
            </div>
            {/* calculated */}
            <div className={styles.e_border_bottom}></div>
            <div className="e-display-flex justify-content-between p-3">
                <div>
                    <p className="mb-1 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                        AUM
                    </p>
                    <p className="mb-0 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-600 line-height-20px">
                        {props.data.fund_size}
                    </p>
                </div>
                <div>
                    <p className="mb-1 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                        CAGR
                    </p>
                    <p className="mb-0 e-text-ufo-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                        {props.data.returns.returns_percentage} <span className="e-text-charleston-green e-font-12 e-font-weight-400 ">({props.data.returns.tenure_text})</span>
                    </p>
                </div>
                {
                    props.data.risk !== null &&
                    <img src={props.data.risk_icon}
                        draggable="false"
                        width={70}
                        height={48}
                        className="object-fit-contain"
                        alt="risk-calculator" />
                }
            </div>
        </div>
    )
}
export default FundCard;