/*
 *   File : family.js
 *   Author : https://evoqins.com
 *   Description : Added family detail card
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";

import React from 'react'
import { PrimaryButton, SecondaryButton } from "../Buttons";

function FamilyRequestCard() {
    return (
        <div className="col padding-24px-all-res e-bg-cultured e-border-radius-16 padding-32px-all">
            <h6 className="mb-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                Jack Lucas
            </h6>
            <div className="d-flex flex-wrap row-gap-4">
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        PAN number
                    </p>
                    <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                        ABCDE1234F
                    </p>
                </div>
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Phone number
                    </p>
                    <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                        +91 9823455596
                    </p>
                </div>
                <div className="col-auto">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Email ID
                    </p>
                    <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                        jayden23@gmail.com
                    </p>
                </div>
            </div>
            <div className="d-flex gap-2 margin-top-40px">
                <SecondaryButton name="Decline" className="e-padding-24px-lr-res padding-8px-tb-res padding-50px-lr padding-12px-tb e-border-bright-gray" />
                <PrimaryButton name="Accept" className="e-padding-24px-lr-res padding-8px-tb-res padding-50px-lr padding-12px-tb" />
            </div>
        </div>
    )
}

export default memo(FamilyRequestCard);