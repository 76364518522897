/*
 *   File : explore-filter.js
 *   Author : https://evoqins.com
 *   Description : Filter modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from "react";

// custom component 
import { PrimaryButton, SecondaryButton } from "../Buttons";

//assets
import Close from "../../Assets/Images/close-btn.svg";

const FilterModal = (props) => {

    const [selectedItem, setSelectedItem] = useState([null]);
    const [selectedCategory, setSelectedCategory] = useState([null]);

    useEffect(() => {
        if (props.selectedType.length !== 0) {
            setSelectedItem(props.selectedType);
        }
        if (props.selectedCategory.length !== 0) {
            setSelectedCategory(props.selectedCategory);
        }
    }, [props.selectedType, props.selectedCategory]);

    //handle amc filter
    const _handleAmcFilter = (id) => {

        let updated_amc;

        if (selectedItem.includes(id) && id !== null) {
            updated_amc = selectedItem.filter((item) => item !== id);
        } else {
            if (id == null || (props.data.amc.length - 2 === selectedItem.length)) {
                updated_amc = [null];
            } else {
                updated_amc = selectedItem.filter((item) => item !== null);
                updated_amc = [...updated_amc, id];
            };
        };

        setSelectedItem(updated_amc);
    };

    //handle category filter
    const _handleCategoryFilter = (id) => {
        let updated_category;

        if (selectedCategory.includes(id) && id !== null) {
            updated_category = selectedCategory.filter((item) => item !== id);
        } else {
            if (id == null || (props.data.category.length - 2 === selectedCategory.length)) {
                updated_category = [null];
            } else {
                updated_category = selectedCategory.filter((item) => item !== null);
                updated_category = [...updated_category, id];
            };
        };

        setSelectedCategory(updated_category);
    };

    //Handle submit filter
    const _handleSubmitFilter = (status) => {
        if (status === false) {
            props.onSubmit(props.selectedType, props.selectedCategory);
        } else {
            // selectedAMCs, SelectedCategories
            props.onSubmit(selectedItem, selectedCategory);
        }
    };

    return (
        <div>
            {/* filter by AMC  */}
            <div className="row">
                <div className="col-6">
                    <p className="mb-4 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-32px">
                        Filter by AMC
                    </p>
                </div>
                <div className="col-6 text-end">
                    <img src={Close}
                        draggable="false"
                        className="cursor-pointer"
                        alt="close"
                        onClick={() => _handleSubmitFilter(false)} />
                </div>
            </div>

            <div className="e-amc-wrapper e-flex-wrap d-flex h-140px overflow-auto ">
                {props.data.amc.map((item, key) => (
                    <p key={key} className={`${selectedItem.includes(item.amc_code) ? 'e-text-egyptian-blue e-bg-lavender e-font-weight-500' : 'e-bg-anti-flash-white e-font-weight-400 e-text-dark-liver'}  mb-3 margin-12px-right px-3 py-1 cursor-pointer e-border-radius-16  e-font-14 e-alt-font-poppins  line-height-24px`}
                        onClick={() => _handleAmcFilter(item.amc_code, item)}>
                        {item.name}
                    </p>
                ))}
            </div>

            {/* Filter by Category  */}
            <div className="row">
                <div className="col-6">
                    <p className="mb-4 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-32px">
                        Filter by category
                    </p>
                </div>
            </div>

            <div className="e-flex-wrap d-flex ">
                {props.data.category.map((item, key) => (
                    <p key={key}
                        className={`${selectedCategory.includes(item.category_code) ? 'e-text-egyptian-blue e-bg-lavender e-font-weight-500' : 'e-bg-anti-flash-white cursor-pointer e-font-weight-400 e-text-dark-liver'}  mb-3 margin-12px-right px-3 py-1  e-border-radius-16  e-font-14 e-alt-font-poppins  line-height-24px`}
                        onClick={() => _handleCategoryFilter(item.category_code, item)}>
                        {item.name}
                    </p>
                ))}
            </div>

            <div className="row">
                <div className="col-6">
                    <SecondaryButton name="Cancel"
                        className="padding-12px-top padding-12px-bottom w-100 e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                        onPress={() => _handleSubmitFilter(false)} />
                </div>
                <div className="col-6">
                    <PrimaryButton name="Apply"
                        className="padding-12px-top padding-12px-bottom w-100 e-font-16"
                        onPress={_handleSubmitFilter} />
                </div>
            </div>
        </div >
    )
}
export default FilterModal;