/*
 *   File : explore-sort.js
 *   Author : https://evoqins.com
 *   Description : Sort modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from "react";

//custom component
import { PrimaryButton, SecondaryButton } from "../Buttons";

//assets
import Close from "../../Assets/Images/close-btn.svg";
import RadioActive from "../../Assets/Images/circle-active.svg";
import RadioInactive from "../../Assets/Images/circle-inactive.svg";

const ExploreSortModal = (props) => {

    const [selected, setSelected] = useState(props.selectedType);

    useEffect(() => {
        setSelected(props.selectedType);
    }, [props.selectedType]);


    const _handleSubmit = (status) => {

        if (status === true) {
            props.onSubmit(selected);
        } else {
            props.onSubmit(props.selectedType);
        }

    };

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <p className="mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-32px">
                        Sort by AUM
                    </p>
                </div>
                <div className="col-6 text-end">
                    <img src={Close}
                        draggable="false"
                        className="cursor-pointer"
                        alt="close"
                        onClick={() => props.onCloseModal()} />
                </div>
            </div>

            {props.data.map((data, key) => (
                <p key={key} className={`${selected === data.id ? 'e-text-egyptian-blue e-font-weight-500' : 'cursor-pointer e-font-weight-400 e-text-dark-liver'} e-display-flex mb-3 margin-12px-righ py-1  e-border-radius-16  e-font-14 e-alt-font-poppins  line-height-24px`}
                    onClick={() => setSelected(data.id)}>
                    <img src={selected === data.id ? RadioActive : RadioInactive}
                        draggable="false"
                        className="me-1"
                        width={24}
                        alt="radio" />
                    {data.label}
                </p>
            ))}

            <div className="row">
                <div className="col-6">
                    <SecondaryButton name="Cancel"
                        className="py-2 w-100 e-font-16 e-bg-lotion  e-border-bright-gray"
                        onPress={() => _handleSubmit(false)} />
                </div>
                <div className="col-6">
                    <PrimaryButton name="Apply"
                        className="py-2 w-100 e-font-16"
                        onPress={() => _handleSubmit(true)} />
                </div>
            </div>
        </div>
    )
}
export default ExploreSortModal;