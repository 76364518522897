/*
 *   File : portfolio.js
 *   Author : https://evoqins.com
 *   Description : portfolio page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

// Components
import { APIService } from '../../Service';
import { _getProfile, _goalSummary, _mfSummary } from '../../Helper';
import { Footer } from '../../Component/Footer';
import { BreadCrumbs, EmptyScreen } from '../../Component/Other';
import { PortfolioCard } from '../../Component/Cards';
import { Icon } from '../../Component/Icon';
import { SectionTitle } from '../../Component/Title';
import Holdings from '../../Component/Cards/holdings';
import OverLay from "../../Component/Other/overlay";
import { ExternalFundImportModal, GoalInvestModal, MFInvestModal, PaymentStatusModal, PortfolioFilterModal, RedeemModal, RedeemStatusModal } from '../../Component/Modal';


// COLORS
import Colors from "../../Style/color.scss"

// Assets
import ImportExternal from "../../Assets/Images/Home/portfolio.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyPortfolio from "../../Assets/Images/EmptyScreen/no-portfolio.webp";
import { PrimaryButton } from '../../Component/Buttons';

// Custom Data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Portfolio",
        path: "/portfolio"
    },
];

const DEFAULT_FILTER = {
    id: null,
    label: "All"
};

const Portfolio = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const initialRender = useRef(true);

    const [portfolioSummary, setPortfolioSummary] = useState("");
    const [holdings, setHoldings] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [toggleFilterModal, setToggleFilterModal] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTER);
    const [isHoldingsLoading, setIsHoldingsLoading] = useState(false);
    const [isInvested, setIsInvested] = useState(false);

    // Invest
    const [fundSummary, setFundSummary] = useState({});
    const [fundId, setFundId] = useState("");
    const [goalSummary, setGoalSummary] = useState({});
    const [goalId, setGoalId] = useState("");

    const [showInvestModal, setShowInvestModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [paymentModalType, setPaymentModalType] = useState(0);
    const [investType, setInvestType] = useState("");

    const available_investment_types = {
        sip: true,
        lumpsum: true,
    };

    // Redeem Invest
    const [redeemSummary, setRedeemSummary] = useState({});
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [showRedeemStatusModal, setShowRedeemStatusModal] = useState(false);

    const [showImportPortfolioModal, setShowImportPortfolioModal] = useState(false);

    useEffect(() => {
        setIsDataLoading(true);
        setIsHoldingsLoading(true);
        _getPortfolioSummary();

        window.scrollTo({
            top: 0, behavior: "smooth"
        });

        window.addEventListener("click", (e) => {
            if (!e.target.closest("#portfolio-filter")) {
                setToggleFilterModal(false);
            }
        });

    }, []);

    useEffect(() => {
        if (toggleFilterModal === true) {
            document.body.style.overflow = "hidden";
            document.querySelector("#portfolio-filter").scrollIntoView({ top: 0, behavior: "smooth" });
        } else {
            document.body.style.overflow = "unset";
        };

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setToggleFilterModal(false);
            setShowRedeemModal(false);
            setShowPaymentModal(false);
            setShowInvestModal(false);
            // if (toggleFilterModal === true) {
            // };

            // if (showRedeemModal === true) {
            // }

            // if (showInvestModal === true) {

            // }
        });

    }, [toggleFilterModal, showRedeemModal, showInvestModal, showPaymentModal]);

    useEffect(() => {
        if (initialRender.current === false) {
            setIsHoldingsLoading(true);
            _getHoldingsData();

            // Saving selected filters in location state
            navigate("/portfolio", {
                state: {
                    "selected_filters": selectedFilters,
                },
                replace: true,
            });
        }

    }, [selectedFilters]);

    useEffect(() => {

        if (showInvestModal !== true) return;

        switch (investType) {
            case "Fund": {
                new Modal(document.getElementById('invest-mf-modal')).show();
                break;
            };
            case "Goal": {
                new Modal(document.getElementById('invest-goal-modal')).show();
                break;
            };
            case "Basket": {
                // new Modal(document.getElementById('invest-basket-modal')).show();
                new Modal(document.getElementById('invest-goal-modal')).show();
                break;
            };
            default: return null;
        }
    }, [showInvestModal]);

    useEffect(() => {
        if (showPaymentModal == true) {
            new Modal(document.querySelector("#payment-status-modal")).show();
        }
    }, [showPaymentModal]);

    useEffect(() => {
        if (showRedeemModal !== true) return;
        new Modal(document.getElementById('redeem-invest-modal')).show();
    }, [showRedeemModal]);

    useEffect(() => {
        if (showRedeemStatusModal) {
            new Modal(document.getElementById('redeem-status-modal')).show();
        }
    }, [showRedeemStatusModal]);

    useEffect(() => {
        if (showImportPortfolioModal === true) {
            let import_portfolio_modal = new Modal(document.getElementById('import-portfolio-modal'));
            import_portfolio_modal.show();
        }
    }, [showImportPortfolioModal]);

    // Setting filters when user reloads the page
    useEffect(() => {
        window.addEventListener("load", () => {
            setSelectedFilters(location.state !== null ? location.state.selected_filters : DEFAULT_FILTER)
        });
    }, []);

    const _handlePortfolioFilterModal = () => {
        setToggleFilterModal(status => status == false ? true : false);
    };

    const _handleCloseFilterModal = () => {
        setToggleFilterModal(false);
    };

    const _handleSelectedFilters = (selected_filter, status) => {
        if (status == true) {
            setSelectedFilters(selected_filter);
        }
        setToggleFilterModal(false);
    };

    const _handleCloseInvestModal = (type) => {

        // Closing modal
        let button = "";
        switch (investType) {
            case "Fund": {
                button = document.getElementById("mf-invest-modal-close");
                break;
            };
            case "Goal": {
                button = document.getElementById("goal-invest-modal-close");
                break;
            };
            case "Basket": {
                // button = document.getElementById("basket-invest-modal-close");
                button = document.getElementById("goal-invest-modal-close");
                break;
            };
            default: return null;
        };

        button.click();
        setShowInvestModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };
    };

    const _handleCloseRedeemModal = (type) => {
        let button = document.getElementById("redeem-invest-modal-close");
        button.click();
        setShowRedeemModal(false);

        if (type === "REDEEM_SUCCESS") {
            setShowRedeemStatusModal(true);
        };
    };

    // Handle investmore on holdings
    const _handleInvestMore = (e, id, type) => {

        e.stopPropagation();
        setInvestType(type);

        if (type === "Fund") {
            _getMFSummary(id);
        } else {
            setGoalId(id);
            _getGoalSummary(id);
        };
    };

    // Handle redeem on holdings
    const _handleRedeemInvest = (e, id, type) => {

        e.stopPropagation();
        setInvestType(type);

        if (type == "Fund") {
            setFundId(id);
            _getMFRedeemOverview(id);
        } else {
            setGoalId(id);
            _getGoalRedeemOverview(id);
        };
    };

    const _handleOnClickProcessingAmount = () => {
        navigate("/orders", {
            state: {
                filter_type: 1,
            },
            replace: true
        });
    };

    // handle close import portfolio modal
    const _handleCloseImportPortfolioModal = () => {
        setShowImportPortfolioModal(false);
    };

    // API - Getting Fund Summary for reinvesting in MF
    const _getMFSummary = (id) => {
        const param = {
            "mf_id": id,
            "type": "REINVEST",
        };

        // Api call
        _mfSummary(param).then(status => {
            if (status.status_code === 200) {
                setFundSummary(status.data);
                setShowInvestModal(true);
            } else {
                toast.dismiss();
                toast.error(status.message, { className: "e-toast", });
            }
        });
    };

    // API - Getting Redeem details for selected fund
    const _getMFRedeemOverview = (id) => {
        let is_post = true;
        let url = 'mf/redeem-overview';
        let data = {
            "mf_id": id
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code == 200) {
                setRedeemSummary(response.data);
                setShowRedeemModal(true);
            } else {
                setRedeemSummary({});
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
        });
    };

    //API - Getting goal summary for reinvesting in goal or basket
    const _getGoalSummary = (id) => {

        let param = {
            "goal_id": id,
            "type": "REINVEST",
        };

        _goalSummary(param).then(response => {
            if (response.status_code === 200) {
                setShowInvestModal(true);
                setGoalSummary(response.data);
            } else {
                setGoalSummary({});
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
        });
    };

    // API - Getting Redeem details for selected goal or basket
    const _getGoalRedeemOverview = (id) => {
        let is_post = true;
        let url = 'goal/redeem-overview';
        let data = {
            "goal_id": id
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code == 200) {
                setRedeemSummary(response.data);
                setShowRedeemModal(true);
            } else {
                setRedeemSummary({});
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
        });
    };

    // API - Get holding from the selected filter
    const _getHoldingsData = () => {
        let is_post = true;
        let url = 'dashboard/holdings';
        let data = {
            "investment_type": selectedFilters.id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setHoldings(response.data);
            } else {
                setHoldings([]);
            };

            setIsHoldingsLoading(false);
            setIsDataLoading(false);
        });
    }

    // API - To get summary of portfolio
    const _getPortfolioSummary = () => {
        let is_post = false;
        let url = 'dashboard/get';
        APIService(is_post, url).then((response) => {
            if (response.status_code === 200) {

                setPortfolioSummary(response.data);
                _getHoldingsData();

                // Checking user invested status
                _getProfile().then(response => {
                    if (response.status_code === 200) {
                        if (response.data.signup_progress_status === "INVESTED") {
                            setIsInvested(true);
                        } else {
                            setIsInvested(false);
                        }
                    }
                });

                initialRender.current = false;

            } else {
                setPortfolioSummary("");
            };
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 align-items-start e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={BREADCRUMBS} />

                {
                    isDataLoading ? (
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                    ) : (
                        <div className="col-lg-11 p-0">

                            {
                                isInvested === true ? (
                                    <div className=' px-0'>
                                        <div className="row p-0 mt-4 pt-sm-3 mb-4 ">
                                            <div className="col-xl-8 col-lg-8 col-md-12 col-12 flex-fill d-flex">
                                                <PortfolioCard data={portfolioSummary.summary} />
                                            </div>

                                            {
                                                false &&
                                                <div className="col-xl-4 col-lg-4 col-12  pt-xl-0 pt-lg-0 pt-md-4 pt-4 d-flex">
                                                    <div className='import-portfolio d-flex flex-column flex-sm-row flex-lg-column overflow-hidden  p-4  mx-auto w-100'>
                                                        <img src={ImportExternal}
                                                            className="col-5 col-sm-4 col-md-3 col-lg-5 mx-auto mx-sm-0 mx-lg-auto margin-10px-bottom"
                                                            draggable="false"
                                                            alt="import external portfolio" />
                                                        <div className='ps-sm-4 ps-lg-0 text-center text-sm-start text-lg-center my-auto'>
                                                            <h2 className='e-text-charleston-green e-alt-font-poppins e-font-18 e-font-weight-600 line-height-28px'>
                                                                Import external portfolio
                                                            </h2>
                                                            <p className='px-lg-2 e-text-onyx e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px mb-4'>
                                                                Import & manage your external portfolio in just 5 minutes.
                                                            </p>
                                                            <PrimaryButton
                                                                name={"Import"}
                                                                className={"py-2 col-7"}
                                                                onPress={() => setShowImportPortfolioModal(true)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {/* show pending amount widget */}
                                        {
                                            portfolioSummary.summary.processing_amount.total !== 0 &&
                                            <div className='ps-0 margin-32px-bottom'>
                                                <div className='e-bg-cultured e-border-radius-16 p-3'>
                                                    <p className='icon-link gap-2 align-items-center py-2 px-3 e-bg-blanched-almond e-border-radius-16 cursor-pointer'
                                                        onClick={_handleOnClickProcessingAmount}>
                                                        <Icon icon="pending" size="1rem" />
                                                        <span className='d-flex gap-2 e-text-mustard-yellow e-alt-font-poppins e-font-14 e-font-weight-500 line-height-26px'>
                                                            Processing amount
                                                            <Icon icon="right-black-icon" size="1.5rem" color={Colors.mustard_yellow} />
                                                        </span>
                                                    </p>

                                                    <p className='mb-0'>
                                                        <span className='e-font-18-res e-text-egyptian-blue e-primary-font-inter e-font-24 e-font-weight-600 line-height-32px me-2'>
                                                            ₹{Number(portfolioSummary.summary.processing_amount.total.toFixed(2)).toLocaleString("en-IN")}
                                                        </span>
                                                        <span className='e-font-12-res e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 fst-italic line-height-24px'>
                                                            (To receive a reflection in your account, wait T+3 working days)
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                        {/* Holdings */}
                                        <div className='ps-0'>
                                            <div className='d-flex justify-content-between align-items-center margin-32px-bottom'>
                                                <SectionTitle title="My holdings" />
                                                {/* Holdings Filter */}
                                                <div className='position-relative' id="portfolio-filter">
                                                    <div className='d-flex gap-12px cursor-pointer e-bg-lavender px-4 padding-10px-tb e-border-radius-32'
                                                        onClick={_handlePortfolioFilterModal}>
                                                        <span className='e-text-egyptian-blue e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px'>
                                                            {selectedFilters.label}
                                                        </span>
                                                        <Icon icon="down-arrow"
                                                            size="1.5rem"
                                                            className={`${toggleFilterModal == true ? "transform-rotate-180" : ""}`}
                                                            color={Colors.egyptian_blue}
                                                        />
                                                    </div>

                                                    {/* filter modal */}
                                                    {
                                                        toggleFilterModal &&
                                                        <>
                                                            <OverLay isOpen={toggleFilterModal} onClose={_handleCloseFilterModal} />
                                                            <PortfolioFilterModal selectedFilter={selectedFilters}
                                                                onClick={_handleCloseFilterModal}
                                                                handleSelectedFilters={_handleSelectedFilters} />
                                                        </>

                                                    }
                                                </div>
                                            </div>

                                            {
                                                isHoldingsLoading == true ? (
                                                    <div className="min-h-100vh  text-center mt-5" >
                                                        <img src={Loader}
                                                            draggable="false"
                                                            alt="loader" />
                                                    </div>
                                                ) : (

                                                    holdings.length !== 0 ? (
                                                        <div className='row p-0 flex-wrap'>
                                                            {
                                                                holdings.map((item, key) => (
                                                                    <Holdings data={item}
                                                                        onInvest={_handleInvestMore}
                                                                        onRedeem={_handleRedeemInvest}
                                                                        key={key} />
                                                                ))
                                                            }
                                                        </div>
                                                    ) : (

                                                        <EmptyScreen
                                                            img={EmptyPortfolio}
                                                            title="No holdings !"
                                                            description={`You don't have any active holdings.${selectedFilters.label == "All" ? " Start investing in simple steps" : " in " + selectedFilters.label}.`} />
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                ) : (

                                    <EmptyScreen
                                        img={EmptyPortfolio}
                                        title="No portfolio !"
                                        description="Your investment portfolio looks empty! Start exploring funds and build your wealth today." />
                                )
                            }

                        </div>
                    )
                }

                {
                    // MF invest modal
                    showInvestModal && investType == "Fund" &&
                    <MFInvestModal fundSummary={fundSummary}
                        reinvest={true}
                        investmentType={2}
                        availableInvestmentTypes={available_investment_types}
                        onCloseModal={_handleCloseInvestModal} />
                }
                {
                    //goal invest modal 
                    showInvestModal && investType == "Goal" &&
                    <GoalInvestModal goalId={goalId}
                        reinvest={true}
                        summary={goalSummary}
                        onCloseModal={_handleCloseInvestModal} />
                }
                {
                    //goal invest modal 
                    showInvestModal && investType == "Basket" &&
                    <GoalInvestModal goalId={goalId}
                        reinvest={true}
                        summary={goalSummary}
                        onCloseModal={_handleCloseInvestModal} />
                }

                {/* Redeeem Invest Modal */}
                {
                    //goal invest redeem  modal
                    showRedeemModal &&
                    <RedeemModal summary={redeemSummary}
                        id={investType == "Fund" ? fundId : goalId}
                        investmentType={investType}
                        onCloseModal={_handleCloseRedeemModal} />
                }

                {
                    //Payment status modal
                    showPaymentModal &&
                    <PaymentStatusModal type={paymentModalType}
                        investmentType={investType}
                        onClose={(() => setShowPaymentModal(false))} />
                }

                {
                    //Redeem status modal
                    showRedeemStatusModal &&
                    <RedeemStatusModal
                        investmentType={investType}
                        onClose={(() => setShowRedeemStatusModal(false))} />
                }

                {
                    showImportPortfolioModal &&
                    <ExternalFundImportModal onClose={_handleCloseImportPortfolioModal} />
                }

            </div >
            <Footer />
        </>
    );
};

export default Portfolio;