/*
 *   File : mf-invest.js
 *   Author : https://evoqins.com
 *   Description : Mutual Fund Investment modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useRef, useEffect, useState, memo } from 'react';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';

//custom component
import { APIService } from '../../Service';
import { _formatToRupees, _getOTP } from '../../Helper';
import { Chip, CustomTab } from '../Other';
import { CustomAmountInput, CustomTextInput, OTPInput } from '../Form-Elements';
import { PrimaryButton } from '../Buttons';
import SIPDateModal from './sip-dates';
import { Icon } from '../Icon';
import { _createMandate, _getNotificationCount, _mfSummary } from '../../Helper/api';
import { _formatSipDate } from '../../Helper/converter';

//assets
import Active from "../../Assets/Images/circle-active.svg";
import Inactive from "../../Assets/Images/circle-inactive.svg";
import UPI from "../../Assets/Images/payment_upi.svg";
import NetBanking from "../../Assets/Images/payment_net_banking.svg";
import Close from "../../Assets/Images/close-btn.svg";
import Back from "../../Assets/Images/Login/back-icon.svg";

// Colors
import Colors from "../../Style/Component/card.module.scss";

// SIP mandate information
const SIP_MANDATE_INFO = [
    "For first SIP order, a mandate will be created automate monthly recurring transactions",
    "The mandate is for Rs. 5 lakhs, which will be used for future rebalancing and other SIP.",
    "We won't deduct any extra money other than the SIP order amount using the mandate.",
    "Once the mandate has been created, you can proceed with the first installment."
];

const AMOUNT_DATA = [
    {
        id: 1,
        amount: 2000,
    },

    {
        id: 2,
        amount: 5000,
    },

    {
        id: 3,
        amount: 10000,
    },
    {
        id: 4,
        amount: 25000,
    },
    {
        id: 5,
        amount: 100000,
    },
];

const CONFIRM_PAYMENT = [
    {
        id: 1,
        label: "Yes",
        status: true,
    },

    {
        id: 2,
        label: "No",
        status: false,
    },
];

const MFInvestModal = (props) => {
    const userData = useSelector((store) => store.Reducer.USER_DATA);
    const otpInputRef = useRef(null);

    const [activeTab, setActiveTab] = useState(props.investmentType);
    const [fundSummary, setFundSummary] = useState(props.fundSummary);
    const [sip, setSip] = useState("");
    const [lumpsum, setLumpsum] = useState("");
    const [minInvestment, setMinInvestment] = useState({});
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [isPayingNow, setIsPayingNow] = useState(true);
    const [mandateStatus, setMandateStatus] = useState(true);


    const [toggleInvest, setToggleInvest] = useState(false);
    const [toggleDateModal, setToggleDateModal] = useState(false);

    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);

    const [focusedInput, setFocusedInput] = useState(0);
    const [errorMsgSIP, setErrorMsgSIP] = useState("");
    const [errorMsgLumpsum, setErrorMsgLumpsum] = useState("");
    const [errorMsgSipDate, setErrorMsgSipDate] = useState("");

    // for sip date
    const [sipActiveDates, setSipActiveDates] = useState([]);
    const [selectedSIPDate, setSelectedSIPDate] = useState("");
    const [selectedSIPDateString, setSelectedSIPDateString] = useState("");

    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [investmentTabs, setInvestmentTabs] = useState([]);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP


    useEffect(() => {

        setSip(props.fundSummary.min_investment.sip);
        setLumpsum(props.fundSummary.min_investment.lumpsum);
        setFundSummary(props.fundSummary);
        setMandateStatus(props.fundSummary.mandate_status);
        setMinInvestment({
            min_sip: props.fundSummary.min_investment.sip,
            min_lumpsum: props.fundSummary.min_investment.lumpsum,
        });

        if (props.availableInvestmentTypes.sip) {
            let sip_dates = props.fundSummary.sip_dates.split(",").map(Number);
            setSipActiveDates(sip_dates);
        };

        if (props.fundSummary.payment_types.length !== 0) {
            let payment_types = props.fundSummary.payment_types;
            payment_types = payment_types.map(data => {
                return data.id == 2 ? { ...data, image: UPI } : data.id == 1 ? { ...data, image: NetBanking } : data;
            });
            setPaymentMethod(payment_types);
            setSelectedPaymentType(payment_types[0].label);
        }

        if (props.availableInvestmentTypes) {
            let investment_tabs = [];
            if (props.availableInvestmentTypes.sip === true) {
                investment_tabs = [...investment_tabs, { id: 1, label: "SIP" },];
            };
            if (props.availableInvestmentTypes.lumpsum === true) {
                investment_tabs = [...investment_tabs, { id: 2, label: "Lumpsum" },];
            };
            setInvestmentTabs(investment_tabs);
        };

    }, [props.fundSummary]);

    useEffect(() => {
        setActiveTab(props.investmentType);
    }, [props.investmentType]);

    //for timer
    useEffect(() => {
        if (toggleInvest === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleInvest, timer]);

    // Setting default selected sip date
    useEffect(() => {

        if (props.availableInvestmentTypes.sip === false) return;

        const sip_dates = props.fundSummary.sip_dates.split(",").map(Number);
        const default_date = new Date().getDate() + 1;

        if (default_date > 28) {
            setSelectedSIPDate(sip_dates[0]);
        } else {
            if (sip_dates.includes(default_date)) {
                setSelectedSIPDate(default_date);
            } else {
                setSelectedSIPDate(sip_dates[0]);
            }
        }
    }, [props.fundSummary.sip_dates]);

    // Closing Sip date modal when clicking outside
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest("#mf-sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

        return window.removeEventListener("click", (e) => {
            if (!e.target.closest("#mf-sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

    }, []);

    useEffect(() => {
        if (selectedSIPDate !== "") {
            // Formatting selected SIP date
            // helper function
            const formatted_sip_date = _formatSipDate(selectedSIPDate);
            setSelectedSIPDateString(formatted_sip_date);
        };
    }, [selectedSIPDate]);

    //handle tab change
    const _handleTabChange = (slected_tab_id) => {
        setActiveTab(slected_tab_id);
        setIsPayingNow(true);
        setLoading(false);
        setIsDisabled(false);
        setSelectedPaymentType(props.fundSummary.payment_types[0].label);
    };

    //handle amount select
    const _handleAmountSelect = (amount) => {
        // removing error msg from input
        setErrorMsgLumpsum("");
        setErrorMsgSIP("");

        if (activeTab === 1) {
            setSip(amount);
        } else {
            setLumpsum(amount);
        }
    };

    const _handleConfirmPayingNow = (status) => setIsPayingNow(status);
    const _handlePaymentMethod = (payment_type) => setSelectedPaymentType(payment_type);

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // Function to handle OTP digit input for a specific position
    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        // API call for resending otp
        let resend = true;
        _sendOTP(resend);
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleInvest(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    const _handleCloseDateModal = () => {
        setToggleDateModal(false);
    };

    const _handleInputLumpsumChange = (value) => {
        if (activeTab === 1) {
            setErrorMsgSIP("");
            setSip(value);
        } else {
            setErrorMsgLumpsum("");
            setLumpsum(value);
        };
    };

    const _handleModalClose = () => {
        setToggleInvest(false);
        setActiveTab(props.investmentType);
        setIsPayingNow(true);
        setToggleDateModal(false);
        setSelectedSIPDate("");
        setSip(0);
        setLumpsum(0);
        setErrorMsgSIP("");
        setErrorMsgLumpsum("");
        setErrorMsgSipDate("");

        // To change the modal open status
        props.onCloseModal();
    };

    //handle SIP date select
    const _handleSIPDateSelect = (date) => {
        setErrorMsgSipDate("");
        setSelectedSIPDate(date);

        // Formatting selected SIP date
        // helper function
        const formatted_sip_date = _formatSipDate(date);
        setSelectedSIPDateString(formatted_sip_date);
    };

    //handle invest MF 
    const _handleInvestMF = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };

        // btn loader
        setLoading(true);
        setIsDisabled(true);
        // API Call for invest in selected fund
        _investMF();
    };

    const _handleToggleDateModal = () => {
        document.getElementById("mf-sip-date-modal").scrollIntoView({ behavior: "smooth" });
        setToggleDateModal(status => !status)
    };

    // handle toggle invest now 
    const _handleToggleInvest = () => {
        // Validation for SIP Investment
        if (activeTab === 1) {
            if (sip === "") {
                setErrorMsgSIP("Please enter amount");
                return;
            } else if (sip < minInvestment.min_sip) {
                setErrorMsgSIP(`Min investment should be ₹${minInvestment.min_sip.toLocaleString("en-IN")}`);
                return;
            };

            if (selectedSIPDate == "") {
                setErrorMsgSipDate("Please select date");
                return;
            };

            if (fundSummary.mandate_status == false) {
                setLoading(true);
                // Call CreateMandate API
                _createMandate().then(status => {
                    if (status.status_code === 200) {

                        const mandate_window = window.open(status.data.mandate_url, "", "width=500,height=500,left=400,top=100");

                        if (mandate_window === null || typeof mandate_window === 'undefined') {
                            toast.dismiss();
                            toast.error("please enable Pop-up to open mandate window", { className: "e-toast", });
                            setIsDisabled(false);
                            setLoading(false);
                            return;
                        };

                        setLoading(false);
                        // Preventing from multiple request
                        setIsDisabled(true);

                        let timer = setInterval(function () {
                            if (mandate_window.closed) {
                                clearInterval(timer);

                                // call summary api again for mandate status
                                // if success change btn to request otp
                                const param = {
                                    "mf_id": fundSummary.mf_id,
                                    "type": null,
                                }

                                _mfSummary(param).then(status => {
                                    if (status.status_code === 200) {
                                        setFundSummary(status.data);

                                        if (status.data.mandate_status == true) {
                                            toast.dismiss();
                                            toast.success("Mandate created successfully", { className: "e-toast", });
                                        } else {
                                            toast.dismiss();
                                            toast.error("Mandate creation failed try again after some time", { className: "e-toast", });
                                        }

                                    } else {
                                        toast.dismiss();
                                        toast.error(status.message, { className: "e-toast", });
                                    }
                                });

                                setIsDisabled(false);
                            };
                        }, 1000);

                    } else {
                        toast.dismiss();
                        toast.error(status.message, { className: "e-toast", });
                        setLoading(false);
                    };
                });
                return;
            };
        };

        // Validation for Lumpsum Investment
        if (activeTab === 2) {
            if (lumpsum === "") {
                setErrorMsgLumpsum("Please provide a lumpsum amount, as it is required for processing");
                return;
            } else if (lumpsum < minInvestment.min_lumpsum) {
                setErrorMsgLumpsum(`Min investment should be ₹${minInvestment.min_lumpsum.toLocaleString("en-IN")}`);
                return;
            }
        };

        // API Call to send OTP
        setFocusedInput(2);
        setLoading(true);
        _sendOTP();
    };

    // API - send OTP 
    const _sendOTP = (resend) => {

        // API CALL from helper function
        const param = {
            type: "MF"
        };

        _getOTP(param).then(response => {
            if (response.status_code === 200) {

                setOtpValue("");
                setTimer(30);
                setOtpError("");

                if (resend === true) {
                    setFocusedInput(2);
                    toast.dismiss();
                    let message_medium = userData.phone.country_code == "+91" ? " mobile" : " email"
                    toast.success(`OTP sent successfully to registered ${message_medium}`, {
                        className: "e-toast",
                    });
                } else {
                    setToggleInvest(true);
                };

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            };
            setLoading(false);
            setDisableResendOTP(false);
        })
    };

    //API -  investMF
    const _investMF = () => {
        let is_post = true;
        let url = 'mf/invest';
        let data = {
            "type": props.reinvest === true ? "REINVEST" : null,
            "payment_type": isPayingNow ? selectedPaymentType : false,
            "otp": Number(otpValue),
            "mf_id": fundSummary.mf_id,
            "platform": "web",
        };

        if (activeTab == 1) {
            data = {
                ...data,
                "sip": {
                    "amount": sip,
                    "date": selectedSIPDate,
                    "tenure": 1,
                    "get_first_installment": isPayingNow,
                },
            };
        } else {
            data = {
                ...data,
                "lumpsum": {
                    "amount": lumpsum
                },
            };
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.data.payment_url) {

                    const payment_window = window.open(response.data.payment_url, "", "width=500,height=500,left=400,top=100");

                    if (payment_window === null || typeof payment_window === 'undefined') {
                        toast.dismiss();
                        toast.error("Please enable Pop-up to open payment window", { className: "e-toast", });
                        setIsDisabled(false);
                        setLoading(false);
                        otpInputRef.current.disabled = false;
                        return;
                    }

                    // remove loader & prevent multiple request
                    setLoading(false);
                    // Prevent user from changing otp
                    otpInputRef.current.disabled = true;

                    // Calling payment status api
                    let timer = setInterval(function () {
                        if (payment_window?.closed) {
                            clearInterval(timer);
                            _paymentCallback(response.data.payment_id)
                        };
                    }, 1000);

                } else {
                    props.onCloseModal("ORDER_SUCCESS");
                    setIsDisabled(false);
                    setLoading(false);
                    otpInputRef.current.disabled = false;

                    // Updating notification
                    _getNotificationCount();
                }



            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
                setIsDisabled(false);
                setLoading(false);
                otpInputRef.current.disabled = false;
            };
        });
    };

    // Payment callback API for checking payment status
    const _paymentCallback = (payment_id) => {
        let is_post = true;
        let url = 'payment/check-status';
        let data = {
            "payment_id": payment_id,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                if (response.data.status === true) {
                    props.onCloseModal("PAYMENT_SUCCESS");
                } else {
                    toast.dismiss();
                    toast.error(response.data.message, { className: "e-toast", });
                }

                setIsDisabled(false);
                otpInputRef.current.disabled = false;

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
                setIsDisabled(false);
                otpInputRef.current.disabled = false;
            };

            // Updating notification 
            _getNotificationCount();

        });

    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="invest-mf-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="MFInvestLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <>
                    {
                        toggleInvest === false ?
                            <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                                <div className="modal-header border-0 justify-content-space-between py-3 px-3 px-sm-4 ">
                                    <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                        Invest details
                                    </h6>
                                    <img src={Close}
                                        id='mf-invest-modal-close'
                                        draggable={false}
                                        className='cursor-pointer'
                                        data-bs-dismiss="modal"
                                        aria-label="close"
                                        alt='close btn'
                                        onClick={_handleModalClose} />
                                </div>
                                <div className="modal-body pb-4 px-3 px-sm-4 pt-0">
                                    <div className='e-border-radius-16 e-bg-cultured p-3'>
                                        <div className="col d-flex gap-2 align-items-center">
                                            <img src={fundSummary.image}
                                                width={48}
                                                height={48}
                                                className="object-fit-contain p-1 e-border-radius-10 me-1 e-bg-white align-self-start"
                                                draggable="false"
                                                alt="fund logo" />
                                            <div className="d-flex flex-column">
                                                <h3 className="e-ellipse e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                    {fundSummary.name}
                                                </h3>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <Chip name={fundSummary.scheme_category}
                                                        className="py-1 px-2 px-sm-3 py-sm-2 e-font-14 e-font-12-res" />
                                                    {
                                                        fundSummary.option_name !== null &&
                                                        <Chip name={fundSummary.option_name}
                                                            className="py-1 px-2 px-sm-3 py-sm-2  e-font-14 e-font-12-res" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3 p-3 e-border-radius-16 e-bg-cultured'>
                                        {/* tab component */}
                                        <div className='d-flex'>
                                            {
                                                investmentTabs.map((data, key) => (
                                                    <CustomTab key={key}
                                                        tabData={data}
                                                        tabIndex={activeTab}
                                                        handleChange={() => _handleTabChange(data.id)} />
                                                ))
                                            }
                                        </div>

                                        {/* custom amount input */}
                                        <div className='row mt-3 position-relative'>
                                            <div className={`${activeTab === 2 ? "col-sm-7" : "col-sm-6"}`}>
                                                <CustomAmountInput require={true}
                                                    label={activeTab === 1 ? "Monthly amount " : "One time investment amount "}
                                                    value={activeTab === 1 ? sip : lumpsum}
                                                    maxLength={8}
                                                    errorMsg={activeTab === 1 ? errorMsgSIP : errorMsgLumpsum}
                                                    placeholder={activeTab === 1 ? "SIP amount" : "Lumpusm amount"}
                                                    handleChange={_handleInputLumpsumChange} />

                                            </div>
                                            {activeTab === 1 &&
                                                <div className='col-sm-6  mb-2 mb-sm-0 order-3 order-sm-2'
                                                    id='mf-sip-date-modal'>
                                                    <CustomTextInput require={true}
                                                        type={2}
                                                        label='Monthly SIP date '
                                                        value={selectedSIPDateString}
                                                        placeholder="Choose date"
                                                        className="cursor-pointer w-100 e-font-12 e-alt-font-poppins e-font-weight-500 no-outline"
                                                        maxLength={8}
                                                        readOnly={true}
                                                        errorMsg={errorMsgSipDate}
                                                        onClick={_handleToggleDateModal}
                                                    />
                                                    {
                                                        toggleDateModal &&
                                                        <SIPDateModal
                                                            selectedSIPDate={selectedSIPDate}
                                                            sipDates={sipActiveDates}
                                                            className="position-absolute mt-sm-2 start-0 w-100 "
                                                            handleSelect={_handleSIPDateSelect}
                                                            handleCloseDateModal={_handleCloseDateModal} />
                                                    }
                                                </div>
                                            }
                                            <div className="col-12 order-2 order-sm-3 mb-3 mb-sm-0 mt-1 mt-sm-0">
                                                {/* amount select  */}
                                                <div className="d-flex flex-wrap gap-2 pt-2">
                                                    {AMOUNT_DATA.map((data, key) => (
                                                        <p key={key}
                                                            className={`${(activeTab === 1 ? sip === data.amount : lumpsum === data.amount) ?
                                                                'e-border-otp-active e-text-egyptian-blue cursor-default' : 'cursor-pointer e-text-charleston-green e-border-1px-charleston-green'}
                                                mb-0 px-2 py-1 e-border-radius-8  e-font-14 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                            onClick={() => _handleAmountSelect(data.amount)}>
                                                            ₹{data.amount.toLocaleString("en-IN")}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        {/* amount select  */}
                                        {/* <div className="d-flex flex-wrap gap-2 pt-2">
                                        {AMOUNT_DATA.map((data, key) => (
                                            <p key={key}
                                                className={`${(activeTab === 1 ? sip === data.amount : lumpsum === data.amount) ?
                                                    'e-border-otp-active e-text-egyptian-blue' : 'cursor-pointer e-text-charleston-green e-border-1px-charleston-green'}
                                                mb-0 px-2 py-1 e-border-radius-8  e-font-14 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                onClick={() => _handleAmountSelect(data.amount)}>
                                                ₹{data.amount.toLocaleString("en-IN")}
                                            </p>
                                        ))}
                                    </div> */}

                                        {activeTab === 1 &&
                                            <>
                                                <p className='mb-2 pt-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    Would you like to proceed with the first instalment at this time?
                                                </p>

                                                {/* confirmation radio button */}
                                                <div className="d-flex e-gap-0-16">
                                                    {CONFIRM_PAYMENT.map((data, key) => (
                                                        <div key={key} className={`${isPayingNow !== data.status ? "cursor-pointer" : "cursor-default"} e-display-flex gap-0-8 `}
                                                            onClick={() => _handleConfirmPayingNow(data.status)}>
                                                            <img src={isPayingNow === data.status ? Active : Inactive}
                                                                draggable="false"
                                                                className="width-24px width-20px-res"
                                                                alt="radio" />
                                                            <p className={` ${isPayingNow === data.status ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} mb-0 e-font-14-res e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                {data.label}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        }

                                    </div>

                                    {/* Payment Method */}
                                    {
                                        isPayingNow &&
                                        <div>
                                            <h6 className='my-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                Payment Method
                                            </h6>
                                            <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                                {paymentMethod.map((data, key) => (
                                                    <React.Fragment key={key}>
                                                        <div className={`${selectedPaymentType !== data.slug ? "cursor-pointer" : "cursor-default"} e-display-flex justify-content-between gap-0-8`}
                                                            onClick={() => _handlePaymentMethod(data.slug)}>
                                                            <p className="d-flex align-items-center gap-2 e-text-charleston-green mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-22px">
                                                                <img src={data.image}
                                                                    alt={`payment method ${data.label}`}
                                                                    draggable="false" />
                                                                <span>{data.label}</span>
                                                            </p>
                                                            <img src={selectedPaymentType === data.slug ? Active : Inactive}
                                                                draggable="false"
                                                                className="width-24px width-20px-res"
                                                                alt="radio" />
                                                        </div>
                                                        {
                                                            key == paymentMethod.length - 2 && paymentMethod.length > 1 &&
                                                            <hr style={{ borderColor: Colors.cool_light_gray }} />
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </div >
                                        </div>
                                    }

                                    {/* SIP Mandate Infromation */}
                                    {
                                        activeTab === 1 && fundSummary.mandate_status === false &&
                                        <div className="d-flex flex-column mt-4 gap-2 mt-1 e-bg-cosmic-latte  e-border-radius-16 p-3">
                                            <h6 className='d-flex gap-2 align-items-center e-text-mustard-yellow e-font-weight-600 line-height-18px'>
                                                <Icon icon="info"
                                                    size="1.5rem"
                                                    color={Colors.mustard_yellow} />
                                                Information
                                            </h6>
                                            <div className='d-flex flex-column gap-2'>
                                                {
                                                    SIP_MANDATE_INFO.map((item, index) => (
                                                        <p className='d-flex gap-1 e-alt-font-poppins e-text-onyx mb-0 e-font-14 e-font-weight-400 line-height-24px'>
                                                            {index + 1}{"."}
                                                            <span>{item}</span>
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className='padding-36px-top'>
                                        <PrimaryButton name={`${activeTab == 1 ? fundSummary.mandate_status === false ? "Create Mandate" : "Request OTP" : "Request OTP"}`}
                                            loading={loading}
                                            className="e-font-14-res w-100 padding-12px-top padding-12px-bottom"
                                            disabled={isDisabled}
                                            onPress={_handleToggleInvest} />
                                    </div>
                                </div>
                            </div>
                            :

                            <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                                <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-3 px-sm-4 border-0">

                                    {/* Hidden close btn for closing modal */}
                                    <span id='mf-invest-modal-close'
                                        data-bs-dismiss="modal"
                                        className='d-none'
                                        aria-label="close"
                                        alt='close btn'
                                    />

                                    <img src={Back}
                                        draggable={false}
                                        className='cursor-pointer'
                                        alt="arrow-back"
                                        onClick={_handleBack} />
                                    <h6 className='e-font-14-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                        Purchase confirmation OTP
                                    </h6>

                                </div>
                                <div className="modal-body pb-4 px-3 px-sm-4 pt-0">

                                    {/* otp section */}
                                    <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                        {
                                            userData.phone.country_code == "+91" ?
                                                <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                    An OTP has been sent to your registered mobile number.
                                                    <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                        {userData.phone.country_code} {userData.phone.number}
                                                    </span>
                                                </p>
                                                :
                                                <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                    An OTP has been sent to your registered email.
                                                    <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                        {userData.email}
                                                    </span>
                                                </p>
                                        }
                                        <div className='pt-3'>
                                            <label className="e-font-14-res e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                Enter your OTP*
                                            </label>
                                            <OTPInput ref={otpInputRef}
                                                id="otp"
                                                value={otpValue}
                                                error={otpError}
                                                spellCheck="false"
                                                focused_input={focusedInput}
                                                onFocus={() => setFocusedInput(2)}
                                                onBlur={() => setFocusedInput(0)}
                                                onChange={_handleChangeOTP} />

                                            <div className='e-inline-flex'>
                                                <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                    disabled={disableResendOTP}
                                                    onClick={_handleResendOTP} >
                                                    Re-send OTP
                                                </button>
                                                {timer !== 0 &&
                                                    <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                        in {timer} {timer == 1 ? "second" : "seconds"}
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                            *In accordance with regulatory, 2FA verification is required in order to create an order.
                                        </p>
                                    </div>

                                    {/* Mandate success msg for SIP */}
                                    {
                                        mandateStatus == false && activeTab == 1 &&
                                        <div className='d-flex gap-2 p-3 e-bg-nyanza-green e-border-radius-16 mt-3'>
                                            <Icon icon="mandate-success"
                                                size={"1.5rem"} />
                                            <p className='e-text-go-green e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px letter-spacing-3 mb-0'>
                                                Your mandate has been successfully created. Now, you can proceed with the first instalment
                                            </p>
                                        </div>
                                    }
                                    <div className='padding-36px-top'>
                                        <PrimaryButton name={"Invest now"}
                                            loading={loading}
                                            disabled={isDisabled}
                                            className="e-font-14-res w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                            onPress={_handleInvestMF} />
                                    </div>
                                </div>
                            </div>
                    }
                </>
            </div>
        </div >
    )
}

export default memo(MFInvestModal);