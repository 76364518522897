/*
 *   File : secondary.js
 *   Author : https://evoqins.com
 *   Description : secondary button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

function SecondaryButton(props) {
    return (
        <button className={`${props.className} cursor-pointer e-border-radius-24 e-bg-white border-transparent e-text-dark-charcoal e-alt-font-poppins e-font-weight-600 line-height-24px e-btn-custom-wrapper`}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-cancel={props.cancel}
            data-bs-dismiss={props.dismiss}
            disabled={props.disabled}
            id={props.id}
            onClick={() => props.onPress()}>
            {props.name}
        </button>
    )
};

SecondaryButton.defaultProps = {
    onPress: () => { },
    className: null,
    id: null,
    name: null,
    icon: null
}

export default SecondaryButton;

