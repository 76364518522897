/*
 *   File : add-address.js
 *   Author : https://evoqins.com
 *   Description : Add adress page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';

// Custom component
import { SelectionSuggestModal } from '../../../Component/Modal';
import { CustomTextInput } from '../../../Component/Form-Elements';
import { PrimaryButton } from '../../../Component/Buttons';
import { APIService } from '../../../Service';
import { _checkFileSize, _formatDateFormat, _getSignupProgress } from '../../../Helper';

// Assets
import Loader from "../../../Assets/Images/loader.gif";


const AddAddress = (props) => {

    //get progress data form redux
    const PROGRESS_DATA = useSelector((store) => store.Reducer.SIGNUP_PROGRESS_DATA);

    const [countryData, setCountryData] = useState(props.country);
    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [secondAddress, setSecondAddress] = useState("");
    const [selectedState, setSelectedState] = useState([]);
    const [stateError, setStateError] = useState("");
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityError, setCityError] = useState("");

    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [pinCode, setPinCode] = useState("");
    const [pinCodeError, setPinCodeError] = useState("");

    const [correspondAddress, setCorrespondAddress] = useState(true);

    //correspond
    const [correspondNation, setCorrespondNation] = useState([]);
    const [correspondNationError, setCorrespondNationError] = useState("");

    const [correspondAddressOne, setCorrespondAddressOne] = useState("");
    const [correspondAddressError, setCorrespondAddressError] = useState("");

    const [correspondSecondAddress, setCorrespondSecondAddress] = useState("");
    const [correspondSecondAddressError, setCorrespondSecondAddressError] = useState("");

    const [correspondState, setCorrespondState] = useState([]);
    const [correspondStateError, setCorrespondStateError] = useState("");

    const [correspondCity, setCorrespondCity] = useState([]);
    const [correspondCityError, setCorrespondCityError] = useState("");

    const [correspondPinCode, setCorrespondPinCode] = useState("");
    const [correspondPinCodeError, setCorrespondPinCodeError] = useState("");


    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [modalType, setModalType] = useState("");

    // Loaders
    const [initialStatus, setInitialStatus] = useState(true);
    const [stateListLoading, setStateListLoading] = useState(true);
    const [loading, setLoading] = useState(false); // Save details loader

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        setStateListLoading(true);
        _getState();
    }, []);

    useEffect(() => {

        if (PROGRESS_DATA.kyc_data.address["permanent_address"] !== undefined) {

            if (PROGRESS_DATA.kyc_data.address.permanent_address["address_line1"] !== undefined) {
                setAddress(PROGRESS_DATA.kyc_data.address.permanent_address.address_line1);
                setSecondAddress(PROGRESS_DATA.kyc_data.address.permanent_address.address_line2);
                setSelectedState({ id: PROGRESS_DATA.kyc_data.address.permanent_address.state_id, name: PROGRESS_DATA.kyc_data.address.permanent_address.state });
                setSelectedCity({ id: PROGRESS_DATA.kyc_data.address.permanent_address.city_id, name: PROGRESS_DATA.kyc_data.address.permanent_address.city });
                setPinCode(PROGRESS_DATA.kyc_data.address.permanent_address.pincode);
            }

        };

        if (PROGRESS_DATA.kyc_data.address['corresponding_address'] !== undefined) {

            if (PROGRESS_DATA.kyc_data.address.corresponding_address["address_line1"]) {

                setCorrespondAddressOne(PROGRESS_DATA.kyc_data.address.corresponding_address?.address_line1);
                setCorrespondSecondAddress(PROGRESS_DATA.kyc_data.address.corresponding_address.address_line2);
                setCorrespondPinCode(PROGRESS_DATA.kyc_data.address.corresponding_address.pincode);
                setCorrespondNation({ id: PROGRESS_DATA.kyc_data.address.corresponding_address.country_id, name: PROGRESS_DATA.kyc_data.address.corresponding_address.country });
                setCorrespondState(PROGRESS_DATA.kyc_data.address.corresponding_address.state);
                setCorrespondCity(PROGRESS_DATA.kyc_data.address.corresponding_address.city);
            } else {
                setCorrespondNation({
                    id: PROGRESS_DATA.kyc_data.tax_country_id,
                    name: PROGRESS_DATA.kyc_data.tax_country
                });
            }

        } else {
            setCorrespondNation({
                id: PROGRESS_DATA.kyc_data.tax_country_id,
                name: PROGRESS_DATA.kyc_data.tax_country
            });
        }

        setCorrespondAddress(PROGRESS_DATA.kyc_data.is_indian_tax_payer ? true : false);

        setInitialStatus(false);

    }, [PROGRESS_DATA]);

    //Modal
    useEffect(() => {

        if (showSuggestModal === true) {
            if (modalType === 2) {
                let state_modal = new Modal(document.getElementById('select-state-modal'));
                state_modal.show();
            } else if (modalType === 3) {
                let city_modal = new Modal(document.getElementById('select-city-modal'));
                city_modal.show();
            } else if (modalType === 4) {
                let city_modal = new Modal(document.getElementById('select-correspond-country-modal'));
                city_modal.show();
            }
        };

    }, [showSuggestModal])

    useEffect(() => {
        setAddressError("");
    }, [address]);

    useEffect(() => {
        setStateError("");
    }, [selectedState]);

    useEffect(() => {
        setCityError("");
    }, [selectedCity]);

    useEffect(() => {
        setPinCodeError("");
    }, [pinCode]);

    //correspond error
    useEffect(() => {
        setCorrespondNationError("");
    }, [correspondNation]);

    useEffect(() => {
        setCorrespondAddressError("");
    }, [correspondAddressOne]);

    useEffect(() => {
        setCorrespondSecondAddressError("");
    }, [correspondSecondAddress]);

    useEffect(() => {
        setCorrespondStateError("");
    }, [correspondState]);

    useEffect(() => {
        setCorrespondCityError("");
    }, [correspondCity]);

    useEffect(() => {
        setCorrespondPinCodeError("");
    }, [correspondPinCode]);

    useEffect(() => {
        setCountryData(props.country);
    }, [props.country, countryData]);

    useEffect(() => {

        if (selectedState.id) {
            _getCity(selectedState.id);
        };

    }, [selectedState]);

    useEffect(() => {

        if (correspondState.id) {
            _getCity(correspondState.id);
        };

    }, [correspondState]);

    // handle back page 
    const _handleBack = () => {
        if (PROGRESS_DATA.kyc_data.status === "ADDRESS_PROOF" || PROGRESS_DATA.current_step > 3 || PROGRESS_DATA.kyc_user === true) {
            props.onUpdateStep(2);
        } else {
            props.onUpdateStep(3);
        }
    };

    //handle modal
    const _handleSuggestionModal = (type) => {
        setModalType(type);
        setShowSuggestModal(true);
    };

    //handle address change 
    const _handleAddressChange = (value) => {
        setAddress(() => value);
    };

    //handle select state
    const _handleSelectState = (value) => {
        setSelectedState(value);
        // clearing city when state changes
        setSelectedCity({ id: null, name: "" });
    };

    //handle select city
    const _handleSelectCity = (value) => {
        setSelectedCity(value);
    };

    const _handlePinChange = (value) => {
        setPinCode(value);
    };

    // correspond handler 

    //handle select correspond nation
    const _handleCorrespondNation = (value) => {
        setCorrespondNation(value);
        setCorrespondState({ id: null, name: "" });
        setCorrespondCity({ id: null, name: "" });
        setCorrespondPinCode("");
        setCorrespondStateError("");
        setCorrespondCityError("");
        setCorrespondPinCodeError("");
    };

    const _handleCorrPinCodeChange = (value) => {
        setCorrespondPinCode(value);
    };


    //handle submit data
    const _handleSubmit = () => {

        let errors = [];
        let validation = true;

        if (PROGRESS_DATA.kyc_user === true) {

            if (address.trim().length === 0) {
                setAddressError("Please provide your address details");
                errors.push("address-1");
                validation = false;
            }

            if (selectedState.length === 0) {
                setStateError("Please provide your state name");
                errors.push("state");
                validation = false;
            }

            if (selectedCity.length === 0) {
                setCityError("Please provide your city name");
                errors.push("city");
                validation = false;
            }

            if (pinCode.length === 0 || pinCode.length < 6) {
                setPinCodeError(pinCode.length === 0 ? "Provide your PIN code" : "Provide your full PIN code");
                errors.push("pincode");
                validation = false;
            }
        }

        if (PROGRESS_DATA.kyc_data.is_indian_tax_payer === false) {
            if (correspondNation.length === 0) {
                setCorrespondNationError("Please provide your country information");
                errors.push("corrs-nationality");
                validation = false;
            }
            if (correspondAddressOne.length === 0) {
                setCorrespondAddressError("Please provide your address details")
                errors.push("corrs-address-1");
                validation = false;
            }

            if (correspondSecondAddress.length === 0) {
                setCorrespondSecondAddressError("Please provide your additional address details");
                errors.push("corrs-address-2");
                validation = false;
            }

            if (correspondState.length === 0) {
                setCorrespondStateError("Please provide your correspond state");
                errors.push("corrs-state");
                validation = false;
            }

            if (correspondCity.length === 0) {
                setCorrespondCityError("Please provide your correspond city");
                errors.push("corrs-city");
                validation = false;
            }

            if (correspondPinCode.length === 0 || correspondPinCode.length < 6) {
                setCorrespondPinCodeError(correspondPinCode.length === 0 ? "Provide your correspond PIN code" : "Provide your full correspond PIN code");
                errors.push("corrs-pincode");
                validation = false;
            }
        };


        // STEP - 3

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        // Saving details
        if (validation === true) {
            setLoading(true);
            _saveAddress();
        };

    };

    //API- Get state
    const _getState = () => {
        let is_post = false;
        let url = 'general/states';
        let data = null;

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStateData(response.data);
            } else {
                setStateData([]);
                // If we don't have state data
                // User cannot continue further
                toast.dismiss();
                toast.error("We are facing issue in fetching state data. Please try later", { className: "e-toast" });
            }

            setStateListLoading(false);

        });
    };

    //API- Post City
    const _getCity = (state_id) => {
        let is_post = true;
        let url = 'general/cities';
        let data = {
            "state_id": state_id,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCityData(response.data);
            } else {
                setCityData([]);
                toast.dismiss();
                toast.error("We are facing issue in fetching city data. Please try later", { className: "e-toast" });
            }

        });
    };


    //API- Post save address
    const _saveAddress = () => {

        let is_india_tax_payer = PROGRESS_DATA.kyc_data.is_indian_tax_payer;

        let is_post = true;
        let url = 'kyc/save-address';
        let data = {
            "address": {
                "permanent_address": {
                    "address_line1": address,
                    "address_line2": secondAddress,
                    "pincode": pinCode,
                    "country_id": 251,
                    "city": selectedCity.name,
                    "state_name": selectedState.name,
                    "state_id": selectedState.id,
                    "city_id": selectedCity.id
                },
                "corresponding_address": is_india_tax_payer == true ? ({}) : ({
                    "address_line1": correspondAddressOne,
                    "address_line2": correspondSecondAddress,
                    "pincode": correspondPinCode,
                    "country_id": correspondNation.id,
                    "city": correspondCity || null,
                    "state_name": correspondState || null,
                    "state_id": correspondState.id || null,
                    "city_id": correspondCity.id || null
                })
            },
            "is_same_corresponding_address": correspondAddress
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _getSignupProgress();
                toast.dismiss();
                toast.success("Address details saved successfully", {
                    className: "e-toast",
                });
                props.onUpdateStep(5);
            } else {

                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }

            setLoading(false);
        });
    };

    return (

        <>
            {
                initialStatus === false && stateListLoading === false ? (
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <div className='padding-26px-top'>
                                <img src={require("../../../Assets/Images/left-arrow.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={_handleBack} />
                            </div>
                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-egyptian-blue e-border-radius-16 top-0'
                                    style={{ width: `${(100 / PROGRESS_DATA.step_detail.length) * 3}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                    Step {PROGRESS_DATA.kyc_user === true ? '3' : '4'} of {PROGRESS_DATA.step_detail.length}
                                </p>
                            </div>

                            {/* Address details section */}
                            <div className='pt-3'>
                                <h3 className='mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    Address details
                                </h3>

                                <h6 className='d-flex flex-column gap-1 mb-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                    Permanent address
                                    {
                                        PROGRESS_DATA.kyc_user === false &&
                                        <span className='e-font-14'>
                                            (as per Aadhaar)
                                        </span>

                                    }
                                </h6>

                                {/* For non-kyc user */}
                                {
                                    PROGRESS_DATA.kyc_user === false ?
                                        <div className='col mb-3'>
                                            <div className="e-font-14 e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-22px e-bg-cultured e-border-radius-8 p-3 border">
                                                {address}{", "}
                                                {selectedCity.name}{", "}
                                                {selectedState.name}{", "}
                                                {pinCode}.
                                            </div>
                                        </div>
                                        :
                                        <>

                                            {/* Nationality select */}
                                            <div id='nationality'
                                                className='h-90 position-relative mb-2'>
                                                <CustomTextInput label="Country*"
                                                    placeholder="India"
                                                    type="text"
                                                    value="India"
                                                    readOnly
                                                    className="pe-none w-100 px-3 padding-12px-top padding-12px-bottom cursor-default"
                                                    labelStyle="e-font-weight-500" />
                                            </div>

                                            {/* address line 1 */}
                                            <div id='address-1'
                                                className='h-90 mb-2'>
                                                <CustomTextInput label={PROGRESS_DATA.kyc_user === true ? "Address*" : "Address"}
                                                    placeholder="202,Madhav Apts, jawar road......."
                                                    type="text"
                                                    value={address}
                                                    errorMsg={addressError}
                                                    className='w-100 px-3 padding-12px-top padding-12px-bottom'
                                                    labelStyle="e-font-weight-500"
                                                    handleChange={_handleAddressChange} />
                                            </div>

                                            {/* state and city */}
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div id='state'
                                                        className='h-90 position-relative mb-2'>
                                                        <CustomTextInput label={"State*"}
                                                            placeholder="Karnataka"
                                                            dropdown={true}
                                                            type="text"
                                                            value={selectedState.name}
                                                            errorMsg={stateError}
                                                            readOnly
                                                            disabled={stateData.length === 0 ? true : false}
                                                            className="w-100 px-3 pe-5 padding-12px-top padding-12px-bottom cursor-pointer"
                                                            labelStyle="e-font-weight-500"
                                                            onClick={() => _handleSuggestionModal(2)} />
                                                    </div>
                                                </div>

                                                {/*select city*/}
                                                <div className='col-lg-6'>
                                                    <div id='city'
                                                        className={`${selectedState.name ? "" : "e-disable"} h-90 position-relative mb-2`}>
                                                        <CustomTextInput label="City*"
                                                            placeholder="Bangalore"
                                                            dropdown={true}
                                                            type="text"
                                                            value={selectedCity.name}
                                                            errorMsg={cityError}
                                                            readOnly
                                                            disabled={selectedState.id && cityData.length !== 0 ? false : true}
                                                            className="w-100 px-3 pe-sm-3 pe-2 padding-12px-top padding-12px-bottom cursor-pointer"
                                                            labelStyle="e-font-weight-500"
                                                            onClick={() => _handleSuggestionModal(3)} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* pincode */}
                                            <div id='pincode'
                                                className='h-90 mb-2'>
                                                <CustomTextInput label={PROGRESS_DATA.kyc_user === true ? "Pincode*" : "Pincode"}
                                                    placeholder="670676"
                                                    type="number"
                                                    value={pinCode}
                                                    errorMsg={pinCodeError}
                                                    maxLength={6}
                                                    className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                    labelStyle="e-font-weight-500"
                                                    handleChange={_handlePinChange} />
                                            </div>
                                        </>
                                }

                                {
                                    PROGRESS_DATA.kyc_data.is_indian_tax_payer === false &&

                                    <>

                                        <h6 className='mb-2 py-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                            Overseas address*
                                        </h6>
                                        {/* Nationality select */}
                                        <div id='corrs-nationality' className='h-90 position-relative mb-2'>
                                            <CustomTextInput label="Country*"
                                                placeholder="India"
                                                dropdown={true}
                                                type="text"
                                                value={correspondNation.name}
                                                errorMsg={correspondNationError}
                                                readOnly
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                onClick={() => _handleSuggestionModal(4)} />

                                        </div>

                                        {/* address line 1 */}
                                        <div id='corrs-address-1' className='h-90 mb-2'>
                                            <CustomTextInput label="Address line 1*"
                                                placeholder="202,Madhav Apts, jawar road......."
                                                type="text"
                                                value={correspondAddressOne}
                                                errorMsg={correspondAddressError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(value) => {
                                                    setCorrespondAddressOne(value);
                                                }} />
                                        </div>

                                        {/* address line 2 */}
                                        <div id='corrs-address-2' className='h-90 mb-2'>
                                            <CustomTextInput label="Address line 2*"
                                                placeholder="202,Madhav Apts, jawar road......."
                                                type="text"
                                                value={correspondSecondAddress}
                                                errorMsg={correspondSecondAddressError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => {
                                                    setCorrespondSecondAddress(e);
                                                }} />
                                        </div>

                                        {/* state and city */}
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div id='corrs-state'
                                                    className='h-90 position-relative mb-2'>

                                                    <CustomTextInput label="State*"
                                                        placeholder="Kerala"
                                                        type="text"
                                                        value={correspondState}
                                                        errorMsg={correspondStateError}
                                                        className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                        labelStyle="e-font-weight-500"
                                                        handleChange={(val) => setCorrespondState(val)} />
                                                </div>
                                            </div>

                                            {/*select city*/}
                                            <div className='col-lg-6'>
                                                <div id='corrs-city'
                                                    className={`${correspondState ? "" : "e-disable"} h-90 position-relative mb-2`}>

                                                    <CustomTextInput label="City*"
                                                        placeholder="Kannur"
                                                        type="text"
                                                        value={correspondCity}
                                                        errorMsg={correspondCityError}
                                                        className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                        labelStyle="e-font-weight-500"
                                                        handleChange={(val) => setCorrespondCity(val)} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* ZIP code*/}
                                        <div id='corrs-pincode'
                                            className='h-90 mb-2'>
                                            <CustomTextInput label={"Zipcode*"}
                                                placeholder="670676"
                                                type="number"
                                                value={correspondPinCode}
                                                errorMsg={correspondPinCodeError}
                                                maxLength={100}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                                labelStyle="e-font-weight-500"
                                                handleChange={_handleCorrPinCodeChange} />
                                        </div>
                                    </>
                                }
                            </div>

                            {/* custom button */}
                            <div className='padding-28px-top'>
                                <PrimaryButton name={"Save & Continue"}
                                    loading={loading}
                                    className="w-40 padding-12px-top padding-12px-bottom"
                                    disabled={loading}
                                    onPress={_handleSubmit} />
                            </div>
                        </div >


                    </div >
                ) : (
                    <div className="min-h-100vh text-center mt-5 pt-5" >
                        <img src={Loader}
                            className='object-fit-contain w-20'
                            draggable="false"
                            alt="loader" />
                    </div>
                )}



            {/* Modals */}
            {/* State modal*/}
            {
                showSuggestModal && modalType === 2 &&
                <SelectionSuggestModal title="Choose a state"
                    data={stateData}
                    type={2}
                    search={true}
                    id='select-state-modal'
                    searchPlaceholder={"Eg : Karnataka"}
                    selectedOption={selectedState}
                    onSelectOption={_handleSelectState}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* city modal */}
            {
                showSuggestModal && modalType === 3 &&
                <SelectionSuggestModal title="Choose a city"
                    data={cityData}
                    type={3}
                    search={true}
                    searchPlaceholder={"Eg : Bangalore"}
                    id='select-city-modal'
                    selectedOption={selectedCity}
                    onSelectOption={_handleSelectCity}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* correspond Address */}

            {
                showSuggestModal && modalType === 4 &&
                <SelectionSuggestModal title="Choose a country"
                    data={countryData.countries_for_address.filter((item => item.id !== 251))}
                    type={4}
                   
                    id='select-correspond-country-modal'
                    selectedOption={correspondNation}
                    onSelectOption={_handleCorrespondNation}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

        </>
    )
}

export default AddAddress;