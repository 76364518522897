/*
 *   File : service.js
 *   Author URI : https://evoqins.com
 *   Description : Global function for API
 *   Integrations : axios
 *   Version : v1.1
*/

import axios from "axios";
// import { logOut } from "../Helper";
import Store from "../Store/store";

export default async function APIService(is_post, url, data, token = null) {
    let API_URL = process.env.REACT_APP_API_URL + url;

    let auth_token = null;

    if (token === null) {
        auth_token = Store.getState().Reducer.ACCESS_TOKEN;
    } else {
        auth_token = token;
    }

    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth_token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                return error.response.data;
            });
    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth_token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
