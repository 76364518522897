/*
 *   File : personal-info.js
 *   Author : https://evoqins.com
 *   Description : Risk profiler personal questions component
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { PrimaryButton } from "../../../Component/Buttons";
import { CustomSlider, RadioGroup } from "../../../Component/Form-Elements";
import { APIService } from "../../../Service";
import { SectionTitle } from "../../../Component/Title";


// Assets 
import Loader from "../../../Assets/Images/coloured_loader.svg"

function PersonalInfo(props) {
    // const [personalQuestions, setPersonalQuestions] = useState();

    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const [questionsList, setQuestionsList] = useState(null);
    const [timePeriod, setTimePeriod] = useState(1);

    const [loading, setLoading] = useState(true);

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getRiskQuestions();
    }, []);

    // useEffect(() => {
    //     if (props.data) {
    //         let data = props.data.find(data => data.category_id === 1);
    //         setPersonalQuestions(data);
    //     };
    // }, [props.data]);

    //handle time period slider
    const _handleTimePeriodSlider = (value, question_id) => {

        setTimePeriod(value);

        let selected_answers = [...selectedAnswer];
        let updated_answer = [];
        updated_answer = {
            question_id: question_id,
            answer: value,
            answer_id: 1
        };
        let selected_index = selectedAnswer.findIndex((selected_item) => selected_item.question_id === question_id);
        if (selected_index !== -1) {
            selected_answers[selected_index] = updated_answer;
        } else {
            selected_answers.push(updated_answer);
        }
        setSelectedAnswer([...selected_answers]);
    };

    //handle select answer
    const _handleSelectAnswer = (data, que_id) => {
        let selected_answers = [...selectedAnswer];
        let updated_answer = [];
        if (data === 1) {
            updated_answer = {
                question_id: que_id,
                answer: null,
                answer_id: 1
            };
        } else if (data === 2) {
            updated_answer = {
                question_id: que_id,
                answer: null,
                answer_id: 2
            };
        }
        else {
            updated_answer = {
                question_id: que_id,
                answer_id: data,
                answer: data
            };

        }

        let selected_index = selectedAnswer.findIndex((selected_item) => selected_item.question_id === que_id);
        if (selected_index !== -1) {
            selected_answers[selected_index] = updated_answer;
        } else {
            selected_answers.push(updated_answer);
        }

        setSelectedAnswer([...selected_answers]);
    }

    // handle save answer
    const _handleSaveAnswer = () => {
        setSaving(true);
        _saveAnswer();
    }

    // API - get questions list
    const _getRiskQuestions = () => {
        let is_post = false;
        let url = "quiz/list-questions";

        APIService(is_post, url).then(response => {
            if (response.status_code === 200) {
                setQuestionsList(response.data);
                let selected_answers = [];
                response.data[0].questions.map((item, index) => {
                    let defaultAnswer = null;
                    if (item.question_type === 1) {  // [type-1] => MCQ
                        for (const answer of item.answers) {
                            if (answer.is_answer) {
                                defaultAnswer = {
                                    question_id: item.question_id,
                                    answer_id: answer.answer_id,
                                    answer: answer.answer
                                };
                                break; // Stop iterating once the default answer is found
                            }
                        }
                        // update default-selected values to state variable
                        if (defaultAnswer === null) {
                            // setting default answer by selecting the first item
                            defaultAnswer = {
                                question_id: item.question_id,
                                answer_id: item.answers[0]?.answer_id,
                                answer: item.answers[0]?.answer
                            };

                        }
                    } else if (item.question_type === 2) {   // [type-2] => boolean with slider-value
                        if (item.answer === null) {
                            defaultAnswer = {
                                question_id: item.question_id,
                                answer: null,
                                answer_id: 2
                            };
                            setTimePeriod(item.config.min_max.default);
                        } else {
                            defaultAnswer = {
                                question_id: item.question_id,
                                answer: item.answer,
                                answer_id: 1
                            };
                            setTimePeriod(item.config.min_max.default);
                        };
                    }
                    selected_answers.push(defaultAnswer);
                    setSelectedAnswer([...selected_answers]);
                });
                setLoading(false);

            } else {
                setQuestionsList(null);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
                setLoading(false);
            }
        })
    };

    //API- Save answer
    const _saveAnswer = () => {
        let is_post = true;
        let url = 'quiz/save-answer';
        let data = {
            answers: selectedAnswer
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                props.onUpdateProfile(2);
                setSaving(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
                setSaving(false);
            }
        });
    }

    return (
        <>
            {loading ?
                // loader
                <div className="min-h-100vh  text-center mt-5 pt-5" >
                    <img src={Loader}
                        draggable="false"
                        alt="loader" />
                </div>
                :
                <div>
                    {/* title */}
                    <SectionTitle title={questionsList[0].name} />

                    {/* mapping question list */}
                    {
                        questionsList[0].questions.map((data, key) => {
                            let selected_item = -1;
                            let selected_index = selectedAnswer.findIndex((selected_item) => selected_item.question_id === data.question_id);
                            if (selected_index !== -1) {
                                selected_item = selectedAnswer[selected_index].answer_id;
                            }
                            return (
                                <div key={key} >
                                    <p className="mb-3 pt-4 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px">
                                        {key + 1}. {data.question}
                                    </p>

                                    {/* custom radio group */}
                                    <div className=' d-flex e-gap-8-16 e-flex-wrap padding-12px-left'>
                                        <RadioGroup data={data.answers}
                                            question_id={data.question_id}
                                            type={2}
                                            selected={selected_item}
                                            handleSelect={_handleSelectAnswer} />
                                    </div>

                                    {/* time period slider  */}
                                    {
                                        data.question_type === 2 &&
                                        <div className="e-bg-cultured p-4 e-border-radius-16 border margin-12px-left no-margin-res">
                                            <div className="e-display-flex justify-content-space-between">
                                                <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                                    Time period
                                                </h4>
                                                <p className="mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                    {/* {timePeriod} {data.config.postfix_enabled === false ? data.config.plural_postfix : data.config.singular_postfix} */}
                                                    {timePeriod} {timePeriod === 1 ? 'month' : 'months'}
                                                </p>
                                            </div>
                                            {/* custom slider  */}
                                            <div className="padding-30px-top pb-3">
                                                <CustomSlider min={data.config.min_max.min}
                                                    max={data.config.min_max.max}
                                                    step={1}
                                                    // defaultValue={data.config.min_max.default}
                                                    defaultValue={timePeriod}
                                                    handleChange={(value) => _handleTimePeriodSlider(value, data.question_id)} />
                                                {/* <div className="d-flex justify-content-space-between mt-4">
                                                    <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                        {data.config.min_max.min} {data.config.min_max.min > 1 ? "Yrs" : "Yr"}
                                                    </p>
                                                    <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                        {data.config.min_max.max} Yrs
                                                    </p>
                                                </div> */}

                                            </div>
                                            <div className="d-flex justify-content-space-between padding-12px-top">
                                                <p className="mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    {data.config.min_max.min} {data.config.singular_postfix}
                                                </p>
                                                <p className="mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    {data.config.min_max.max} {data.config.plural_postfix}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

                    {/* //custom button */}
                    <PrimaryButton name={"Save & continue"}
                        loading={saving ? true : false}
                        className="w-40 mt-5 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                        onPress={_handleSaveAnswer}
                    />
                </div>
            }
        </>
    );
};

export default memo(PersonalInfo);