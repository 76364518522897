/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : SIP listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from "react-toastify";
import { Modal } from 'bootstrap';

//custom components
import { BreadCrumbs, CustomTab, EmptyScreen } from '../../Component/Other';
import { ActiveSIPCard, ExecutedSIPCard, CancelledSIPCard } from '../../Component/Cards';
import { Footer } from '../../Component/Footer';
import { APIService } from '../../Service';
import { EditSIPModal, SIPConfirmationModal } from '../../Component/Modal';
import { Pagination } from '../../Component/Pagination';

//assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptySIP from "../../Assets/Images/EmptyScreen/no-sip.webp";
import NextIcon from "../../Assets/Images/right-black-icon.svg";


const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Manage SIP",
    },
];

const SIP_TAB = [
    {
        id: 1,
        label: "Active"
    },
    {
        id: 2,
        label: "Executed"
    },
    {
        id: 3,
        label: "Cancelled"
    },
];

const ITEMS_PER_PAGE = 10;


const SIPListing = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(location.state !== null && location.state.tab_index ? location.state.tab_index : 1);

    const [activeSIPList, setActiveSIPList] = useState([]);
    const [executedList, setExecutedList] = useState([]);
    const [cancelledList, setCancelledList] = useState([]);
    const [sipCancelReasons, setSipCancelReasons] = useState([]);

    const [selectedSIP, setSelectedSIP] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(null);
    const [showEditSIPModal, setShowEditSIPModal] = useState(false);
    const [showCancelSIPModal, setShowCancelSIPModal] = useState(false);

    const [totalPageActive, setTotalPageActive] = useState(0);
    const [totalPageProcessed, setTotalPageProcessed] = useState(0);
    const [totalPageCancelled, setTotalPageCancelled] = useState(0);

    const [pageNumberActive, setPageNumberActive] = useState(1);
    const [pageNumberProcessed, setPageNumberProcessed] = useState(1);
    const [pageNumberCancelled, setPageNumberCancelled] = useState(1);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        window.addEventListener("load", () => {
            setTabIndex(location.state !== null ? location.state.tab_index : 1);
        });

        // Getting sip cancel reasons
        _getSipCancelReasons();

    }, [location.state]);

    useEffect(() => {

        if (tabIndex === 1 && activeSIPList.length === 0) {
            setLoading(true);
            _getActiveLIst();
        } else if (tabIndex === 2 && executedList.length === 0) {
            setLoading(true);
            _getExecutedList();
        } else if (tabIndex === 3 && cancelledList.length === 0) {
            setLoading(true);
            _getCancelledList();
        }

    }, [tabIndex]);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        if (tabIndex === 1 && activeSIPList.length !== 0) {
            setLoading(true);
            _getActiveLIst();
        } else if (tabIndex === 2 && executedList.length !== 0) {
            setLoading(true);
            _getExecutedList();
        } else if (tabIndex === 3 && cancelledList.length !== 0) {
            setLoading(true);
            _getCancelledList();
        }


    }, [pageNumberActive, pageNumberProcessed, pageNumberCancelled]);

    useEffect(() => {
        // Saving current tab in location state
        navigate("/sip", {
            state: {
                "tab_index": tabIndex
            },
            replace: true,

        });

    }, [tabIndex]);

    useEffect(() => {

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            if (showEditSIPModal === true) {
                setShowEditSIPModal(false);
            };

            if (showCancelSIPModal === true) {
                setShowCancelSIPModal(false);
            };
        });

    }, [showEditSIPModal, showCancelSIPModal]);

    useEffect(() => {
        if (update) {
            _getActiveLIst();
        };
    }, [update]);

    useEffect(() => {

        if (showEditSIPModal) {
            let edit_sip = new Modal(document.getElementById('edit-sip-modal'));
            edit_sip.show();
        };

    }, [showEditSIPModal]);

    useEffect(() => {

        if (showCancelSIPModal) {
            let confirm_modal = new Modal(document.getElementById('sip-confirmation-modal'));
            confirm_modal.show();
        };

    }, [showCancelSIPModal]);

    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    // handle open cancel confirm modal 
    const _handleCancelModal = (id) => {
        setShowCancelSIPModal(true);
        setSelectedSIP(id);
    };

    //handle modal close
    const _handleCloseConfirmModal = () => {
        var button = document.getElementById("sip-confirmation-close");
        button.click();
    };

    // handle delete active sip
    const _handleCancelActiveSIP = (cancel_reason) => {
        _cancelSIP(cancel_reason.id);
    };

    // handle edit modal
    const _handleEditSIPModal = (data) => {



        if (Object.keys(data).length !== 0) {

            setShowEditSIPModal(true);
            setData(data)


        }
    };

    // handle trigger the change
    const _handleUpdate = () => {
        setUpdate(true);
    };

    const _handlePaginationActive = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setPageNumberActive(selected_page);
    };
    const _handlePaginationProcessed = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setPageNumberProcessed(selected_page);
    };
    const _handlePaginationCancelled = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setPageNumberCancelled(selected_page);
    };

    //API- sip active list
    const _getActiveLIst = () => {
        let is_post = true;
        let url = 'sip/active-list';
        let data = {
            "page_num": pageNumberActive,
            "page_size": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setActiveSIPList(response.data.sips);
                setTotalPageActive(Math.ceil(response.data.total_sips / ITEMS_PER_PAGE));
            } else {
                setActiveSIPList([]);
            }
            setLoading(false);
        });
    };

    //API- sip executed list
    const _getExecutedList = () => {
        let is_post = true;
        let url = 'sip/processed-list';
        let data = {
            "page_num": pageNumberProcessed,
            "page_size": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setExecutedList(response.data.sips);
                setTotalPageProcessed(Math.ceil(response.data.total_sips / ITEMS_PER_PAGE));
            } else {
                setExecutedList([]);
            }
            setLoading(false);
        });
    };


    //API- sip cancelled list
    const _getCancelledList = () => {
        let is_post = true;
        let url = 'sip/cancelled-list';
        let data = {
            "page_num": pageNumberCancelled,
            "page_size": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCancelledList(response.data.sips);
                setTotalPageCancelled(Math.ceil(response.data.total_sips / ITEMS_PER_PAGE));
            } else {
                setCancelledList([]);

            }
            setLoading(false);
        });
    };

    //API- Cancel SIP
    const _cancelSIP = (cancellation_reason_id) => {
        let is_post = true;
        let url = 'sip/cancel';
        let data = {
            "sip_id": selectedSIP,
            "cancellation_reason_id": cancellation_reason_id,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoading(true);
                _handleCloseConfirmModal(); //modal close
                _getActiveLIst();
                _getCancelledList();

                toast.dismiss();
                toast.success("Sip cancelled successfully", { className: "e-toast", });

            } else {
            }
        });
    };

    //API- Cancel SIP Reasons
    const _getSipCancelReasons = () => {
        let is_post = false;
        let url = 'sip/cancel-reason';

        APIService(is_post, url).then((response) => {
            if (response.status_code === 200) {
                let cancel_reason = response.data.map((data) => {
                    const { id, reason } = data;
                    return { id, name: reason }
                })
                setSipCancelReasons(cancel_reason);
            }
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px" >
                {/* breadcrumbs */}

                <div className="col-lg-11 p-0 pb-sm-4">
                    <BreadCrumbs data={BREADCRUMBS} />

                    <div className='px-0 padding-32px-top'>

                        {/* tab component */}
                        <div
                            className="d-flex e-border-bottom-2px-bright-gray">
                            {
                                SIP_TAB.map((data, key) => (
                                    <CustomTab key={key}
                                        tabData={data}
                                        tabIndex={tabIndex}
                                        handleChange={_handleTabChange} />
                                ))
                            }
                        </div>

                        {
                            loading ?
                                <div className="min-h-100vh text-center mt-5" >
                                    <img src={Loader}
                                        draggable="false"
                                        alt="loader" />
                                </div>
                                :
                                <>
                                    {/* active sip card */}
                                    <div className='row pt-4 mt-2 pb-2 row-gap-4'>

                                        {
                                            tabIndex === 1 && (
                                                activeSIPList.length !== 0 ? (
                                                    <>
                                                        {
                                                            activeSIPList.map((data, key) => (
                                                                <div key={key}
                                                                    className='col-xl-4 col-sm-6 col-12'>
                                                                    <ActiveSIPCard key={key}
                                                                        data={data}
                                                                        onCancel={_handleCancelModal}
                                                                        handleEdit={_handleEditSIPModal}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            totalPageActive > 1 &&
                                                            <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 " >
                                                                <Pagination
                                                                    initialPage={pageNumberActive - 1}
                                                                    nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                                    totalPage={totalPageActive}
                                                                    onChange={_handlePaginationActive}
                                                                    showFirstLastPages={true} />
                                                            </div >
                                                        }
                                                    </>
                                                ) : (

                                                    <EmptyScreen
                                                        img={EmptySIP}
                                                        title="No active SIP !"
                                                        description="Start building your wealth effortlessly with SIPs - Explore funds and invest in simple steps." />
                                                )
                                            )
                                        }

                                        {
                                            tabIndex === 2 && (
                                                executedList.length !== 0 ? (
                                                    <>
                                                        {
                                                            executedList.map((data, key) => (
                                                                <div key={key}
                                                                    className='col-xl-4 col-sm-6 col-12'>
                                                                    <ExecutedSIPCard key={key}
                                                                        data={data} />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            totalPageProcessed > 1 &&
                                                            <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 " >
                                                                <Pagination
                                                                    initialPage={pageNumberProcessed - 1}
                                                                    nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                                    totalPage={totalPageProcessed}
                                                                    onChange={_handlePaginationProcessed}
                                                                    showFirstLastPages={true} />
                                                            </div >
                                                        }

                                                    </>
                                                ) : (

                                                    <EmptyScreen
                                                        img={EmptySIP}
                                                        title="No executed SIP !"
                                                        description="Start building your wealth effortlessly with SIPs - Explore funds and invest in simple steps." />

                                                )
                                            )
                                        }

                                        {
                                            tabIndex === 3 && (
                                                cancelledList.length !== 0 ? (
                                                    <>
                                                        {
                                                            cancelledList.map((data, key) => (
                                                                <div key={key}
                                                                    className='col-xl-4 col-sm-6 col-12'>
                                                                    <CancelledSIPCard key={key}
                                                                        data={data} />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            totalPageCancelled > 1 &&
                                                            <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 " >
                                                                <Pagination
                                                                    initialPage={pageNumberCancelled - 1}
                                                                    nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                                    totalPage={totalPageCancelled}
                                                                    onChange={_handlePaginationCancelled}
                                                                    showFirstLastPages={true} />
                                                            </div >
                                                        }
                                                    </>
                                                ) : (

                                                    <EmptyScreen
                                                        img={EmptySIP}
                                                        title="No cancelled SIP !"
                                                        description="Start building your wealth effortlessly with SIPs - Explore funds and invest in simple steps." />
                                                )
                                            )
                                        }

                                    </div>

                                </>
                        }

                    </div>
                </div>

                {/* cancel conformation modal  */}
                {
                    showCancelSIPModal &&
                    <SIPConfirmationModal
                        sipCancelReasons={sipCancelReasons}
                        cancelSip={_handleCancelActiveSIP}
                        onClose={() => setShowCancelSIPModal(false)} />
                }

                {/* edit sip modal */}
                {
                    showEditSIPModal &&
                    <EditSIPModal data={data}
                        state={_handleUpdate}
                        onClose={() => setShowEditSIPModal(false)} />
                }
            </div>

            {/* footer  */}
            <Footer />
        </>
    )
}

export default SIPListing;