/*
 *   File : search-branch.js
 *   Author : https://evoqins.com
 *   Description : Search branch modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//custom components
import { CustomTextInput } from '../Form-Elements';

//Assets
import Close from "../../Assets/Images/close-btn.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";
import NoDataScreen from "../../Assets/Images/EmptyScreen/no-data.webp";

const SearchBranchModal = (props) => {

    const [selected, setSelected] = useState({});
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (props.selectedBranch) {
            setSelected(props.selectedBranch);
        };
    }, [props.selectedBranch]);

    useEffect(() => {
        setSearchValue(props.searchValue);
    }, [props.searchValue]);

    // handle close modal 
    const _handleModalClose = () => {
        var button = document.getElementById("branch-search-modal-close");
        button.click();
        props.onModalClose();
    };

    //handle search
    const _handleSearch = (value) => {
        props.handleSearch(value);
    };

    //handle select 
    const _handleSelect = (data) => {
        props.handleSelectedBranch(data);

        _handleModalClose();
    };


    return (
        <div className={`modal fade e-manage-modal`}
            id="branch-search-modal"
            data-bs-backdrop="false"
            tabIndex="-1"
            aria-labelledby="branchSearchModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
                <div className={`e-bg-lotion modal-content e-border-radius-24 e-how-works-box-shadow h-70vh`}>
                    <div className="modal-header border-0 justify-content-space-between pt-4 pb-4 px-3 px-sm-4 ">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Select branch
                        </h6>
                        <img src={Close}
                            id='branch-search-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={_handleModalClose}
                        />
                    </div>
                    {/* Body */}
                    <div className="modal-body d-flex flex-column position-relative pb-3 px-3 px-sm-4 pt-0">
                        {/* custom search */}
                        <div className="position-sticky top-0 h-52 h-42-res mb-2 z-3 bg-white">
                            <CustomTextInput placeholder="Search with branch name"
                                className="w-100 no-outline padding-12px-tb padding-56px-lr padding-32px-lr-res"
                                height="h-auto"
                                inputWrapperClassName={"e-border-1px-bright-gray"}
                                inputWrapperBorderStyle={"e-border-radius-16"}
                                autoComplete="off"
                                value={searchValue}
                                handleChange={_handleSearch} />
                            <img src={require("../../Assets/Images/search-icon.svg").default}
                                draggable={false}
                                className="width-20px-res position-absolute top-24px translate-middle-y start-0 ms-2 ms-sm-3"
                                alt="search" />

                            {searchValue.length !== 0 &&
                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    draggable={false}
                                    className="width-24px-res w-24px object-fit-contain position-absolute top-24px translate-middle-y end-0 me-2 me-sm-3 cursor-pointer"
                                    alt="clear"
                                    onClick={props.handleClearSearch} />
                            }
                        </div>

                        {props.branchLoading === true ?
                            <div className="text-center mt-5 pt-5" >
                                <img src={Loader}
                                    draggable={false}
                                    width={62}
                                    alt="loader" />
                            </div>
                            :
                            <>
                                {props.data.length !== 0 ?
                                    <>
                                        {/* branch list */}
                                        {props.data.map((data, key) => (
                                            <div key={key} className='z-1'>
                                                <div className='e-display-flex gap-2 cursor-pointer'
                                                    onClick={() => _handleSelect(data)}>
                                                    {selected.ifsc === data.ifsc &&
                                                        <img src={require('../../Assets/Images/KYC/tick.svg').default}
                                                            draggable={false}
                                                            alt='mark' />
                                                    }
                                                    <p className={`${selected.ifsc === data.ifsc ? `e-text-egyptian-blue ps-2` : `e-text-sonic-silver ps-4 ms-3`}  mb-0 py-3  e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                        {data.display_name ? data.display_name : data.name}
                                                    </p>
                                                </div>
                                                {/* border */}
                                                {key !== props.data.length - 1 && (
                                                    <div className="margin-40px-left e-border-bottom-1px-light-gray"></div>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <div className='text-center mt-auto'>
                                        <img src={NoDataScreen}
                                            className='img-fluid col-5 col-sm-6 col-lg-5'
                                            draggable="false" />
                                        <p className='my-2 e-text-charleston-green e-alt-font-poppins e-font-18 e-font-weight-500 line-height-24px'>
                                            No branches found
                                        </p>
                                        <p className='col-8 col-sm-auto mx-auto e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
                                            There is no branches found.
                                        </p>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SearchBranchModal;
