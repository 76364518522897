/*
 *   File :digilocker.js
 *   Author : https://evoqins.com
 *   Description :Digilocker page
 *   Integrations : react-redux,react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

//custom components
import { APIService } from '../../../Service';
import { _getSignupProgress } from '../../../Helper';
import { PrimaryButton } from '../../../Component/Buttons';

//Assets
import LeftArrow from "../../../Assets/Images/left-arrow.svg";
import Loader from "../../../Assets/Images/loader-blue.gif";

const Digilocker = (props) => {

    //get progress data form redux
    const PROGRESS_DATA = useSelector((store) => store.Reducer.SIGNUP_PROGRESS_DATA);

    const [fetchResponse, setFetchResponse] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (PROGRESS_DATA.is_identity_verified === true) {
            if (PROGRESS_DATA.kyc_data.status === "ADDRESS_PROOF" || PROGRESS_DATA.current_step > 3 || PROGRESS_DATA.kyc_user === true) {
                props.onUpdateStep(4);
            }
        }
        setLoader(false);
    }, [PROGRESS_DATA]);

    // handle fetch 
    const _handleFetch = () => {
        setLoader(true);
        _fetchAadhaar();
    };

    // handle save and continue 
    const _handleSave = () => {
        _getSignupProgress();
        props.onUpdateStep(4);
    };

    //API - Fetch aadhaar details from DigiLocker
    const _fetchAadhaar = (check_status) => {
        let is_post = false;
        let url = 'kyc/get-aadhaar';
        let data = null;

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {


                if (check_status === true) {

                    if (response.data.status === 'SUCCESSFUL') {
                        setFetchResponse(true);
                    }

                } else {

                    if (response.data.redirect_url != null) {
                        const fetch_window = window.open(response.data.redirect_url, "", "width=500,height=500,left=400,top=100");
                        if (fetch_window === null || typeof fetch_window === 'undefined') {
                            toast.dismiss();
                            toast.error("Please enable Pop-up to open fetch window", { className: "e-toast", });
                            return;
                        };

                        let timer = setInterval(function () {
                            if (fetch_window.closed) {
                                clearInterval(timer);
                                let check_status = true;
                                _fetchAadhaar(check_status)
                            }
                        }, 1000);

                    } else {
                        if (response.data.status === 'SUCCESSFUL') {
                            setFetchResponse(true);
                        }
                    }

                }

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
            setLoader(false);
        });
    };

    return (
        <div className='px-0 pb-4'>
            <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                <div className='padding-26px-top'>
                    <img src={LeftArrow}
                        draggable={false}
                        className='cursor-pointer'
                        alt='arrow'
                        onClick={() => props.onUpdateStep(2)} />
                </div>

                {/* step flow */}
                <div className='position-relative'>
                    <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                    <div className='position-absolute h-6 e-bg-egyptian-blue e-border-radius-16 top-0'
                        style={{ width: `${(100 / PROGRESS_DATA.step_detail.length) * 2}%` }}></div>
                    <p className='mb-0 text-end pt-1 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Step 3 of {PROGRESS_DATA.step_detail.length}
                    </p>
                </div>

                {/* Personal details */}
                <div className=' pt-3'>
                    <h3 className='e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                        Aadhaar fetching
                    </h3>
                    <div className='row pt-4'>
                        <div className='col-xl-7 col-lg-8 col-md-6 col-sm-9 col-12'>
                            <div className='e-bg-cultured p-4 e-border-radius-16'>
                                <label className='e-text-charleston-green e-font-weight-500 e-alt-font-poppins line-height-24px e-font-14'>
                                    Fetch Aadhaar from Digilocker*
                                </label>

                                <div className={`${loader ? 'pe-none' : ''} ${fetchResponse === true ? 'e-border-1px-sonic-silver pe-none' : 'e-bg-lavender-blue-dark e-border-1px-egyptian-blue'}  mt-3 mb-2 text-center e-border-radius-24 padding-12px-tb cursor-pointer`}
                                    onClick={_handleFetch} >
                                    {loader ?
                                        <img src={Loader}
                                            width={20}
                                            height={20}
                                            className='object-fit-contain my-1'
                                            draggable="false"
                                            alt='loader' />
                                        :
                                        <img src={require("../../../Assets/Images/KYC/digilocker.webp")}
                                            draggable={false}
                                            width={114}
                                            height={28}
                                            className=''
                                            alt='digilocker' />
                                    }
                                </div>

                                {fetchResponse === true &&
                                    <>
                                        <span className='e-text-ufo-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-14px'>
                                            Aadhaar fetched successfully
                                        </span>
                                        <img src={require("../../../Assets/Images/KYC/success-icon.svg").default}
                                            draggable={false}
                                            className='ps-2'
                                            alt='success' />

                                        <div className='row padding-40px-top'>
                                            <div className='col-auto'>
                                                <PrimaryButton name={"Save & Continue"}
                                                    className="w-100 padding-12px-top padding-12px-bottom px-4"
                                                    onPress={_handleSave} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Digilocker;
