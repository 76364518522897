/*
 *   File : create-ticket.js.js
 *   Author : https://evoqins.com
 *   Description : Ticket creating modal for customer support
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useState } from "react";
import { CustomTextAreaInput, CustomTextInput, FilePicker } from "../Form-Elements";
import { _checkFileSize } from "../../Helper";
import { PrimaryButton } from "../Buttons";
import { toast } from "react-toastify";
import { APIService, awsService } from "../../Service";

//Assets
import Success from "../../Assets/Images/success.webp";


function CreateTicketModal(props) {

    const [subject, setSubject] = useState("");
    const [description, setDesCription] = useState("");
    const [subjectErr, setSubjectErr] = useState("");
    const [descriptionErr, setDesCriptionErr] = useState("");
    const [attachedFile, setAttachedFile] = useState(null);
    const [attachedFileErr, setAttachedFileErr] = useState("");
    const [attachedFileUrl, setAttachedFileUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const _handleUploadImgProof = (file) => {

        if (file.target.files.length !== 0) {

            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setAttachedFileErr("Maximum file size should be 5mb only");
                return;
            };

            setAttachedFileErr("");

            _getPresignedUrl(file);
        };
    };

    const _handleSubmitTicket = () => {

        if (subject.length === 0) {
            setSubjectErr("Please specify the subject of your request.");
        };

        if (description.length === 0) {
            setDesCriptionErr("Please provide a detailed summary of your request.")
        } else {
            if (description.length < 15) {
                setDesCriptionErr("Minimum 15 characters required");
            }
        }

        if (subject !== "" && description !== "" && description.length >= 15) {
            setLoading(true);
            _createTicket();
        };

    };

    //get presigned url
    async function _getPresignedUrl(file) {

        let is_post = true;
        let url = 'file/upload';
        let data = {
            "type": file.target.files[0].type,
            "file": file.target.files[0].name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            let aws_URL = '';
            if (response.status_code == 200) {

                aws_URL = response.data.upload_info.url;
                let view_url = response.data.view_info;

                let file_param = new File(
                    [file.target.files[0]],
                    file.target.files[0].name,
                    { type: file.target.files[0].type }
                );

                try {
                    let awsResponse = await awsService(response.data.upload_info.fields, file_param, aws_URL);

                    if (awsResponse.status == 'ok') {
                        setAttachedFileUrl(view_url);
                        setAttachedFile(file.target.files[0].name);
                    } else {
                        toast.dismiss();
                        toast.error(awsResponse.message, { className: "e-toast" });
                    }
                } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, { className: "e-toast" });
                }
            }
        } catch (err) {
        }
    };

    // Create ticket API call
    const _createTicket = () => {

        let is_post = true;
        let url = 'support/new-ticket';
        let data = {
            "query_type": props.data.id,
            "subject": subject,
            "description": description,
            "image_url": attachedFileUrl,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast" })
            }

            setLoading(false);
        });

    };

    return (
        <div className="modal fade e-manage-modal"
            id="create-ticket-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="CreateTicketModal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">

                {
                    success === false &&
                    <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                        <div className="modal-header border-0 justify-content-space-between py-3 px-4 ">
                            <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                {props.data.name}
                            </h6>
                            <img src={require("../../Assets/Images/close-btn.svg").default}
                                id='create-ticket-modal-close'
                                draggable={false}
                                className='cursor-pointer'
                                data-bs-dismiss="modal"
                                aria-label="close"
                                alt='close btn'
                                onClick={props.handleModalClose} />
                        </div>
                        <div className="modal-body pb-4 px-4 pt-0">
                            <div className="d-flex flex-column gap-4">

                                <CustomTextInput label="Subject*"
                                    placeholder="Eg: Why my KYC got rejected"
                                    type="text"
                                    value={subject}
                                    errorMsg={subjectErr}
                                    handleChange={(value) => {
                                        setSubject(value);
                                        setSubjectErr('');
                                    }} />

                                <CustomTextAreaInput label="Describe your issue in detail*"
                                    row="5"
                                    value={description}
                                    errorMsg={descriptionErr}
                                    placeholder="For a better response, kindly include a screenshot or photograph."
                                    handleChange={(value) => {
                                        setDesCription(value);
                                        setDesCriptionErr("");
                                    }} />

                                <div>
                                    <label id='ticket-img-proof'
                                        className="e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-24px e-font-14 pb-2">
                                        Upload img(Max.5mb)
                                    </label>
                                    {/* Additional proof image */}
                                    {attachedFile !== null &&
                                        <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                            <div className='e-display-flex justify-content-space-between'>
                                                <p className='e-font-12-res mb-0 padding-12px-top padding-12px-bottom e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    {attachedFile}
                                                </p>
                                                <img src={require("../../Assets/Images/KYC/selected-close.svg").default}
                                                    draggable={false}
                                                    className='cursor-pointer'
                                                    alt='delete'
                                                    onClick={() => setAttachedFile(null)} />
                                            </div>
                                        </div>
                                    }
                                    <FilePicker id="img-proof"
                                        label="Click here to upload(PNG or JPG)"
                                        type="file"
                                        className="w-100"
                                        accept=".png, .jpg"
                                        error={attachedFileErr}
                                        currentFile={attachedFile}
                                        selectFile={_handleUploadImgProof} />
                                </div>


                            </div>
                            {/* Submit button */}
                            <PrimaryButton name="Submit"
                                loading={loading}
                                className="e-font-14-res w-100 padding-12px-top padding-12px-bottom mt-3"
                                disabled={loading}
                                onPress={_handleSubmitTicket} />
                        </div>
                    </div>
                }

                {
                    success === true &&
                    <div className=" modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow ">
                        <div className="modal-gradient-bg d-flex flex-column align-items-center modal-body p-4 e-border-radius-24">
                            <img className="img-fluid col-5 mt-4 pt-3"
                                draggable={false}
                                src={Success}
                                alt="payment status image" />
                            <p className="e-text-ufo-green e-alt-font-poppins e-font-18 e-font-weight-600 mt-4 mb-2">
                                Your ticket has been created successful!
                            </p>
                            <p className="col-9 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-26px text-center mx-auto mb-0 mb-5 ">
                                We will get back to as soon as possible to resolve your query.                            </p>
                            <div className="d-flex gap-3 w-100">
                                <PrimaryButton name={"Go To Tickets"}
                                    dismiss={"modal"}
                                    className="w-100 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                    onPress={() => props.handleModalClose("success")}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div >
    );
};

export default memo(CreateTicketModal);