/*
 *   File : manage-nominee.js
 *   Author : https://evoqins.com
 *   Description : Manage nominee modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { differenceInYears } from 'date-fns';
import { toast } from "react-toastify";

//custom component
import { OTPInput, RadioGroup } from '../Form-Elements';
import { PrimaryButton } from '../Buttons';
import { NomineeCard } from '../Cards';
import { useSelector } from 'react-redux';
import { _formatDateFormat, _getOTP, _getProfile } from '../../Helper';
import { APIService } from '../../Service';

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import Delete from "../../Assets/Images/KYC/delete.svg";

// Custom data
const NOMINEE = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

const ManageNomineeModal = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.Reducer.GENERAL_DATA);
    const USER_DATA = useSelector((store) => store.Reducer.USER_DATA); // name it
    const otpInputRef = useRef(null);
    const newNomineeRef = useRef(false);

    const [optInNominee, setOptInNominee] = useState(2);
    const [tabIndex, setTabIndex] = useState(1);
    const [toggleNominee, setToggleNominee] = useState(false);

    const [nomineeAllocation, setNomineeAllocation] = useState([]);

    // OTP
    const [focusedInput, setFocusedInput] = useState(2);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP

    const [nomineeData, setNomineeData] = useState([]);
    const [loadNomineeStatus, setLoadNomineeStatus] = useState(false);

    const [errorMessage, setErrorMessage] = useState([
        {
            "name_error": null,
            "relation_id_error": null,
            "dob_error": null,
            "allocation_error": null,
            "guardian_name_error": null,
            "guardian_relationship_id_error": null,
            "guardian_pan_error": null
        }
    ]);



    useEffect(() => {
        _getNomineeData();
    }, []);

    //for otp timer
    useEffect(() => {
        if (toggleNominee === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleNominee, timer]);

    useEffect(() => {
        if (nomineeData.length !== 0) {
            setOptInNominee(1);
        } else {
            setOptInNominee(2);
        }
    }, [nomineeData]);

    // Update Nominee data
    const _handleOnChangeInput = (val, nominee_index, type) => {

        let updated_nominees = nomineeData.map((data, key) => {
            if (nominee_index === key) {

                // updating err msg on change
                let error_list = errorMessage.map(((err, i) => {
                    if (nominee_index == i) {
                        return { ...err, [`${type}_error`]: "" }
                    } else {
                        return err;
                    }
                }))

                setErrorMessage(error_list);

                if (type === "relation") {
                    return { ...data, [type]: val, ["relation_id"]: val.id, ["relation_name"]: val.display_name };
                };

                if (type === "guardian_relationship_name") {
                    return {
                        ...data, [type]: val.display_name, ["guardian_relationship_id"]: val.id
                    };
                }

                if (type === "allocation") {
                    return { ...data, [type]: Number(val) };
                } else {
                    return { ...data, [type]: val };
                };

            };
            return data;
        });

        setNomineeData(updated_nominees);

        // Updating nominee allocation
        let updated_nominee_allocation = nomineeAllocation;
        updated_nominee_allocation[nominee_index] = Number(val);
        setNomineeAllocation(updated_nominee_allocation);

        // // Setting nominee allocation err
        // if (type == "allocation") {
        //     let total_allocation = Object.values(updated_nominee_allocation).reduce((a, b) => a + b);
        //     let updated_err_message;
        //     if (total_allocation > 100) {
        //         updated_err_message = errorMessage.map((data, key) => {
        //             if (nominee_index === key) {
        //                 return { ...data, allocation_error: "You cannot allocate " + val + "% see other nominee allocation" };
        //             } else {
        //                 return data;
        //             }
        //         });

        //     } else {
        //         updated_err_message = errorMessage.map((data, key) => {
        //             if (nominee_index === key) {
        //                 return { ...data, allocation_error: "" };
        //             }
        //             return data;
        //         })
        //     }

        //     setErrorMessage(updated_err_message);
        // };
    };

    //handle nominee select
    const _handleAddNominee = (id) => {
        setOptInNominee(id);
    };

    const _handleAddMoreNominee = () => {

        if (nomineeData.length !== 3) {
            // Create a new nominee with default values
            const new_nominee = {
                "name": null,
                "relation_id": null,
                "dob": null,
                "allocation": null,
                "guardian_name": null,
                "guardian_relationship_id": null,
                "guardian_pan": null,
                "id": nomineeData.length + 1,
            };

            // Update the nomineeData array with the new nominee
            setNomineeData((prevNomineeData) => [...prevNomineeData, new_nominee]);

            const new_error = {
                "name_error": null,
                "relation_id_error": null,
                "dob_error": null,
                "allocation_error": null,
                "guardian_name_error": null,
                "guardian_relationship_id_error": null,
                "guardian_pan_error": null
            };

            setErrorMessage([...errorMessage, new_error]);
            newNomineeRef.current = true;
            setTabIndex(nomineeData.length + 1);
        }
    };

    // remove nominee from list
    const _handleRemoveNominee = () => {

        setNomineeData((data) => data.filter((item, key) => key !== tabIndex - 1))

        if (tabIndex !== 1) {
            setTabIndex(index => index - 1);
        };

    };

    //validating input field 
    const _validateNomineeData = (nominees) => {

        // Setting err array to scroll to first err input
        let error_inputs = [];
        let is_minor_nominee = false;
        let error_list = [...errorMessage];
        let validation_status = true;
        let tab_index = tabIndex;

        for (let i = 0; i < nominees.length; i++) {
            const nominee_data = nominees[i];

            if (nominee_data?.dob != null) {
                is_minor_nominee = differenceInYears(new Date(), _formatDateFormat(nominee_data?.dob)) < 18;
            }


            if (nominee_data.name === null || nominee_data.name === "") {
                error_list[i].name_error = "Provide the nominee's full name";
                validation_status = false;
                error_inputs.push(`name-${i}`);
                tab_index = i + 1;
            } else {
                error_list[i].name_error = null;
            }

            if (nominee_data.relation_id === null) {
                error_list[i].relation_id_error = 'Please declare the relationship with the nominee';
                validation_status = false;
                error_inputs.push(`relation-${i}`);
                tab_index = i + 1;
            } else {
                error_list[i].relation_id_error = '';
            }


            if (nominee_data.allocation === null || nominee_data.allocation === 0) {
                error_list[i].allocation_error = 'Please specify the allocation details';
                validation_status = false;
                error_inputs.push(`allocation-${i}`);
                tab_index = i + 1;
            } else {
                error_list[i].allocation_error = '';
            }

            if (nominee_data.dob === null) {
                error_list[i].dob_error = "Please provide the nominee's date of birth";
                validation_status = false;
                error_inputs.push(`dob-${i}`);
                tab_index = i + 1;
            } else {
                error_list[i].dob_error = '';
            }

            //guardian validation
            if (is_minor_nominee === true) {
                if (nominee_data.guardian_name === null || nominee_data.guardian_name === "") {
                    error_list[i].guardian_name_error = "Please provide the guardian's full name in this field";
                    validation_status = false;
                    error_inputs.push(`guardian-name-${i}`);
                    tab_index = i + 1;
                } else {
                    error_list[i].guardian_name_error = '';
                }

                if (nominee_data.guardian_relationship_id == null) {
                    error_list[i].guardian_relationship_id_error = 'Please select the relationship with the nominee from the list';
                    validation_status = false;
                    error_inputs.push(`guardian-relation-${i}`);
                    tab_index = i + 1;
                } else {
                    error_list[i].guardian_relationship_id_error = '';
                }

                if (nominee_data.guardian_pan === null || nominee_data.guardian_pan === "") {
                    error_list[i].guardian_pan_error = "Please provide your guardian's valid PAN number";
                    validation_status = false;
                    error_inputs.push(`guardian-pan-${i}`);
                    tab_index = i + 1;
                } else {
                    error_list[i].guardian_pan_error = '';
                }
            }
        }

        setErrorMessage([...error_list]);
        setTabIndex(() => tab_index);

        // Scrolling to first error
        // if (error_inputs.length !== 0) {
        //     let first_err_id = error_inputs[0];
        //     let element = document.getElementById(first_err_id);
        //     element.scrollIntoView({ behavior: "smooth" });
        // };

        return validation_status;
    };

    //handle save nominee data
    const _handleSaveNominee = () => {
        // Validate UI input fields

        if (optInNominee === 2) {
            setLoading(true);
            _sendOTP();
        } else {

            let total_allocation = nomineeAllocation.reduce((a, b) => a + b);
            if (total_allocation > 100) {
                toast.dismiss();
                toast.error("Total nominee allocation must be 100%", { className: "e-toast" });
                return;
            }

            const is_valid = _validateNomineeData(nomineeData); // will return boolean value

            if (is_valid === true) {
                setLoading(true);
                _sendOTP();
            }
        };
    };

    //handle tab change
    const _handleTabChange = (id) => {
        setLoadNomineeStatus(true);
        setTabIndex(id);
        setTimeout(() => {
            setLoadNomineeStatus(false);
        }, 300);
    };

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // Function to handle OTP digit input for a specific position
    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        // API call for resending otp
        let resend = true;
        _sendOTP(resend);
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleNominee(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    const _handleNomineeSubmit = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };


        setLoading(true);
        setIsDisabled(true);
        if (optInNominee === 2) {
            _addNominee([]);
        } else {
            _addNominee(nomineeData);
        };
    };

    // API - send OTP 
    const _sendOTP = (resend) => {

        // API CALL from helper function
        const param = {
            type: "Nominee"
        };

        _getOTP(param).then(response => {
            if (response.status_code === 200) {

                setOtpValue("");
                setTimer(30);
                setFocusedInput(2);
                setOtpError("");

                if (resend === true) {
                    toast.dismiss();
                    let message_medium = USER_DATA.phone.country_code == "+91" ? " mobile" : " email"
                    toast.success(`OTP sent successfully to registered ${message_medium}`, {
                        className: "e-toast",
                    });
                } else {
                    setToggleNominee(true);
                };

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            };
            setLoading(false);
            setDisableResendOTP(false);
        })
    };

    //API- Add nominee
    const _addNominee = (nominees) => {

        let is_post = true;
        let url = 'profile/upsert-nominee ';
        let data = {
            "nominee": nominees,
            "is_nominee_available": optInNominee === 1 ? true : false,
            "otp": otpValue
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                const button = document.querySelector("#close-nominee-modal");
                button.click();
                toast.dismiss();
                toast.success("Nominees updated successfully", {
                    className: "e-toast",

                });
                props.onClose("SUCCESS");
            } else {
                setToggleNominee(false);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            }
            setLoading(false);
            setIsDisabled(false);
        });
    };

    // API - Get profile data
    const _getNomineeData = () => {

        // Helper function to get profile data
        _getProfile().then(response => {
            if (response.status_code === 200) {

                let nominee_data = [];

                if (response.data.nominee.length !== 0) {
                    nominee_data = response.data.nominee;
                } else {
                    nominee_data = [
                        {
                            "id": 1,
                            "name": null,
                            "relation_id": null,
                            "dob": null,
                            "allocation": null,
                            "guardian_name": null,
                            "guardian_relationship_id": null,
                            "guardian_pan": null
                        }
                    ]
                };

                setNomineeData(nominee_data);

                // setting error object
                let err_list = nominee_data.map(() => {
                    return {
                        "name_error": null,
                        "relation_id_error": null,
                        "dob_error": null,
                        "allocation_error": null,
                        "guardian_name_error": null,
                        "guardian_relationship_id_error": null,
                        "guardian_pan_error": null
                    }
                });

                setErrorMessage(err_list);

                let allocation = nominee_data.map((data) => {
                    let { allocation } = data;
                    return allocation
                });

                setNomineeAllocation(allocation);

            } else {
                setNomineeData([]);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <div className="modal fade e-manage-modal"
            id="manage-nominee-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="manageNomineeModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                {
                    toggleNominee === false ? (
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow">
                            <div className="modal-header justify-content-space-between pt-4 px-3 px-sm-4 pb-3 border-0">
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Add nominee
                                </h6>

                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    id='close-nominee-modal'
                                    draggable={false}
                                    className='cursor-pointer'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={props.onClose} />
                            </div>
                            <div className="d-flex flex-column modal-body pb-4 px-3 px-sm-4 pt-0">
                                <div className='e-border-radius-16 e-bg-cornsilk padding-12px-all'>
                                    <div className='d-flex gap-0-8 e-alignflex-start'>
                                        <img src={require("../../Assets/Images/Profile/hint.svg").default}
                                            draggable={false}
                                            alt='hint' />
                                        <p className='mb-0 e-text-ochre e-font-12 e-alt-font-poppins e-font-weight-400 line-height-16px letter-spacing-3' >
                                            Adding a nominee ensures your chosen person will receive your assets, simplifying the transfer process when needed
                                        </p>
                                    </div>
                                </div>

                                <div className='pt-3'>
                                    <p className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Add a nominee to your investment account?
                                    </p>

                                    <div className='d-flex e-gap-0-16'>
                                        <RadioGroup data={NOMINEE}
                                            selected={optInNominee}
                                            handleSelect={_handleAddNominee} />
                                    </div>
                                </div>


                                {/* Nominee Inputs */}
                                {
                                    optInNominee === 1 &&
                                    <div className='row mt-4 pt-1 position-relative'>
                                        {
                                            nomineeData.map((nominee, key) => (
                                                <React.Fragment key={key}>

                                                    {/* Nominee tabs */}
                                                    <div className={`order-${key + 1} col-auto pe-0 pe-sm-2  d-flex`}>
                                                        <p className={`${tabIndex === key + 1 ? 'e-text-egyptian-blue e-border-tab-active' : 'e-text-dark-liver cursor-pointer'} e-font-14-res text-nowrap mb-0 e-font-16 e-alt-font-poppins e-font-weight-600 line-height-14px`}
                                                            onClick={() => _handleTabChange(key + 1)}>
                                                            {`Nominee ${key + 1}`}
                                                        </p>

                                                        {
                                                            tabIndex !== 3 && key == 0 && nomineeData.length < 3 &&
                                                            <p className='e-font-14-res position-absolute end-0 me-3 text-nowrap cursor-pointer mb-0 e-text-indigo e-font-16 e-alt-font-poppins e-font-weight-500 line-height-14px'
                                                                onClick={_handleAddMoreNominee} >
                                                                + Add new
                                                            </p>
                                                        }
                                                    </div>

                                                    {/* Nominee delete icon */}
                                                    {
                                                        tabIndex !== 1 && key == 0 &&
                                                        <div className={`mt-2 order-4 text-end`}>
                                                            <img src={Delete}
                                                                width={30}
                                                                height={30}
                                                                draggable={false}
                                                                className={'cursor-pointer object-fit-contain'}
                                                                alt='delete'
                                                                onClick={() => _handleRemoveNominee()} />
                                                        </div>
                                                    }

                                                    {/* Nominee data */}
                                                    {
                                                        loadNomineeStatus == true ?
                                                            key === 0 &&
                                                            <div className="order-5 min-h-100vh text-center mt-5">
                                                                <img src={Loader}
                                                                    draggable="false"
                                                                    alt="loader" />
                                                            </div>

                                                            :
                                                            tabIndex === key + 1 &&
                                                            <div className={`${tabIndex === 1 && "mt-4 pt-3"} order-5 px-3`}>

                                                                <NomineeCard
                                                                    data={GENERAL_DATA}
                                                                    index={key}
                                                                    nomineeData={nominee}
                                                                    error={errorMessage[key]}
                                                                    onChange={_handleOnChangeInput}
                                                                />
                                                            </div>
                                                    }

                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                }

                                <PrimaryButton name={"Save & Continue"}
                                    loading={loading}
                                    disabled={loading}
                                    className="w-100 padding-12px-top padding-12px-bottom mt-5"
                                    onPress={_handleSaveNominee} />

                            </div>
                        </div>
                    ) : (
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-3 px-sm-4 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='close-nominee-modal'
                                    data-bs-dismiss="modal"
                                    className='visually-hidden'
                                    aria-label="close"
                                    alt='close btn'
                                />
                                <img src={require("../../Assets/Images/Login/back-icon.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Enter OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-3 px-sm-4 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                    {
                                        USER_DATA.phone.country_code == "+91" ?
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered mobile number.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.phone.country_code} {USER_DATA.phone.number}
                                                </span>
                                            </p>
                                            :
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered email.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.email}
                                                </span>
                                            </p>
                                    }
                                    <div className='pt-3'>
                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <OTPInput ref={otpInputRef}
                                            id="otp"
                                            value={otpValue}
                                            spellCheck="false"
                                            error={otpError}
                                            focused_input={focusedInput}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                        <div className='e-inline-flex'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                // disabled={isResendDisabled}
                                                disabled={disableResendOTP}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                <div className='d-flex gap-3 padding-36px-top'>
                                    <PrimaryButton name={"Cancel"}
                                        dismiss="modal"
                                        target=""
                                        className="w-50 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                        onPress={props.onClose} />
                                    <PrimaryButton name={"Confirm"}
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="w-50 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleNomineeSubmit} />
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </div >
    )
}

export default ManageNomineeModal;