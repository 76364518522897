/*
 *   File : slider.js
 *   Author : https://evoqins.com
 *   Description : Custom slider component
 *   Integrations : react-slider
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import ReactSlider from "react-slider";

const CustomSlider = (props) => {

    const [sliderValue, setSliderValue] = useState(props.defaultValue);

    useEffect(() => {
        setSliderValue(props.defaultValue);
    }, [props.defaultValue]);

    // slider change function
    function _handleSliderChange(value) {
        setSliderValue(value);
        props.handleChange(value);
    }

    // slider track click function
    function _handleSliderClick(value) {
        setSliderValue(value);
        props.handleChange(value);
    }

    // rendering custom track
    function Track(props) {
        return (
            <div {...props}></div>
        )
    }

    // rendering custom thumb
    function Thumb(props) {
        return (
            <div {...props}></div>
        )
    }

    return (
        <ReactSlider
            value={sliderValue}
            max={props.max}
            min={props.min}
            step={props.step}
            thumbClassName="height-20px-res width-20px-res h-24 w-24 e-border-radius-50per top-neg-10px cursor-pointer e-bg-egyptian-blue no-outline"
            trackClassName="cursor-pointer h-2 e-bg-bright-gray slider-track"
            renderTrack={Track}
            renderThumb={Thumb}
            onChange={_handleSliderChange}
            onSliderClick={_handleSliderClick} />
    )
};

export default CustomSlider