/*
 *   File : select-suggestion.js
 *   Author : https://evoqins.com
 *   Description : Select Suggestion modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//custom styles
import styles from "../../Style/Component/modal.module.scss";
import { CustomTextInput } from '../Form-Elements';

const SelectionSuggestModal = (props) => {

    const [selected, setSelected] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [list, setList] = useState(props.data);

    useEffect(() => {
        if (props.selectedOption) {
            setSelected(props.selectedOption);
        };
    }, [props.selectedOption]);


    //handle select 
    const _handleSelect = (data) => {
        props.onSelectOption(data);

        //handle modal close
        var button = document.getElementById(`suggestion-modal-close${props.type}`);
        button.click();
    };

    //handle search
    const _handleSearch = (value) => {
        if (value.trim().length == 0) {
            setSearchValue("");
            setList(props.data);
            return;
        };

        setSearchValue(value);

        if (value.length > 2) {
            let filtered_data = props.data.filter(item => (
                item.name.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())
            ));
            setList(filtered_data);
        }
    };


    return (
        <div className={`${styles.e_suggestion_modal} modal fade e-manage-modal`}
            data-bs-backdrop={props.backdrop ? props.backdrop : "static"}
            id={props.id}
            tabIndex="-1" aria-labelledby="selectSuggestModalLabel" aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered w-460px e-res-width-auto`}>
                <div className={`modal-content h-max-500px overflow-auto  e-bg-lotion e-border-radius-24 e-modal-box-shadow ${props.search && "h95vh"}`}>
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0 pb-3">
                        <h6 className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            {props.title}
                        </h6>

                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            draggable={false}
                            id={`suggestion-modal-close${props.type}`}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onModalClose} />
                    </div>
                    {
                        props.search &&
                        <div className='px-4 pb-3'>
                            <div className="position-relative">
                                <CustomTextInput id="search"
                                    className="e-font-14 w-100 no-outline py-2 padding-50px-lr padding-32px-lr-res"
                                    placeholder={props.searchPlaceholder}
                                    inputWrapperClassName={"e-border-1px-bright-gray"}
                                    inputWrapperBorderStyle={"e-border-radius-16"}
                                    autoComplete="off"
                                    value={searchValue}
                                    height={"h-auto"}
                                    handleChange={_handleSearch} />
                                <img src={require("../../Assets/Images/search-icon.svg").default}
                                    draggable={false}
                                    className="width-16px-res width-18px position-absolute top-50 translate-middle-y start-0 ms-2 ms-sm-3"
                                    alt="search" />

                                {
                                    searchValue &&
                                    <img src={require("../../Assets/Images/close-btn.svg").default}
                                        draggable={false}
                                        className="width-16px-res width-20px object-fit-contain position-absolute top-50 translate-middle-y end-0 me-2 me-sm-3 cursor-pointer"
                                        alt="clear"
                                        onClick={() => {
                                            setSearchValue("");
                                            setList(props.data);
                                        }} />
                                }
                            </div>
                        </div>
                    }
                    <div className="modal-body pb-4 pt-0 px-4 overflow-auto">
                        {
                            list && list.map((data, key) => (
                                <div key={key}>
                                    <div className='e-display-flex gap-2 cursor-pointer'
                                        onClick={() => _handleSelect(data)}>
                                        {selected.id === data.id &&
                                            <img src={require('../../Assets/Images/KYC/tick.svg').default}
                                                className={`${key == 0 && props.search && "mb-3"}`}
                                                draggable={false}
                                                alt='mark' />
                                        }
                                        <p className={`${selected.id === data.id ? `e-text-egyptian-blue ps-2` : `e-text-sonic-silver ps-4 ms-3`}  mb-0 ${key == 0 && props.search ? "pb-3" : "py-3"}  e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                            {data.display_name ? data.display_name : data.name}
                                        </p>
                                    </div>
                                    {/* border */}
                                    {
                                        (key !== list.length - 1 || list.length == 1) && (
                                            <div className="margin-40px-left e-border-bottom-1px-light-gray"></div>
                                        )
                                    }
                                </div>
                            ))
                        }
                        {
                            list.length == 0 &&
                            <p className='text-center mt-4 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                No data found!
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectionSuggestModal;


// {
//     "id": 1,
//         "name": "Andaman & Nicobar Islands"
// }