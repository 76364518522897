/*
 *   File : App.js
 *   Author URI : https://evoqins.com
 *   Description : Initial page of Index Fund Sahi Hai
 *   Integrations : js-cookie, react-toastify, react-redux
 *   Version : v1.1
*/

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import OneSignal from 'react-onesignal';
import { Modal } from "bootstrap";

// Custom components
import { Dashboard } from "./Container/Dashboard";
import { ForgotPassword, Login, SignUp, Unlock } from "./Container/Auth";
import { LeftNavigationWidget, TopNavigationWidget } from "./Component/Navigation";

// Custom container files
import { Explore, FundDetail } from "./Container/Funds";
import { Profile } from "./Container/Profile";

import { KYC } from "./Container/KYC";
import { Report } from "./Container/Report";
import { SIPListing } from "./Container/SIP";
import { CalculateRiskScore } from "./Container/RiskProfiler";
import { ConfirmLogoutModal } from "./Component/Modal";
import { BasketDetail, BasketList } from "./Container/Basket";
import { OrderListing, OrderDetail } from "./Container/Orders";
import { AboutUs, Notifications, Support } from "./Container/Others";
import { Portfolio, InvestedGoals, InvestedMF, InvestedBasket } from "./Container/Portfolio";
import { Listing, EmergencyFund, KidsEducation, Retirement, SpecialTuitionFund, Vacation } from "./Container/Goals";
import { FamilyProfile, ManageFamily } from "./Container/Family";

import Store from "./Store/store";
import { APIService } from "./Service";
import { TransactionManager } from "./General";
import { _getProfile, _getFilterData, _getSignupProgress, _getGeneralData, _getNotificationCount } from "./Helper";

function App() {

    const dispatch = useDispatch();
    let ACCESS_TOKEN = useSelector(state => state.Reducer.ACCESS_TOKEN);
    let REFRESH_TOKEN = useSelector(state => state.Reducer.REFRESH_TOKEN);

    const [accessToken, setAccessToken] = useState(null);
    const [isPublic, setIsPublic] = useState(false);
    const [setUpOneSignal, setSetUpOneSignal] = useState(false);
    const [browserZoom, setBrowserZoom] = useState(100);
    const [loading, setLoading] = useState(true);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {

        // Initializing oneSignal
        const ONESIGNAL_ID = process.env.REACT_APP_ONE_SIGNAL_ID;

        if (setUpOneSignal === false) {
            OneSignal.init({ appId: ONESIGNAL_ID }).then(() => {
                setSetUpOneSignal(true);
                // console.log("OneSignal initialized");
                OneSignal.Slidedown.promptPush();
            });
        };

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    // Update browser zoom level
    useEffect(() => {

        function updateZoom() {
            const new_zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
            const rounded_zoom = Math.ceil(new_zoom / 5) * 5;
            setBrowserZoom(rounded_zoom);
        };

        updateZoom();

        window.addEventListener("resize", updateZoom);

        return () => {
            window.removeEventListener("resize", updateZoom);
        };

    }, []);


    // Calculate the default zoom level based on the device pixel ratio
    useEffect(() => {

        const default_zoom = window.devicePixelRatio;
        const initial_value = `${default_zoom * 100}%`;

        switch (initial_value) {
            case "200": {
                document.body.style.zoom = "60%";
                break;
            }
            case "175": {
                document.body.style.zoom = "60%";
                break;
            }
            case "150": {
                document.body.style.zoom = "70%";
                break;
            }
            case "125": {
                document.body.style.zoom = "80%";
                break;
            }
            case "110": {
                document.body.style.zoom = "90%";
                break;
            }
            default: {
                document.body.style.zoom = "100%";
            }
        }

    }, []);

    // Verifying referesh token
    useEffect(() => {
        window.addEventListener("load", () => {
            let refresh_token = Cookies.get("refresh_token");
            if (accessToken) {
                _verifyRefreshToken(refresh_token);
            }
        });
    }, []);


    useEffect(() => {

        if (showLogoutModal) {
            const logout_modal = new Modal(document.querySelector("#confirm-logout"));
            logout_modal.show();
        };

    }, [showLogoutModal]);


    useEffect(() => {

        if (window.location.pathname === "/transaction") {
            setIsPublic(true);
            return;
        }

        window.addEventListener('popstate', function () {

            // Removing no scroll form body 
            document.body.classList.remove("body-no-scroll");

            // Removing modal backdrop for all modals when browser back button clicked
            if (document.querySelector(".modal-backdrop")) {
                document.querySelector(".modal-backdrop").remove();
                document.body.style.overflow = 'unset';
            };

            let refresh_token = Cookies.get("refresh_token");
            Store.dispatch({
                type: 'UPDATE_REFRESH_TOKEN',
                payload: refresh_token,
            });

            let access_token = sessionStorage.getItem("access_token");
            Store.dispatch({
                type: 'UPDATE_ACCESS_TOKEN',
                payload: access_token,
            });

            setIsPublic(false);

            // Removing logout modal from UI if opened
            setShowLogoutModal(false);

        });

    }, [window.location.pathname]);

    useEffect(() => {

        let refresh_token = Cookies.get("refresh_token");
        dispatch({
            type: 'UPDATE_REFRESH_TOKEN',
            payload: refresh_token ? refresh_token : null,
        });

        let access_token = sessionStorage.getItem("access_token");
        dispatch({
            type: 'UPDATE_ACCESS_TOKEN',
            payload: access_token,
        });

        setAccessToken(() => access_token ? access_token : null);
        setLoading(false);

    }, [REFRESH_TOKEN, ACCESS_TOKEN]);

    useEffect(() => {

        if (accessToken !== null) {
            _getNotificationCount();
            _getProfile();
            _getSignupProgress();
        }
        _getGeneralData();
        _getFilterData();

    }, [accessToken]);


    const _handleCloseModal = () => {
        setShowLogoutModal(false);
    };

    const _handleLogout = () => {
        setShowLogoutModal(true);
    };



    // ============================= //
    // ======== API CALL =========== //

    // verify refresh token 
    const _verifyRefreshToken = (refresh_token) => {
        let is_post = false;
        let url = 'auth/validate-refresh-token';
        let data = {};
        APIService(is_post, url, data, refresh_token).then((response) => {
            if (response.status_code === 200) {
            } else {
                dispatch({
                    type: 'UPDATE_REFRESH_TOKEN',
                    payload: null,
                });

                dispatch({
                    type: 'UPDATE_ACCESS_TOKEN',
                    payload: null,
                });

                sessionStorage.removeItem('access_token');
                Cookies.remove("refresh_token", { path: '/', domain: process.env.REACT_APP_DOMAIN })
            }
        });
    };

    return (
        <div className={` ${browserZoom >= 150 ? 'e-app-zoom-level-7' :
            browserZoom === 125 ? 'e-app-zoom-level-8' :
                browserZoom === 110 ? 'e-app-zoom-level-9' : null
            }`}>
            {
                // We are adding loader over here 
                // To avoid routing to login page when user reloads page
                // While reloading page redux will take time to update is store
                loading == true ? null
                    :
                    isPublic === true ?
                        <Router>
                            <Routes>
                                <Route path="/transaction" element={<TransactionManager />} />
                            </Routes>
                        </Router>
                        : REFRESH_TOKEN !== null && ACCESS_TOKEN !== null ?
                            <Router>
                                <div className={`container-fluid e-bg-alice-blue`}>
                                    <div className="row">
                                        <div id="sidebar"
                                            className="col-lg-2 px-0 d-block d-lg-block d-md-none d-none">
                                            <div className="position-fixed top-0 start-0 h-100 overflow-auto no-scrollbar w-100-md w-16-point-5per">
                                                <LeftNavigationWidget handleLogout={_handleLogout} />
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-12 overflow-auto no-scrollbar ">
                                            <TopNavigationWidget handleLogout={_handleLogout} />
                                            <Routes>
                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="/home" element={<Dashboard />} />
                                                <Route path="/profile" element={<Profile />} />
                                                <Route path="/explore">
                                                    <Route index element={<Explore />} />
                                                    <Route path="fund-details" element={<FundDetail />} />
                                                </Route>
                                                <Route path="/portfolio">
                                                    <Route index element={<Portfolio />} />
                                                    <Route path="invested-goal-detail" element={<InvestedGoals />} />
                                                    <Route path="invested-mf-detail" element={<InvestedMF />} />
                                                    <Route path="invested-basket-detail" element={<InvestedBasket />} />
                                                </Route>
                                                <Route path="/goals">
                                                    <Route index element={<Listing />} />
                                                    <Route path="retirement" element={<Retirement />} />
                                                    <Route path="kids-education" element={<KidsEducation />} />
                                                    <Route path="vacation" element={<Vacation />} />
                                                    <Route path="special-tuition-fund" element={<SpecialTuitionFund />} />
                                                    <Route path="emergency-fund" element={<EmergencyFund />} />
                                                </Route>
                                                <Route path="/baskets">
                                                    <Route index element={<BasketList />} />
                                                    <Route path="basket-details" element={<BasketDetail />} />
                                                </Route>
                                                <Route path="/orders">
                                                    <Route index element={<OrderListing />} />
                                                    <Route path="order-detail" element={<OrderDetail />} />
                                                </Route >

                                                <Route path="/manage-family">
                                                    <Route index element={<ManageFamily />} />
                                                    <Route path="family-profile" element={<FamilyProfile />} />
                                                </Route >

                                                <Route path="/sip" element={<SIPListing />} />
                                                <Route path="/report" element={<Report />} />

                                                <Route path="/notifications" element={<Notifications />} />
                                                <Route path="/about-us" element={<AboutUs />} />
                                                <Route path="/support" element={<Support />} />
                                                <Route path="/kyc" element={<KYC />} />
                                                <Route path="/risk-profiler" element={<CalculateRiskScore />} />
                                                <Route path="*" element={<Navigate to="/" replace={true} />} />
                                            </Routes>
                                        </div>
                                    </div>
                                </div>
                            </Router>
                            : (REFRESH_TOKEN) ?
                                <Router>
                                    <Routes>
                                        <Route path="/" element={<Unlock />} />
                                        <Route path="/unlock" element={<Unlock />} />
                                        <Route exact path="/login" element={<Login />} />
                                        <Route path="/forgot-pin" element={<ForgotPassword />} />
                                        <Route path="*" element={<Navigate to="/unlock" replace={true} />} />
                                    </Routes>
                                </Router>
                                :
                                <Router>
                                    <Routes>
                                        <Route exact path="/" element={<Login />} />
                                        <Route exact path="/login" element={<Login />} />
                                        <Route path="/signup" element={<SignUp />} />
                                        <Route path="/unlock" element={<Unlock />} />
                                        <Route path="/forgot-pin" element={<ForgotPassword />} />
                                        <Route path="*" element={<Navigate to="/login" replace={true} />} />
                                    </Routes>
                                </Router>
            }

            <ToastContainer
                autoClose={4000}
                pauseOnHover={false}
                position="bottom-right" />


            {/* Logout Modal */}
            {
                showLogoutModal &&
                <ConfirmLogoutModal onClose={_handleCloseModal} />
            }

        </div>
    );
};

export default App;


