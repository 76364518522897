/*
 *   File : payment-status.js
 *   Author : https://evoqins.com
 *   Description : Add family member success modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";

// Assets
import SuccessImg from "../../Assets/Images/payment-successful-bg.webp";
import CloseBtn from "../../Assets/Images/close-btn.svg";

function AddFamilyMemberStatusModal(props) {

    return (
        <div className="modal fade p-0 m-0"
            id="add-family-member-status-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="AddFamilyMemberStatusModal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered min-h90vh">
                <div className=" modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow ">
                    <div className="payment-modal d-flex flex-column align-items-center modal-body p-4 e-border-radius-24">
                        <img src={CloseBtn}
                            draggable={false}
                            className='cursor-pointer float-end align-self-end'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onClose} />
                        <img className="img-fluid col-5 mt-4 pt-3"
                            draggable={false}
                            src={SuccessImg}
                            alt="payment status image" />
                        <p className="e-font-14-res text-center e-text-ufo-green e-alt-font-poppins e-font-18 e-font-weight-600 mt-4 mb-2">
                            Family member added successfully
                        </p>
                        <p className="e-font-12-res line-height-20px-res col-sm-10 mx-auto text-center e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-26px text-center mx-auto mb-0 mb-5 ">
                            Family member added successfully. Start exploring your family member investments.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(AddFamilyMemberStatusModal);