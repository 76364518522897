import Store from "../Store/store";

export const _goalDetailPath = (type) => {

    let path = "";

    switch (type) {
        case "Pension": {
            path = "retirement";
            break;
        }
        case "Post Graduation": {
            path = "kids-education";
            break;
        }
        case "Vacation Fund": {
            path = "vacation";
            break;
        }
        case "Special Tuition Fund": {
            path = "special-tuition-fund";
            break;
        }
        case "Emergency Fund": {
            path = "emergency-fund";
            break;
        }
        default: {
            path = "retirement";
        }
    };
    
    return path;
};

export const _checkUserAccess = () => {
    const profile_data = Store.getState().Reducer.USER_DATA;

    if (profile_data !== null) {
        const status = profile_data.signup_progress_status;
        if (status === "ACCOUNT_CREATED" || status === "INVESTED") {
            return true;
        };
    }

    return false;
};

export const _checkFileSize = (file_size) => {

    // 1 mb == 1000000 bytes , we are receiving the file size in bytes so,
    // Converting bytes to mb for easy usage
    let file_size_in_mb = file_size / 1000000;

    if (file_size_in_mb <= 5) {
        return true;
    } else {
        return false;
    };

};

export const _formatDateFormat = (selected_date) => {
    const [day, month, year] = selected_date.split("-");
    return new Date(`${year}-${month}-${day}`);
};
