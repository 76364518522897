/*
 *   File : fund-portfolio.js
 *   Author : https://evoqins.com
 *   Description : Portfolio modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useState } from "react";

// Components
import CustomTab from "../Other/tab"
import { PrimaryButton } from "../Buttons";
import { useEffect } from "react";

const PORTFOLIO_TAB = [
    {
        id: 1,
        label: "Holdings"
    },
    {
        id: 2,
        label: "Sector"
    },
]



function FundPortfolioModal(props) {

    const [tabIndex, setTabIndex] = useState(1);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data);
        setTabIndex(props.tab);
    }, [props]);

    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    return (
        <div className="modal fade e-manage-modal"
            id="modal-fund-portfolio"
            tabIndex="-1"
            data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header py-4 px-4 border-bottom-0">
                        <h1 className="modal-title e-text-charleston-green e-alt-font-poppins e-font-18 e-font-weight-600 line-height-32px">
                            Fund portfolio
                        </h1>
                        <PrimaryButton className="btn-close m-0 opacity-100 shadow-none"
                            dismiss="modal"
                            onPress={props.onClose} />
                    </div>
                    <div className="modal-body pb-4 pt-0 ps-4 pe-3">
                        {/* Tabs */}
                        <div className="d-flex flex-column position-sticky top-0 bg-white">
                            <div className="d-flex mb-3">
                                {PORTFOLIO_TAB.map((data, key) => (
                                    <CustomTab key={key}
                                        tabData={data}
                                        tabIndex={tabIndex}
                                        handleChange={_handleTabChange} />
                                ))}
                            </div>

                            <div className="row pb-3">
                                <div className="col-9">
                                    <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                        Name
                                    </p>
                                </div>
                                <div className="col-3">
                                    <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-24px letter-spacing-neg-2 w-max-content">
                                        % Assets
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Portfolio items */}
                        <div className="d-flex flex-column gap-3">
                            {
                                tabIndex === 1 ? (
                                    data.holdings?.map((item, key) => (
                                        <div className="row" key={key}>
                                            <div className="col-9">
                                                <p className="break-word mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    {item.name}
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p className=" mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    {item.allocation}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    data.sector?.map((item, key) => (
                                        <div className="row " key={key}>
                                            <div className="col-9 pe-0">
                                                <p
                                                    className="break-word mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    {item.name}
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                    {item.allocation}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default FundPortfolioModal