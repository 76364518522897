/*
 *   File : calculator.js
 *   Author : https://evoqins.com
 *   Description : SIP/Lumpsum Calculator component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//custom component
import { CustomTab } from "../Other";
import { CustomAmountInput, CustomSlider } from "../Form-Elements";
import { _amountInWords, _formatToRupees } from "../../Helper";
import { PrimaryButton } from "../Buttons";

const CALCULATOR_TAB = [
    {
        id: 1,
        label: "SIP"
    },
    {
        id: 2,
        label: "Lumpsum"
    },
]

const Calculator = (props) => {

    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(1);
    const [sip, setSip] = useState('');
    const [sipTenure, setSipTenure] = useState(15);
    const [sipReturnRate, setSipReturnRate] = useState(0);

    const [lumpsum, setLumpsum] = useState('');
    const [lumpsumTenure, setLumpsumTenure] = useState(15);
    const [lumpsumReturnRate, setLumpsumReturnRate] = useState(0);

    const [investedAmount, setInvestedAmount] = useState(0);
    const [returnAmount, setReturnAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const [otherFund, setOtherFund] = useState(0);
    const [annualStep, setAnnualStep] = useState(0);
    const [focusId, setFocusId] = useState('');

    const [graphHeights, setGraphHeights] = useState({});


    // initial sip
    useEffect(() => {
        if (props.min_investment) {
            setSip(props.min_investment.min_invest_sip);
            setLumpsum(props.min_investment.min_invest);
        } else {
            setSip(5000);
            setLumpsum(25000);
        }
        setSipTenure(15);
        setLumpsumTenure(15);
    }, []);

    //graph handling
    useEffect(() => {
        let max_retruns = Math.max(...[totalAmount, otherFund]);
        setGraphHeights({
            index_fund: _getGraphHeight(totalAmount, max_retruns),
            other_fund: _getGraphHeight(otherFund, max_retruns)
        })
    }, [totalAmount, otherFund]);


    // sip calculation
    useEffect(() => {
        let monthly_investment = sip; // Monthly investment amount
        let interest_rate = props.type === 2 ? props.returns_percentage : 14.5; // Annual interest rate       // returns % for Index-fund: 14.5%
        let other_fund_interest_rate = 11.5; // other fund interest rate            // returns % for other funds: 11.5%
        let years = sipTenure; // Number of years
        let step_up_percent = annualStep; // Annual step-up rate
        let inflation_rate = 0; // Annual inflation rate
        if (tabIndex === 1) {
            _calculateSip(monthly_investment, interest_rate, years, step_up_percent, inflation_rate);
            _calculateSipOtherFund(monthly_investment, other_fund_interest_rate, years, step_up_percent, inflation_rate);
        }

    }, [sipTenure, sip, annualStep, tabIndex, sipReturnRate, props.returns_percentage]);


    // lumpsum calculation
    useEffect(() => {
        let principal_amount = lumpsum;
        let interest_rate = props.type === 2 ? props.returns_percentage : 14.5; // Annual interest rate
        let other_fund_interest_rate = 11.5; // other fund interest rate
        let years = lumpsumTenure; // Number of years
        if (tabIndex === 2) {
            _calculateLumpsum(principal_amount, interest_rate, years);
            _calculateLumpsumOtherFund(principal_amount, other_fund_interest_rate, years);
        }
    }, [lumpsumTenure, lumpsum, tabIndex, lumpsumReturnRate]);

    // graph height handler
    function _getGraphHeight(item_returns, max_returns) {
        let canvas_height = 80;
        let calculated_height = canvas_height * item_returns / max_returns;
        if (calculated_height > canvas_height) {
            return calculated_height = canvas_height
        }
        return calculated_height;
    }


    //calculate SIP
    function _calculateSip(monthly_investment, annual_interest_rate, years, stepup_rate, inflation_rate) {
        let future_value = 0;
        let monthly_rate = (annual_interest_rate / 12) / 100;
        let totalMonths = years * 12;

        for (let month = 1; month <= totalMonths; month++) {
            let adjusted_investment = monthly_investment * Math.pow(1 + stepup_rate / 100, Math.floor((month - 1) / 12));
            future_value += adjusted_investment;
            future_value *= (1 + monthly_rate);
            future_value /= (1 + inflation_rate / 100);
        }
        // calculate invested amount using annual step up
        let corpus = 0; // total invested amount
        let sip = monthly_investment; // SIP selected by customer
        let step_sip = stepup_rate; // step sip percentage
        let yearly_invested_sip = 0;
        for (let year = 1; year <= years; year++) {
            if (year != 1) {
                sip = sip + (sip * (step_sip / 100)); // updated sip value for selected year
            }
            yearly_invested_sip = sip * 12;
            corpus += yearly_invested_sip;
        }
        let total_investment = corpus;
        let total_return = future_value - total_investment;


        setInvestedAmount(total_investment);
        setReturnAmount(total_return);
        setTotalAmount(future_value);

        let invest_amount_percent = (total_investment / future_value) * 100;
        // setInvestAmountPercent((invest_amount_percent == 'NaN') ? 100 : invest_amount_percent.toFixed(2));

    }

    // calculate Lumpsum 
    function _calculateLumpsum(principal_amount, annual_rate, years) {
        const rate = annual_rate / 100;
        // Calculate the future value
        const future_value = principal_amount * Math.pow(1 + rate, years);
        let total_return = future_value - principal_amount;

        setInvestedAmount(principal_amount);
        setReturnAmount(total_return);
        setTotalAmount(future_value);

        let invest_amount_percent = (principal_amount / future_value) * 100;
        // setInvestAmountPercent(invest_amount_percent.toFixed(2));

    }

    // calculate other fund 
    function _calculateSipOtherFund(monthly_investment, annual_interest_rate, years, stepup_rate, inflation_rate) {
        let future_value = 0;
        let monthly_rate = (annual_interest_rate / 12) / 100;
        let total_months = years * 12;

        for (let month = 1; month <= total_months; month++) {
            let adjusted_investment = monthly_investment * Math.pow(1 + stepup_rate / 100, Math.floor((month - 1) / 12));
            future_value += adjusted_investment;
            future_value *= (1 + monthly_rate);
            future_value /= (1 + inflation_rate / 100);
        }
        setOtherFund(future_value.toFixed(2));
    }

    // calculate other funds lumsum
    function _calculateLumpsumOtherFund(principal_amount, annual_rate, years) {
        // Convert the annual rate to decimal
        const rate = annual_rate / 100;
        // Calculate the future value
        const future_value = principal_amount * Math.pow(1 + rate, years);
        setOtherFund(future_value.toFixed(2));
    }


    //handle SIP value
    const _handleSIP = (value) => {
        setSip(value);
    }

    //handle lumsum value
    const _handleLumpsum = (value) => {
        setLumpsum(value);
    }

    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
    }

    //handle anual step slider
    const _handleSlider = (value) => {
        if (tabIndex === 1) {
            setSipReturnRate(value);
        } else {
            setLumpsumReturnRate(value);
        }
        setAnnualStep(value);
    }

    // //handle tenure slider
    const _handleTenureSlider = (value) => {
        if (tabIndex === 1) {
            setSipTenure(value);
        } else {
            setLumpsumTenure(value);
        }
    }

    return (
        <div className="p-4 e-border-radius-16 e-bg-cultured">
            <div className="d-flex pb-4">

                {/* tab component */}
                {CALCULATOR_TAB.map((data, key) => (
                    <CustomTab key={key}
                        tabData={data}
                        tabIndex={tabIndex}
                        handleChange={_handleTabChange} />
                ))}
            </div>

            <div className="row">
                <div className={`${props.type === 1 ? "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" : "col"}`}>
                    <CustomAmountInput
                        label={tabIndex === 1 ? 'Monthly investment amount' : 'Total investment amount'}
                        value={tabIndex === 1 ? sip : lumpsum}
                        focusId={focusId}
                        // error={sipError}
                        maxLength={8}
                        handleChange={(value) => {
                            if (tabIndex === 1) {
                                _handleSIP(value)
                            } else {
                                _handleLumpsum(value);
                            }
                        }} />

                    {/* annual step  */}
                    {tabIndex === 1 &&
                        <div className="pt-4">
                            <div className="e-display-flex justify-content-space-between">
                                <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                    Annual step up(%)
                                </h4>
                                <p className="mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                    {tabIndex === 1 ? sipReturnRate : lumpsumReturnRate}%
                                </p>
                            </div>
                            <div className="padding-34px-top">
                                <CustomSlider min={0}
                                    max={50}
                                    step={0.5}
                                    defaultValue={tabIndex === 1 ? sipReturnRate : lumpsumReturnRate}
                                    handleChange={(value) => _handleSlider(value)} />
                            </div>
                            <div className="d-flex justify-content-space-between mt-4">
                                <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                    0%
                                </p>
                                <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                    50%
                                </p>
                            </div>
                        </div>
                    }

                    {/* tenure slider  */}
                    <div className="padding-34px-top">
                        <div className="e-display-flex justify-content-space-between">
                            <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                Tenure
                            </h4>
                            <p className="mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                {tabIndex === 1 ?
                                    `${sipTenure} ${sipTenure === 1 ? 'Yr' : 'Yrs'}`
                                    :
                                    `${lumpsumTenure} ${lumpsumTenure === 1 ? 'Yr' : 'Yrs'}`
                                }
                            </p>
                        </div>
                        <div className="padding-34px-top">
                            <CustomSlider min={1}
                                max={40}
                                step={1}
                                defaultValue={tabIndex === 1 ? sipTenure : lumpsumTenure}
                                handleChange={(value) => _handleTenureSlider(value)} />
                        </div>
                        <div className="d-flex justify-content-space-between mt-4">
                            <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                1 Yr
                            </p>
                            <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                40 Yrs
                            </p>
                        </div>
                    </div>

                    {/* Will be enabled in future || update next div pt-5 tp pt-3 */}
                    {
                        // props.type == 2 &&
                        // <div className="mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px padding-42px-top">
                        //     <p>*Estimated return is calculated based on the funds {props.returns_period} returns {props.returns_percentage}%.</p>
                        // </div>

                    }

                    <div className={`e-display-flex justify-content-space-between ${props.type == 1 ? "padding-42px-top" : "pt-5"} `}>
                        <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                            Invested amount
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-20px">
                            ₹{_formatToRupees(investedAmount)}
                        </p>
                    </div>

                    <div className="pt-4 e-display-flex justify-content-space-between padding-42px-top">
                        <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                            Est. returns
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-500 line-height-20px">
                            ₹{_formatToRupees(returnAmount)}
                        </p>
                    </div>

                    <div className="pt-4 e-display-flex justify-content-space-between padding-42px-top">
                        <p className="mb-0 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                            Total value
                        </p>
                        <p className="mb-0 e-text-egyptian-blue e-font-16 e-primary-font-inter e-font-weight-600 line-height-20px">
                            ₹{_formatToRupees(totalAmount)}
                        </p>
                    </div>
                </div>

                {/* Returns comparison chart */}

                {
                    props.type == 1 &&
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-4">
                        <h4 className="e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                            Returns comparison
                        </h4>
                        <p className="mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px">
                            *The estimated returns are determined by analyzing the historical returns of the index finds.
                        </p>

                        <div className="row ">
                            <div className="offset-lg-1 col-lg-10 col-12 pt-5">
                                {/* <div className="row">
                                    <div className="col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                        <div>
                                            <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                ₹{totalAmount < 100000 ? _formatToRupees(totalAmount) : _amountInWords(totalAmount)}
                                            </p>
                                            <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                Avg. 14.5% returns
                                            </p>
                                            <span className="vr opacity-10 w-60px e-bg-emerald"
                                                style={{ height: graphHeights.index_fund > 0 ? graphHeights.index_fund : 20 }} />
                                            <p className="mb-0 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                Index funds
                                            </p>
                                        </div>
                                    </div>
                                    <div className="px-0 col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                        <div>
                                            <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                ₹{otherFund < 100000 ? _formatToRupees(otherFund) : _amountInWords(otherFund)}
                                            </p>
                                            <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                Avg. 11.5% returns
                                            </p>
                                            <span className="vr opacity-10  w-60px e-bg-lavender"
                                                style={{ height: graphHeights.other_fund > 0 ? graphHeights.other_fund : 20 }} />
                                            <p className="mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                Other mutual funds
                                            </p>
                                        </div>
                                    </div>

                                </div> */}
                                <div className="row mb-0">
                                    <div className="line-height-0 col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                        <div>
                                            <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                ₹{totalAmount < 100000 ? _formatToRupees(totalAmount) : _amountInWords(totalAmount)}
                                            </p>
                                            <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                Avg. 14.5% returns
                                            </p>
                                            <span className="vr opacity-10 w-60px e-bg-emerald"
                                                style={{ height: graphHeights.index_fund > 0 ? graphHeights.index_fund : 20 }} />
                                        </div>
                                    </div>
                                    <div className="line-height-0 px-0 col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                        <div>
                                            <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                ₹{otherFund < 100000 ? _formatToRupees(otherFund) : _amountInWords(otherFund)}
                                            </p>
                                            <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                Avg. 11.5% returns
                                            </p>
                                            <span className="vr opacity-10  w-60px e-bg-lavender"
                                                style={{ height: graphHeights.other_fund > 0 ? graphHeights.other_fund : 20 }} />
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0 h-point-5px border-0 e-bg-light-gray opacity-75" />
                                <div className="row mt-2">
                                    <div className="col-6 text-center">
                                        <p className="mb-0 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                            Alpha on Index
                                        </p>
                                    </div>
                                    <div className="col-6 text-center">
                                        <p className="text-center mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                            Other mutual funds
                                        </p>
                                    </div>
                                </div>

                                <div className="pt-4 mt-2 text-center mx-2">
                                    <PrimaryButton name="Explore funds"
                                        className="e-primary-button-hover transition padding-12px-top padding-12px-bottom w-80-per e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px"
                                        onPress={() => navigate("/explore")} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </div>
    )
}
export default Calculator;

Calculator.defaultProps = {
    type: 1
}