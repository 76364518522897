/*
 *   File : unlock.js
 *   Author : https://evoqins.com
 *   Description : unlock page
 *   Integrations : js-cookie,react-toastify,react-redux
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

//custom components
import { OTPInput } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import APIService from "../../Service/service";
import { Footer } from "../../Component/Footer";
import { Header } from "../../Component/Navigation";
import Store from "../../Store/store";  //redux
import { _getProfile } from "../../Helper";


//custom styles
import styles from "../../Style/Component/login.module.scss";


const Unlock = () => {

    const inputRef = useRef(null);
    const userName = useRef("");
    const { state } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentPinValue, setCurrentPinValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(2);
    const [loading, setLoading] = useState(true);
    const [refreshToken, setRefreshToken] = useState(null);

    useEffect(() => {

        setLoading(false);
        if (refreshToken != null) {
            _verifyRefreshToken();
        };
    }, [refreshToken]);

    useEffect(() => {
        let refresh_token = null;
        if (state === null) {
            refresh_token = Cookies.get('refresh_token');
        } else {
            refresh_token = state.refresh_token;
            if (state.user_name) {
                userName.current = state.user_name;
            }

        }
        setRefreshToken(refresh_token);
    }, [state]);

    useEffect(() => {

        const _handleOnEnter = (e) => {
            if (e.key === 'Enter') {
                _handleLogin();
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };

    }, [currentPinValue]);

    //handle current pin
    const _handleCurrentPin = (value) => {
        setCurrentPinValue(value);
    };

    //handle login app
    const _handleLogin = () => {

        if (currentPinValue.length === 4) {
            setLoading(true);
            _postVerifyLoginPIN();
        }
    };

    //handle forgot pin
    const _handleForgotPIN = () => {
        navigate("/forgot-pin");
    };

    // API - GET for login
    const _verifyRefreshToken = () => {
        let is_post = false;
        let url = 'auth/validate-refresh-token';
        let data = {};
        let refresh_token = refreshToken;
        APIService(is_post, url, data, refresh_token).then((response) => {
            if (response.status_code === 200) {
            } else {
                dispatch({
                    type: 'UPDATE_REFRESH_TOKEN',
                    payload: null,
                });

                dispatch({
                    type: 'UPDATE_ACCESS_TOKEN',
                    payload: null,
                });

                sessionStorage.removeItem('access_token');
                Cookies.remove("refresh_token", { path: '/', domain: process.env.REACT_APP_DOMAIN })

                navigate("/login", {
                    replace: true,
                    state: {}
                });
                // clear cookie ans session
            }
            setLoading(false);
        });
    }

    // API - POST for verify-pin
    const _postVerifyLoginPIN = () => {
        let is_post = true;
        let url = 'auth/verify-pin';
        let data = {
            "pin": currentPinValue
        };
        let refresh_token = refreshToken;

        APIService(is_post, url, data, refresh_token).then((response) => {
            if (response.status_code === 200) {
                // set access token in REDUX
                Store.dispatch({
                    type: 'UPDATE_ACCESS_TOKEN',
                    payload: response.data.access_token,
                });

                Cookies.set('refresh_token', response.data.refresh_token, { domain: process.env.REACT_APP_DOMAIN, path: '/', expires: 30 });

                //set session storage
                sessionStorage.setItem('access_token', response.data.access_token);

                // enable navigation to Home page
                navigate("/", {
                    replace: true,
                    state: {
                        access_token: response.data.access_token,
                    }
                });

                // Update user data
                _getProfile();

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });

            }
            setLoading(false);

        });
    }

    return (
        <>
            {/* forgot pin section */}
            <section className="e-login-gradient">
                {/* header  */}
                <Header />
                <div className="container px-4">

                    {/* login  */}
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="e-padding-block-40px-res  padding-76px-top padding-150px-bottom position-relative">
                                <div className={`${styles.e_unlock_wrapper} e-border-radius-32`}>
                                    <div className="row">
                                        <div className="col-md-5 me-5 d-md-block d-none mt-auto">
                                            <h3 className="mb-0 e-text-charleston-green e-font-24 e-alt-font-poppins e-font-weight-600 line-height-40px letter-spacing-neg-2per">
                                                Invest with <br />
                                                <span className="e-font-32" >
                                                    Confidence
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-12 ms-2 pe-sm-0">

                                            {/* security pin */}
                                            <div className="gradient-vr-line ps-md-4">
                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                    {userName.current ? `Hi ${userName.current}` : "Glad to have you back!"}
                                                </h3>
                                                <p className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    Enter your secret pin to continue
                                                    you almost there
                                                </p>
                                                <div>
                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                        Enter your pin*
                                                    </label>
                                                    <OTPInput ref={inputRef}
                                                        id="pin"
                                                        value={currentPinValue}
                                                        spellCheck="false"
                                                        focused_input={focusedInput}
                                                        onFocus={() => setFocusedInput(2)}
                                                        onBlur={() => setFocusedInput(0)}
                                                        onChange={_handleCurrentPin} />

                                                </div>
                                                {/* Forget pin link */}
                                                <span className="e-alt-font-poppins e-font-12 cursor-pointer e-font-weight-500 e-text-egyptian-blue text-decoration-hover-underline line-height-18px"
                                                    onClick={_handleForgotPIN} >
                                                    Forgot PIN?
                                                </span>
                                                <div className="mt-4 w-100">
                                                    <PrimaryButton name="Continue"
                                                        loading={loading}
                                                        disabled={loading}
                                                        className={currentPinValue.length === 4 ? `w-100 padding-12px-top padding-12px-bottom e-font-16` :
                                                            `e-bg-bright-gray e-cursor-none w-100 padding-12px-top padding-12px-bottom e-font-16`}
                                                        onPress={_handleLogin} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* footer section  */}
            <div className="e-bg-anti-flash-white">
                <Footer auth={true} />
            </div>
        </>
    )
}

export default Unlock;