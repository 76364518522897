/*
 *   File : add-family.js
 *   Author : https://evoqins.com
 *   Description : Add family modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';

// Component
import { _getOTP } from '../../Helper';
import { CustomPhoneInput, CustomTextInput, OTPInput } from '../Form-Elements';
import { PrimaryButton } from '../Buttons';
import { Icon } from '../Icon';
import { APIService } from '../../Service';

// Colors
import Colors from "../../Style/color.scss";
import { isValidPhoneNumber } from 'react-phone-number-input';

function AddFamilyModal(props) {

    const otpInputRef = useRef(null);
    const [toggleOTP, setToggleOTP] = useState(false);

    const [clientID, setClientID] = useState("");
    const [clientIDError, setClientIDError] = useState("");
    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");
    const [number, setNumber] = useState("");
    const [numberError, setNumberError] = useState("");

    // OTP
    const [focusedInput, setFocusedInput] = useState(2);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);

    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [selectedCountryLocale, setSelectedCountryLocale] = useState("IN");

    //for otp timer
    useEffect(() => {
        if (toggleOTP === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleOTP, timer]);


    //handle id change
    const _handleClientIDChange = (value) => {
        setClientID(value.toUpperCase().trim());
        setClientIDError("");
    };

    const _handlePANChange = (value) => {
        let pan  = value.trim();
        if (pan.length <= 10) {
            setPan(pan.toUpperCase());
        };
        setPanError("");
    };

    // handle phone number change
    const _handlePhoneNumberChange = (value, selected_country_code, country_locale) => {
        setNumber(value);
        setNumberError("");

        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }

        if (country_locale) {
            setSelectedCountryLocale(country_locale);
        }
    };


    const _handleSendOTP = () => {

        let is_valid = true;
        let valid_customer_id = /^(IFSH)\d+$/;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN
        let valid_phone = /^[6-9]\d{9}$/; // validation of phone
        let phone_number = Number(number.replace(selectedCountryCode, ""));   // removing country code

        if (clientID.length === 0) {
            setClientIDError("Please enter the client ID");
            is_valid = false;
        } else if (valid_customer_id.test(clientID) === false) {
            setClientIDError("Entered client id is invalid");
            is_valid = false;
        }

        if (validate_pan.test(pan) === false) {
            if (pan.length === 0) {
                setPanError("Please enter the PAN number");
            } else {
                setPanError("Invalid PAN number ");
            }
            is_valid = false;
        };

        if (selectedCountryCode == "+91") {
            if (!valid_phone.test(phone_number)) {
                setNumberError("Please enter a valid 10-digit phone number");
                is_valid = false;
            }
        } else {
            let is_valid_number = isValidPhoneNumber(String(number));
            if (is_valid_number === false) {
                setNumberError("Please enter a valid number");
                is_valid = false;
            }
        }

        if (is_valid) {
            setLoading(true);
            _sendOTP();
        }
    };

    const _handleAddMember = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };

        // btn loader
        setLoading(true);
        setIsDisabled(true);
        // API - add member
        _addMemberToFamily();
    };

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // Resend otp
    const _handleResendOTP = () => {
        setIsResendDisabled(true);
        _resendOTP();
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleOTP(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    // API - send OTP 
    const _sendOTP = () => {

        let is_post = true;
        let url = "family/add";
        let data = {
            "client_id": clientID,
            "pan": pan,
            "country_code": selectedCountryCode,
            "phone_number": Number(number.replace(selectedCountryCode, "")),
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {
                setToggleOTP(true);
                setOtpValue("");
                setTimer(30);
                setFocusedInput(2);
                setOtpError("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            };

            setLoading(false);
        })
    };

    // API - resend OTP 
    const _resendOTP = () => {

        let is_post = true;
        let url = "family/resend-otp";
        let data = {
            "country_code": selectedCountryCode,
            "phone_number": Number(number.replace(selectedCountryCode, "")),
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {
                setOtpValue("");
                setTimer(30);
                setFocusedInput(2);
                setOtpError("");
                toast.dismiss();
                toast.success("OTP sent successfully to entered phone number and email", {
                    className: "e-toast",
                });

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            };
            setIsResendDisabled(false);
            setLoading(false);
        })
    };

    // API - Verify OTP and add member to family
    const _addMemberToFamily = () => {

        let is_post = true;
        let url = "family/verify-otp";
        let data = {
            "country_code": selectedCountryCode,
            "phone_number": Number(number.replace(selectedCountryCode, "")),
            "otp": otpValue
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {
                const close_btn = document.querySelector("#add-family-modal-close");
                close_btn.click();
                setIsDisabled(false);
                props.onSuccess();

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            };
            setIsDisabled(false);
            setLoading(false);
        })
    };

    return (
        <div className="modal fade e-manage-modal"
            id="add-family-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="addFamilyModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                {
                    toggleOTP === false ? (
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow">
                            <div className="modal-header justify-content-space-between pt-4 px-3 px-sm-4 pb-3 border-0">
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Add family member
                                </h6>
                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    id='add-family-modal-close'
                                    draggable={false}
                                    className='cursor-pointer'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={props.onClose} />
                            </div>
                            <div className="d-flex flex-column gap-4 modal-body pb-4 px-3 px-sm-4 pt-0">

                                <div id='name'>
                                    <CustomTextInput label="Client ID*"
                                        placeholder="IFSH1919"
                                        type="text"
                                        value={clientID}
                                        errorMsg={clientIDError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={_handleClientIDChange} />
                                </div>
                                <div className='pan'>
                                    <CustomTextInput label="PAN*"
                                        placeholder="PAN number"
                                        maxLength={10}
                                        type="text"
                                        value={pan}
                                        errorMsg={panError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                        handleChange={_handlePANChange} />
                                </div>
                                <div className="phone">
                                    <CustomPhoneInput label="Phone Number"
                                        placeholder="9876543210"
                                        className={number ? `e-number-input-padding w-100 e-input-border ` : `e-number-input-padding w-100`}
                                        type="text"
                                        value={number}
                                        selectedCountryCode={selectedCountryCode}
                                        selectedCountryLocale={selectedCountryLocale}
                                        require
                                        error={numberError}
                                        handleChange={_handlePhoneNumberChange} />
                                </div>

                                {/* Info message */}
                                <div className='d-flex align-items-start gap-2 e-bg-cornsilk e-border-radius-16 p-3 margin-12-top'>
                                    <Icon icon="info"
                                        size={"1.125rem"}
                                        color={Colors.ochre} />
                                    <span className='e-text-ochre e-font-manrope e-font-12 e-font-weight-400 letter-spacing-3 line-height-16px'>
                                        Adding a family member receive your assets, simplifying the transfer process when needed
                                    </span>
                                </div>

                                <PrimaryButton name="Request OTP"
                                    loading={loading}
                                    disabled={loading}
                                    className="padding-12px-tb"
                                    onPress={_handleSendOTP} />

                            </div>
                        </div>
                    ) : (
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-3 px-sm-4 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='add-family-modal-close'
                                    data-bs-dismiss="modal"
                                    className='visually-hidden'
                                    aria-label="close"
                                    alt='close btn' />

                                <img src={require("../../Assets/Images/Login/back-icon.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />

                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Enter OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-3 px-sm-4 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                    <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                        An OTP has been sent to the family member's registered phone number and email.
                                    </p>
                                    <div className='pt-3'>
                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <OTPInput ref={otpInputRef}
                                            id="otp"
                                            value={otpValue}
                                            spellCheck="false"
                                            focused_input={focusedInput}
                                            error={otpError}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                        <div className='e-inline-flex'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                disabled={isResendDisabled}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                <div className='d-flex gap-3 padding-36px-top'>
                                    <PrimaryButton name={"Cancel"}
                                        dismiss="modal"
                                        target=""
                                        className="w-50 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                        onPress={props.onClose} />
                                    <PrimaryButton name={"Confirm"}
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="w-50 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleAddMember} />
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </div >
    )
}

export default AddFamilyModal;