import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../Store/Reducers/index.js';
import { rootSaga } from '../Store/Sagas/index';

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// Redux: Store
const Store = createStore(rootReducer, applyMiddleware(...middlewares));

// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

// Exports
export default Store;
