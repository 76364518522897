/*
 *   File : pagination.js
 *   Author : https://evoqins.com
 *   Description : Pagination component
 *   Integrations : react-paginate,
 *   Version : 1.0.0
*/


import ReactPaginate from "react-paginate";

const Pagination = (props) => {

    // const [initialPage, setInitialPage] = useState(props.initialPage ? props.initialPage : 0);

    // useEffect(() => {
    //     setInitialPage(props.initialPage);
    // }, [props.initialPage]);

    const _handlePageClick = (event) => {
        let new_start_limit = event.selected * props.itemsPerPage;
        let new_end_limit = new_start_limit + props.itemsPerPage;
        props.onChange(new_start_limit, new_end_limit);
    };

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel={props.nextButtonLabel}
            onPageChange={_handlePageClick}
            pageRangeDisplayed={2}
            // marginPagesDisplayed={0}
            pageCount={props.totalPage}
            previousLabel={props.prevButtonLabel}
            renderOnZeroPageCount={null}
            pageClassName="e-page-item"
            previousClassName="e-prev-button"
            nextClassName="e-next-button"
            containerClassName="e-pagination"
            activeClassName="e-active"
            marginPagesDisplayed={0}
            forcePage={props.initialPage}
        />
    )
}
export default Pagination;