import React, { memo } from 'react'
import { Icon } from '../Icon';

function WhyInvestInBasket(props) {

    return (
        <div className="modal fade e-manage-modal min-h90vh"
            data-bs-backdrop="static"
            id="why-invest-in-basket-modal"
            tabIndex="-1"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-header border-0 justify-content-space-between py-3 px-4 ">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            {props.data.view_more[0].title}
                        </h6>
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='edit-bank-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onClose} />
                    </div>
                    <div className="modal-body d-flex flex-column gap-4 p-4 pt-0">



                        {
                            props.data.view_more.map((item, key) => (
                                <div key={key}>
                                    {
                                        key !== 0 &&
                                        <h6 className='d-flex justify-content-between align-items-center e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            {item.title}
                                        </h6>
                                    }
                                    <p className='mb-0 e-text-dark_liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                        {item.description}
                                    </p>
                                    {
                                        item.allocation &&

                                        <div className='mt-4'>
                                            <div className="d-flex justify-content-between">
                                                <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                                    0%
                                                </span>
                                                <span className="e-text-sonic-silver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                                    100%
                                                </span>
                                            </div>
                                            <div className="height-40px-res progress-stacked e-border-radius-12 h-48 mt-2 mb-2">
                                                {
                                                    item.allocation.map((item, key) => (
                                                        <div style={{ width: `${item.percentage}%` }}
                                                            className="progress h-100"
                                                            role="progressbar"
                                                            key={key} >
                                                            <div style={{ backgroundColor: item.color }}
                                                                className="progress-bar"></div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            <div className="row flex-wrap row-gap-3">
                                                {
                                                    item.allocation.map((item, key) => (
                                                        <div className="col-auto" key={key}>
                                                            <span className="w-100 e-font-14-res col-6 col-md-7 d-flex align-items-center gap-2 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                <Icon icon="bullet"
                                                                    color={item.color}
                                                                    size={"1rem"} />
                                                                {item.name}
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>



                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(WhyInvestInBasket);