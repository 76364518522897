// Imports: Dependencies
import { all } from 'redux-saga/effects';

// Imports: Redux Sagas
import { loginData, signupData, profileData } from './sagas';

// Redux Saga: Root Saga
export function* rootSaga() {
    yield all([
        loginData,
        signupData,
        profileData,
    ]);
};
