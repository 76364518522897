/*
 *   File : add-nominee.js
 *   Author : https://evoqins.com
 *   Description : Add nominee page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import { differenceInYears } from 'date-fns'

//custom component
import { RadioGroup } from '../../../Component/Form-Elements';
import { PrimaryButton } from '../../../Component/Buttons';
import { NomineeCard } from '../../../Component/Cards';
import { APIService } from '../../../Service';
import { _getSignupProgress, _formatDateFormat, _getProfile } from '../../../Helper';
import { KycStatusModal } from '../../../Component/Modal';

//Assets
import SuccessImg from "../../../Assets/Images/KYC/kyc-success.webp";
import PendingImg from "../../../Assets/Images/KYC/pending.webp";
import Delete from "../../../Assets/Images/KYC/delete.svg";

const NOMINEE = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    }
];


const AddNominee = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.Reducer.GENERAL_DATA);
    //get progress data form redux
    const PROGRESS_DATA = useSelector((store) => store.Reducer.SIGNUP_PROGRESS_DATA);
    const [generalData, setGeneralData] = useState(GENERAL_DATA);

    const [addNomineeSelect, setAddNomineeSelect] = useState(2);
    const [nomineeData, setNomineeData] = useState([
        {
            "id": 1,
            "name": null,
            "relation_id": null,
            "dob": null,
            "allocation": null,
            "guardian_name": null,
            "guardian_relationship_id": null,
            "guardian_pan": null
        }
    ]);
    const [errorMessage, setErrorMessage] = useState([
        {
            "name_error": null,
            "relation_id_error": null,
            "dob_error": null,
            "allocation_error": null,
            "guardian_name_error": null,
            "guardian_relationship_id_error": null,
            "guardian_pan_error": null
        }
    ]);

    const [fetchNominee, setFetchNominee] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [statusModal, setStatusModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        let nominee_errors = [];
        if (PROGRESS_DATA.kyc_data.nominee.length !== 0) {
            setNomineeData(PROGRESS_DATA.kyc_data.nominee);
            for (let nominee_count = 0; nominee_count < PROGRESS_DATA.kyc_data.nominee.length; nominee_count++) {
                let nominee_error = {
                    "name_error": null,
                    "relation_id_error": null,
                    "dob_error": null,
                    "allocation_error": null,
                    "guardian_name_error": null,
                    "guardian_relationship_id_error": null,
                    "guardian_pan_error": null
                };
                nominee_errors.push(nominee_error);
            }
            setErrorMessage(nominee_errors);

            setAddNomineeSelect(1);
        } else {
            setAddNomineeSelect(2);
        };
    }, [PROGRESS_DATA]);

    useEffect(() => {
        if (GENERAL_DATA) {
            setGeneralData(GENERAL_DATA);
        };
    }, [GENERAL_DATA]);


    useEffect(() => {

        if (showSuccessModal === true) {
            let kyc_status = new Modal(document.getElementById('kyc-status-modal'));
            kyc_status.show();
        }

    }, [showSuccessModal]);

    useEffect(() => {
        if (statusModal === true) {
            let kyc_status = new Modal(document.getElementById('kyc-status-modal'));
            kyc_status.show();
        }
    }, [statusModal]);

    //handle nominee select
    const _handleAddNominee = (id) => {
        setAddNomineeSelect(id);
    };

    //handle add more nominee
    const _handleAddMoreNominee = () => {
        if (nomineeData.length < 3) {

            const new_nominee = {
                "name": null,
                "relation_id": null,
                "dob": null,
                "allocation": null,
                "guardian_name": null,
                "guardian_relationship_id": null,
                "guardian_pan": null
            };

            const new_error = {
                "name_error": null,
                "relation_id_error": null,
                "dob_error": null,
                "allocation_error": null,
                "guardian_name_error": null,
                "guardian_relationship_id_error": null,
                "guardian_pan_error": null
            };

            // Add a new nominee to the array
            setNomineeData([
                ...nomineeData,
                {
                    ...new_nominee,
                    id: nomineeData.length + 1
                }
            ]);

            setErrorMessage([
                ...errorMessage,
                new_error
            ]);
        };
    };

    // remove nominee from list
    const _handleRemoveNominee = (key) => {
        let updated_nominee = [...nomineeData];
        updated_nominee = updated_nominee.filter((item, index) => index !== key);
        setNomineeData(updated_nominee);

        // Clearing err object related to nominee  
        let updated_err_message = [...errorMessage];
        updated_err_message = updated_err_message.filter((item, index) => index !== key);
        setErrorMessage(updated_err_message);
    };

    //validating input field 
    const _validateNomineeData = (nominees) => {

        // Setting err array to scroll to first err input
        let error_inputs = [];
        let is_minor_nominee = false;
        let error_list = [...errorMessage];
        let validation_status = true;

        for (let i = 0; i < nominees.length; i++) {
            const nominee_data = nominees[i];

            if (nominee_data?.dob != null) {
                is_minor_nominee = differenceInYears(new Date(), _formatDateFormat(nominee_data?.dob)) < 18;
            }


            if (nominee_data.name === null) {
                error_list[i].name_error = "Provide the nominee full name";
                validation_status = false;
                error_inputs.push(`name-${i}`);
            } else {
                error_list[i].name_error = null;
            }

            if (nominee_data.relation_id === null) {
                error_list[i].relation_id_error = 'Please declare the relationship with the nominee';
                validation_status = false;
                error_inputs.push(`relation-${i}`);
            } else {
                error_list[i].relation_id_error = '';
            }

            // nominee_data.allocation = number or empty string
            if (nominee_data.allocation === null) {
                error_list[i].allocation_error = 'Please specify the allocation details';
                validation_status = false;
                error_inputs.push(`allocation-${i}`);
            } else {
                error_list[i].allocation_error = '';
            }

            if (nominee_data.dob === null) {
                error_list[i].dob_error = "Please provide the nominee's date of birth";
                validation_status = false;
                error_inputs.push(`dob-${i}`);
            } else {
                error_list[i].dob_error = '';
            }

            //guardian validation
            if (is_minor_nominee === true) {
                if (nominee_data.guardian_name === null) {
                    error_list[i].guardian_name_error = "Please provide the guardian's full name in this field";
                    validation_status = false;
                    error_inputs.push(`guardian-name-${i}`);
                } else {
                    error_list[i].guardian_name_error = '';
                }

                if (nominee_data.guardian_relationship_id == null) {
                    error_list[i].guardian_relationship_id_error = 'Please select the relationship with the nominee from the list';
                    validation_status = false;
                    error_inputs.push(`guardian-relation-${i}`);
                } else {
                    error_list[i].guardian_relationship_id_error = '';
                }

                if (nominee_data.guardian_pan === null) {
                    error_list[i].guardian_pan_error = "Please provide your guardian's valid PAN number";
                    validation_status = false;
                    error_inputs.push(`guardian-pan-${i}`);
                } else {
                    error_list[i].guardian_pan_error = '';
                }
            }
        }

        setErrorMessage([...error_list]);

        // Scrolling to first error
        if (error_inputs.length !== 0) {
            let first_err_id = error_inputs[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        return validation_status;
    };

    //handle add nominee submit
    const _handleSubmitAddNominee = () => {
        // Validate UI input fields
        setLoading(true);

        if (addNomineeSelect === 2) {
            _addNominee([]);
        } else {
            setFetchNominee(true);
        };
    };

    //nominee data
    const _handleNomineeData = (selected_nominee, index) => {

        let current_nominees = nomineeData;

        if (selected_nominee) {
            current_nominees[index] = selected_nominee;
            setNomineeData([...current_nominees]);
        }

        if (fetchNominee === true && index === current_nominees.length - 1) {
            const is_valid = _validateNomineeData(current_nominees); // pass data through param to avoid delay in useState 

            if (is_valid) {
                _addNominee(current_nominees); // passing data through props
            } else {
                setLoading(false);
            }
            setFetchNominee(false);
        }
    };

    //API- Add nominee
    const _addNominee = (nominees) => {

        let is_post = true;
        let url = 'kyc/add-nominee';
        let data = {
            "nominee": nominees,
            "is_nominee_available": addNomineeSelect === 1 ? true : false
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                // toast.dismiss();
                // if (addNomineeSelect === 1) {
                //     toast.success("Nominee added successfully", {
                //         className: "e-toast",
                //     });
                // } else {
                //     toast.success("Nominee details updated successfully", {
                //         className: "e-toast",
                //     });
                // }

                if (PROGRESS_DATA.kyc_user === false) {
                    setStatusModal(true);
                } else {
                    setShowSuccessModal(true);
                };

                _getSignupProgress();
                _getProfile();

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }
            setLoading(false);
        });
    };

    return (
        <div className='px-0 pb-4'>
            <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                <div className='padding-26px-top'>
                    <img src={require("../../../Assets/Images/left-arrow.svg").default}
                        draggable={false}
                        className='cursor-pointer'
                        alt='arrow'
                        onClick={() => props.onUpdateStep(5)} />
                </div>

                {/* step flow */}
                <div className='position-relative'>
                    <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                    <div className='position-absolute h-6 e-bg-egyptian-blue e-border-radius-16 top-0'
                        style={{ width: `${(100 / PROGRESS_DATA.step_detail.length) * 5}%` }}></div>
                    <p className='mb-0 text-end pt-1 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Step {PROGRESS_DATA.kyc_user === true ? '5' : '6'} of {PROGRESS_DATA.step_detail.length}
                    </p>
                </div>

                {/* Nominee details */}
                <div className='pt-3'>
                    <h3 className='mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                        Nominee details
                    </h3>
                    <div className='e-border-radius-16 e-bg-cornsilk p-3 mt-3 '>
                        <div className='d-flex e-alignflex-start gap-0-8'>
                            <img src={require("../../../Assets/Images/Profile/hint.svg").default}
                                draggable={false}
                                alt='hint' />
                            <p className='mb-0 e-text-ochre e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px letter-spacing-3' >
                                Adding a nominee ensures your chosen person will receive your assets, simplifying the transfer process when needed
                            </p>
                        </div>
                    </div>

                    {/* add nominee */}
                    <div className='pt-4'>
                        <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Do you want to add a nominee to your investment account?
                        </h6>
                        <div className='d-flex e-gap-0-16'>
                            <RadioGroup data={NOMINEE}
                                selected={addNomineeSelect}
                                handleSelect={_handleAddNominee} />
                        </div>
                    </div>

                    {addNomineeSelect === 1 &&
                        <>
                            {/* nominee card */}
                            {nomineeData.map((data, key) => (
                                <div className='pt-4 mt-2' key={key}>
                                    <div className='e-display-flex justify-content-space-between'>
                                        <h6 className='mb-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            Nominee {key + 1}
                                        </h6>

                                        {key !== 0 && (
                                            <img src={Delete}
                                                draggable={false}
                                                className='cursor-pointer'
                                                alt='delete'
                                                onClick={() => _handleRemoveNominee(key)} />
                                        )}
                                    </div>
                                    <NomineeCard data={generalData}
                                        index={key}
                                        nomineeData={data} // relation data
                                        error={errorMessage[key]}
                                        fetchNominee={fetchNominee}
                                        getNomineeData={_handleNomineeData} />
                                </div>
                            ))}
                        </>
                    }

                    {/* custom button*/}
                    <div className='pt-4 pt-sm-5 e-display-flex justify-content-space-between e-flex-wrap '>

                        <PrimaryButton name={"Submit"}
                            loading={loading}
                            className="order-2 order-sm-1 w-40 padding-12px-top padding-12px-bottom mb-2"
                            disabled={loading}
                            onPress={_handleSubmitAddNominee} />

                        {addNomineeSelect === 1 && nomineeData.length < 3 &&
                            <p className='e-font-14-res order-1 order-sm-2 cursor-pointer mb-4 mb-sm-0 e-text-indigo e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'
                                onClick={_handleAddMoreNominee} >
                                + Add new nominee
                            </p>
                        }
                    </div>
                </div>
            </div>

            {/* kyc status modal from kyc user */}
            {
                showSuccessModal &&
                <KycStatusModal title="Congratulations!"
                    image={SuccessImg}
                    description="The account creation process has been completed successfully. Happy investing!"
                    onClose={() => setShowSuccessModal(false)} />
            }

            {/* kyc status modal from non-kyc user */}
            {statusModal === true &&
                <KycStatusModal title="Processing!"
                    image={PendingImg}
                    description="Your KYC request under verification. Will take 1-2 mins to get it processed. Need to complete E-Sign to update KRA details."
                    onClose={() => setStatusModal(false)}
                />
            }
        </div>
    )
}

export default AddNominee;