/*
 *   File : profile.js
 *   Author : https://evoqins.com
 *   Description : Profile detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';


//custom component
import { Footer } from '../../Component/Footer';
import { BreadCrumbs } from '../../Component/Other';
import Bank from './bank';
import Settings from './settings';
import NomineeList from './nominee-list';
import { _checkUserAccess, _getProfile } from '../../Helper';
import { Icon } from '../../Component/Icon';
import { EditPhoneNumberModal, PhoneNumberChangeStatusModal } from '../../Component/Modal';

//styles
import styles from "../../Style/Component/profile.module.scss";
import Colors from "../../Style/Component/card.module.scss";

// Assets
import ProfileIcon from "../../Assets/Images/profile-icon.svg";

// If you change label name please update the respected filter in useEffect
const PROFILE_TABS = [
    {
        id: 1,
        icon_name: "profile",
        label: "Basic Details",
    },
    {
        id: 2,
        icon_name: "nominee",
        label: "Manage nominee",
    },
    {
        id: 3,
        icon_name: "manage-bank",
        label: "Manage bank",
    },
    {
        id: 4,
        icon_name: "settings",
        label: "Settings",
    },
]

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",

    },
    {
        label: "Account",
    }
]

const Profile = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const PROFILE_DATA = useSelector((store) => store.Reducer.USER_DATA);

    const { state } = useLocation();
    const [tabIdex, setTabIndex] = useState(location.state !== null && location.state.active_tab ? location.state.active_tab : 1);
    const [personalData, setPersonalData] = useState({});
    const [profileTabs, setProfileTabs] = useState(PROFILE_TABS);

    // Edit access to user
    const [editAccess, setEditAccess] = useState(false);
    const [showEditPhoneModal, setShowEditPhoneModal] = useState(false);
    const [showPhoneEditSuccessModal, setShowPhoneEditSuccessModal] = useState(false);



    // Setting active tab in location state to show active tab in profile option menu
    useEffect(() => {

        navigate("/profile", {
            state: {
                "active_tab": tabIdex,
                "optional_id": tabIdex === 2 ? "PROFILE-TAB-02" : tabIdex === 3 ? "PROFILE-TAB-03" : tabIdex === 4 ? "PROFILE-TAB-04" : 100,
            }, replace: true
        });

    }, [tabIdex]);

    useEffect(() => {

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setShowEditPhoneModal(false);
            setShowPhoneEditSuccessModal(false);
        });

    }, [showEditPhoneModal, showPhoneEditSuccessModal]);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

        window.addEventListener("load", () => {
            setTabIndex(location.state.active_tab)
        });
    }, []);

    useEffect(() => {
        if (PROFILE_DATA !== null) {
            setPersonalData(PROFILE_DATA);

            let edit_access = _checkUserAccess(); // will return boolean value
            setEditAccess(edit_access);

            if (edit_access === false) {
                let updated_tabs = profileTabs.filter(item => item.label !== "Manage nominee" && item.label !== "Manage bank");
                setProfileTabs(updated_tabs);
            };
        };
    }, [PROFILE_DATA]);


    useEffect(() => {

        if (state !== null && state.data) {
            setTabIndex(state.data);
        };

    }, [state]);

    // Phone number edit modal
    useEffect(() => {

        if (showEditPhoneModal) {
            const phone_edit_modal = new Modal(document.getElementById('edit-phone-modal'));
            phone_edit_modal.show();
        }

    }, [showEditPhoneModal]);

    // Phone number change success modal
    useEffect(() => {

        if (showPhoneEditSuccessModal) {
            const phone_edit_status_modal = new Modal(document.getElementById('phone-number-change-status-modal'));
            phone_edit_status_modal.show();
        }

    }, [showPhoneEditSuccessModal]);

    //handle change tab
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    const _handleCloseSuccessModal = () => {
        setShowPhoneEditSuccessModal(false);
        _getProfileData();
    }

    // Updating Profile data
    const _getProfileData = () => {
        // Helper function to get profile data
        _getProfile().then(response => {
            if (response.status_code === 200) {
                setPersonalData(response.data);
            }
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 d-flex flex-column e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">

                {/* breadcrumbs */}
                <BreadCrumbs data={BREADCRUMBS} />

                {/* section  */}
                <div className='col-lg-11 px-0 pt-4 mt-2 mb-5'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-12'>

                            {/* personal details */}
                            <div className='e-profile-gradient e-border-radius-16 p-3'>
                                <div className='e-display-flex gap-0-8'>
                                    <img src={ProfileIcon}
                                        draggable={false}
                                        alt='profile' />
                                    <div>
                                        <p className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            {personalData.name}
                                        </p>
                                        <p className='e-font-12-res mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                            User Id: {personalData.customer_id}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* tab  */}
                            <div className='pt-3 e-tab-wrapper'>
                                {profileTabs.map((data, key) => (
                                    <div key={key} className={`${tabIdex === data.id ? `${styles.e_active_item} e-tab-active` : `${styles.e_nav_item_hover} cursor-pointer`} d-flex justify-content-space-between padding-12px-top padding-12px-bottom padding-12-right padding-12px-left margin-12px-bottom e-tab-inactive`}
                                        onClick={() => _handleTabChange(data.id)} >
                                        <div className='e-display-flex e-gap-0-16 e-tab-item-wrapper'>
                                            <Icon icon={data.icon_name}
                                                size="1.125rem"
                                                color={tabIdex === data.id ? Colors.egyptian_blue : Colors.charleston_green} />
                                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-20px'>
                                                {data.label}
                                            </p>
                                        </div>
                                        <img src={tabIdex === data.id ?
                                            require("../../Assets/Images/right-blue-icon.svg").default : require("../../Assets/Images/right-black-small.svg").default}
                                            className={`${styles.e_arrow} transition e-arrow`}
                                            draggable={false}
                                            alt='arrow' />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='col-xl-8 col-lg-8 col-md-8 col-12 '>


                            {/* basic details */}
                            <>
                                {tabIdex === 1 ?
                                    <div className='e-bg-cultured e-border-radius-16 p-4'>
                                        <h6 className='mb-2 mb-sm-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                            Basic details
                                        </h6>
                                        <div className='row flex-wrap row-gap-16px-res row-gap-32px margin-24px-bottom-res margin-32px-bottom'>

                                            <div className='col-12 col-sm-4'>
                                                <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    Full name
                                                </p>
                                                <p className='e-font-14-res text-nowrap mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    {personalData.name}
                                                </p>
                                            </div>
                                            <div className='col-6 col-sm-4'>
                                                <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    User ID
                                                </p>
                                                <p className='e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    {personalData.customer_id}
                                                </p>
                                            </div>
                                            <div className='col-6 col-sm-4'>

                                                <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    PAN card
                                                </p>
                                                <p className='e-font-14-res text-nowrap mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>

                                                    {personalData.pan ? personalData.pan : '-'}
                                                </p>
                                            </div>

                                            <div className='col-6 col-sm-4'>
                                                <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    Gender
                                                </p>
                                                <p className='e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    {personalData.gender ? personalData.gender : '-'}
                                                </p>
                                            </div>
                                            <div className='col-6 col-sm-4'>
                                                <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                    Marital status
                                                </p>
                                                <p className='e-font-14-res mb-0 word-break e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    {personalData.bank ? personalData.marital_status : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <h6 className='mb-2 mb-sm-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                Contact details
                                            </h6>
                                            <div className='d-flex flex-wrap row-gap-3'>
                                                <div className='col-12 col-sm-6'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Phone number
                                                    </p>
                                                    <p className='e-font-14-res mb-0 text-nowrap e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {personalData.phone && personalData.phone.country_code} {personalData.phone && personalData.phone.number}

                                                        {/* Will be enabled in future */}
                                                        {
                                                            editAccess === true &&
                                                            <span className='ms-2 e-text-egyptian-blue e-font-14 text-decoration-hover-underline cursor-pointer'
                                                                onClick={() => setShowEditPhoneModal(true)}>
                                                                Edit
                                                            </span>
                                                        }
                                                    </p>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Email ID
                                                    </p>
                                                    <p className='e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {personalData.email ? personalData.email : '-'}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    tabIdex === 2 ?
                                        <NomineeList data={personalData} />
                                        :
                                        tabIdex === 3 ?
                                            <div className='row'>
                                                <Bank data={personalData}
                                                    editAccess={editAccess} />
                                            </div>
                                            :
                                            tabIdex === 4 ?
                                                <Settings />
                                                : null
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div >

            {/* footer  */}
            < Footer />

            {/* Modal */}
            {
                showEditPhoneModal &&
                <EditPhoneNumberModal onClose={() => setShowEditPhoneModal(false)}
                    onSuccess={() => setShowPhoneEditSuccessModal(true)} />
            }
            {
                showPhoneEditSuccessModal &&
                <PhoneNumberChangeStatusModal
                    onClose={_handleCloseSuccessModal} />
            }
            {/*  */}
        </>
    )
}

export default Profile;