/*
 *   File : support.js
 *   Author : https://evoqins.com
 *   Description : support page
 *   Integrations : NA
 *   Version : 1.0.0
*/


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Footer } from "../../Component/Footer";
import { BreadCrumbs, CustomTab, EmptyScreen } from "../../Component/Other";
import { PrimaryButton } from "../../Component/Buttons";
import { Icon } from "../../Component/Icon";
import { CreateTicketModal } from "../../Component/Modal";
import { Modal } from "bootstrap";
import { APIService } from "../../Service";

//Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyTicket from "../../Assets/Images/EmptyScreen/no-data.webp"

// Custom data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "Support",
    },
];

const MAIN_TABS = [
    {
        id: 1,
        label: "Create a ticket"
    },
    {
        id: 2,
        label: "My tickets"
    },
]

const SUPPORT_TABS = [
    {
        id: 1,
        label: "KYC",
    },
    {
        id: 2,
        label: "Index Funds Sahi Hai account",
    },
    {
        id: 3,
        label: "Bank account",
    },
    {
        id: 4,
        label: "Others",
    },
];

const TICKETS = [
    {
        id: 123243243,
        question: "How to complete my account creation?",
        status: "Open",
    },
    {
        id: 123243243,
        question: "How to complete my account creation?",
        status: "Close",
    },
    {
        id: 123243243,
        question: "How to complete my account creation?",
        status: "Open",
    }
];

const ACCORDION_ITEMS_1 = [
    {
        id: "support-1",
        title: "What is an exit load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don’t apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-2",
        title: "What is Indexation?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-3",
        title: "What is risk grade",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-4",
        title: "What is entry load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
];
const ACCORDION_ITEMS_2 = [
    {
        id: "support-1",
        title: "What is an exit load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don’t apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-2",
        title: "What is Indexation?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-3",
        title: "What is risk grade",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-4",
        title: "What is entry load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
];
const ACCORDION_ITEMS_3 = [
    {
        id: "support-1",
        title: "What is an exit load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don’t apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-2",
        title: "What is Indexation?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-3",
        title: "What is risk grade",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-4",
        title: "What is entry load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
];
const ACCORDION_ITEMS_4 = [
    {
        id: "support-1",
        title: "What is an exit load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don’t apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-2",
        title: "What is Indexation?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-3",
        title: "What is risk grade",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
    {
        id: "support-4",
        title: "What is entry load?",
        description: `An exit load is a fee that you may have to pay if you 
        withdraw your money from a mutual fund within a specified period. 
        Exist loads don't apply to liquid mutual funds. They are more likely 
        to apply to duration debt funds and equity funds.`
    },
];


function Support() {

    // When working with API remove ! operator from line 245
    const navigate = useNavigate();
    const [isDataLoading, setIsDataLoading] = useState(true); // API
    const [activeMainTabId, setActiveMainTabId] = useState(1);
    const [accordionTabId, setAccordionTabId] = useState(1);
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [tickets, setTickets] = useState([]);


    // Setting active tab in location state to show active tab in profile option menu
    useEffect(() => {

        navigate("/support", {
            state: {
                "optional_id": "IFSH07",
            },
        }, { replace: true });

    }, []);

    useEffect(() => {

        setIsDataLoading(true);
        _getTicketTypes();
        _getTicketList();

    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [tickets]);

    useEffect(() => {

        if (showTicketModal === true) {
            let ticket_modal = new Modal(document.querySelector("#create-ticket-modal"));
            ticket_modal.show();
        }
    }, [showTicketModal])


    const _handleOnMainTabChange = (id) => setActiveMainTabId(id);

    const _handleOnTabClick = (tab_id) => {
        setAccordionTabId(tab_id);
    };

    const _handleModalClose = (status) => {

        setShowTicketModal(false);
        if (status === "success") {
            let type = "update";
            _getTicketList(type);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

        }
    };


    // Get ticket types - API
    const _getTicketTypes = () => {

        let is_post = false;
        let url = 'support/query-types';

        APIService(is_post, url).then((response) => {
            if (response.status_code === 200) {
                setTicketTypes(response.data);
            } else {
                setTicketTypes([]);
            }

        });

    };

    // Get list of tickets - API

    const _getTicketList = (type) => {
        let is_post = true;
        let url = 'support/list-tickets';
        let data = {
            "page_num": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setTickets(response.data);
                if (type === "update") {
                    setActiveMainTabId(2);

                }
            } else {
                setTickets([]);
            }
            setIsDataLoading(false);
        });

    };

    return (
        <>

            <div className="min-h-100vh row e-bg-lotion d-flex flex-column px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={BREADCRUMBS} />
                {isDataLoading === true ? (
                    <div className="text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>

                ) : (
                    <div className="col-lg-11 p-0">
                        <div className="row mt-4 pt-2">
                            <div className="d-flex mb-4">
                                {MAIN_TABS.map((data, key) => (
                                    <CustomTab key={key}
                                        tabData={data}
                                        tabIndex={activeMainTabId}
                                        handleChange={_handleOnMainTabChange} />
                                ))}
                            </div>
                        </div>
                        <div className="row mb-5">
                            {
                                activeMainTabId == 1 && (
                                    <>
                                        <div className="col-5 col-sm-4 d-flex flex-column gap-12px gap-0-res ">
                                            {ticketTypes.map((tab, key) => (
                                                <p className={`${accordionTabId == key + 1 ? "active-tab" : ""} e-font-12-res mb-0 d-flex justify-content-between align-items-center faq-tab cursor-pointer e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-20px padding-12px-all`}
                                                    key={key}
                                                    onClick={() => _handleOnTabClick(key + 1)}>
                                                    {tab.name}
                                                    <Icon icon="right-black-icon"
                                                        size="1.5rem"
                                                        color={accordionTabId == tab.id ? "#1C359F" : "#23262F"} />
                                                </p>
                                            ))}
                                        </div>
                                        <div className="col-7 col-sm-8">
                                            {accordionTabId === 1 &&
                                                <div className="accordion accordion-flush" id="accordion-support">
                                                    {ACCORDION_ITEMS_1.map((item, key) => (
                                                        <div className="accordion-item mb-3" key={key}>
                                                            <h2 className="accordion-header">
                                                                <button className={`e-font-12-res line-height-20px-res ${key !== 0 && "collapsed"}  accordion-button bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${item.id}`}>
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                            <div id={item.id}
                                                                className={`accordion-collapse collapse ${key == 0 && "show"}`}
                                                                data-bs-parent="#accordion-support">
                                                                <div className="accordion-body p-0 pb-4">
                                                                    <p className="e-font-12-res line-height-22px-res mb-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {accordionTabId === 2 &&
                                                <div className="accordion accordion-flush" id="accordion-support">
                                                    {ACCORDION_ITEMS_2.map((item, key) => (
                                                        <div className="accordion-item mb-3" key={key}>
                                                            <h2 className="accordion-header">
                                                                <button className={`e-font-12-res line-height-20px-res ${key !== 0 && "collapsed"}  accordion-button bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${item.id}`}>
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                            <div id={item.id}
                                                                className={`accordion-collapse collapse ${key == 0 && "show"}`}
                                                                data-bs-parent="#accordion-support">
                                                                <div className="accordion-body p-0 pb-4">
                                                                    <p className="mb-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {accordionTabId === 3 &&
                                                <div className="accordion accordion-flush" id="accordion-support">
                                                    {ACCORDION_ITEMS_3.map((item, key) => (
                                                        <div className="accordion-item mb-3" key={key}>
                                                            <h2 className="accordion-header">
                                                                <button className={`e-font-12-res line-height-20px-res ${key !== 0 && "collapsed"}  accordion-button bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${item.id}`}>
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                            <div id={item.id}
                                                                className={`accordion-collapse collapse ${key == 0 && "show"}`}
                                                                data-bs-parent="#accordion-support">
                                                                <div className="accordion-body p-0 pb-4">
                                                                    <p className="mb-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {accordionTabId === 4 &&
                                                <div className="accordion accordion-flush" id="accordion-support">
                                                    {ACCORDION_ITEMS_4.map((item, key) => (
                                                        <div className="accordion-item mb-3" key={key}>
                                                            <h2 className="accordion-header">
                                                                <button className={`e-font-12-res line-height-20px-res ${key !== 0 && "collapsed"}  accordion-button bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${item.id}`}>
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                            <div id={item.id}
                                                                className={`accordion-collapse collapse ${key == 0 && "show"}`}
                                                                data-bs-parent="#accordion-support">
                                                                <div className="accordion-body p-0 pb-4">
                                                                    <p className="mb-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }

                                            {/* {Still need a help } */}
                                            <div className="my-5 py-4">
                                                <p className="e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px">Still need a help?</p>
                                                <PrimaryButton
                                                    name="Create a ticket"
                                                    className="py-2 padding-40px-lr"
                                                    onPress={() => setShowTicketModal(true)} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            {
                                activeMainTabId == 2 && (
                                    tickets.length > 0 ?
                                        tickets.map((item, key) => (
                                            <div className="col-sm-6 col-md-4 mb-4" key={key}>
                                                <div className="e-border-radius-16 e-bg-cultured">
                                                    <h6 className="px-3 pt-3 px-sm-4 pt-sm-4 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-20px margin-32px-bottom">
                                                        {item.subject}
                                                    </h6>
                                                    <hr className="my-3 opacity-75 border-color-light-gray" />
                                                    <div className="d-flex px-3 pb-3 px-sm-4 pb-sm-4">
                                                        <div className="col-8">
                                                            <p className="mb-1 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                                ID
                                                            </p>
                                                            <p className="e-text-dark-liver e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px mb-0">
                                                                {item.ticket_id}
                                                            </p>
                                                        </div>
                                                        <div className="col-4">
                                                            <p className="mb-1 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                                Status
                                                            </p>
                                                            <p className={`${item.status == "Open" ? "e-text-ufo-green" : item.status === "Pending" ? "e-text-mustard-yellow" : "e-text-sunset-orange"} e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px mb-0`}>
                                                                {item.status}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <EmptyScreen
                                            img={EmptyTicket}
                                            title="No tickets raised !"
                                            description="You can raise a ticket if you have any queries or need any assistance. We are ready to help you." />
                                )
                            }
                        </div>
                    </div>
                )
                }

            </div>

            <Footer />
            {
                showTicketModal &&
                <CreateTicketModal
                    data={ticketTypes[accordionTabId - 1]}
                    handleModalClose={_handleModalClose} />
            }
        </>
    );
};

export default Support;