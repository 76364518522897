/*
 *   File : family-profile.js
 *   Author : https://evoqins.com
 *   Description : Detail page of family member
 *   Integrations : NA
 *   Version : 1.0.0
 *   Date:24-05-2024
*/

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";


// Components
import { BreadCrumbs, CustomTab, EmptyScreen, OverLay } from "../../Component/Other";
import { CustomDateRangePicker, CustomTextInput } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import { Footer } from "../../Component/Footer";
import { Icon } from "../../Component/Icon";
import { SectionTitle } from "../../Component/Title";
import { PortfolioFilterModal, RemoveFamilyConfirmationModal } from "../../Component/Modal";
import Holdings from "../../Component/Cards/holdings";
import { APIService } from "../../Service";

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyPortfolio from "../../Assets/Images/EmptyScreen/no-portfolio.webp";
import ProfileIcon from "../../Assets/Images/profile.svg";

// COLORS
import Colors from "../../Style/color.scss"

// Custom Data

// Breadcrums
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Manage family",
        path: "/manage-family"
    },

];

// Tab data
const FAMILY_PROFILE_TAB = [
    {
        id: 1,
        label: "Investments"
    },
    {
        id: 2,
        label: "Profile"
    },
];

// Initial holdings filter
const DEFAULT_FILTER = {
    id: null,
    label: "All"
};

function FamilyProfile() {

    const location = useLocation();
    const navigate = useNavigate();
    const initialRender = useRef(true);

    const [tabIndex, setTabIndex] = useState(2);
    const [familyId, setFamilyId] = useState(0);
    const [breadcrumbs, setBreadcrumbs] = useState(BREADCRUMBS);

    //===== Investment tab states  =====//
    const [portfolioSummary, setPortfolioSummary] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [toggleFilterModal, setToggleFilterModal] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTER);
    const [holdings, setHoldings] = useState([]);
    const [isHoldingsLoading, setIsHoldingsLoading] = useState(false);

    //===== Profile tab states  =====//
    const [profileData, setProfileData] = useState();
    const [capitalDateRange, setCapitalDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [transactionDateRange, setTransactionDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedCapitalRange, setSelectedCapitalRange] = useState("");
    const [selectedTransactionRange, setSelectedTransactionRange] = useState("");
    const [capitalDateError, setCapitalDateError] = useState("");
    const [transactionDateError, setTransactionDateError] = useState("");
    const [showCapitalDatePicker, setShowCapitalDatePicker] = useState(false);
    const [showTransactionDatePicker, setShowTransactionDatePicker] = useState(false);
    // Download report
    const [capitalLoading, setCapitalLoading] = useState(false);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [holdingsLoading, setHoldingsLoading] = useState(false);

    // Modal state
    const [showFamilyRemoveModal, setShowFamilyRemoveModal] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Setting active tab & filters when user reloads the page
        window.addEventListener("load", () => {
            setTabIndex(() => location.state !== null ? location.state.tab_index : 1);
            setSelectedFilters(() => location.state !== null ? location.state.selected_filters : DEFAULT_FILTER);
            setFamilyId(() => location.state !== null ? location.state.family_detail_id : 0)
        });

        // Closing datepicker modal when user clicks outside
        const _handleDatePicker = (e) => {
            if (e.target.closest("#capital-gain-report") === null) {
                setShowCapitalDatePicker(false);
            };

            if (e.target.closest("#transaction-report") === null) {
                setShowTransactionDatePicker(false);
            };
        };

        window.addEventListener("click", (e) => _handleDatePicker(e));
        return window.removeEventListener("click", (e) => _handleDatePicker(e));

    }, []);

    useEffect(() => {

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            if (showFamilyRemoveModal === true) {
                setShowFamilyRemoveModal(false);
            };
        });

    }, [showFamilyRemoveModal]);

    // handle get data from api
    useEffect(() => {
        if (familyId) {
            setIsDataLoading(true);
            _getProfileData();
            _getPortfolioSummary();
        }
    }, [familyId]);

    // Saving selected tab an filters state in location state 
    // To set it when user reloads the page
    useEffect(() => {
        navigate(location.pathname, {
            state: {
                "selected_filters": selectedFilters,
                "tab_index": tabIndex,
                "family_detail_id": familyId
            },
            replace: true,
        });

    }, [tabIndex, selectedFilters, familyId]);


    // Setting family detail id form location state - when navigating from family page
    useEffect(() => {
        if (location.state && location.state.family_detail_id) {
            setFamilyId(location.state.family_detail_id);
        } else {
            navigate(-1);
        }
    }, [location.state]);

    // handle body from scrolling when filter modal opened
    useEffect(() => {

        if (toggleFilterModal === true) {
            document.body.style.overflow = "hidden";
            document.querySelector("#portfolio-filter").scrollIntoView({ top: 0, behavior: "smooth" });
        } else {
            document.body.style.overflow = "unset";
        };

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setToggleFilterModal(false);
        });

    }, [toggleFilterModal, selectedFilters]);

    // handle get holdings data when filter changes
    useEffect(() => {
        if (initialRender.current === false) {
            setIsHoldingsLoading(true);
            _getHoldingsData();
        }

    }, [selectedFilters]);

    // handle open family member remove modal
    useEffect(() => {

        if (showFamilyRemoveModal) {
            const remove_family_modal = new Modal(document.getElementById('confirm-family-remove'));
            remove_family_modal.show();
        }

    }, [showFamilyRemoveModal]);

    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    // handle trigger family member remove modal
    const _handleRemoveMember = () => {
        setShowFamilyRemoveModal(true);
    };

    // handle download report date select
    const _handleUpdateRange = (range, type) => {

        const _formatDate = (date) => {
            return date.toLocaleDateString("en-GB").replaceAll("/", "-");
        };

        if (type === 1) {
            setCapitalDateRange(range);
            setSelectedCapitalRange(_formatDate(range[0].startDate) + "  -  " + _formatDate(range[0].endDate));
            setShowCapitalDatePicker(false);
            setCapitalDateError("");
        } else if (type === 2) {
            setTransactionDateRange(range);
            setSelectedTransactionRange(_formatDate(range[0].startDate) + "  -  " + _formatDate(range[0].endDate));
            setShowTransactionDatePicker(false);
            setTransactionDateError("");
        }

    };

    // handle download CapitalGain report
    const _handleDownloadCapitalGain = () => {
        if (selectedCapitalRange.length === 0) {
            setCapitalDateError("Select start and end date to proceed");
            return;
        }
        setCapitalLoading(true);
        _downloadCapitalGainReport();
    };

    // handle download Transaction report
    const _handleDownloadTransactionReport = () => {
        if (selectedTransactionRange.length === 0) {
            setTransactionDateError("Select start and end date to proceed");
            return;
        }
        setTransactionLoading(true);
        _downloadTransactionReport();
    };

    // handle download Holdings report
    const _handleDownloadHoldingsReport = () => {
        setHoldingsLoading(true);
        _downloadHoldingsReport();
    };

    // handle toggle filter modal state
    const _handlePortfolioFilterModal = () => {
        setToggleFilterModal(status => status == false ? true : false);
    };

    // handle select filter
    const _handleSelectedFilters = (selected_filter, status) => {
        if (status == true) {
            setSelectedFilters(selected_filter);
        }
        setToggleFilterModal(false);
    };

    // handle close filter modal
    const _handleCloseFilterModal = () => {
        setToggleFilterModal(false);
    };



    /* ===============================================
                      API CALLS
   ================================================ */

    // API -  get family member profile data
    const _getProfileData = () => {

        let is_post = true;
        let url = "family/profile";
        let data = {
            "family_detail_id": familyId
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {
                setProfileData(response.data);
                setBreadcrumbs([...BREADCRUMBS, { label: response.data.name }]);
            } else {
                setProfileData({});
            }

            setIsDataLoading(false);
        });

    };

    // API -  get family member investment summary data
    const _getPortfolioSummary = () => {

        let is_post = true;
        let url = "family/portfolio";
        let data = {
            "family_detail_id": familyId,
            "investment_type": null,
            "is_external_portfolio": false
        };

        APIService(is_post, url, data).then(response => {

            if (response.status_code === 200) {
                setPortfolioSummary(response.data);
                setHoldings(response.data.holdings);
            } else {
                setPortfolioSummary({});
                setHoldings([]);
            }

            setIsHoldingsLoading(false);
            initialRender.current = false;
        });
    };

    // API -  get holdings data - with filters
    const _getHoldingsData = () => {

        let is_post = true;
        let url = "family/portfolio";
        let data = {
            "family_detail_id": familyId,
            "investment_type": selectedFilters.id,
            "is_external_portfolio": false
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code === 200) {
                setHoldings(response.data.holdings);
            } else {
                setHoldings([]);
            }
            setIsHoldingsLoading(false);
        });
    };

    // API -  download capital gain report 
    const _downloadCapitalGainReport = () => {

        let is_post = true;
        let url = 'family/capital-gain-report';

        let data = {
            "from_date": capitalDateRange[0].startDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
            "till_date": capitalDateRange[0].startDate.toLocaleDateString('en-UK').split("/").reverse().join("-"),
            "family_detail_id": familyId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Capital gain report download successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setCapitalLoading(false);
        });
    };

    // API -  download transaction report 
    const _downloadTransactionReport = () => {

        let is_post = true;
        let url = 'family/transaction-report';

        let data = {
            "from_date": transactionDateRange[0].startDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
            "till_date": transactionDateRange[0].startDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
            "family_detail_id": familyId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Transaction report download successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setTransactionLoading(false);
        });
    };

    // API -  download holdings report 
    const _downloadHoldingsReport = () => {

        let is_post = true;
        let url = 'family/holdings-report';

        let data = {
            "family_detail_id": familyId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Holding report download successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setHoldingsLoading(false);
        });
    };

    return (
        <div className="min-h-100vh">
            <div className="row   pb-4 pb-sm-5 align-items-start e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={breadcrumbs} />

                {
                    isDataLoading ? (
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                    ) : (
                        <div className='col-lg-11 p-0 mb-4 mb-sm-5'>

                            <div className="d-flex e-border-bottom-2px-bright-gray padding-34px-top margin-40px-bottom">
                                {
                                    FAMILY_PROFILE_TAB.map((data, key) => (
                                        <CustomTab key={key}
                                            tabData={data}
                                            tabIndex={tabIndex}
                                            handleChange={_handleTabChange} />
                                    ))
                                }
                            </div>

                            {
                                tabIndex == 1 ?
                                    // Investment summary
                                    <div>
                                        <div className="col-12 col-sm-9 col-md-7 col-lg-6 e-bg-cultured d-flex gap-3 gap-sm-4 flex-column p-3 p-sm-4 e-border-radius-16">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="mb-0 e-font-14-res e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500">
                                                    Investment summary
                                                </p>
                                            </div>

                                            {/* investment */}
                                            <div className="e-bg-lavender p-3 e-border-radius-16 d-flex flex-wrap row-gap-4 justify-content-between align-items-center">
                                                <div className="col-6 col-sm-6">
                                                    <p className="mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                        Current
                                                    </p>
                                                    <span className="break-word e-font-14-res e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600">
                                                        {portfolioSummary.summary.current_amount_text}
                                                    </span>
                                                </div>
                                                <div className="col-6 col-sm-6 ">
                                                    <p className="mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                        Invested
                                                    </p>
                                                    <span className="break-word e-font-14-res e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600">
                                                        {portfolioSummary.summary.invested_amount_text}
                                                    </span>
                                                </div>
                                                <div className="col-6 col-sm-6 ">
                                                    <p className="mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                        Returns
                                                    </p>
                                                    <div className="d-flex flex-wrap">
                                                        <span className={`${portfolioSummary.summary.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} break-word e-font-14-res e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 pe-1 line-height-24px`}>
                                                            {portfolioSummary.summary.returns_amount_text}
                                                        </span>
                                                        <span className={`${portfolioSummary.summary.returns_percentage >= 0 ? "e-text-ufo-green" : "e-text-red"} break-word e-font-14-res e-text-emerald e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                                                            ({portfolioSummary.summary.returns_percentage_text})
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 ">
                                                    <p className="mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                        XIRR
                                                    </p>
                                                    <span className={`${portfolioSummary.summary.xirr >= 0 ? "e-text-ufo-green" : "e-text-red"} break-word e-font-14-res e-text-emerald e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                                                        {portfolioSummary.summary.xirr_text}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Holdings */}
                                        <div className="col margin-top-32px">
                                            <div className='ps-0'>
                                                <div className='d-flex justify-content-between align-items-center margin-32px-bottom'>
                                                    <SectionTitle title="Holdings" />
                                                    {/* Holdings Filter */}
                                                    <div className='position-relative' id="portfolio-filter">
                                                        <div className='d-flex gap-12px cursor-pointer e-bg-lavender px-4 padding-10px-tb e-border-radius-32'
                                                            onClick={_handlePortfolioFilterModal}>
                                                            <span className='e-text-egyptian-blue e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px'>
                                                                {selectedFilters.label}
                                                            </span>
                                                            <Icon icon="down-arrow"
                                                                size="1.5rem"
                                                                className={`${toggleFilterModal == true ? "transform-rotate-180" : ""}`}
                                                                color={Colors.egyptian_blue}
                                                            />
                                                        </div>

                                                        {/* filter modal */}
                                                        {
                                                            toggleFilterModal &&
                                                            <>
                                                                <OverLay isOpen={toggleFilterModal} onClose={_handleCloseFilterModal} />
                                                                <PortfolioFilterModal selectedFilter={selectedFilters}
                                                                    onClick={_handleCloseFilterModal}
                                                                    handleSelectedFilters={_handleSelectedFilters} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    isHoldingsLoading == true ? (
                                                        <div className="min-h-100vh  text-center mt-5" >
                                                            <img src={Loader}
                                                                draggable="false"
                                                                alt="loader" />
                                                        </div>
                                                    ) : (

                                                        holdings && holdings.length !== 0 ? (
                                                            <div className='row p-0 flex-wrap'>
                                                                {
                                                                    holdings.map((item, key) => (
                                                                        <Holdings data={item}
                                                                            type={2}
                                                                            key={key} />
                                                                    ))
                                                                }
                                                            </div>
                                                        ) : (
                                                            <EmptyScreen img={EmptyPortfolio}
                                                                title="No holdings !"
                                                                description={`You don't have any active holdings ${selectedFilters.label == "All" ? ". start investing in simple steps" : " in " + selectedFilters.label}.`} />
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    // Profile data
                                    <div className="col col-lg-8">
                                        <div className='e-profile-gradient e-border-radius-16 p-3'>
                                            <div className='e-display-flex gap-0-8'>
                                                <img src={ProfileIcon}
                                                    draggable={false}
                                                    alt='profile' />
                                                <div>
                                                    <p className='break-word e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                        {profileData.name}
                                                    </p>
                                                    <p className='e-font-12-res mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Customer Id: {profileData.user_id}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='e-bg-cultured e-border-radius-16 p-3 p-sm-4 mt-4'>
                                            <div className='row flex-wrap  row-gap-16px-res row-gap-32px '>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Date of Birth
                                                    </p>
                                                    <p className='e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.dob}
                                                    </p>
                                                </div>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Gender
                                                    </p>
                                                    <p className='e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.gender}
                                                    </p>
                                                </div>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Marital status
                                                    </p>
                                                    <p className='e-font-14-res mb-0 word-break e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.marital_status}
                                                    </p>
                                                </div>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Phone number
                                                    </p>
                                                    <p className='break-word e-font-14-res mb-0 text-nowrap e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.phone}
                                                    </p>
                                                </div>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        Email ID
                                                    </p>
                                                    <p className='break-word e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.email}
                                                    </p>
                                                </div>
                                                <div className='col-6 col-sm-4'>
                                                    <p className='mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                        PAN
                                                    </p>
                                                    <p className='break-word e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                        {profileData.pan}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Report download section */}
                                        <div className="margin-top-32px">
                                            <h4 className={`e-font-14-res mb-4 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px`}>
                                                Download report
                                            </h4>
                                            <div className="accordion accordion-flush e-bg-cultured p-3 p-sm-4 pb-0 pb-sm-2 e-border-radius-16" id="accordion-download-report">
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header">
                                                        <button className={`accordion-button e-font-14-res line-height-20px-res  bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#capital-gain`}>
                                                            Capital gain
                                                        </button>
                                                    </h2>
                                                    <div id="capital-gain"
                                                        className="accordion-collapse collapse show"
                                                        data-bs-parent="#accordion-download-report">
                                                        <div className="accordion-body p-0 pb-4">
                                                            <div className='col col-sm-8 col-md-6 col-lg-8 col-xl-6'>
                                                                <div id="capital-gain-report"
                                                                    className='position-relative mb-1'>
                                                                    <CustomTextInput
                                                                        label="Select start and end date"
                                                                        placeholder="Start date - End date"
                                                                        type="text"
                                                                        maxLength={1000}
                                                                        errorMsg={capitalDateError}
                                                                        value={selectedCapitalRange}
                                                                        readOnly={true}
                                                                        className="cursor-pointer w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                                                        labelStyle="e-font-weight-500"
                                                                        icon={"calendar"}
                                                                        onClick={() => {
                                                                            setShowCapitalDatePicker((prev) => !prev);
                                                                            setShowTransactionDatePicker(false);
                                                                            setCapitalDateError("");
                                                                        }} />
                                                                    {
                                                                        showCapitalDatePicker &&
                                                                        <CustomDateRangePicker
                                                                            initialRange={capitalDateRange}
                                                                            onUpdate={(range) => _handleUpdateRange(range, 1)}
                                                                            cancel={() => setShowCapitalDatePicker(false)}
                                                                            minDate={null}
                                                                            maxDate={new Date()}
                                                                            leftAlign={true} />
                                                                    }

                                                                </div>
                                                                <PrimaryButton name="Download report"
                                                                    disabled={capitalLoading ? true : false}
                                                                    loading={capitalLoading ? true : false}
                                                                    className="w-180px e-font-14 mt-3 mt-sm-4 py-2"
                                                                    onPress={_handleDownloadCapitalGain} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header">
                                                        <button className={`accordion-button collapsed e-font-14-res line-height-20px-res bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#transaction`}>
                                                            Transaction
                                                        </button>
                                                    </h2>
                                                    <div id={"transaction"}
                                                        className={`accordion-collapse collapse`}
                                                        data-bs-parent="#accordion-download-report">
                                                        <div className="accordion-body p-0 pb-4">
                                                            <div className='col col-sm-8 col-md-6 col-lg-8 col-xl-6'>
                                                                <div id="transaction-report"
                                                                    className='position-relative mb-1'>
                                                                    <CustomTextInput
                                                                        label="Select start and end date"
                                                                        placeholder="Start date - End date"
                                                                        type="text"
                                                                        maxLength={1000}
                                                                        value={selectedTransactionRange}
                                                                        errorMsg={transactionDateError}
                                                                        readOnly={true}
                                                                        className="cursor-pointer w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                                                        labelStyle="e-font-weight-500"
                                                                        icon={"calendar"}
                                                                        onClick={() => {
                                                                            setShowTransactionDatePicker((prev) => !prev);
                                                                            setShowCapitalDatePicker(false);
                                                                            setTransactionDateError("");
                                                                        }}
                                                                    />
                                                                    {
                                                                        showTransactionDatePicker &&
                                                                        <CustomDateRangePicker
                                                                            initialRange={transactionDateRange}
                                                                            onUpdate={(range) => _handleUpdateRange(range, 2)}
                                                                            cancel={() => setShowTransactionDatePicker(false)}
                                                                            minDate={null}
                                                                            maxDate={new Date()}
                                                                            leftAlign={true} />
                                                                    }
                                                                </div>
                                                                <PrimaryButton name="Download report"
                                                                    disabled={transactionLoading ? true : false}
                                                                    loading={transactionLoading ? true : false}
                                                                    className="w-180px e-font-14 mt-3 mt-sm-4 py-2"
                                                                    onPress={_handleDownloadTransactionReport} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header">
                                                        <button className={`accordion-button collapsed e-font-14-res line-height-20px-res bg-transparent shadow-none p-0 pb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-28px`}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#holdings`}>
                                                            Current holding report
                                                        </button>
                                                    </h2>
                                                    <div id={"holdings"}
                                                        className={`accordion-collapse collapse`}
                                                        data-bs-parent="#accordion-download-report">
                                                        <div className="accordion-body p-0 pb-4">
                                                            <div className='col col-sm-6 col-md-5'>
                                                                <div id="holdings-report"
                                                                    className='position-relative mb-1'>
                                                                    <span className={`e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                                                                        Holdings Report
                                                                    </span>
                                                                </div>
                                                                <PrimaryButton name="Download report"
                                                                    disabled={holdingsLoading ? true : false}
                                                                    loading={holdingsLoading ? true : false}
                                                                    className="w-180px e-font-14  py-2 mt-2"
                                                                    onPress={_handleDownloadHoldingsReport} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Remove from family btn */}
                                        <span className="margin-24px-top-res d-inline-block padding-2px-bottom cursor-pointer margin-top-40px e-border-bottom-1px-egyptian-blue e-font-14-res e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-14px"
                                            onClick={_handleRemoveMember}>
                                            Remove from family
                                        </span>
                                    </div>
                            }
                        </div>
                    )
                }
            </div >
            <Footer />

            {/* Modals */}
            {
                showFamilyRemoveModal &&
                <RemoveFamilyConfirmationModal
                    familyId={familyId}
                    onClose={() => setShowFamilyRemoveModal(false)} />
            }
        </div>
    );
};

export default FamilyProfile;