/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Life goals listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//custom components
import { GoalCard } from "../../Component/Cards";
import APIService from "../../Service/service";
import { Footer } from "../../Component/Footer";
import { BreadCrumbs } from "../../Component/Other";
import { _goalDetailPath } from "../../Helper";

//assets
import Loader from "../../Assets/Images/coloured_loader.svg";

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Life goals",
    },
];

const Listing = () => {

    const navigate = useNavigate();

    const [goalListData, setGoalListData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        _getGoalList();
        window.scrollTo({
            top: 0, behavior: "smooth"
        });
    }, []);

    //handle Goal cards navigate
    const _handleGoalCardClick = (id, type) => {

        // helper function for goal detail path
        let path = _goalDetailPath(type)
        navigate(path, {
            state: {
                goal_id: id,
            }
        });
    };

    //API- Get Filter data 
    const _getGoalList = () => {
        let is_post = false;
        let url = 'goal/list';
        let data = null;

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setGoalListData(response.data);
            } else {
                setGoalListData([]);
            }
            setLoading(false);
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                {loading ?
                    <div className="min-h-100vh text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>
                    :
                    <div className="col-lg-11 p-0">
                        {/* breadcrumbs */}
                        <BreadCrumbs data={BREADCRUMBS} />

                        {/*life goals  */}
                        <div className="padding-26px-top">
                            <div className="row pt-sm-4 mt-1">
                                {goalListData.map((data, key) => (
                                    <div key={key} className="col-md-6 col-12"
                                        onClick={() => _handleGoalCardClick(data.goal_id, data.short_name)}>
                                        <GoalCard data={data} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* footer  */}
            <Footer />
        </>
    )
}
export default Listing;