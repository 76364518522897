/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Fund detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useState, useEffect, useRef, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


// Components
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import PrimaryButton from "../../Component/Buttons/primary";
import { AreaChart } from "../../Component/Chart";
import { Calculator } from "../../Component/Calculator";
import { Footer } from "../../Component/Footer";
import { BreadCrumbs, CustomTab } from "../../Component/Other";
import { FundPortfolioModal, MFInvestModal, PaymentStatusModal } from "../../Component/Modal";
import Chip from "../../Component/Other/chip";
import { APIService } from "../../Service";
import { _mfSummary } from "../../Helper/api";

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";


// Custom data
const PORTFOLIO_TAB = [
    {
        id: 1,
        label: "Holdings"
    },
    {
        id: 2,
        label: "Sector"
    },
];

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "Explore funds",
        path: "/explore",
    },
];

function Detail() {

    const userData = useSelector(store => store.Reducer.USER_DATA);
    const navigate = useNavigate();
    const location = useLocation();
    const chartRef = useRef("");
    const available_investment_types = useRef({});

    const [fundId, setFundId] = useState("");
    const [fundData, setFundData] = useState({});
    const [performanceData, setPerformanceData] = useState({});
    const [performanceFilters, setPerformanceFilters] = useState([]);
    const [filterType, setFilterType] = useState(-1);
    const [investmentType, setInvestmentType] = useState(null);
    const [fundSummary, setFundSummary] = useState({});

    const [allTypeReturns, setAllTypeReturns] = useState([]);
    const [selectedTypeReturn, setSelectedTypeReturn] = useState({});

    const [tabIndex, setTabIndex] = useState(1);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);
    const [breadcrumbs, setBreadcrumbs] = useState(BREADCRUMBS);

    // Modal
    const [showInvestModal, setShowInvestModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPortfolioModal, setShowPortfolioModal] = useState(false);
    const [paymentModalType, setPaymentModalType] = useState(0);

    useEffect(() => {
        //scroll to top when new fund details displayed
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
        if (location.state !== null) {
            setFundId(location.state.fund_id);
        } else {
            navigate("/explore");
        }
    }, [location]);

    useEffect(() => {
        if (fundId !== "") {
            _getFundDetails();
        };
    }, [fundId]);

    useEffect(() => {
        if (filterType != -1) {
            setIsChartLoading(true);
            _getPerformanceData();
        };

    }, [filterType]);

    useEffect(() => {
        if (showInvestModal == true) {
            const invest_modal = new Modal(document.getElementById('invest-mf-modal'));
            invest_modal.show();
        };
    }, [showInvestModal]);

    useEffect(() => {
        if (showPaymentModal == true) {
            new Modal(document.querySelector("#payment-status-modal")).show();
        }
    }, [showPaymentModal]);

    useEffect(() => {
        if (showPortfolioModal == true) {
            new Modal(document.querySelector("#modal-fund-portfolio")).show();
        }
    }, [showPortfolioModal]);


    const _handleTabChange = (id) => setTabIndex(id);

    const _handleFilterTypeChange = (type) => {
        setFilterType(type);

        const performance_chart = document.getElementById("performance-chart-wrapper");
        const demo_chart = document.getElementById("chart-content");

        performance_chart.scrollIntoView(
            {
                behavior: "smooth",
                inline: "start",
                block: "nearest",
            }
        );

        demo_chart.scrollIntoView(
            {
                behavior: "smooth",
                inline: "start",
                block: "nearest",
            }
        );


        // performance_chart.scrollTo(
        //     {
        //         top: 0,
        //         left: 0,
        //         behavior: "smooth"
        //     }
        // );

    };

    const _handleCloseInvestModal = (type) => {

        // Closing modal
        var button = document.getElementById("mf-invest-modal-close");
        button.click();

        setShowInvestModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };
    };

    // handle on click - investment btn
    const _handleOnClickInvest = (type) => {

        if (userData !== null && (userData.signup_progress_status == "INVESTED" || userData.signup_progress_status == "ACCOUNT_CREATED")) {
            // API call to get fund data
            _fundSummary();
            setInvestmentType(type == "sip" ? 1 : 2);
        } else {
            toast.dismiss();
            toast.error("Complete KYC to start with investment", {
                className: "e-toast",

            });
        };
    };

    // API - request for getting summary detail of fund
    const _fundSummary = () => {

        const param = {
            "mf_id": fundId,
            "type": null,
        };

        // Api call
        _mfSummary(param).then(status => {
            if (status.status_code === 200) {
                setFundSummary(status.data);
                setShowInvestModal(true);
            } else {
                toast.dismiss();
                toast.error(status.message, { className: "e-toast", });
            }
        });

    };

    // API - Getting fund detail
    const _getFundDetails = () => {
        let is_post = true;
        let url = 'scheme/detail';
        let data = {
            "mf_id": fundId,
            "isin": null,
        };
        
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setFundData(response.data);
                setPerformanceFilters(response.data.fund_perf_filters);
                setBreadcrumbs([...BREADCRUMBS, { label: response.data.scheme_name_unique }])
                available_investment_types.current = {
                    sip: response.data.sip_available,
                    lumpsum: response.data.lumpsum_available,
                };

                if (response.data.fund_perf_filters.length != 0) {
                    const filter_type = response.data.fund_perf_filters[0].filter_type;
                    // for adding selected style to selected filter type
                    setFilterType(filter_type);
                }

                // Taking returns and adding it into overallReturns state
                const returns = response.data.trailing_returns.map((item, i) => {
                    let { fund } = item;
                    return { fund, type: i + 1, period: item.period };

                });

                setAllTypeReturns(returns);

                let three_year_return = response.data.trailing_returns.filter(item => item.period == "3Y");
                let selected_return;

                if (three_year_return.length !== 0) {
                    selected_return = three_year_return[0];
                } else {
                    selected_return = response.data.trailing_returns.at(-1);
                }

                setSelectedTypeReturn(selected_return);

            } else {
                navigate("/explore");
                setFundData({});
            };

        });
    };

    // API - Fetch performance details of selected fund
    const _getPerformanceData = () => {
        let is_post = true;
        let url = "fund/performance"
        let data = {
            "mf_id": fundId,
            "filter_type": filterType,
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setPerformanceData(response.data);
            } else {
                setPerformanceData({});
            }

            setIsChartLoading(false);
            setIsDataLoading(false);
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 ps-4 e-bg-lotion d-flex flex-column px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                {
                    isDataLoading ? (
                        <div className="min-h-100vh text-center mt-5 pt-5" >
                            <img src={Loader}
                                draggable="false" />
                        </div>
                    ) : (
                        <div className="col-lg-11 p-0">
                            {/* Breadcrumb */}
                            <div className="col ps-0 mb-4">
                                <BreadCrumbs data={breadcrumbs} />
                            </div>

                            {/* Fund Info banner */}
                            <div className="col mb-4 ps-0">
                                <div className="row m-0 e-bg-cultured e-border-radius-16 justify-content-between p-sm-3 py-sm-4 py-3 px-1">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-12 d-flex gap-2 align-items-start ">
                                        <img src={fundData.image}
                                            width={48}
                                            height={48}
                                            className="object-fit-contain e-bg-white p-1 e-border-radius-10 me-1"
                                            draggable="false"
                                            alt="fund logo" />
                                        <div className="d-flex flex-column">
                                            <h3 className="e-font-14-res e-ellipse mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                                {fundData.scheme_name}
                                            </h3>
                                            <div className="d-flex gap-2">
                                                <Chip className="py-xl-2  py-lg-2 py-md-2 py-sm-1 py-1 px-xl-4 px-lg-4 px-md-4 px-sm-3 px-3 e-font-14 e-res-font-12"
                                                    name={fundData.asset_category} />
                                                {
                                                    fundData.option_name !== null &&
                                                    <Chip className="py-xl-2  py-lg-2 py-md-2 py-sm-1 py-1 px-xl-4 px-lg-4 px-md-4 px-sm-3 px-3 e-font-14 e-res-font-12"
                                                        name={fundData.option_name} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-8 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-2 pt-2 margin-auto-0px">
                                        <div className=" d-flex gap-2 mt-2 mt-md-0">
                                            <PrimaryButton name="Lumpsum"
                                                disabled={fundData.lumpsum_available === true ? false : true}
                                                className="w-100 py-xl-2 py-lg-2 py-md-2 py-sm-2 py-1 px-4 e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                                onPress={() => _handleOnClickInvest("lumpsum")}
                                            />
                                            <PrimaryButton name="SIP"
                                                disabled={fundData.sip_available === true ? false : true}
                                                className="py-xl-2 py-lg-2 py-md-2 py-sm-2 py-1 px-4 w-100"
                                                onPress={() => _handleOnClickInvest("sip")} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Performance Chart   */}
                            <div className="ps-0 margin-24px-bottom-res margin-40px-bottom">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                        <div className="e-over-flow-auto h-100">
                                            <div id="performance-chart-wrapper"
                                                className="h-100 d-flex flex-column e-bg-cultured e-border-radius-16  position-relative e-graph-wrapper" >

                                                {
                                                    performanceData["datasets"] ?
                                                        <>
                                                            <div id="chart-content" className="mb-3 p-4">
                                                                <span className={`${allTypeReturns[filterType - 1].fund >= 0 ? "e-text-ufo-green" : "e-text-red"}  e-font-20-res e-font-26 e-alt-font-poppins e-font-weight-500 line-height-30px pe-2`}>
                                                                    {(allTypeReturns[filterType - 1].fund).toFixed(2)}%
                                                                </span>
                                                                <span className="e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-30px">
                                                                    {performanceFilters[filterType - 1].period + " Returns"}
                                                                </span>
                                                            </div>

                                                            <div id="fund-chart"
                                                                ref={chartRef} className="p-4 pt-0 flex-grow-1 h-240">
                                                                {
                                                                    isChartLoading ? (
                                                                        <div style={{ height: chartRef.current.clientHeight }}
                                                                            className="text-center pt-lg-5">
                                                                            <img src={Loader}
                                                                                draggable="false" />
                                                                        </div>
                                                                    ) : (
                                                                        <AreaChart data={performanceData} tenure={filterType} />
                                                                    )
                                                                }
                                                            </div>
                                                            {/* filters */}
                                                            <div className="align-self-center d-flex align-items-center flex-wrap bg-white e-border-radius-16 px-3 py-2 mb-4" >
                                                                {
                                                                    performanceFilters.map((item, key) => (
                                                                        <p className={`${filterType === item.filter_type ? "e-text-egyptian-blue e-bg-lavender e-font-weight-600  e-border-radius-12"
                                                                            : "e-text-chinese_black e-font-weight-400 "} e-alt-font-poppins e-font-14 cursor-pointer px-3 py-2 mb-0`}
                                                                            key={key}
                                                                            onClick={() => _handleFilterTypeChange(item.filter_type)}>
                                                                            {item.period}
                                                                        </p>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="p-5 my-5 text-center">
                                                            <p className="py-5 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 mb-0" >
                                                                Fund Performance data not available!!
                                                            </p>
                                                        </div>

                                                }


                                            </div>
                                        </div>
                                    </div>
                                    {/* fund details */}
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pt-md-0 pt-4">
                                        <div className="h-100">
                                            <h3 className="mb-4 mb-sm-3 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600">
                                                Fund details
                                            </h3>
                                            <div className="d-flex flex-column gap-xl-5 gap-lg-5 gap-md-3 gap-sm-2 gap-2 p-4 e-bg-cultured e-border-radius-16 gap-res-5">
                                                <div className="row">
                                                    <FundDetails style={"col-xl-7 col-lg-7 col-md-12 col-6"}
                                                        title={`NAV  (${fundData.nav_date})`}
                                                        data={'₹' + fundData.nav} />
                                                    <FundDetails style={"col-xl-5 col-lg-5 col-md-12 col-6"}
                                                        title={"Fund size"}
                                                        data={'₹' + fundData.fund_size} />
                                                </div>
                                                <div className="row">
                                                    <FundDetails style={"col-xl-7 col-lg-7 col-md-12 col-6 "}
                                                        title={"Min SIP"}
                                                        data={'₹' + fundData.min_investment.min_invest_sip} />
                                                    <FundDetails style={"col-xl-5 col-lg-5 col-md-12 col-6 "}
                                                        title={"Min Lumpsum"}
                                                        data={"₹" + fundData.min_investment.min_invest} />

                                                </div>
                                                <div className="row">
                                                    <FundDetails style={"col-xl-7 col-lg-7 col-md-12 col-6"}
                                                        title={"Expense ratio"}
                                                        data={fundData.regular_expense} />
                                                    <FundDetails style={"col-xl-5 col-lg-5 col-md-12 col-6"}
                                                        title={"Stamp duty"}
                                                        data={fundData.stamp_duty} />
                                                </div>
                                                <div className="row align-sm-items-center">
                                                    <FundDetails style={"col-xl-7 col-lg-7 col-md-12 col-6"}
                                                        title={"Exit load"}
                                                        data={fundData.exit_load.exit_load_rate || "-"} />
                                                    {
                                                        fundData.risk_icon &&
                                                        <div className="col-xl-5 col-lg-5 col-md-12 col-6">
                                                            <img width={48}
                                                                src={fundData.risk_icon} alt="fund risk img"
                                                                draggable="false" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* fund managers */}
                            <div className="ps-0 mb-4">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                        <h3 className="mb-4 mb-sm-3 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600">
                                            Fund Managers
                                        </h3>
                                        <div className="d-flex flex-column gap-3 e-bg-cultured p-4 e-border-radius-16">
                                            {
                                                fundData.fund_manager.length > 0 ? (
                                                    fundData.fund_manager.map((item, i) => (
                                                        <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500"
                                                            key={i}>
                                                            <span className="pe-1">{i + 1}.</span>
                                                            <span>{item}</span>
                                                        </p>
                                                    ))
                                                ) : (
                                                    <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500">
                                                        No data found!
                                                    </p>
                                                )
                                            }
                                        </div>

                                        {/* Fund Calculator */}
                                        <div className="my-4">
                                            <h3 className="mb-4 mb-sm-3 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600">
                                                Return calculator
                                            </h3>
                                            <Calculator type={2}

                                                min_investment={fundData.min_investment}
                                                // returns_period={selectedTypeReturn.period}
                                                returns_percentage={selectedTypeReturn.fund}
                                            />
                                        </div>

                                    </div>

                                    {/* fund portfolio */}
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-4">
                                        <h3 className="mb-4 mb-sm-3 ps-1 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600">
                                            Fund portfolio
                                        </h3>
                                        <div className="p-4 e-bg-cultured e-border-radius-16">
                                            <div className="d-flex">
                                                {PORTFOLIO_TAB.map((data, key) => (
                                                    <CustomTab key={key}
                                                        tabData={data}
                                                        tabIndex={tabIndex}
                                                        handleChange={_handleTabChange} />
                                                ))}
                                            </div>
                                            <div className="d-flex flex-column gap-3 mt-3">
                                                <div className="row justify-content-between ">
                                                    <div className="col-auto">
                                                        <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                            Name
                                                        </p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-24px letter-spacing-neg-2 w-max-content">
                                                            % Assets
                                                        </p>
                                                    </div>
                                                </div>

                                                {
                                                    tabIndex === 1 ? (
                                                        <>
                                                            {fundData.portfolio.holdings.length === 0 ? (
                                                                <span className="e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500">
                                                                    No data found !
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    {fundData.portfolio.holdings.slice(0, 10).map((item, key) => (
                                                                        <div className="row justify-content-between align-items-center" key={key}>
                                                                            <div className="col-8">
                                                                                <p className="mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                                    {item.name}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <p className=" mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                                    {item.allocation}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    }

                                                                    {fundData.portfolio.holdings.length > 10 &&
                                                                        (<PrimaryButton className="text-start border-0 no-outline ps-0 bg-transparent e-text-egyptian-blue e-alt-font-poppins e-font-16 e-font-weight-500 line-height-24px letter-spacing-neg-2"
                                                                            name="View more"
                                                                            onPress={() => setShowPortfolioModal(true)}
                                                                        />
                                                                        )
                                                                    }
                                                                </>

                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {fundData.portfolio.sector.length === 0 ? (
                                                                <span className="text-center py-4 e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500">
                                                                    No data found !
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    {fundData.portfolio.sector.slice(0, 10).map((item, key) => (
                                                                        <div className="row justify-content-between align-items-center" key={key}>
                                                                            <div className="col-8">
                                                                                <p className="text-truncate mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                                    {item.name}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <p className=" mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                                    {item.allocation}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    }

                                                                    {
                                                                        fundData.portfolio.sector.length > 10 && (
                                                                            <PrimaryButton className="text-start border-0 no-outline ps-0 bg-transparent e-text-egyptian-blue e-alt-font-poppins e-font-16 e-font-weight-500 line-height-24px letter-spacing-neg-2"
                                                                                name="View more"
                                                                                onPress={() => setShowPortfolioModal(true)} />
                                                                        )
                                                                    }
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                showPortfolioModal == true &&
                                <FundPortfolioModal data={fundData.portfolio}
                                    tab={tabIndex}
                                    onClose={() => setShowPortfolioModal(false)} />
                            }
                            {
                                showInvestModal &&
                                <MFInvestModal fundSummary={fundSummary}
                                    availableInvestmentTypes={available_investment_types.current}
                                    investmentType={investmentType}
                                    onCloseModal={_handleCloseInvestModal} />
                            }
                            {
                                showPaymentModal &&
                                <PaymentStatusModal type={paymentModalType}
                                    investmentType="Fund"
                                    onClose={(() => setShowPaymentModal(false))} />
                            }
                        </div>
                    )
                }
            </div >
            <Footer />
        </>

    )
}

function FundDetails(props) {
    return (
        <div className={`${props.style} d-flex flex-column`}>
            <span className="e-font-14-res e-text-sonic-silver e-alt-font-poppins e-font-16 e-font-weight-400 line-height-28px ">
                {props.title}

            </span>
            <span className="e-font-14-res e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-28px w-max-content">
                {props.data}
            </span>
        </div>
    )

};

export default memo(Detail);