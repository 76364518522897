/*
 *   File : goal-summary.js
 *   Author : https://evoqins.com
 *   Description : Goal summary page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

//custom components
import { CustomAmountInput, CustomSlider } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import { DataTableComponent } from "../../Component/DataTable";
import { APIService } from "../../Service";
import { _checkUserAccess, _formatToRupees, _goalSummary, _setGoal } from "../../Helper";
import { BreadCrumbs } from "../../Component/Other";
import { GoalInvestModal, PaymentStatusModal } from "../../Component/Modal";
import GaugeComponent from "react-gauge-component";
import { Icon } from "../../Component/Icon";

// COLORS
import Colors from "../../Style/color.scss"

//assets
import Loader from "../../Assets/Images/coloured_loader.svg";


const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Life goals",
        path: "/goals"
    },
];



const GoalSummary = (props) => {

    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();

    const [sip, setSip] = useState(0);
    const [sipErrorMsg, setSipErrorMsg] = useState("");
    const [lumpsum, setLumpsum] = useState('0');
    const [lumpsumErrMsg, setLumpsumErrMsg] = useState("");
    const [tenure, setTenure] = useState(0);
    const [goalDetail, setGoalDetail] = useState({});

    const [goalSummary, setGoalSummary] = useState({});
    const [loading, setLoading] = useState(true);
    const [breadcrumbs, setBreadCrumbs] = useState([]);

    const [showInvestModal, setShowInvestModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentModalType, setPaymentModalType] = useState("");

    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);

    // data table column 
    const TABLE_COLUMNS = [
        {
            name: 'Fund Name',
            selector: row => row.scheme_name,
            width: "400px",
            style: {
                cursor: "pointer"
            }
        },
        {
            name: 'Allocation',
            selector: row => row.allocation + "%",
        },
        {
            name: 'SIP Amount',
            // selector: row => "₹" + _sipCalculation(row)
            cell: row => <div className="e-cell-sip">
                ₹{_sipCalculation(row).toLocaleString("en-IN")}
            </div>
        },
        {
            name: 'Lumpsum amount',
            // selector: row => _lumpsumCalculation(row),
            cell: row => <div className="e-cell-lumpsum">
                ₹{_lumpsumCalculation(row).toLocaleString("en-IN")}
            </div>
        },
    ];

    useEffect(() => {
        window.addEventListener('hashchange', () => {
            setShowInvestModal(false);
            setShowPaymentModal(false);
            navigate("/goals");
        });
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        _getGoalDetail();

    }, []);

    useEffect(() => {
        if (goalDetail["name"]) {
            setSip(goalDetail.calculated_sip);
            setLumpsum(goalDetail.lumpsum);
            setTenure(goalDetail.tenure);
            setBreadCrumbs([...BREADCRUMBS, { label: goalDetail.name }]);
        }
    }, [goalDetail]);

    useEffect(() => {
        if (showInvestModal == true) {
            let invest_modal = new Modal(document.querySelector("#invest-goal-modal"));
            invest_modal.show();
        };
    }, [showInvestModal]);

    useEffect(() => {
        if (showPaymentModal == true) {
            let payment_modal = new Modal(document.querySelector("#payment-status-modal"));
            payment_modal.show()
        }
    }, [showPaymentModal]);

    const _handleCloseInvestModal = (type) => {

        // Closing modal
        var button = document.getElementById("goal-invest-modal-close");
        button.click();

        setShowInvestModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };

    };

    const _handleFundClick = (data) => {

        navigate("/explore/fund-details", {
            state: {
                fund_id: data.mf_id,
            }
        })
    }

    //sip calculation
    const _sipCalculation = (row) => {
        let calculated_sip = sip * row.allocation / 100;
        return calculated_sip;
    }

    // lumpsum calculation 
    const _lumpsumCalculation = (row) => {
        let calculated_lumpsum = lumpsum * row.allocation / 100;
        return calculated_lumpsum;
    }

    //handle SIP value
    const _handleSip = (value) => {
        setSipErrorMsg("");
        setSip(value);
    }

    //handle Lumpsum value
    const _handleLumpsum = (value) => {
        setLumpsum(value);
        setLumpsumErrMsg("");
    }

    //handle tenure slider
    const _handleTenureSlider = (value) => {
        setTenure(value);
    }

    //handle invest modal
    const _handleInvestNow = (type) => {

        if (type === 2) {
            setLoader2(true);
        } else {
            setLoader1(true);
        };

        _handleInvestSet();
    };

    //API- Get home data 
    const _getGoalDetail = () => {

        let is_post = true;
        let url = 'goal/detail';
        let data = {
            "goal_id": state.goal_id
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setGoalDetail(response.data);
            } else {
                setGoalDetail({});
                navigate("/goals");
            }
            setLoading(false);
        });
    }

    //handle calculate 
    const _handleInvestSet = () => {

        if (sip === 0 && lumpsum === 0) {
            toast.dismiss();
            toast.error("Either SIP or Lumpsum is mandatory", { className: "e-toast" });
            setLoader1(false);
            setLoader2(false);
            return;
        }

        // if (lumpsum === 0 && (sip === 0 || sip === "")) {
        //     setSipErrorMsg(`Please provide the sip amount to proceed`);
        //     setLoader1(false);
        //     setLoader2(false);
        //     return;
        // };

        // if (props.isLumpsum === true && (lumpsum == 0 || lumpsum == "")) {
        //     setLumpsumErrMsg("Please provide the lumpsum amount to proceed");
        //     setLoader1(false);
        //     setLoader2(false);
        //     return;
        // };

        const params = {
            "goal_id": state.goal_id,
            "target": goalDetail.target_value,
            // "sip": sip,
            "sip": sip ? sip : null,
            "lumpsum": lumpsum ? lumpsum : null,
            "sip_tenure": tenure,
            "lumpsum_select": 1,
            "inflation_select": props.inflationSelect
        };

        _setGoal(params).then(status => {
            if (status.status_code === 200) {

                const user_access = _checkUserAccess();

                if (user_access === false || goalDetail.risk_score === null) {

                    toast.dismiss();

                    if (goalDetail.risk_score === null) {
                        toast.error("Please complete your risk profile to invest", {
                            className: "e-toast",
                        });
                    } else {
                        toast.error("Complete KYC to start with investment", {
                            className: "e-toast",
                        });
                    };

                    setLoader1(false);
                    setLoader2(false);
                    return;
                };

                _getGoalSummary();

            } else {
                toast.dismiss();
                toast.error(status.message, {
                    type: "error",
                    className: "e-toast",

                });
                setLoader1(false);
                setLoader2(false);
            }
        });
    };

    //API - get goal summary 
    const _getGoalSummary = () => {

        let param = {
            "goal_id": state.goal_id,
            "type": null,
        };

        _goalSummary(param).then(response => {
            if (response.status_code === 200) {
                setShowInvestModal(true);
                setGoalSummary(response.data);
            } else {
                setGoalSummary({});
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }

            setLoader1(false);
            setLoader2(false);
        })
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px pb-5">
                {
                    loading ?
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable={false}
                                alt="loader" />
                        </div>
                        :
                        <div className="col-lg-11 p-0 pb-4 pb-sm-5">
                            {/* breadcrumbs */}
                            <BreadCrumbs data={breadcrumbs} />

                            {/*goal flow */}
                            <div className="my-3 mb-4 my-sm-4">
                                <h4 className="e-font-16-res line-height-22px-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-500 letter-spacing-neg-2 line-height-30px">
                                    You are few steps away from creating {props.goal_name} goal
                                </h4>
                                <div className="col-12 col-sm-10 col-md-7 mt-3 mt-sm-4 pt-1 d-flex  justify-content-between align-items-center">
                                    <img src={require("../../Assets/Images/LifeGoals/success.svg").default}
                                        draggable="false"
                                        className="e-padding-30px-left-res padding-36px-left"
                                        alt="detail" />
                                    <div className="horizontal-rule"></div>
                                    <img src={require("../../Assets/Images/LifeGoals/suggestion-selected.svg").default}
                                        draggable="false"
                                        alt="suggestion" />
                                    <div className="horizontal-rule"></div>
                                    <img src={require("../../Assets/Images/LifeGoals/invest.svg").default}
                                        draggable="false"
                                        alt="invest" />
                                </div>

                                <div className="col-12 col-sm-10 col-md-7  d-flex justify-content-between">
                                    <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        Enter your details
                                    </p>
                                    <p className="e-goal-step margin-28px-neg-left mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        SIP Suggestion
                                    </p>
                                    <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        Invest
                                    </p>
                                </div>
                            </div>

                            <div className="p-md-4 p-3 e-border-radius-16 e-bg-cultured ">
                                <div className="e-inline-flex e-gap-0-12px">
                                    <img src={goalDetail.icon}
                                        draggable="false"
                                        className="e-border-radius-8"
                                        width={46}
                                        alt="house" />
                                    <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                        Life goal: {goalDetail.name}
                                    </p>
                                </div>
                                <div className="pt-2">
                                    <h6 className="e-font-14-res mb-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-30px">
                                        Recommended SIP amount
                                    </h6>
                                    <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        Based on the cost, tenure, inflation, and lumpsum amount, you must invest subsequent monthly SIP amounts to achieve your smart goal.
                                    </p>
                                    <p className="e-font-18-res mb-0 pt-3 e-text-egyptian-blue e-primary-font-inter e-font-26 e-font-weight-600">
                                        ₹ {_formatToRupees(goalDetail.calculated_sip)}
                                        <span className="e-font-12-res e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400">
                                            /per month
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {/* chart  */}
                            <div>
                                <h4 className="e-font-16-res pt-4 pb-4 mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                    Your wish to change amount
                                </h4>
                                <div className="p-xl-4 p-lg-4 p-md-4 p-sm-3 p-3 e-border-radius-16 e-bg-cultured ">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                            {/* sip input  */}
                                            <CustomAmountInput label='Monthly SIP amount'
                                                value={sip}
                                                maxLength={8}
                                                errorMsg={sipErrorMsg}
                                                handleChange={(value) => { _handleSip(value) }} />

                                            {/* lumpsum input  */}
                                            <div className="pt-0 pt-sm-3">
                                                <CustomAmountInput label='Lumpsum amount'
                                                    value={lumpsum}
                                                    maxLength={8}
                                                    errorMsg={lumpsumErrMsg}
                                                    handleChange={(value) => { _handleLumpsum(value) }} />
                                            </div>

                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                            {/* tenure slider  */}
                                            <div className="padding-24px-top-res padding-30px-top">
                                                <div className="e-display-flex justify-content-space-between">
                                                    <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                                        Tenure
                                                    </h4>
                                                    <p className="e-font-14-res mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                        {tenure} {tenure === 1 ? 'Yr' : 'Yrs'}
                                                    </p>
                                                </div>

                                                {/* tenure slider */}
                                                <div className="padding-30px-top">
                                                    <CustomSlider min={props.tenure.min}
                                                        max={props.tenure.max}
                                                        step={1}
                                                        defaultValue={tenure}
                                                        handleChange={(value) => _handleTenureSlider(value)} />
                                                </div>
                                                <div className="d-flex justify-content-between mt-3">
                                                    <span className="e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                                        {props.tenure.min} {props.tenure.min === 1 ? 'Yr' : 'Yrs'}
                                                    </span>
                                                    <span className="e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                                        {props.tenure.max} Yrs
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="pt-4 mt-2 col-sm-4 col-8">
                                            <PrimaryButton name="Invest now"
                                                loading={loader1}
                                                disabled={loader1}
                                                className="w-100 padding-12px-top padding-12px-bottom"
                                                onPress={_handleInvestNow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* gauge chart */}
                            <div className="px-0">
                                <div className="row pt-4">
                                    <div className="col-md-5 mb-4">
                                        <h4 className="e-font-16-res mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                            {goalDetail.risk_score?.score > 0 ? "Your risk score" : "Risk profile"}
                                        </h4>
                                        {
                                            goalDetail.risk_score?.score > 0 ?

                                                <div className='mt-4 e-border-radius-16 e-bg-cultured p-4 pt-0 text-center'>
                                                    <div className="position-relative">
                                                        <GaugeComponent type="semicircle"
                                                            arc={{
                                                                cornerRadius: 16,
                                                                colorArray: [Colors.iguana_green, Colors.orange_yellow, Colors.fuzzy_wuzzy_red],
                                                                padding: 0.02,
                                                                width: 0.17,
                                                                subArcs:
                                                                    [
                                                                        { limit: 50 },
                                                                        { limit: 70 },
                                                                        { limit: 80 },
                                                                    ]
                                                            }}
                                                            pointer={{ type: "blob", animationDelay: 0 }}
                                                            value={goalDetail.risk_score.score * 10} />
                                                        <p className="mb-0 margin-top-neg-56px  e-text-charleston-green e-font-52 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                                            {goalDetail.risk_score.score}
                                                        </p>
                                                        <p className="mb-0 pt-4 mt-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-30px">
                                                            {goalDetail.risk_score.score_text}
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {/* <h4 className="e-font-16-res mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                                    Risk profile
                                                </h4> */}
                                                    {/* check risk card */}
                                                    <div className="e-bg-magnolia-dark p-xl-4 p-lg-4 p-md-4 p-sm-3 p-3 e-border-radius-16">
                                                        <h6 className="e-font-16-res e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                                            What's your risk profile
                                                        </h6>
                                                        <p className="e-font-14-res mb-0 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-22px">
                                                            Import and export your external portfolio in just 5 minutes.
                                                        </p>
                                                        <div className="pt-3 pt-sm-4 mt-sm-2">
                                                            <PrimaryButton name="Check now"
                                                                className="px-3 py-2 e-primary-button-hover transition"
                                                                onPress={() => navigate("/risk-profiler")} />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>


                                    <div className="col-xl-7 col-lg-7 col-md-7">
                                        <h4 className="e-font-16-res mb-4 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                            Allocation based on your risk score
                                        </h4>

                                        {/* risk score */}
                                        <div className="e-border-radius-16 e-bg-cultured p-xl-4 p-lg-4 p-md-4 p-sm-3 p-3 ">
                                            <div className="d-flex justify-content-space-between padding-12px-bottom">
                                                <p className="mb-0 e-text-slate-gray e-font-14 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    0
                                                </p>
                                                <p className="mb-0 e-text-slate-gray e-font-14 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    100%
                                                </p>
                                            </div>

                                            {/* Allocation graph */}
                                            <div className="height-40px-res progress-stacked e-border-radius-12 h-48 mt-2 mb-4">
                                                {
                                                    goalDetail.suggestion.data[0].data.map((item, index) => (
                                                        <div style={{ width: `${item.allocation}%` }}
                                                            className="progress h-100"
                                                            role="progressbar"
                                                            key={index} >
                                                            <div style={{ backgroundColor: goalDetail.graph_data.colors[index] }}
                                                                className="progress-bar"></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className="">
                                                <div className="d-flex justify-content-space-between">
                                                    <div className="col-9">
                                                        <h6 className="pb-3 e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                            Name
                                                        </h6>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <h6 className="e-font-14-res mb-0 mb-3 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px letter-spacing-neg-2">
                                                            Allocation
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                goalDetail.suggestion.data[0].data.map((data, key) => (
                                                    <div className="pt-2" key={key}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="col-9 d-flex align-items-center gap-2 pb-0">
                                                                <Icon icon="bullet"
                                                                    color={goalDetail.graph_data.colors[key]}
                                                                    size={"1rem"} />
                                                                <p className="e-font-14-res mb-0 ps-sm-2  e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                    {data.fund_alias}
                                                                </p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                                    {data.allocation}%
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* data table */}
                            <div className="px-0 pt-4 mt-1">
                                <div className="e-border-radius-16 e-bg-cultured px-3 py-2 text-center e-goal-table">
                                    <DataTableComponent
                                        columns={TABLE_COLUMNS}
                                        data={goalDetail.suggestion.data[0].data}
                                        rowClick={_handleFundClick} />
                                </div>
                            </div>
                            <div className="text-center padding-40px-top mx-auto col-xl-4 col-lg-4 col-md-4 col-sm-6 col-8">
                                <PrimaryButton name="Invest now"
                                    loading={loader2}
                                    disabled={loader2}
                                    className="w-100 padding-12px-top padding-12px-bottom" onPress={() => _handleInvestNow(2)} />
                            </div>
                        </div>
                }
            </div>

            {/* goal invest modal */}
            {
                showInvestModal &&
                <GoalInvestModal
                    goalId={state.goal_id}
                    reinvest={false}
                    summary={goalSummary}
                    onCloseModal={_handleCloseInvestModal} />
            }

            {/* Payment status modal */}
            {
                showPaymentModal &&
                <PaymentStatusModal type={paymentModalType}
                    investmentType="Goal"
                    onClose={(() => setShowPaymentModal(false))} />
            }

        </>
    )
}
export default GoalSummary;