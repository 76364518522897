/*
 *   File : remove-family-confirmation.js
 *   Author : https://evoqins.com
 *   Description : family member removal confirmation modal
 *   Integrations : NA
 *   Version : 1.0.0
 *   Date : 24-05-2024
*/

import { memo, useState } from "react";
import { toast } from "react-toastify";

//custom component
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { APIService } from "../../Service";
import { useNavigate } from "react-router-dom";

const RemoveFamilyConfirmationModal = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const _handleRemoveFamily = () => {
        setLoading(true);
        _removeFamilyMember();
    };

    // API -  Removing family member from list
    const _removeFamilyMember = () => {

        let is_post = true;
        let url = "family/remove";
        let data = {
            "family_detail_id": props.familyId
        };

        APIService(is_post, url, data).then(response => {

            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Family member removed successfully", { className: "e-toast" })
                // Closing modal
                var close_button = document.getElementById("family-remove-confirmation-modal-close");
                close_button.click();
                navigate("/manage-family");
            } else {
                toast.dismiss();
                toast.success(response.message, { className: "e-toast" })
            }

            setLoading(false);
        })
    };

    return (
        <div className="modal fade"
            id="confirm-family-remove"
            tabIndex="-1"
            aria-labelledby="Confirm family member removal Modal Label"
            data-bs-backdrop="static"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-header justify-content-between pt-4  px-4 pb-0 border-0">
                        <h6 className='e-font-16-res mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            Confirm removal
                        </h6>
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='family-remove-confirmation-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="close"
                            alt='close btn'
                            onClick={props.onClose} />
                    </div>
                    <div className="modal-body px-4 pb-4 pt-3">
                        <p className='e-font-14-res mb-2 e-text-chinese-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            Are you sure you want to remove this member from family?
                        </p>
                        <div className="d-flex gap-3 mt-4">
                            <SecondaryButton name="No, Cancel"
                                dismiss="modal"
                                className="flex-fill py-2 e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                onPress={props.onClose} />
                            <PrimaryButton name="Yes, I want"
                                loading={loading}
                                disabled={loading}
                                className="py-2 flex-fill"
                                onPress={_handleRemoveFamily} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(RemoveFamilyConfirmationModal);