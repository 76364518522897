/*
 *   File : self-evaluation.js
 *   Author : https://evoqins.com
 *   Description :Self evaluation page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { SectionTitle } from '../../../Component/Title';
import { PrimaryButton } from '../../../Component/Buttons';
import { APIService } from '../../../Service';

// Assets
import Loader from "../../../Assets/Images/coloured_loader.svg";

const SelfEvaluation = (props) => {

    const [selectedRisk, setSelectedRisk] = useState(null);
    const [questionsList, setQuestionsList] = useState(null);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getRiskQuestions();
    }, []);

    useEffect(() => {
        _setDefaultRisk();
    }, [questionsList]);

    //handle select risk 
    const _handleSelectRisk = (data, question_id) => {
        let param = {
            question_id: question_id,
            answer_id: data.answer_id,
            answer: data.answer
        }
        setSelectedRisk(param);
    }

    // Set default data
    const _setDefaultRisk = () => {
        if (questionsList) {
            let defaultAnswer = null;
            questionsList[2].questions.map((item) => {
                if (item.question_type === 3) { // [type-1] => MCQ
                    for (const answer of item.answers) {
                        if (answer.is_answer) {
                            defaultAnswer = {
                                question_id: item.question_id,
                                answer_id: answer.answer_id,
                                answer: answer.answer
                            };
                            break; // Stop iterating once the default answer is found
                        }
                    }

                    if (defaultAnswer === null) {
                        defaultAnswer = {
                            question_id: item.question_id,
                            answer_id: item.answers[0]?.answer_id,
                            answer: item.answers[0]?.answer
                        };
                    }
                }
                setSelectedRisk(defaultAnswer);

            });

            setLoading(false);
        }
    };

    //evaluate risk 
    const _handleEvaluate = () => {
        setSaving(true);
        _saveAnswer();
    }

    // API - get questions
    const _getRiskQuestions = () => {
        let is_post = false;
        let url = "quiz/list-questions";
        APIService(is_post, url).then(response => {
            if (response.status_code === 200) {
                setQuestionsList(response.data);
                setLoading(false);
            } else {
                setQuestionsList(null);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });

                setLoading(false);
            }
        })
    };

    //API- Save answer
    const _saveAnswer = () => {
        let is_post = true;
        let url = 'quiz/save-answer';
        let data = {
            answers: [selectedRisk]
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSaving(false);
                props.onUpdateProfile(4, response.data);
            } else {
                setSaving(false);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
            }
        });
    };


    return (
        <>
            {loading ?
                // loader 
                <div className="min-h-100vh  text-center mt-5 pt-5" >
                    <img src={Loader}
                        draggable="false"
                        alt="loader" />
                </div>
                :
                <div>
                    {/* title */}
                    <SectionTitle title={questionsList[2].name} />
                    <div className='pt-3'>
                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            {questionsList[2].questions[0].question}
                        </p>

                        {/* Answers */}
                        <div className="overflow-hidden  e-border-radius-24">

                            <div className='mt-3 d-flex overflow-scroll e-manage-scrollbar e-self-evaluation justify-content-space-between py-3 px-3 px-sm-4 e-bg-anti-flash-white e-border-radius-24'>
                                {questionsList[2].questions[0].answers.map((data, key) => {
                                    return (
                                        <p key={key} className={`${selectedRisk?.answer_id === data.answer_id ? `e-bg-lavender e-text-egyptian-blue e-font-weight-600 ` : `e-font-weight-400  cursor-pointer e-text-chinese-black`} mb-0 px-3  py-2 e-border-radius-8  e-alt-font-poppins e-font-14 line-height-20px`}
                                            onClick={() => _handleSelectRisk(data, questionsList[2].questions[0].question_id)} >
                                            {data.answer}
                                        </p>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='d-flex justify-content-space-between padding-14px-top'>
                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-500 line-height-14px'>
                                Low-risk, Low- returns
                            </p>
                            <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-500 line-height-14px'>
                                High-risk, High-returns
                            </p>
                        </div>

                        {/* custom button */}
                        <div className='pt-4 mt-3'>
                            <PrimaryButton name={"Evaluate now"}
                                loading={saving ? true : false}
                                className="w-40 padding-12px-top padding-12px-bottom px-4 e-evaluate-btn"
                                onPress={_handleEvaluate} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SelfEvaluation;