/*
 *   File : explore.js
 *   Author : https://evoqins.com
 *   Description : Explore page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


//custom components
import { FilterModal, ExploreSortModal } from "../../Component/Modal";
import { FundCard } from "../../Component/Cards";
import { APIService } from "../../Service";
import { CustomTextInput } from "../../Component/Form-Elements";
import { Pagination } from "../../Component/Pagination";
import { Footer } from "../../Component/Footer";
import { BreadCrumbs, EmptyScreen, OverLay } from "../../Component/Other";
import { _getFilterData } from "../../Helper/api";

//assets
import NextIcon from "../../Assets/Images/right-black-icon.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";
import EmptyFunds from "../../Assets/Images/EmptyScreen/no-data.webp";

const ITEMS_PER_PAGE = 12;

const SORT_DATA = [
    {
        id: 1,
        label: "AUM - High to low"
    },
    {
        id: 2,
        label: "AUM - Low to High"
    },
    {
        id: 3,
        label: "Returns - High to low"
    },
    {
        id: 4,
        label: "Returns - Low to High"
    }
]

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Explore funds",
    },
]

const bodyNoScrollClass = 'body-no-scroll';


const Explore = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const initialRender = useRef(true);

    //get filter data
    const FILTER_DATA = useSelector(state => state.Reducer.FILTER_DATA);

    const [fundData, setFundData] = useState([]);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [toggleSort, setToggleSort] = useState(false);

    // const [currentFundItems, setCurrentFundItems] = useState(fundData);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);

    const [selectedAMCs, setSelectedAMCs] = useState([]);
    const [selectedFilterCategory, setSelectedFilterCategory] = useState([]);

    const [selectedSortType, setSelectedSortType] = useState(1);

    const [loading, setLoading] = useState(true);
    const [appliedFilters, setAppliedFilters] = useState(0);

    useEffect(() => {

        if (selectedAMCs.length !== 0 && selectedFilterCategory.length !== 0) {

            navigate(location.pathname, {
                state: {
                    "filter_by_amc": selectedAMCs,
                    "filter_by_category": selectedFilterCategory,
                    "sort_by": selectedSortType,
                },
                replace: true
            });
        };


        const selected_filters_count = [...selectedAMCs, ...selectedFilterCategory].filter((item) => item !== null).length;
        setAppliedFilters(selected_filters_count);

        if (initialRender.current == false) {
            setLoading(true);
            _filterFunds();
        };


    }, [selectedAMCs, selectedFilterCategory, selectedSortType]);

    // Setting filters when navigating from home page - "index fund category section"
    useEffect(() => {

        if (location.state !== null && location.state["category"]) {
            setLoading(true);
            setSelectedFilterCategory([location.state.category]);

        } else {
            if (initialRender.current == true) {
                setLoading(true);
                _filterFunds();
            };
        };

    }, [location.state]);

    // Calling api when user navigate from home page
    useEffect(() => {

        if (initialRender.current == true && selectedFilterCategory.length !== 0) {
            setLoading(true);
            _filterFunds();

        };

    }, [selectedFilterCategory]);

    useEffect(() => {
        window.addEventListener("load", () => {
            setSelectedAMCs(location.state !== null ? location.state.filter_by_amc : []);
            setSelectedFilterCategory(location.state !== null ? location.state.filter_by_category : []);
            setSelectedSortType(location.state !== null ? location.state.sort_by : 1);
        });
    }, []);

    useEffect(() => {
        if (FILTER_DATA) {
            setFilterData(FILTER_DATA);
        };
    }, [FILTER_DATA, filterData]);

    //scroll to top
    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

    }, [fundData]);

    useEffect(() => {
        if (initialRender.current == false) {
            if (searchValue.length !== 0) {
                setLoading(true);
                _searchFunds();
            } else {
                setLoading(true);
                _filterFunds();
            }

        };

        const selected_filters_count = [...selectedAMCs, ...selectedFilterCategory].filter((item) => item !== null).length;
        setAppliedFilters(selected_filters_count);

    }, [currentPage]);




    useEffect(() => {

        if (searchValue.length !== 0) {
            setLoading(true);
            _searchFunds();
        } else {
            if (initialRender.current === false && searchValue.length == 0) {
                setLoading(true);
                _filterFunds();
            };
        };

        if (searchValue.length === 0) {
            if (currentPage > 1) {
                setCurrentPage(1);
            }
        };

    }, [searchValue]);

    useEffect(() => {
        if (toggleFilter === true) {
            document.querySelector("#explore-filter").scrollIntoView({ top: 0, behavior: "smooth" });
        };

        if (toggleSort === true) {
            document.querySelector("#explore-sort").scrollIntoView({ top: 0, behavior: "smooth" });
        };

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setToggleFilter(false);
            setToggleSort(false);
        });

    }, [toggleFilter, toggleSort]);

    // handle paginating 
    const _handlePagination = (new_start_limit, new_end_limit) => {
        // setCurrentFundItems(fundData.slice(new_start_limit, new_end_limit));
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);
    };

    //handle sort submit
    const _handleSelectSort = (id) => {
        setSelectedSortType(id);
    };

    //handle toggle filter
    const _handleToggleFilter = () => {
        setToggleFilter((toggleFilter => !toggleFilter));
        setToggleSort(false);

        document.body.classList.add(bodyNoScrollClass);
    };

    //handle Close filter modal overlay
    const _handleCloseModal = () => {
        setToggleFilter(false);
        setToggleSort(false);
        document.body.classList.remove(bodyNoScrollClass);
    };

    // handle toggle sort 
    const _handleToggleSort = () => {
        setToggleSort((status) => !status);
        setToggleFilter(false);
        document.body.classList.add(bodyNoScrollClass);
    };

    //handle filter submit
    const _handleFilterSubmit = (selected_amcs, category_ids) => {

        setSelectedAMCs(selected_amcs);
        setSelectedFilterCategory(category_ids);
        setSearchValue(""); //clear search value
        setToggleFilter(false);
        setCurrentPage(1);
        document.body.classList.remove(bodyNoScrollClass);

        if (selected_amcs.length === 0 || category_ids.length === 0) {
            setLoading(false);
        }
    };

    //handle search
    const _handleSearch = (value) => {

        if (value.trim().length == 0) {
            setSearchValue("");
            return;
        };

        setSearchValue(value);

        setToggleFilter(false);
        setToggleSort(false);
    };

    //handle sort submit
    const _handleSortSubmit = (selected) => {
        setSelectedSortType(selected);
        setToggleSort((status) => !status);
        document.body.classList.remove(bodyNoScrollClass);
    };

    //API- Post Filter 
    const _filterFunds = () => {
        let is_post = true;
        let url = 'scheme/list';

        let data = {
            "page_num": currentPage,
            "page_size": ITEMS_PER_PAGE,
            "amc_code": selectedAMCs.every(item => item === null) ? [] : selectedAMCs,
            "scheme_name": searchValue,
            "scheme_category": selectedFilterCategory,
            "sort": selectedSortType
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFundData(response.data.funds);
                setTotalPageCount(Math.floor(response.data.total_items / ITEMS_PER_PAGE));
            } else {
                setFundData([]);
            }
            initialRender.current = false;
            setLoading(false);
        });
    };

    //API- Post Filter 
    const _searchFunds = () => {
        let is_post = true;
        let url = 'scheme/list';
        let data = {
            "page_num": currentPage,
            "page_size": ITEMS_PER_PAGE,
            "amc_code": [],
            "scheme_name": searchValue,
            "scheme_category": [],
            "sort": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFundData(response.data.funds);
                setTotalPageCount(Math.ceil(response.data.total_items / ITEMS_PER_PAGE));
            } else {
                setFundData([]);
            }
            setLoading(false);
        });
    };

    return (
        <>
            <div className={`${searchValue.length !== 0 && "pb-5"} row min-h-100vh e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px`}>
                <div className="col-lg-11 p-0">
                    {/* breadcrumbs */}
                    <BreadCrumbs data={BREADCRUMBS} />

                    {/* search funds  */}
                    <div className="row align-items-center padding-30px-top padding-24px-top-res">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div className="position-relative h-52 h-42-res">
                                <CustomTextInput id="explore-search"
                                    className="w-100 no-outline padding-14px-tb padding-56px-lr padding-32px-lr-res"
                                    placeholder="Search with fund name"
                                    inputWrapperClassName={"e-border-1px-bright-gray"}
                                    inputWrapperBorderStyle={"e-border-radius-16"}
                                    autoComplete="off"
                                    value={searchValue}
                                    handleChange={_handleSearch} />
                                <img src={require("../../Assets/Images/search-icon.svg").default}
                                    draggable={false}
                                    className="width-20px-res position-absolute top-50 translate-middle-y start-0 ms-2 ms-sm-3"
                                    alt="search" />

                                {
                                    searchValue &&
                                    <img src={require("../../Assets/Images/close-btn.svg").default}
                                        draggable={false}
                                        className="width-24px-res object-fit-contain position-absolute top-50 translate-middle-y end-0 me-2 me-sm-3 cursor-pointer"
                                        alt="clear"
                                        onClick={() => {
                                            setSearchValue("");
                                            setLoading(true);
                                        }} />
                                }
                            </div>
                        </div>

                        {
                            searchValue.length === 0 ?
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-start pt-lg-0 pt-md-0 pt-sm-0 pt-4 position-relative">

                                    <div id="explore-filter"
                                        className="d-flex justify-content-between justify-content-sm-start e-gap-0-16 e-filter-sort-wrapper">

                                        {/* filter funds  */}
                                        <div className="position-relative px-xl-4 px-lg-4 px-md-4 px-sm-4 px-3 cursor-pointer gap-0-12px e-inline-flex padding-10px-bottom padding-10px-top e-bg-anti-flash-white  e-border-radius-32"
                                            onClick={_handleToggleFilter}  >
                                            <p className="e-font-14-res mb-0 e-text-dark-liver e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                Filter
                                            </p>
                                            <img src={require("../../Assets/Images/down-arrow.svg").default}
                                                draggable="false"
                                                alt="arrow" />

                                            {
                                                appliedFilters !== 0 &&
                                                <span className="d-flex align-items-center justify-content-center width-24px h-24 e-font-10 e-bg-egyptian-blue e-text-white e-alt-font-poppins e-font-weight-400 e-border-radius-50per position-absolute top-0 start-50 margin-12px-left mt-1 translate-middle">
                                                    {appliedFilters}
                                                </span>
                                            }
                                        </div>

                                        {/* filter modal */}
                                        {
                                            toggleFilter &&
                                            <>
                                                <OverLay isOpen={toggleFilter} onClose={_handleCloseModal} />
                                                <div className="position-absolute w-520px right-0px top-50px z-index-9 p-4 e-border-radius-24 e-bg-white e-box-shadow-filter-modal e-filer-modal-wrapper"
                                                    onClick={(e) => e.stopPropagation()} >
                                                    <FilterModal data={filterData}
                                                        selectedType={selectedAMCs}
                                                        show={toggleFilter}
                                                        selectedCategory={selectedFilterCategory}
                                                        onSubmit={_handleFilterSubmit} />
                                                </div>
                                            </>
                                        }

                                        {/* sort funds  */}
                                        <div id="explore-sort"
                                            className="px-md-4 px-sm-4 px-3 cursor-pointer gap-0-12px d-flex padding-10px-bottom padding-10px-top e-bg-anti-flash-white  e-border-radius-32"
                                            onClick={_handleToggleSort} >
                                            <p className="e-font-14-res mb-0 e-text-dark-liver e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                Sort by
                                            </p>
                                            <img src={require("../../Assets/Images/down-arrow.svg").default}
                                                draggable="false"
                                                alt="arrow" />
                                        </div>

                                        {
                                            toggleSort &&
                                            <>
                                                <OverLay isOpen={toggleSort} onClose={_handleCloseModal} />
                                                <div className="position-absolute w-348px right-12px  top-50px z-index-9 p-4 e-border-radius-24 e-bg-white e-box-shadow-filter-modal e-sort-modal-wrapper"
                                                    onClick={(e) => e.stopPropagation()}>
                                                    <ExploreSortModal data={SORT_DATA}
                                                        selectedType={selectedSortType}
                                                        onCloseModal={_handleCloseModal}
                                                        show={toggleSort}
                                                        onSubmit={_handleSortSubmit} />
                                                </div>
                                            </>
                                        }

                                    </div>

                                </div>
                                :
                                <div className="col-12 pt-4">
                                    <h3 className="mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                        Search result
                                    </h3>
                                </div>
                        }
                    </div>

                    {
                        loading === true ?
                            <div className="min-h-100vh text-center mt-5" >
                                <img src={Loader}
                                    draggable="false"
                                    alt="loader" />
                            </div>
                            :
                            <>
                                {/* fund card  */}
                                {
                                    fundData.length !== 0 ?
                                        <div className="px-0">
                                            <div className={`${searchValue ? 'pt-sm-2' : 'pt-sm-3'} row  mt-3 mt-sm-4 `}>
                                                {fundData.map((data, key) => (
                                                    <div key={key} className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 margin-40px-bottom margin-8px-bottom-res">
                                                        <FundCard data={data} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        <EmptyScreen
                                            img={EmptyFunds}
                                            title="No funds found !"
                                            description="There is nothing to show right now." />

                                }

                                {/* Pagination  */}
                                {/* show pagination only for normal filter and sort. Hide it for search since page number and page sizes are being hardcoded in the code */}
                                {
                                    fundData.length > 0 &&
                                    <div className="e-display-flex justify-content-center e-gap-0-16 pt-4 mt-2 padding-92px-bottom">
                                        <Pagination nextButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                            prevButtonLabel={<img src={NextIcon} draggable="false" alt="Next" />}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            totalPage={totalPageCount}
                                            initialPage={currentPage - 1}
                                            onChange={_handlePagination}
                                            showFirstLastPages={true} />
                                    </div>
                                }
                            </>
                    }

                </div>
            </div >

            {/* footer  */}
            <Footer />
        </>
    )
}
export default Explore;