/*
 *   File : text-button.js
 *   Author : https://evoqins.com
 *   Description : Text button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useState } from "react";

//assets
import RightArrow from "../../Assets/Images/right-black-small.svg";
import RightBlue from "../../Assets/Images/right-blue-icon.svg";

const TextButton = (props) => {

    const [hover, setHover] = useState(false);

    return (
        <div className="e-inline-flex gap-0-6px cursor-pointer hover-arrow-wrapper"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={props.onClick} >
            <button className={`e-font-14-res no-border e-bg-transparent e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-14px`}>
                <span className={hover ? `e-border-bottom-1px-egyptian-blue e-text-egyptian-blue` : `e-border-bottom-transparent`}
                    onClick={props.onClick}  >
                    {props.name}
                </span>
            </button>
            <img src={hover ? RightBlue : RightArrow}
                className="hover-arrow"
                draggable="false"
                alt="arrow" />
        </div>
    )
}
export default TextButton;

TextButton.defaultProps = {
    onClick: () => { },
}