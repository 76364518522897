// for converting the numbers to rupees format
export const _formatToRupees = (val) => {
    var amount = Number(val).toFixed();
    amount = amount.toString();
    var last_three = amount.substring(amount.length - 3);
    var other_numbers = amount.substring(0, amount.length - 3);
    if (other_numbers !== '')
        last_three = ',' + last_three;
    var res = other_numbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + last_three;
    return res;
};

// for converting amount value to amount in words
export const _amountInWords = (val) => {
    let value = val;
    if (value >= 10000000) {
        value = (value / 10000000).toFixed(2) + 'crore';
    } else if (value >= 100000) {
        value = (value / 100000).toFixed(2) + 'L';
    } else if (value >= 1000) {
        value = (value / 1000).toFixed(2) + 'k';
    }
    return value;
};

export const _formatSipDate = (date) => {
    switch (date) {
        case 1: return "1st of every month";
        case 2: return "2nd of every month";
        case 3: return "3rd of every month";
        case 21: return "21st of every month";
        case 22: return "22nd of every month";
        case 23: return "23rd of every month";
        default: return date + "th of every month";
    };
};