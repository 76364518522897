/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : All BasketList component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


// Components
import { APIService } from "../../Service";
import { BreadCrumbs } from "../../Component/Other";
import { SectionTitle } from "../../Component/Title";
import { BasketCard } from "../../Component/Cards";
import { Footer } from "../../Component/Footer";

//Assets
import Loader from "../../Assets/Images/coloured_loader.svg";


// custom data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "Baskets",
    },
];

function Listing() {

    const navigate = useNavigate();
    const [basketsData, setBasketsData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    useEffect(() => {
        _getBaskets();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const _handleOnBasketClick = (basket_id) => {
        navigate("basket-details", {
            state: {
                "basket_id": basket_id,
            }
        });
    };

    //API- Get request for Baskets data 
    const _getBaskets = () => {
        let is_post = false;
        let url = 'home/get';

        APIService(is_post, url).then((response) => {
            if (response.status_code === 200) {
                setBasketsData(response.data.basket);
            } else {
                setBasketsData([]);
            }
            setIsDataLoading(false);
        });
    };

    return (
        <>
            <div
                className="min-h-100vh pb-4 pb-sm-5 row ps-4 e-padding-24-right-res e-bg-lotion d-flex flex-column  padding-80px-right padding-26px-top e-border-radius-TL-BL-24px padding-246-bottom ">
                <BreadCrumbs data={BREADCRUMBS} />
                {/* Basket items */}
                {isDataLoading ? (
                    <div className="text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>

                ) : (
                    <div className="p-0  mt-4">
                        <SectionTitle title="Invest in baskets" />
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 pb-5">
                            {basketsData.map((item, key) => (
                                <div className="mt-4 col " key={key}>
                                    <BasketCard data={item}
                                        onClick={() => _handleOnBasketClick(item.basket_id)} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>

    );
};

export default Listing;