/*
 *   File : radio-group.js
 *   Author : https://evoqins.com
 *   Description : Radio button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon';

// Importing Style
import Style from "../../Style/Component/radio.module.scss";
import Colors from "../../Style/color.scss";

const RadioGroup = (props) => {

    const [radioData, setRadioData] = useState([]);

    useEffect(() => {
        if (props.data) {
            setRadioData(props.data);
        }
    }, [props.data]);

    return (
        <>
            {
                props.type === 3 ?
                    radioData.map((data, key) => (
                        <div key={key}
                            className={`${props.selected === data.id ? Style.border_active : Style.border_inactive} e-display-flex gap-1 gap-sm-2  padding-8px-all-res padding-12px-all cursor-pointer`}
                            onClick={() => props.handleSelect(data.id)} >
                            <div className='d-flex gap-1'>
                                <Icon icon={data.icon}
                                    size={20}
                                    color={props.selected === data.id ? Colors.egyptian_blue : ""} />
                                <p className={`mb-0 ${props.selected === data.id ? 'e-text-egyptian-blue' : 'e-text-charleston-green'} e-font-14  e-alt-font-poppins e-font-weight-400 line-height-20px`}>
                                    {data.display_name}
                                </p>
                            </div>
                            <img src={props.selected === data.id ?
                                require("../../Assets/Images/circle-active.svg").default :
                                require("../../Assets/Images/circle-inactive.svg").default}
                                draggable={false}
                                width={20}
                                alt='radio' />
                        </div>
                    ))
                    :
                    props.type === 2 ?  //for question answer 
                        <>
                            {radioData.map((data, key) => (
                                <div key={key} className='e-display-flex gap-0-8 cursor-pointer'
                                    onClick={() => props.handleSelect(data.answer_id, props.question_id)} >
                                    <img src={props.selected === data.answer_id ?
                                        require("../../Assets/Images/circle-active.svg").default :
                                        require("../../Assets/Images/circle-inactive.svg").default}
                                        draggable={false}
                                        width={24}
                                        alt='radio' />
                                    <p className={`mb-0 ${props.selected === data.answer_id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver'} e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px`}>
                                        {data.answer}
                                    </p>
                                </div>
                            ))}
                        </>
                        :
                        <>
                            {radioData.map((data, key) => (
                                <div key={key} className='e-display-flex gap-0-8 cursor-pointer'
                                    onClick={() => props.handleSelect(data.id)} >
                                    <img src={props.selected === data.id ?
                                        require("../../Assets/Images/circle-active.svg").default :
                                        require("../../Assets/Images/circle-inactive.svg").default}
                                        draggable={false}
                                        className='w-24px width-20px-res'
                                        alt='radio' />
                                    <p className={`mb-0 ${props.selected === data.id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver'} e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px`}>
                                        {data.display_name}
                                    </p>
                                </div>
                            ))}
                        </>
            }
        </>
    )
}

export default RadioGroup;