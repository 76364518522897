/*
 *   File : edit-sip.js
 *   Author : https://evoqins.com
 *   Description : Edit Sip modal component
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/


import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryButton } from '../Buttons';
import { CustomAmountInput, CustomTextInput, OTPInput } from '../Form-Elements';
import { _formatSipDate } from '../../Helper/converter';
import { _getOTP } from '../../Helper';
import { APIService } from '../../Service';
import { SIPDateModal } from '.';
import Store from '../../Store/store';

const EditSIPModal = (props) => {

    const otpInputRef = useRef(null);
    const USER_DATA = Store.getState().Reducer.USER_DATA;

    const [sipAmount, setSipAmount] = useState("");
    const [sipAmountError, setSipAmountError] = useState("");

    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [selectedSIPDateString, setSelectedSIPDateString] = useState("");
    const [sipDateError, setSipDateError] = useState("");

    const [selectedSIPDate, setSelectedSIPDate] = useState(2);
    const [sipActiveDates, setSipActiveDates] = useState([]);

    // otp 
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [focusedInput, setFocusedInput] = useState(0);
    const [timer, setTimer] = useState(30);
    const [toggleOTP, setToggleOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP

    useEffect(() => {
        setSipAmount(props.data.amount);
        let selected_sip_date = Number.parseInt(props.data.next_sip_date);
        setSelectedSIPDate(selected_sip_date);

    }, [props.data]);

    useEffect(() => {
        setSipAmountError("");
    }, [sipAmount]);

    useEffect(() => {
        setOtpError("");
    }, [otpValue]);

    useEffect(() => {

        if (Object.keys(props.data).length !== 0) {
            let sip_dates = props.data.sip_date.split(",").map(Number);
            setSipActiveDates(sip_dates);
        }

        if (props.data.date !== null) {
            setSelectedSIPDate(props.data.date)
        }

    }, [props.data]);


    useEffect(() => {
        if (selectedSIPDate !== "") {
            const formatted_sip_date = _formatSipDate(selectedSIPDate);  // Formatting selected SIP date
            setSelectedSIPDateString(formatted_sip_date);
        };

    }, [selectedSIPDate]);

    //for timer
    useEffect(() => {
        if (toggleOTP === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        };
    }, [toggleOTP, timer]);

    useEffect(() => {

        if (toggleDateModal === true) {
            const sip_date_picker = document.querySelector("#sip-date-modal");
            sip_date_picker.scrollIntoView({ behavior: "smooth", block: "end" });
        };

    }, [toggleDateModal]);


    //handle SIP value
    const _handleSIPAmount = (value) => {
        setSipAmount(value);
    }

    //handle SIP date select
    const _handleSIPDateSelect = (date) => {

        setSipDateError("");
        setSelectedSIPDate(date);

        const updated_date = _formatSipDate(date);
        setSelectedSIPDateString(updated_date);
    };

    // handle close  modal 
    const _handleCloseDateModal = () => {
        setToggleDateModal(false);
    };

    const _handleBack = () => {
        setToggleOTP(false);
        setOtpError("");
    };


    //handle get otp
    const _handleGetOTP = () => {

        if (sipAmount.length === 0) {
            setSipAmountError("Enter SIP amount");
        }

        if (selectedSIPDate.length === 0) {
            setSipDateError("Select monthly SIP date");
        }

        if (sipAmount && selectedSIPDate) {
            setLoading(true);
            const param = {
                type: "Edit SIP"
            };

            _getOTP(param).then(status => {
                if (status.status_code === 200) {
                    setToggleOTP(true);
                    setLoading(false);
                    setTimer(30);
                } else {
                    toast.dismiss();
                    toast.error(status.message, {
                        className: "e-toast",

                    });
                };
            });
        }
    }

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
    };

    // handle RESEND otp
    const _handleResendOTP = () => {

        setDisableResendOTP(true);

        const param = {
            type: "Edit SIP"
        };

        _getOTP(param).then(status => {
            if (status.status_code === 200) {
                setOtpValue("");
                setOtpError("");
                setTimer(30);
                setFocusedInput(2);
                toast.dismiss();
                let message_medium = USER_DATA.phone.country_code == "+91" ? " mobile" : " email"
                toast.success(`OTP sent successfully to registered ${message_medium}`, {
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error("Something went wrong please try later", { className: "e-toast" })
            }

            setDisableResendOTP(false);

        });
    };

    // handle update SIP
    const _handleUpdateSIP = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };

        setLoading(true);
        _editSIP();
    }

    //handle close modal
    const _handleCloseEditModal = () => {
        var button = document.getElementById("edit-sip-modal-close");
        button.click();
    };

    const _handleOpenDateModal = () => {
        setToggleDateModal(status => !status);
    };

    //API- Edit SIP
    const _editSIP = () => {
        let is_post = true;
        let url = 'sip/edit';
        let data = {
            "sip_id": props.data.sip_id,
            "sip_date": selectedSIPDate,
            "amount": sipAmount,
            "type": null,
            "otp": otpValue
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoading(false);
                _handleCloseEditModal(); //modal close
                setToggleOTP(false);
                setOtpValue("");

                toast.dismiss();
                toast.success(response.message, {
                    className: "e-toast",
                });

                props.state();

            } else {
                setLoading(false)
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",

                });
                setToggleOTP(false);
            }
        });
    };



    return (
        <div className="modal fade e-manage-modal"
            id="edit-sip-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="editSipModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered modal-md">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <img src={require("../../Assets/Images/close-btn.svg").default}
                        draggable={false}
                        id="edit-sip-modal-close"
                        className='d-none'
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        alt='close' />
                    {toggleOTP === false ?
                        <>

                            <div className="modal-header justify-content-space-between pt-4  px-4 pb-3 border-0">
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Edit SIP
                                </h6>

                                <img src={require("../../Assets/Images/close-btn.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={() => {
                                        setToggleDateModal(false);
                                        props.onClose();
                                    }} />
                            </div>
                            <div className="modal-body p-4 pt-1">
                                <div className='e-bg-cultured e-border-radius-16 p-3'>
                                    <div className='e-display-flex gap-0-8'>
                                        <img src={props.data.icon}
                                            className='object-fit-contain e-border-radius-10 e-bg-white p-1'
                                            draggable={false}
                                            width={48}
                                            height={48}
                                            alt='item logo' />
                                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                            {props.data.name}
                                        </p>
                                    </div>
                                </div>

                                <div className='e-bg-cultured e-border-radius-16 p-3 mt-3'>

                                    {/* sip input  */}
                                    <CustomAmountInput label='Monthly SIP amount'
                                        value={sipAmount}
                                        maxLength={8}
                                        errorMsg={sipAmountError}
                                        handleChange={(value) => { _handleSIPAmount(value) }} />

                                    <div className='pt-3 position-relative'
                                        id='mf-sip-date-modal'>
                                        <CustomTextInput require={true}
                                            type={2}
                                            label='Monthly SIP date '
                                            value={selectedSIPDateString}
                                            placeholder="Choose date"
                                            className="cursor-pointer w-100 e-font-12 e-alt-font-poppins e-font-weight-500 no-outline"
                                            maxLength={8}
                                            readOnly={true}
                                            errorMsg={sipDateError}
                                            onClick={_handleOpenDateModal}
                                        />
                                        {
                                            toggleDateModal &&
                                            <SIPDateModal
                                                id="sip-date-modal"
                                                selectedSIPDate={selectedSIPDate}
                                                sipDates={sipActiveDates}
                                                className="position-absolute mt-sm-2"
                                                handleSelect={_handleSIPDateSelect}
                                                handleCloseDateModal={_handleCloseDateModal} />
                                        }
                                    </div>
                                </div>

                                <div className='padding-34px-top'>
                                    <PrimaryButton name="Get OTP"
                                        loading={loading}
                                        disabled={loading}
                                        className="w-100 padding-12px-top padding-12px-bottom"
                                        onPress={_handleGetOTP} />
                                </div>
                            </div>

                        </>
                        :


                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-4 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='sip-update-otp-modal'
                                    data-bs-dismiss="modal"
                                    className='visually-hidden'
                                    aria-label="close"
                                    alt='close btn' />

                                <img src={require("../../Assets/Images/Login/back-icon.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Update confirmation OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-4 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                    {
                                        USER_DATA.phone.country_code == "+91" ?
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered mobile number.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.phone.country_code} {USER_DATA.phone.number}
                                                </span>
                                            </p>
                                            :
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered email.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.email}
                                                </span>
                                            </p>
                                    }
                                    <div className='pt-3'>
                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <OTPInput ref={otpInputRef}
                                            id="otp"
                                            autoFocus={true}
                                            value={otpValue}
                                            spellCheck="false"
                                            focused_input={focusedInput}
                                            error={otpError}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                        <div className='e-inline-flex'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                disabled={disableResendOTP}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>


                                <div className='padding-36px-top'>
                                    <PrimaryButton name="Update"
                                        loading={loading}
                                        disabled={loading}
                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleUpdateSIP} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditSIPModal;