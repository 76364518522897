/*
 *   File : top-navigation.js
 *   Author : https://evoqins.com
 *   Description : Top navigation widget component
 *   Integrations : NA
 *   Version : 1.0.0
*/
import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Component
import { ProfileOptionsModal } from "../Modal";
import LeftNavigationWidget from "./left-navigation";


//styles
import styles from "../../Style/Component/navigation.module.scss";

//assets
import Notification from "../../Assets/Images/Navigation/notification.svg";
import Profile from "../../Assets/Images/Navigation/profile.svg";
import Down from "../../Assets/Images/Navigation/down-icon.svg";
import WavingIcon from "../../Assets/Images/Navigation/waving-icon.svg";
import Hamburger from "../../Assets/Images/menu.svg";

const TopNavigationWidget = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const USER_DATA = useSelector(store => store.Reducer.USER_DATA);
    const UNREAD_NOTIFICATION = useSelector(store => store.Reducer.UNREAD_NOTIFICATION);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [header, setHeader] = useState();
    const [activeProfileOptionId, setActiveProfileOptionId] = useState(-1);

    useEffect(() => {

        let path = location.pathname.split("/")[1];
        switch (path) {
            case "explore": {
                setHeader("Explore funds");
                break;
            };
            case "portfolio": {
                setHeader("Portfolio");
                break;
            };
            case "goals": {
                setHeader("Invest in life goals");
                break;
            };
            case "baskets": {
                setHeader("Baskets");
                break;
            };
            case "sip": {
                setHeader("Manage SIPs");
                break;
            };
            case "orders": {
                setHeader("Orders");
                break;
            };
            case "manage-family": {
                setHeader("Manage family");
                break;
            };
            case "profile": {
                setHeader("Account");
                break;
            };
            case "kyc": {
                setHeader("Account setup");
                break;
            };
            case "notifications": {
                setHeader("Notifications");
                break;
            };
            case "about-us": {
                setHeader("About us");
                break;
            };
            case "support": {
                setHeader("Support");
                break;
            };
            case "risk-profiler": {
                setHeader("Risk profiling");
                break;
            };
            case "report": {
                setHeader("Report");
                break;
            };

            default: return;

        }

    }, [location]);



    useEffect(() => {
        let active_tab_id = location.state !== null && location.state.optional_id ? location.state.optional_id : -1;
        setActiveProfileOptionId(active_tab_id);
    }, [location.state]);

    const _handleShowProfileModal = (event) => {
        setShowProfileModal(() => event == "hover" ? true : false)
    };

    const _handleHideProfileModal = () => {
        setShowProfileModal(false);
        document.body.style.overflow = 'unset';
    };

    const _handleCloseOffcanvas = () => {
        var button = document.getElementById("offcanvas-close");
        button.click();
    };




    return (
        <div className="row px-4 px-sm-5 ps-lg-4 pe-lg-0">
            <div className="col-lg-11 py-4 ">

                <div className="row">

                    <div className="offcanvas offcanvas-start p-0 width-75per-res"
                        data-bs-backdrop="static"
                        data-bs-scroll="false"
                        tabIndex="-1"
                        id="left-navigation-offcanvas"
                        aria-labelledby="offcanvasWithBothOptionsLabel">
                        <div className="e-over-flow-auto e-offcanvas-wrapper">
                            <div className="pe-3">
                                <div className="text-end pe-3 d-none">
                                    <button id="offcanvas-close"
                                        type="button"
                                        className="btn-close mt-2"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                                </div>
                                <LeftNavigationWidget type="offcanvas"
                                    handleLogout={props.handleLogout}
                                    _handleCloseOffCanvas={_handleCloseOffcanvas} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap align-items-center justify-content-between p-0">

                        {/* Offcanvas toggle button */}
                        <img src={Hamburger}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#left-navigation-offcanvas"
                            className="d-lg-none d-block cursor-pointer me-3 mt-1"
                            height={24}
                            width={24}
                            alt="menu" />

                        <div className="col e-top-navigation-wrapper">
                            <div className="d-flex py-lg-0  gap-0-10">
                                {
                                    USER_DATA && USER_DATA.name &&
                                    <h3 className="e-font-16-res pt-1 pt-lg-0 mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-700 line-height-24px">

                                        {
                                            location.pathname == "/" || location.pathname == "/home" ? (
                                                <div className="d-flex">
                                                    <img src={WavingIcon}
                                                        draggable={false}
                                                        className="padding-10px-right"
                                                        alt="wave" />
                                                    <span className="ps-1">Hello {USER_DATA && USER_DATA.name}, </span>
                                                </div>
                                            ) :
                                                (
                                                    <p className="first-letter-uppercase mb-0">
                                                        {header}
                                                    </p>
                                                )
                                        }

                                    </h3>
                                }
                            </div>
                        </div>

                        <div className="col-auto text-end ">
                            <div className="e-inline-flex">
                                <div
                                    id="notification"
                                    className="d-flex align-items-center position-relative cursor-pointer e-border-radius-42 padding-12px-all padding-8px-all-res"
                                    onClick={() => navigate("/notifications")}>
                                    <img
                                        src={Notification}
                                        draggable={false}
                                        className={`w-24px h-24 width-20px-res height-20px-res transition-02s `}
                                        alt="notification icon"
                                    />
                                    {
                                        UNREAD_NOTIFICATION !== 0 &&
                                        <span className="d-flex align-items-center justify-content-center width-24px h-24 e-font-10 e-bg-go-green e-text-white e-alt-font-poppins e-font-weight-400 e-border-radius-50per position-absolute top-0 end-0 translate-middle-y">
                                            {UNREAD_NOTIFICATION}
                                        </span>
                                    }
                                </div>

                                <div className="d-none d-lg-block position-relative profile e-border-radius-32 ms-sm-3"
                                    onMouseOver={() => _handleShowProfileModal("hover")}
                                    onMouseLeave={() => _handleShowProfileModal("leave")}>
                                    <div className={`${styles.e_profile} py-1 px-2 py-sm-2 px-sm-3 cursor-pointer transition-02s`}>
                                        <img src={Profile}
                                            draggable={false}
                                            className={`me-2 col-4 col-sm-auto`}
                                            alt="profile" />
                                        <img src={Down}
                                            className={`${showProfileModal == true ? "translateY-4px" : ""} col-5 col-sm-auto`}
                                            draggable={false}
                                            alt="arrow" />
                                    </div>
                                    {
                                        showProfileModal == true &&
                                        <ProfileOptionsModal activeOptionID={activeProfileOptionId}
                                            handleShowProfileModal={_handleHideProfileModal}
                                            handleLogout={props.handleLogout} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default memo(TopNavigationWidget);