/*
 *   File : first-investment.js
 *   Author : https://evoqins.com
 *   Description : first investment modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//Custom components
import { BasketCard } from '../Cards';
import { PrimaryButton, SecondaryButton } from '../Buttons';

//Assets
import Close from "../../Assets/Images/close-btn.svg";

const FirstInvestmentModal = (props) => {

    const navigate = useNavigate();

    const [selectedBasketId, setSelectedBasketId] = useState(6);

    // handle close modal 
    const _handleModalClose = () => {
        var button = document.getElementById("first-investment-modal-close");
        button.click();
        props.onCloseModal();
    };

    // handle select basket 
    const _handleSelectedBasket = (id) => {
        setSelectedBasketId(id);
    };

    // handle navigate basket detail 
    const _handleBasketDetail = () => {
        if (selectedBasketId !== null) {
            navigate("/baskets/basket-details", {
                state: {
                    "basket_id": selectedBasketId,
                }
            });
            _handleModalClose();
        } else {
            toast.dismiss();
            toast.error("Select basket  and proceed", { className: "e-toast", });
        }
    };

    // handle navigate explore page 
    const _handleNavigateExplore = () => {
        _handleModalClose();
        navigate("/explore");
    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="first-investment-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="firstInvestmentLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className={`e-bg-lotion modal-content e-border-radius-24 e-how-works-box-shadow `}>
                    <div className="modal-header border-0 justify-content-space-between pt-4 pb-0 px-4 ">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Let’s start your first investment
                        </h6>
                        <img src={Close}
                            id='first-investment-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={_handleModalClose}
                        />
                    </div>
                    {/* Body */}
                    <div className="modal-body pb-4 px-4 pt-3">
                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            Basket configuration is based on different investment preferences. Please select your preferences and proceed.
                        </p>

                        <div>
                            {props.basketData.map((data, key) => (
                                <div className="padding-12px-top col " key={key}>
                                    <BasketCard data={data}
                                        type="modal"
                                        selected={selectedBasketId}
                                        handleSelect={() => _handleSelectedBasket(data.basket_id)} />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Custom button */}
                    <div className="modal-footer border-0 p-4">
                        <PrimaryButton name="Invest now"
                            className="w-100  mx-0 mt-0 mb-3 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                            onPress={_handleBasketDetail} />
                        <SecondaryButton name="Skip and explore app"
                            className="m-0 w-100 padding-12px-top padding-12px-bottom e-border-bright-gray"
                            onPress={_handleNavigateExplore} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FirstInvestmentModal;
