/*
 *   File : profile-options.js
 *   Author : https://evoqins.com
 *   Description : profile navigation modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import { Icon } from "../Icon";
import Store from "../../Store/store";
import { _checkUserAccess } from "../../Helper";


// Styles 
import Colors from "../../Style/Component/card.module.scss";
import Styles from "../../Style/Component/modal.module.scss";

// Assets
import Profile from "../../Assets/Images/profile-icon.svg";

// Custom Data
// If you change label name please update the respected filter in useEffect
const OPTIONS = [
    // {

    //     icon: "external-fund",
    //     label: "External fund import",
    //     path: null,
    //     data: "",
    //     optional_id: "PROFILE-TAB-01"
    // },
    {

        icon: "nominee",
        label: "Manage nominee",
        path: "/profile",
        data: 2,
        optional_id: "PROFILE-TAB-02"
    },
    {
        icon: "manage-bank",
        label: "Manage bank",
        path: "/profile",
        data: 3,
        optional_id: "PROFILE-TAB-03"
    },
    {
        icon: "settings",
        label: "Settings",
        path: "/profile",
        data: 4,
        optional_id: "PROFILE-TAB-04"
    },
    {
        icon: "about",
        label: "About us",
        path: "/about-us",
        data: "",
        optional_id: "PROFILE-TAB-05"
    },
    {
        icon: "faq",
        label: "FAQ",
        path: "/faq",
        data: "",
        optional_id: "IFSH06"
    },
    {
        icon: "support",
        label: "Support",
        path: "/support",
        data: "",
        optional_id: "IFSH07"
    },
    {
        icon: "logout",
        label: "Logout",
        path: "/login",
        data: "",
    },
];


function ProfileOptionsModal(props) {

    const navigate = useNavigate();
    const userData = useSelector((store) => store.Reducer.USER_DATA);
    const [options, setOptions] = useState(OPTIONS);

    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        let edit_access = _checkUserAccess(); // will return boolean value
        if (edit_access === false) {
            let updated_options = options.filter(item => item.label !== "Manage nominee" && item.label !== "Manage bank");
            setOptions(updated_options);
        };
    }, []);


    const _handleProfileOptionClick = (data, id) => {

        if (data.label === "Logout") {
            props.handleLogout();
        } else {

            props.handleShowProfileModal();

            if (data.label === "FAQ") {
                window.open("https://indexfundssahihai.com/faq", "_blank");
            } else {
                if (data.path !== null) {

                    navigate(data.path, {
                        state: {
                            data: data.data,
                            "optional_id": id,
                        }
                    });
                }
            }

        };

    };

    return (
        <div className={`${Styles.e_profile_modal} position-absolute pt-3 z-index-99 top-100 end-0 e-res-w-max-content`}
            tabIndex="-1"
            aria-labelledby="profile-modal"
            aria-hidden="true">
            <div className={`p-3 e-bg-white e-border-radius-24 e-box-shadow-filter-modal `}>
                {/* User Info */}
                <div className="d-flex align-items-center gap-12px e-border-radius-16 padding-12px-all e-profile-gradient">
                    <img src={Profile}
                        alt="user-icon"
                        draggable="false" />
                    {
                        userData !== null &&
                        <div>
                            <p className="text-start e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px mb-1">
                                {userData.name}
                            </p>
                            <p className="text-start e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px mb-0">
                                User Id: {userData.customer_id}
                            </p>
                        </div>
                    }
                </div>
                <hr className="mt-3 mb-2 opacity-75 border-color-bright_gray" />

                {/* Navigation Options */}

                {
                    options.map((option, key) => (
                        <React.Fragment key={key}>
                            {key === options.length - 1 && (
                                <hr className="border-width-2px mt-3 mb-2 opacity-75 border-color-bright_gray" />
                            )}

                            <div id="profile-tab"
                                className={`${props.activeOptionID === option.optional_id && "e-bg-profile-tab"} cursor-pointer d-flex align-items-center padding-12px-all e-border-radius-16`}
                                key={key}
                                onClick={() => _handleProfileOptionClick(option, option.optional_id)}>
                                <div className="col-1">
                                    <Icon icon={option.icon} size={"1.25rem"}
                                        color={props.activeOptionID === option.optional_id ? Colors.egyptian_blue : Colors.charleston_green} />
                                </div>
                                <div className="col-10 text-start ps-3">
                                    <span className={`${props.activeOptionID === option.optional_id ? "e-text-egyptian-blue e-font-weight-500" : "e-text-charleston-green"}  e-alt-font-poppins e-font-16 e-font-weight-400 line-height-20px mb-0`}>
                                        {option.label}
                                    </span>
                                </div>

                                {key !== OPTIONS.length - 1 ? (
                                    <div className="col-1">
                                        <Icon icon={"right-black-icon"} size={"1.25rem"}
                                            color={props.activeOptionID === option.optional_id ? Colors.egyptian_blue : Colors.charleston_green} />
                                    </div>
                                ) : ""
                                }
                            </div>
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
};

export default memo(ProfileOptionsModal);


