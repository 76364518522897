/*
 *   File : left-navigation.js
 *   Author : https://evoqins.com
 *   Description : Left navigation widget component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Component
import { Icon } from "../Icon";

// assets 
import Logo from "../../Assets/Images/index-fund-logo.svg";

//style
import styles from "../../Style/Component/navigation.module.scss";
import Colors from "../../Style/Component/card.module.scss";


const LEFT_ITEMS = [
    {
        label: "Home",
        path: "/",
        child_path: ["/notifications", "/faq", "/about-us", "/support", "/kyc"],
        icon_name: "home-icon"
    },
    {
        label: "Explore",
        path: "/explore",
        child_path: [],
        icon_name: "explore"
    },
    {
        label: "Portfolio",
        path: "/portfolio",
        child_path: [],
        icon_name: "portfolio"
    },
    {
        label: "Life goals",
        path: "/goals",
        child_path: [],
        icon_name: "lifegoals"
    },
    {
        label: "Baskets",
        path: "/baskets",
        child_path: [],
        icon_name: "basket"
    },
    {
        label: "Orders",
        path: "/orders",
        child_path: [],
        icon_name: "orders"
    },
    {
        label: "Manage SIP",
        path: "/sip",
        child_path: [],
        icon_name: "sip"
    },
    {
        label: "Manage family",
        path: "/manage-family",
        child_path: [],
        icon_name: "manage-family"
    },
    {
        label: "Report",
        path: "/report",
        child_path: [],
        icon_name: "orders"
    },
    {
        label: "Risk profiler",
        path: "/risk-profiler",
        child_path: [],
        icon_name: "risk-profiling"
    },
    {
        label: "Account",
        path: "/profile",
        child_path: [],
        icon_name: "accounts"
    },
    {
        label: "Logout",
        path: "/login",
        child_path: [],
        icon_name: "logout"
    },
];

const LeftNavigationWidget = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const location_path = location.pathname.split("/")[1];

    const [list, setList] = useState(LEFT_ITEMS);

    useEffect(() => {
        if (props.type === "offcanvas") {
            setList(prevList => {
                const new_list = [...prevList];
                new_list.splice(new_list.length - 1, 0,
                    {
                        icon_name: "about",
                        label: "About us",
                        path: "/about-us",
                        child_path: [],
                    },
                    {
                        icon_name: "support",
                        label: "Support",
                        path: "/support",
                        child_path: [],
                    },
                );
                return new_list;
            });
        }
    }, [props.type]);

    const _handleOnLinkClick = (type, path) => {
        if (type == "Logout") {
            props.handleLogout();
        } else {
            navigate(path);
        };

        if (props.type === "offcanvas") {
            props._handleCloseOffCanvas();
        };
    };

    return (
        <div>
            <div className="d-flex justify-content-between ps-4 padding-32px-top">

                <img src={Logo}
                    draggable="false"
                    className="transform-scale-hover cursor-pointer"
                    alt="logo"
                    onClick={() => {
                        navigate("/")
                        if (props.type === "offcanvas") {
                            props._handleCloseOffCanvas();
                        }
                    }} />
                {
                    props.type === "offcanvas" &&
                    <div className="text-end pe-3">
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='offcanvas-close-btn'
                            draggable={false}
                            className='cursor-pointer mt-2'
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            alt='close' />
                    </div>
                }
            </div>
            <div className="ps-4 padding-32px-top">
                {
                    list.map((data, key) => (
                        <div key={key} className="margin-12px-bottom">
                            <div className={`padding-12px-top padding-12px-bottom d-flex e-gap-0-16 
                         ${`/${location_path}` === data.path ? `${styles.e_nav_item}` : data.child_path.includes(`/${location_path}`) == true ? `${styles.e_nav_item}` : `${styles.e_nav_item_hover} border-left-4px-transparent`} cursor-pointer`}
                                onClick={() => _handleOnLinkClick(data.label, data.path)}>
                                <Icon icon={data.icon_name}
                                    size={"1.125rem"}
                                    color={`/${location_path}` === data.path || data.child_path.includes(`/${location_path}`) == true ? Colors.egyptian_blue : Colors.charleston_green}
                                    className="ms-2" />
                                <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-20px">
                                    {data.label}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div >
    );
};

export default LeftNavigationWidget;