/*
 *   File : redeem.js
 *   Author : https://evoqins.com
 *   Description : Redeem investment modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { PrimaryButton } from "../Buttons";
import { CustomAmountInput, OTPInput } from "../Form-Elements";
import { Chip } from "../Other";
import { _getOTP } from "../../Helper";
import { Icon } from "../Icon";
import { APIService } from "../../Service";

// Assets
import Inactive from "../../Assets/Images/check-box.svg";
import ActiveCheckbox from "../../Assets/Images/check-box-active.svg";
import CloseBtn from "../../Assets/Images/close-btn-white.svg";

function RedeemModal(props) {

    const USER_DATA = useSelector((store) => store.Reducer.USER_DATA);
    const otpInputRef = useRef(null);

    const [summary, setSummary] = useState(props.summary);
    const [holdingsId, setHoldingsID] = useState(props.id);
    const [focusedInput, setFocusedInput] = useState(2);
    const [toggleInvest, setToggleInvest] = useState(false);
    const [redeemAmount, setRedeemAmount] = useState(0);
    const [redeemAll, setRedeemAll] = useState(true);
    const [redeemAmtErr, setRedeemAmtErr] = useState("");
    const [approxUnits, setApproxUnits] = useState("");

    // OTP
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);

    // For btn
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableAmtInput, setDisableAmtInput] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP

    useEffect(() => {
        setSummary(props.summary);
        setRedeemAmount(props.summary.redeemable_amount);
        setHoldingsID(props.id);

        // _handleApproxUnits(props.summary.redeemable_amount);
    }, [props.summary]);

    //for otp timer
    useEffect(() => {
        if (toggleInvest === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleInvest, timer]);

    useEffect(() => {
        if (redeemAll == true) {
            setRedeemAmount(props.summary.redeemable_amount);
            // _handleApproxUnits(props.summary.redeemable_amount);
            setRedeemAmtErr("");
            setDisableAmtInput(true);
        } else {
            setDisableAmtInput(false);
        };

    }, [redeemAll]);

    // handle calculation of unit
    useEffect(() => {
        let per_unit = props.summary.redeemable_amount / props.summary.units;
        let calculate_apporox_unit = redeemAmount / per_unit;
        if (redeemAmount > 0) {
            if (redeemAmount > props.summary.redeemable_amount) {
                setApproxUnits(0);
            } else {
                setApproxUnits(calculate_apporox_unit.toFixed(4));
            };
        } else {
            setApproxUnits(0);
        }
    }, [redeemAmount]);

    const _handleRedeemAmountChange = (value) => {

        setRedeemAmount(value);

        // Setting error onChange happens
        if (value <= 0) {
            setRedeemAmtErr("Please enter the amount");
            return;
        } else if (value > props.summary.redeemable_amount) {
            setRedeemAmtErr("Maximum redeemable amount ₹" + props.summary.redeemable_amount.toLocaleString("en-IN"));
            return;
        } else if (value < props.summary.min_withdrawal_amount) {
            setRedeemAmtErr("Minimum redeemable amount is ₹" + props.summary.min_withdrawal_amount.toLocaleString("en-IN"));
            return;
        } else {
            setRedeemAmtErr("");
        };

        // Toggling redeem all checkbox
        if (value == props.summary.redeemable_amount) {
            setRedeemAll(true);
        } else {
            setRedeemAll(false);
        };
    };

    // Handle Redeem all
    const _handleRedeemAll = () => {
        setRedeemAll((redeemAll) => !redeemAll);
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleInvest(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    const _handleModalClose = () => {
        toast.dismiss();
        setToggleInvest(false);
        props.onCloseModal();
    };

    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        let resend = true;
        _sendOTP(resend);

    };

    const _handleRedeemAmount = () => {

        if (redeemAmount <= 0) {
            setRedeemAmtErr("Please specify the amount you wish to redeem");
            return;
        } else if (redeemAmount > props.summary.redeemable_amount) {
            setRedeemAmtErr("Maximum redeemable amount is ₹" + props.summary.redeemable_amount.toLocaleString("en-IN"));
            return;
        } else if (redeemAmount < props.summary.min_withdrawal_amount) {
            setRedeemAmtErr("Minimum redeemable amount is ₹" + props.summary.min_withdrawal_amount.toLocaleString("en-IN"));
            return;
        } else {
            setRedeemAmtErr("");
        };

        // API Call for confirming redeem amount
        setLoading(true);
        _sendOTP();

    };

    const _handleRedeemNow = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };


        // btn loader
        setLoading(true);
        setIsDisabled(true);

        // API Call for redeem amount
        _redeem();
    };

    // Calculating Approx unit value based on input
    // const _handleApproxUnits = (value) => {
    //     let cur_nav = props.summary.cur_nav || 100;
    //     if (value <= props.summary.redeemable_amount) {
    //         let approx_units = value / cur_nav;
    //         setApproxUnits(approx_units)
    //     } else {
    //         setApproxUnits("-");
    //     };
    // };

    // API - send OTP 
    const _sendOTP = (resend) => {

        const param = {
            type: props.investmentType == "Fund" ? "MF" : "Goal"
        };

        _getOTP(param).then(status => {
            if (status.status_code === 200) {

                setOtpValue("");
                setTimer(30);
                setFocusedInput(2)
                setOtpError("");

                if (resend === true) {
                    toast.dismiss();
                    let message_medium = USER_DATA.phone.country_code == "+91" ? " mobile" : " email"
                    toast.success(`OTP sent successfully to registered ${message_medium}`, {
                        className: "e-toast",
                    });
                } else {
                    setToggleInvest(true);
                };

            } else {
                toast.dismiss();
                toast.error(status.message, {
                    className: "e-toast",

                });
            };
            setLoading(false);
            setDisableResendOTP(false);
        });
    };

    // API - Redeem final api 
    const _redeem = () => {
        let is_post = true;
        let url = "goal/redeem";
        let data = {
            "amount": redeemAmount,
            "redeem_all": redeemAll,
            "otp": Number(otpValue),
        };

        if (props.investmentType == "Fund") {
            url = "mf/redeem";
            data = {
                ...data,
                "mf_id": holdingsId,
            };
        } else {
            data = {
                ...data,
                "goal_id": holdingsId,
            }
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code == 200) {
                // Show Redeem Success modal
                props.onCloseModal("REDEEM_SUCCESS");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",


                });
            };
            setLoading(false);
            setIsDisabled(false);
        });
    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="redeem-invest-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="RedeemInvestLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                {
                    toggleInvest === false ?
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header border-0 justify-content-space-between py-3 px-4 ">
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Redeem
                                </h6>
                                <img src={CloseBtn}
                                    id='redeem-invest-modal-close'
                                    draggable={false}
                                    className='cursor-pointer'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={_handleModalClose}
                                />
                            </div>

                            {/* Body */}
                            <div className="d-flex flex-column  modal-body pb-4 px-4 pt-0">
                                <div className='d-flex flex-column gap-3 align-items-start e-bg-cultured e-border-radius-16 p-3 mb-3'>
                                    {
                                        props.investmentType == "Fund" ? (
                                            <div className="col d-flex gap-2 align-items-center">
                                                <img src={summary.icon}
                                                    width={48}
                                                    height={48}
                                                    className="object-fit-contain p-1 e-border-radius-10 me-1 e-bg-white"
                                                    draggable="false"
                                                    alt="fund logo" />
                                                <div className="d-flex flex-column">
                                                    <h3 className="e-ellipse e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                        {summary.name}
                                                    </h3>
                                                    <div className="d-flex gap-2">
                                                        <Chip name={summary.asset_category}
                                                            className="px-3 py-1 e-font-14" />
                                                        {
                                                            summary.option_name !== null &&
                                                            <Chip name={summary.option_name}
                                                                className="px-3 py-1 e-font-14" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex gap-0-12px align-items-center">
                                                <img src={summary.icon}
                                                    draggable={false}
                                                    className="object-fit-contain p-1 e-border-radius-10 me-1 e-bg-white"
                                                    width={40}
                                                    height={40}
                                                    alt='goal logo' />
                                                <div>
                                                    <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-16px'>
                                                        Life goal : <span>{summary.name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="d-flex flex-wrap w-100">
                                        <div className="col-7">
                                            <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                Units
                                            </p>
                                            <p className="e-text-charleston-green e-primary-font-inter e-font-14 e-font-weight-600 line-height-24px">
                                                {summary.units}
                                            </p>
                                        </div>
                                        <div className="col-5">
                                            <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                Invested.value
                                            </p>
                                            <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-14 e-font-weight-600 line-height-24px">
                                                ₹{summary.invested_amount.toLocaleString("en-IN")}
                                            </p>
                                        </div>

                                        <div className="col-7">
                                            <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                Redemption Time
                                            </p>
                                            <p className="mb-0 e-text-charleston-green e-primary-font-inter e-font-14 e-font-weight-600 line-height-24px">
                                                T+3 Working days
                                            </p>
                                        </div>
                                        <div className="col-5">
                                            <p className="mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                                Approx.value
                                            </p>
                                            <p className={`${summary.redeemable_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} mb-0 e-primary-font-inter e-font-14 e-font-weight-600 line-height-24px`}>
                                                ₹{summary.redeemable_amount.toLocaleString("en-IN")}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Amount to redeem */}
                                <div className="mb-2">
                                    <div className="d-flex justify-content-between my-2">
                                        <p className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Amount to redeem*
                                        </p>
                                        <span className="e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                            Approx. units :
                                            <span className="e-font-weight-500"> {approxUnits}</span>
                                        </span>
                                    </div>
                                    <CustomAmountInput require={false}
                                        className={redeemAll ? "opacity-6" : ""}
                                        labelStyle={"d-none"}
                                        value={redeemAmount}
                                        maxLength={8}
                                        errorMsg={redeemAmtErr}
                                        placeholder={"Amount to redeem"}
                                        isDisabled={disableAmtInput}
                                        handleChange={_handleRedeemAmountChange}
                                    />
                                </div>

                                {/* Redeem all  */}
                                <div>
                                    <div className='d-flex gap-2'>
                                        <img src={redeemAll === false ? Inactive : ActiveCheckbox}
                                            draggable={false}
                                            className='cursor-pointer'
                                            alt='checkbox'
                                            onClick={_handleRedeemAll} />
                                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                            Redeem all
                                        </p>
                                    </div>
                                    <p className='mt-1 mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                        Min. withdrawal amount is ₹{props.summary.min_withdrawal_amount.toLocaleString("en-IN")}
                                    </p>
                                </div>


                                {/* Additional Information */}
                                <div className="d-flex gap-2 mt-4 mb-1 e-bg-orange-yellow-opacity-06  e-border-radius-16 p-3">
                                    <Icon icon="info-circle"
                                        size="1.3rem" />
                                    <p className="mb-0 e-text-indian-yellow e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                                        As we don't support immediate withdrawal, your request will
                                        be sent to your concerned financial advisor. Once the admin confirms,
                                        the order will be triggered.
                                        OTP is required for withdrawal.
                                    </p>
                                </div>

                                <div className='mt-4'>
                                    <PrimaryButton name={"Request OTP"}
                                        loading={loading}
                                        disabled={loading}
                                        className="w-100 padding-12px-top padding-12px-bottom"
                                        onPress={_handleRedeemAmount} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-4 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='redeem-invest-modal-close'
                                    data-bs-dismiss="modal"
                                    className='visually-hidden'
                                    aria-label="close"
                                    alt='close btn'
                                />


                                <img src={require("../../Assets/Images/Login/back-icon.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Purchase confirmation OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-4 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                    {
                                        USER_DATA.phone.country_code == "+91" ?
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered mobile number.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.phone.country_code} {USER_DATA.phone.number}
                                                </span>
                                            </p>
                                            :
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered email.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {USER_DATA.email}
                                                </span>
                                            </p>
                                    }
                                    <div className='pt-3'>
                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <OTPInput ref={otpInputRef}
                                            id="otp"
                                            value={otpValue}
                                            spellCheck="false"
                                            error={otpError}
                                            focused_input={focusedInput}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                        <div className='e-inline-flex'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                disabled={disableResendOTP}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                {/* Mandate success msg for SIP */}

                                <div className='padding-36px-top'>
                                    <PrimaryButton name={"Redeem now"}
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleRedeemNow} />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};

export default memo(RedeemModal);