/*
 *   File : index-fund.js
 *   Author : https://evoqins.com
 *   Description : Learn how index fund works modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';

// Custom button 
import { PrimaryButton } from '../Buttons';
import { _amountInWords, _formatToRupees } from '../../Helper';

// Assets
import CloseBtn from "../../Assets/Images/close-btn-white.svg";

const IndexFundModal = (props) => {

    const navigate = useNavigate();

    const _handleModalClose = () => {
        props.onCloseModal();
        navigate("/explore");
    };

    return (
        <div className="modal fade e-manage-modal"
            id="index-fund-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="indexFundModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-bg-cultured e-border-radius-16">
                    <div className="modal-header justify-content-space-between pt-4 px-4 pb-0 border-0">
                        <h4 className='mb-0 e-alt-font-poppins e-text-charleston-green e-font-18 e-font-weight-600 line-height-32px'>
                            How does an index fund work?
                        </h4>
                        <img src={CloseBtn}
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onCloseModal} />
                    </div>
                    <div className="modal-body pb-4 px-4">
                        <p className='mb-3 e-alt-font-poppins e-text-onyx e-font-14 e-font-weight-400 line-height-22px'>
                            An index fund aims to match the performance of a specific market index,
                            like the S&P 500. It enables investors to invest in all or a sample of
                            the index's assets, offering diversification at a low cost.
                        </p>
                        <div>
                            <h4 className="e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                Returns comparison
                            </h4>
                            <p className="mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px">
                                *The estimated returns are determined by analyzing the historical returns of the mutual funds
                            </p>

                            <div className="row">
                                <div className="offset-lg-1 col-lg-10 col-12 pt-4">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                            <div>
                                                <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                    ₹{_amountInWords(356000)}
                                                </p>
                                                <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    Avg. 14.5% returns
                                                </p>
                                                <span className="vr opacity-10 w-60px e-bg-emerald" style={{ height: 80 }} />
                                                <p className="mb-0 e-text-egyptian-blue e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                    Alpha on Index
                                                </p>
                                            </div>
                                        </div>
                                        <div className="px-0 col-lg-6 col-md-6 col-sm-6 col-6 text-center d-flex e-flex-direction-column justify-content-end">
                                            <div>
                                                <p className="mb-1 e-text-charleston-green e-font-14 e-primary-font-inter e-font-weight-500 line-height-20px">
                                                    ₹ {_amountInWords(150000)}
                                                </p>
                                                <p className="mb-2 e-text-sea-green e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                    Avg. 11.5% returns
                                                </p>
                                                <span className="vr opacity-10  w-60px e-bg-lavender" style={{ height: 60 }} />
                                                <p className="mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                    Other mutual funds
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="pt-4 mt-2 text-center">
                                    <PrimaryButton name="Invest now"
                                        className="e-primary-button-hover transition padding-12px-top padding-12px-bottom w-80-per e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px"
                                        dismiss="modal"
                                        onPress={_handleModalClose} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexFundModal;