// Imports: Dependencies
import { takeEvery, put } from 'redux-saga/effects';

function* _logIn(data) {
    try {
        yield put({
            type: 'UPDATE_REFRESH_TOKEN',
            data: data.payload
        })
    } catch (error) {
    }
}

function* _signUP(data) {
    try {
        yield put({
            type: 'UPDATE_ACCESS_TOKEN',
            data: data.data
        })
    } catch (error) {
    }
}

function* _profile(data) {
    try {
        // Dispatch Action To Redux Store
        yield put({
            type: 'UPDATE_USER_DATA',
            data: data.data
        });
    }
    catch (error) {
    }
}

function* _general(data) {
    try {
        // Dispatch Action To Redux Store
        yield put({
            type: 'GENERAL_DATA',
            data: data.data
        });
    }
    catch (error) {
    }
}

function* _signupProgress(data) {
    try {
        yield put({
            type: 'SIGNUP_PROGRESS',
            payload: data.data,
        });
    }
    catch (error) {
    }
}

//get filter data
function* _filter(data) {
    try {
        yield put({
            type: 'EXPLORE_FILTER_DATA',
            payload: data.data,
        });
    }
    catch (error) {
    }
}

export function* loginData() {
    yield takeEvery('REFRESH_TOKEN', _logIn);
}

export function* signupData() {
    yield takeEvery('ACCESS_TOKEN', _signUP);
}

export function* profileData() {
    yield takeEvery('USER_DATA', _profile);
}

export function* generalBnkData() {
    yield takeEvery('GENERAL_DATA', _general);
}

export function* signup() {
    yield takeEvery('SIGNUP_PROGRESS_DATA', _signupProgress);
}

export function* filterData() {
    yield takeEvery('FILTER_DATA', _filter);
}


