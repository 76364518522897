/*
 *   File : manage-bank.js
 *   Author : https://evoqins.com
 *   Description : Add Bank modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom component
import { PrimaryButton } from '../Buttons';
import { CustomTextInput } from '../Form-Elements';
import { APIService } from '../../Service';
import { _getProfile } from '../../Helper';
import FindIFSCModal from './find-ifsc';
import SearchBankModal from './search-bank';
import SearchBranchModal from './search-branch';

const AddBankModal = (props) => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [reConfirmAccountNumber, setReConfirmAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [reConfirmAccountNumberError, setReConfirmAccountNumberError] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");
    const [accType, setAccType] = useState(40);
    const [accDetails, setAccountDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const [ifscModal, setIfscModal] = useState(false);

    const [bankSearchModal, setBankSearchModal] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});

    const [branchSearchModal, setBranchSearchModal] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState({});

    const [bankList, setBankList] = useState([]);
    const [bankDataSearch, setBankDataSearch] = useState("");
    const [bankDataLoading, setBankDataLoading] = useState(true);

    const [branchList, setBranchList] = useState([]);
    const [branchSearch, setBranchSearch] = useState("");
    const [branchDataLoading, setBranchDataLoading] = useState(true);

    useEffect(() => {
        if (props.data.bank_account_types) {
            setAccountDetails(props.data.bank_account_types);
        }
    }, [props.data, accDetails]);


    useEffect(() => {
        if (Object.keys(props.accountData).length !== 0) {
            setName(props.accountData.account_holder_name);
            setAccountNumber(props.accountData.account_number);
            setReConfirmAccountNumber(props.accountData.account_number);
            setAccType(props.accountData.account_type_id);
            setIfsc(props.accountData.ifsc);
        }
    }, [props.accountData]);

    useEffect(() => {
        if (bankSearchModal === true) {
            _getBank();
            setBankDataLoading(true);
        }
    }, [bankDataSearch, bankSearchModal]);

    useEffect(() => {
        if (branchSearchModal === true) {
            _getBranch();
            setBranchDataLoading(true);
        }
    }, [branchSearch, branchSearchModal]);

    useEffect(() => {
        if (ifscModal === true) {
            let ifsc_modal = new Modal(document.getElementById('find-ifsc-modal'));
            ifsc_modal.show();
        };
    }, [ifscModal]);

    useEffect(() => {
        if (bankSearchModal === true) {
            let bank_modal = new Modal(document.getElementById('bank-search-modal'));
            bank_modal.show();
        };
    }, [bankSearchModal]);

    useEffect(() => {
        if (branchSearchModal === true) {
            let branch_modal = new Modal(document.getElementById('branch-search-modal'));
            branch_modal.show();
        };
    }, [branchSearchModal]);

    //handle change account type
    const _handleAccountType = (id) => {
        setAccType(id);
    };

    //handle re-confirm acc number
    const _handleReconfirmACNumber = (e) => {
        if (e.length < 19) {
            setReConfirmAccountNumber(e);
            setReConfirmAccountNumberError("");
        }
    };

    //handle confirm acc number
    const _handleConfirmACNumber = (e) => {
        if (e.length < 19) {
            setAccountNumber(e);
            setAccountNumberError("");
        }
    };

    // handle open find ifsc modal 
    const _handleIfscModal = () => {
        setIfscModal(true);
    };

    // handle open bank search modal 
    const _handleBankSearchModal = () => {
        setBankSearchModal(true);
    };

    // handle select bank 
    const _handleSelectBank = (selected) => {
        setSelectedBank(selected);
    };

    // handle open branch search modal 
    const _handleBranchSearchModal = () => {
        setBranchSearchModal(true);
    };

    // handle select branch 
    const _handleSelectBranch = (selected) => {
        setSelectedBranch(selected);
    };

    // handle ifsc 
    const _handleFind = (data) => {
        setIfsc(data.ifsc);
    };

    //handle submit update bank
    const _handleSubmit = () => {
        let is_valid = true;

        if (name.length === 0) {
            setNameError("Please enter a name with at least 2 characters");
            is_valid = false;
        }
        if (accountNumber.length === 0) {
            setAccountNumberError("Please enter a valid account number");
            is_valid = false;
        }

        if (reConfirmAccountNumber.length === 0) {
            setReConfirmAccountNumberError("Please confirm your account number");
            is_valid = false;
        } else {
            if (accountNumber !== reConfirmAccountNumber) {
                setReConfirmAccountNumberError("Account number is not matching");
                is_valid = false;
            };
        };

        if (ifsc.length === 0) {
            setIfscError("Enter IFSC code");
            is_valid = false;
        }

        if (is_valid === true) {
            setLoading(true);
            _postUpdateBank();
        }
    };

    //handle modal close
    const _handleManageBankClose = (status) => {
        var button = document.getElementById("edit-bank-close");
        button.click();
        // Changing modal open status
        props.onCloseModal(status);
    };

    //API- Post update bank 
    const _postUpdateBank = () => {
        let is_post = true;
        let url = 'profile/bank/update';
        let data = {
            "account_number": accountNumber,
            "account_holder_name": name,
            "account_type": accType,
            "ifsc": ifsc,
            // "is_joint_account": false
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _getProfile(); //getProfile

                //handle modal close
                _handleManageBankClose("SUCCESS");

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }
            setLoading(false);
        });
    };

    //API- Get bank list
    const _getBank = () => {
        let is_post = true;
        let url = 'general/banks';
        let data = {
            "query": bankDataSearch.length !== 0 ? bankDataSearch : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBankList(response.data);
            } else {
                setBankList([]);
            }
            setBankDataLoading(false);
        });
    };

    //API- Get branch list
    const _getBranch = () => {
        let is_post = true;
        let url = 'general/branches';
        let data = {
            "bank_id": selectedBank.id,
            "query": branchSearch.length !== 0 ? branchSearch : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBranchList(response.data);
            } else {
                setBranchList([]);
            }
            setBranchDataLoading(false);
        });
    };

    return (
        <div className="modal fade e-manage-modal"
            id="addBankModal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="addBankModalLabel"
            aria-hidden="true">
            <div className={`${ifscModal === true ? 'invisible' : ''} modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable `}>
                <div className="modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow">
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-2 border-0">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Edit bank details
                        </h6>

                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='edit-bank-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={_handleManageBankClose} />
                    </div>
                    <div className="modal-body pb-3 px-4 pt-2 h-max-500px overflow-auto">

                        {/* Account number */}
                        <div className='h-100px'>
                            <CustomTextInput label="Account number*"
                                placeholder="9998010817668"
                                type="password"
                                style="number"
                                className="p-3 w-100"
                                maxLength={18}
                                value={accountNumber}
                                errorMsg={accountNumberError}
                                handleChange={_handleConfirmACNumber} />
                        </div>

                        {/* Confirm account number */}
                        <div className='h-100px'>
                            <CustomTextInput label="Confirm account number*"
                                placeholder="9998010817668"
                                type="password"
                                style="number"
                                maxLength={18}
                                className="p-3 w-100"
                                value={reConfirmAccountNumber}
                                errorMsg={reConfirmAccountNumberError}
                                handleChange={_handleReconfirmACNumber} />
                        </div>

                        {/* Account holder name */}
                        <div className='h-100px'>
                            <CustomTextInput label="Account holder name*"
                                placeholder="Jhon Doe"
                                type="text"
                                value={name}
                                errorMsg={nameError}
                                className="w-100 p-3"
                                handleChange={(e) => {
                                    setName(e);
                                    setNameError("");
                                }} />
                        </div>

                        {/* Account type radio */}
                        <div className='pt-1 '>
                            <h6 className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                Account Type*
                            </h6>
                            <div className='d-flex gap-0-12px e-flex-wrap '>
                                {accDetails.map((data, key) => (
                                    <div key={key} className='e-display-flex cursor-pointer mb-1'
                                        onClick={() => _handleAccountType(data.id)} >
                                        <img src={accType === data.id ?
                                            require("../../Assets/Images/circle-active.svg").default : require("../../Assets/Images/circle-inactive.svg").default}
                                            draggable={false}
                                            className='me-2'
                                            alt='radio' />
                                        <p className={`${accType === data.id ? `e-text-egyptian-blue` : `e-text-sonic-silver `} mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                            {data.display_name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* IFSC code */}
                        <div className='h-100px pt-2'>
                            <CustomTextInput label="IFSC code*"
                                placeholder="FDRL556023"
                                type="text"
                                value={ifsc}
                                errorMsg={ifscError}
                                className="w-100 p-3"
                                handleChange={(e) => {
                                    setIfsc(e);
                                    setIfscError("");
                                }} />
                        </div>
                        {/* find ifsc  */}
                        <p className='mb-4 mb-sm-0 text-end'>
                            <span className='e-text-indigo e-font-14 e-alt-font-poppins e-font-weight-600 line-height-14px e-font-14-res cursor-pointer text-decoration-hover-underline'
                                onClick={_handleIfscModal}>
                                Find IFSC code
                            </span>
                        </p>
                    </div>
                    <div className='row pt-2 px-4 pb-4 mt-1'>
                        <div className='col-6'>
                            <PrimaryButton name="Cancel"
                                className="w-100 e-bg-lotion e-border-bright-gray e-text-charleston-green padding-12px-top padding-12px-bottom"
                                dismiss="modal"
                                onPress={_handleManageBankClose} />
                        </div>
                        <div className='col-6'>
                            <PrimaryButton name="Update"
                                loading={loading}
                                className="w-100 padding-12px-top padding-12px-bottom"
                                onPress={_handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>

            {/* find ifsc modal  */}
            {ifscModal === true &&
                <FindIFSCModal type="modal"
                    selectedBank={selectedBank}
                    selectedBranch={selectedBranch}
                    bankSearchModal={_handleBankSearchModal}
                    branchSearchModal={_handleBranchSearchModal}
                    onModalClose={() => {
                        setIfscModal(false);
                        setSelectedBank({});
                        setSelectedBranch({});
                    }}
                    handleFind={_handleFind} />
            }

            {/* bank search modal  */}
            {bankSearchModal === true &&
                <SearchBankModal data={bankList}
                    type="modal"
                    selectedBank={selectedBank}
                    bankLoading={bankDataLoading}
                    searchValue={bankDataSearch}
                    handleClearSearch={() => setBankDataSearch("")}
                    handleSearch={(value) => setBankDataSearch(value)}
                    handleSelectedBank={_handleSelectBank}
                    onModalClose={() => {
                        setBankSearchModal(false);
                        setBankDataSearch("");
                    }} />
            }

            {/* branch search modal  */}
            {branchSearchModal === true &&
                <SearchBranchModal data={branchList}
                    type="modal"
                    selectedBranch={selectedBranch}
                    branchLoading={branchDataLoading}
                    searchValue={branchSearch}
                    handleClearSearch={() => setBranchSearch("")}
                    handleSearch={(value) => setBranchSearch(value)}
                    handleSelectedBranch={_handleSelectBranch}
                    onModalClose={() => {
                        setBranchSearchModal(false);
                        setBranchSearch("");
                    }} />
            }

        </div>
    )
}

export default AddBankModal;


