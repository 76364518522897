/*
 *   File : invested-basket.js
 *   Author : https://evoqins.com
 *   Description : Invested basket detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom components
import { BreadCrumbs } from "../../Component/Other";
import { PrimaryButton, SecondaryButton, TextButton } from '../../Component/Buttons';
import { Footer } from '../../Component/Footer';
import { APIService } from '../../Service';
import { SectionTitle } from '../../Component/Title';
import { _goalSummary } from '../../Helper';
import { GoalInvestModal, PaymentStatusModal, RedeemModal } from '../../Component/Modal';

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Portfolio",
        path: "/portfolio"
    },
];

function InvestedBasket() {

    const location = useLocation();
    const navigate = useNavigate();

    const [basketId, setBasketId] = useState("");
    const [basketData, setBasketData] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [breadcrumbs, setBreadcrumbs] = useState(BREADCRUMBS);

    // Reinvest
    const [basketSummary, setBasketSummary] = useState({});
    const [showInvestModal, setShowInvestModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentModalType, setPaymentModalType] = useState(0);

    // Redeem
    const [redeemSummary, setRedeemSummary] = useState({});
    const [showRedeemModal, setShowRedeemModal] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            setBasketId(location.state.id)
        } else {
            navigate("/portfolio");
        };
    }, [location.state]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (basketId !== "") {
            _getBasketDashboardDetails();
        };

    }, [basketId]);

    useEffect(() => {
        if (showInvestModal !== true) return;
        new Modal(document.getElementById('invest-goal-modal')).show();
    }, [showInvestModal]);

    useEffect(() => {
        if (showPaymentModal !== true) return;
        new Modal(document.querySelector("#payment-status-modal")).show();
    }, [showPaymentModal]);

    useEffect(() => {
        if (showRedeemModal !== true) return;
        new Modal(document.getElementById('redeem-invest-modal')).show();
    }, [showRedeemModal]);

    const _handleOnFundClick = (id) => {
        let path = location.pathname === "/explore" ? "fund-details" : "/explore/fund-details";
        navigate(path, {
            state: {
                fund_id: id,
            }
        });
    };

    const _handleNavigate = () => {
        navigate("/sip", { replace: true });
    };


    const _handleCloseInvestModal = (type) => {

        // Closing modal
        let button = document.getElementById("goal-invest-modal-close");
        button.click();
        setShowInvestModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };
    };

    const _handleCloseRedeemModal = (type) => {
        // Closing modal
        let button = document.getElementById("redeem-invest-modal-close");
        button.click();
        setShowRedeemModal(false);

        if (type) {
            setShowPaymentModal(true);
            setPaymentModalType(type);
        };
    };

    // Handle investmore on holdings
    const _handleInvestMore = () => {

        let param = {
            "goal_id": basketId,
            "type": "REINVEST",
        };

        _goalSummary(param).then(response => {
            if (response.status_code === 200) {
                setShowInvestModal(true);
                setBasketSummary(response.data);
            } else {
                setBasketSummary({});
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
        });
    };

    // Handle redeem on holdings
    const _handleRedeemInvest = () => {
        let is_post = true;
        let url = 'goal/redeem-overview';
        let data = {
            "goal_id": basketId
        };

        APIService(is_post, url, data).then(response => {
            if (response.status_code == 200) {
                setRedeemSummary(response.data);
                setShowRedeemModal(true);
            } else {
                setRedeemSummary({});
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            };
        });
    };

    // API - Post selected basket details
    const _getBasketDashboardDetails = () => {
        let is_post = true;
        let url = 'dashboard/goal';
        let data = {
            "goal_id": basketId,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBasketData(response.data);
                setBreadcrumbs([...BREADCRUMBS, { label: response.data.name }]);
            } else {
                setBasketData({});
            }
            setIsDataLoading(false);
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px" >
                {/* breadcrumbs */}
                <BreadCrumbs data={breadcrumbs} />

                {isDataLoading ? (
                    <div className="min-h-100vh  text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>
                ) : (
                    <div className='col-lg-11 pt-3 mt-1 px-0'>
                        <div className='e-bg-cultured e-border-radius-16 p-4'>
                            <div className='row gap-3 justify-content-between align-items-center'>
                                <div className='col-lg-6'>
                                    <div className='e-display-flex gap-0-12px '>
                                        <img src={basketData.icon}
                                            className='object-fit-contain e-border-radius-10 e-bg-white p-1'
                                            width={48}
                                            height={48}
                                            draggable={false}
                                            alt='img' />
                                        <p className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                            {basketData.name}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-auto'>
                                    <SecondaryButton name="Redeem"
                                        className="e-padding-16px-tb-res e-font-14-res col-12 col-sm-auto mb-3 mb-sm-0 me-3 e-font-16 e-border-bright-gray  e-bg-lotion padding-12px-top padding-12px-bottom padding-12px-top padding-48px-left padding-48px-right"
                                        onPress={_handleRedeemInvest} />
                                    <PrimaryButton name="Invest more"
                                        className="e-padding-16px-tb-res e-font-14-res col-12 col-sm-auto e-font-16 padding-12px-top padding-12px-bottom  padding-32px-left padding-32px-right"
                                        onPress={_handleInvestMore} />
                                </div>
                            </div>
                        </div>

                        <div className='row padding-26px-top'>
                            <div className='col-lg-7 pe-0'>
                                {/* summary section */}
                                <SectionTitle title="Summary" />
                                <div className='mt-3 e-bg-cultured e-border-radius-16 p-4'>
                                    <div className='row row-cols-2 row-cols-sm-3'>
                                        <div className='col'>
                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
                                                Invested
                                            </p>
                                            <p className='mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px'>
                                                {basketData.summary.invested_amount_text}
                                            </p>
                                        </div>
                                        <div className='col mb-3'>
                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
                                                Current
                                            </p>
                                            <p className='mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px'>
                                                {basketData.summary.current_amount_text}
                                            </p>
                                        </div>
                                        <div className='col'>
                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
                                                Returns
                                            </p>
                                            <p className={`${basketData.summary.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} d-flex flex-wrap mb-0 e-text-ufo-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                                                <span className='pe-1'>
                                                    {basketData.summary.returns_amount_text}
                                                </span>
                                                <span className='e-font-14 e-font-weight-600'>
                                                    ({basketData.summary.returns_percentage_text})
                                                </span>
                                            </p>
                                        </div>

                                        <div className='col'>
                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
                                                XIRR
                                            </p>
                                            <p className={`${basketData.summary.xirr >= 0 ? "e-text-ufo-green" : "e-text-red"} mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                                                {basketData.summary.xirr_text}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* investment allocations  */}
                                <SectionTitle title="Your investment allocations"
                                    className="pt-4" />
                                <div className='col d-flex flex-column gap-3 mt-3 e-bg-cultured e-border-radius-16 p-4'>
                                    <div className="d-flex justify-content-between mb-1">
                                        <div className="col-6">
                                            <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                Fund Name
                                            </h6>
                                        </div>
                                        <div className="col-2 p-0 text-center  ">
                                            <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                Weightage
                                            </h6>
                                        </div>
                                        <div className="col-2 p-0 text-center  ">
                                            <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                Cur. value
                                            </h6>
                                        </div>
                                    </div>
                                    {basketData.funds.map((item, key) => (
                                        <div className=" d-flex justify-content-between align-items-center" key={key}>
                                            <div className="col-6 pe-0">
                                                <span className='cursor-pointer mb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px'
                                                    onClick={() => _handleOnFundClick(item.mf_id)}>
                                                    {item.scheme_name}
                                                </span>
                                            </div>
                                            <div className="col-2 p-0 text-center">
                                                <span className='mb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px'>
                                                    {item.allocation_text}
                                                </span>
                                            </div>
                                            <div className="col-2 p-0 text-center">
                                                <span className='mb-3 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-26px'>
                                                    {item.current_amount_text}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Active SIP’s  */}
                            {
                                basketData.summary.active_sips.length > 0 &&
                                <div className='col-lg-5 ps-4 mt-4 mt-lg-0'>
                                    <SectionTitle title="Active SIP's" />
                                    <div className='e-over-flow-auto d-flex flex-column gap-3 mt-3 e-bg-cultured e-border-radius-16 p-4'>
                                        <div className='e-over-flow-auto no-scrollbar e-width-540px-res d-flex flex-column row-gap-3'>
                                            <div className='row justify-content-between'>
                                                <div className='col-4 '>
                                                    <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                        Amount
                                                    </h6>
                                                </div>
                                                <div className='col-4 text-center'>
                                                    <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                        SIP date
                                                    </h6>
                                                </div>
                                                <div className='col-4 text-center p-0'>
                                                    <h6 className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                        Next SIP date
                                                    </h6>
                                                </div>
                                            </div>

                                            {
                                                basketData.summary.active_sips.map((item, key) => (
                                                    <div className='row justify-content-between'
                                                        key={key}>
                                                        <div className='col-4'>
                                                            <p className='mb-0 e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-500 line-height-24px'>
                                                                {item.amount_text}
                                                            </p>
                                                        </div>
                                                        <div className='col-4 text-center'>
                                                            <p className='mb-0 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-500 line-height-24px'>
                                                                {item.sip_date}
                                                            </p>
                                                        </div>
                                                        <div className='col-4 text-center'>
                                                            <p className='mb-0 text-center e-text-egyptian-blue e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                                                {item.next_due}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            <div div className='text-center pt-1'>
                                                <TextButton name="Manage SIP"
                                                    onClick={_handleNavigate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div >

            {
                //goal invest modal 
                showInvestModal &&
                <GoalInvestModal goalId={basketId}
                    reinvest={true}
                    summary={basketSummary}
                    onCloseModal={_handleCloseInvestModal} />
            }
            {
                //goal invest redeem  modal
                showRedeemModal &&
                <RedeemModal summary={redeemSummary}
                    id={basketId}
                    investmentType="Goal"
                    onCloseModal={_handleCloseRedeemModal} />
            }
            {
                //Payment status modal
                showPaymentModal &&
                <PaymentStatusModal type={paymentModalType}
                    investmentType="Basket"
                    onClose={(() => setShowPaymentModal(false))} />
            }

            {/* footer */}
            < Footer />
        </>
    );
};

export default InvestedBasket;