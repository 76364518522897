/*
 *   File : section.js
 *   Author : https://evoqins.com
 *   Description : Section title component
 *   Integrations : NA
 *   Version : 1.0.0
*/

const SectionTitle = (props) => {
    return (
        <h4 className={`${props.className} e-font-14-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px`}>
            {props.title}
        </h4>
    )
}
export default SectionTitle;