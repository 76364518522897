/*
 *   File : how-it-works.js
 *   Author : https://evoqins.com
 *   Description : How it work modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';

//Assets
import Close from "../../Assets/Images/close-btn.svg";
import HowItWorks from "../../Assets/Images/Home/how-it-works.webp";

//Styles
import styles from "../../Style/Component/modal.module.scss";

const HowItWorksModal = (props) => {

    // handle close modal 
    const _handleModalClose = () => {
        props.onCloseModal();
    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="how-it-work-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="howWorkLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className={`${styles.e_how_works_wrapper} modal-content e-border-radius-24 e-how-works-box-shadow `}>
                    <div className="modal-header border-0 justify-content-space-between pt-4 pb-2 px-4 ">
                        <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            How it works?
                        </h6>
                        <img src={Close}
                            id='goal-invest-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={_handleModalClose}
                        />
                    </div>
                    {/* Body */}
                    <div className="modal-body pb-4 px-4 pt-2">
                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            These strategies can be used to manage risk. For example, low-volatility smart beta funds can provide a more stable investment
                            experience, which may be appealing to risk-averse investors.
                        </p>

                        <div className='text-center padding-18px-top'>
                            <img src={HowItWorks}
                                draggable={false}
                                className='img-fluid col-7 mx-auto'
                                alt='how it works image' />
                        </div>

                        <div className='pt-4'>
                            <div>
                                <h6 className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-14px'>
                                    Step 1: Create investment account
                                </h6>

                                <ul className='mb-0 ps-4 e-works-li'>
                                    <li className='e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                        Start your investment journey by creating an account. It only takes a few minutes!
                                    </li>
                                </ul>
                            </div>
                            <div className='pt-3'>
                                <h6 className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-14px'>
                                    Step 2: Invest in life goals and baskets
                                </h6>

                                <ul className='mb-0 ps-4 e-works-li'>
                                    <li className='e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                        Choose from a variety of life goals and baskets curated by experts. Start investing in your future today!
                                    </li>
                                </ul>
                            </div>
                            <div className='pt-3'>
                                <h6 className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-14px'>
                                    Step 3: View portfolio performance
                                </h6>

                                <ul className='mb-0 ps-4 e-works-li'>
                                    <li className='e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                        Keep track of your investments and monitor your portfolio performance effortlessly. Achieve your financial goals with confidence!
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HowItWorksModal;
