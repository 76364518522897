// Initial State
const initialState = {
    REFRESH_TOKEN: null,
    ACCESS_TOKEN: null,
    USER_DATA: null,
    GENERAL_DATA: {},
    SIGNUP_PROGRESS_DATA: {},
    FILTER_DATA: {},
    UNREAD_NOTIFICATION: 0,
    ACCOUNT_OPENING_MODAL_STATUS: false
};


// Redux: Reducer
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_REFRESH_TOKEN': {
            return {
                ...state,
                REFRESH_TOKEN: action.payload
            };
        }

        case 'UPDATE_ACCESS_TOKEN': {
            return {
                ...state,
                ACCESS_TOKEN: action.payload
            };
        }

        case 'UPDATE_USER_DATA': {
            return {
                ...state,
                USER_DATA: action.data
            };
        }

        case 'GENERAL_DATA': {
            return {
                ...state,
                GENERAL_DATA: action.data
            };
        }

        case 'SIGNUP_PROGRESS': {
            return {
                ...state,
                SIGNUP_PROGRESS_DATA: action.data
            }
        }

        case 'EXPLORE_FILTER_DATA': {
            return {
                ...state,
                FILTER_DATA: action.data
            }
        }

        case 'UPDATE_UNREAD_NOTIFICATION': {
            return {
                ...state,
                UNREAD_NOTIFICATION: action.payload
            }
        }

        case 'UPDATE_ACCOUNT_OPENING_MODAL_STATUS': {
            return {
                ...state,
                ACCOUNT_OPENING_MODAL_STATUS: action.payload
            }
        }

        default: {
            return state;
        }
    }
};

export default Reducer;