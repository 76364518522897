/*
 *   File : chip.js
 *   Author : https://evoqins.com
 *   Description :  chip component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

function Chip(props) {
    return (
        <span className={`${props.className} e-border-radius-24 border-1-all border-color-egyptian-blue e-text-egyptian-blue e-alt-font-poppins e-font-weight-400 line-height-18px`}>
            {props.name}
        </span>
    );
};

export default memo(Chip);