/*
 *   File : executed-sip.js
 *   Author : https://evoqins.com
 *   Description : Index fund category card component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React from 'react';

//styles
import styles from "../../Style/Component/card.module.scss";

const ExecutedSIPCard = (props) => {
    return (
        <div className={`${styles.e_sip_card_wrapper} transition cursor-pointer e-bg-anti-flash-white-light e-border-radius-16 h-100`}            >
            <div className='d-flex gap-2 p-3'>
                <div>
                    <img src={props.data.icon}
                        width={48}
                        height={48}
                        className="object-fit-contain e-border-radius-10 e-bg-white p-1"
                        draggable="false"
                        alt="fund" />
                </div>
                <div>
                    <h3 className="e-ellipse ms-2 mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                        {props.data.name}
                    </h3>
                </div>
            </div>

            {/* calculated */}
            <div className={styles.e_border_bottom}></div>
            <div className='p-3'>

                <div className="row row-cols-2 row-gap-2 justify-content-space-between">
                    <div className='col'>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Amount
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            ₹ {props.data.amount}
                        </p>
                    </div>
                    <div className='col'>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Date
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            {props.data.executed_date}
                        </p>
                    </div>
                    <div className='col'>
                        <p className="mb-0 e-text-dark-live4 e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Status
                        </p>
                        <p className={`${props.data.status === "Failed" ? "e-text-sunset-orange" : props.data.status === "Success" ? "e-text-ufo-green" : "e-text-mustard-yellow"} mb-0 e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px`}>
                            {props.data.status}
                        </p>
                    </div>
                </div>

                {/* error message */}
                {
                    props.data.failure_reason &&
                    <div className='mt-3 e-bg-misty-rose e-border-radius-24 py-1 padding-12px-left'>
                        <div className='e-inline-flex'>
                            <img src={require("../../Assets/Images/Orders/error-message.svg").default} />
                            <p className='mb-0 ps-2 e-text-sunset-orange e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px'>
                                {props.data.failure_reason}
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ExecutedSIPCard;