/*
 *   File : pan.js
 *   Author : https://evoqins.com
 *   Description : PAN card verify page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

//custom Component
import { APIService } from '../../../Service';
import { PrimaryButton } from '../../../Component/Buttons';
import { CustomTextInput, RadioGroup } from '../../../Component/Form-Elements';
import { _getSignupProgress } from '../../../Helper';
import { Modal } from 'bootstrap';
import { NRIWarningModal } from '../../../Component/Modal';


// Custom data
const CITIZEN_SELECT = [
    {
        id: 2,
        display_name: "Yes"
    },
    {
        id: 1,
        display_name: "No"
    },
];

const PAN = (props) => {

    //get progress data
    let PROGRESS_DATA = useSelector(state => state.Reducer.SIGNUP_PROGRESS_DATA);

    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");
    const [panSuccess, setPanSuccess] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [citizenSelect, setCitizenSelect] = useState(1);

    const [loader, setLoader] = useState(false);
    const [showNRIWarningModal, setShowNRIWarningModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {

        if (PROGRESS_DATA.kyc_data.pan !== null) {
            setPan(PROGRESS_DATA.kyc_data.pan);
        };
        if (PROGRESS_DATA.kyc_data.name !== null) {
            setName(PROGRESS_DATA.kyc_data.name);
        };
        
    }, [PROGRESS_DATA]);

    useEffect(() => {
        setPanError('');
        setPanSuccess('');
    }, [pan]);

    useEffect(() => {
        setNameError('');
    }, [name]);

    // Handle open NRI warning modal
    useEffect(() => {
        if (showNRIWarningModal === true) {
            let nri_warning_modal = new Modal(document.getElementById('nri-warning-modal'));
            nri_warning_modal.show();
        };
    }, [showNRIWarningModal]);

    //handle pan change
    const _handlePanChange = (value) => {
        if (value.length <= 10) {
            setPan(value.toUpperCase());
        };
    };

    //handle verify pin
    const _handleVerifyPAN = () => {

        let is_valid = true;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN

        if (!validate_pan.test(pan)) {
            setPanError("Invalid PAN number");
            is_valid = false;
        }
        if (name.trim().length === 0) {
            setNameError("Name cannot be empty");
            is_valid = false;
        }

        if (is_valid === true) {
            setLoader(true);
            _verifyPAN();
        }
    };

    //handle citizenship select
    const _handleCitizenSelect = (id) => {
        setCitizenSelect(id);
    };


    // ==== API CALLS ====///

    //API- Check kyc status
    const _verifyPAN = () => {
        let is_post = true;
        let url = 'kyc/check-kyc-status';
        let data = {
            "pan": pan,
            "name": name,
            "investor_type": citizenSelect == 1 ? 1 : 2,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                // Updating Signup progress status
                _getSignupProgress();

                // Non-KYC NRI user not allowed to invest
                // So we are showing warning modal and preventing KYC completion
                if (response.data.non_kyc_nri) {
                    setShowNRIWarningModal(true);
                    setLoader(false);
                    return;
                };

                // Setting toast message based on pan status 

                let is_pan_err = PROGRESS_DATA.step_detail[0].error == true ? true : false;
                let toast_message = is_pan_err ? "Pan updated successfully" : "Pan verified successfully";
                toast.dismiss();
                toast.success(toast_message, {
                    className: "e-toast",
                });

                setPanSuccess("Your PAN is valid");
                props.onUpdateStep(1);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });

               
            }
            setLoader(false);


        });;
    };

    return (
        <div className='pt-3 pt-sm-4 pb-4'>
            <div className='col-xl-6 col-lg-7 col-md-9 col-sm-10 col-12 p-0'>
                <h3 className='e-font-16-res mb-1 pb-2 e-text-yankees-blue e-font-18 e-alt-font-poppins e-font-weight-700 line-height-24px'>
                    Lets get started
                </h3>

                {/* verification */}
                <p className='e-font-14-res line-height-22px-res mb-4 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                    Submit your PAN card details. This is a regulatory requirement.
                </p>

                <div className='p-3 p-sm-4 e-bg-cultured e-border-radius-16'>
                    <div className='h-98px'>
                        <CustomTextInput label="Enter your PAN card*"
                            placeholder="ANJPL3753F"
                            maxLength={10}
                            type="text"
                            value={pan}
                            errorMsg={panError}
                            successMsg={panSuccess}
                            className="w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                            handleChange={_handlePanChange} />
                    </div>

                    {/* name */}
                    <div className='h-98px'>
                        <CustomTextInput label="Full name(As per PAN)*"
                            placeholder="Joseph Thomas"
                            type="text"
                            value={name}
                            errorMsg={nameError}
                            className="w-100 px-3 padding-12px-top padding-12px-bottom"
                            labelStyle="e-font-weight-500"
                            handleChange={(e) => setName(e)} />
                    </div>

                    {/* Citizen Select*/}
                    <div>
                        <h6 className='mb-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Are you a NRI investor?*
                        </h6>
                        <div className='d-flex flex-wrap gap-3'>
                            <RadioGroup data={CITIZEN_SELECT}
                                selected={citizenSelect}
                                handleSelect={_handleCitizenSelect} />
                        </div>
                    </div>

                    {/* custom button */}
                    <PrimaryButton name="Verify & Continue"
                        loading={loader}
                        className="w-40 padding-12px-top padding-12px-bottom margin-top-28px"
                        onPress={_handleVerifyPAN} />
                </div>
            </div>

            {/* Modals */}
            {
                showNRIWarningModal &&
                <NRIWarningModal onClose={() => setShowNRIWarningModal(false)} />
            }
        </div>
    )
}

export default PAN;