/*
 *   File : family.js
 *   Author : https://evoqins.com
 *   Description : Listing page of added family members
 *   Integrations : bootstrap
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Component
import { BreadCrumbs, CustomTab, EmptyScreen } from "../../Component/Other";
import { Footer } from "../../Component/Footer";
import { FamilyCard } from "../../Component/Cards";
import { PrimaryButton } from "../../Component/Buttons";
import { AddFamilyMemberStatusModal, AddFamilyModal } from "../../Component/Modal";
import { APIService } from "../../Service";

// Assets
import Loader from "../../Assets/Images/coloured_loader.svg";
import NoFamilyMembers from "../../Assets/Images/EmptyScreen/no-family-members.webp";

// Custom Data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Manage family",
    },
];

function ManageFamily() {

    const [tabIndex, setTabIndex] = useState(1);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [familyList, setFamilyList] = useState([]);

    const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);
    const [showAddFamilySuccessModal, setShowAddFamilySuccessModal] = useState(false);

    useEffect(() => {

        // API - getting family members list
        _getFamilyList();

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }, []);

    // handle open add family member modal
    useEffect(() => {

        if (showAddFamilyModal) {
            let add_family_modal = new Modal(document.getElementById("add-family-modal"));
            add_family_modal.show();
        };

    }, [showAddFamilyModal]);

    // handle open add family member success modal
    useEffect(() => {
        if (showAddFamilySuccessModal == true) {
            new Modal(document.querySelector("#add-family-member-status-modal")).show();
        }
    }, [showAddFamilySuccessModal]);


    //handle tab change
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    // handle open success modal and update list
    const _handleAddFamilyMemberSuccess = () => {
        setShowAddFamilySuccessModal(true);
        setShowAddFamilyModal(false);
        _getFamilyList();
    };

    // API - get family list 
    const _getFamilyList = () => {
        let is_post = false;
        let url = "family/get";

        APIService(is_post, url).then(response => {
            if (response.status_code === 200) {
                setFamilyList(response.data);
            };
            setIsDataLoading(false);
        });
    };

    return (
        <div className="min-h-100vh">
            <div className="row pb-4 pb-sm-5 justify-content-start align-items-start e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={BREADCRUMBS} />

                {
                    isDataLoading ? (
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                    ) : (
                        <div className="col-lg-11 p-0 mb-4 mb-sm-5">
                            <div className="d-flex e-border-bottom-2px-bright-gray padding-34px-top">
                                {/* Tab component */}
                                <CustomTab tabData={{ id: 1, label: "Family members" }}
                                    tabIndex={tabIndex}
                                    handleChange={_handleTabChange} />
                            </div>
                            <div className="row flex-column flex-md-row padding-40px-top">

                                {/* Family listing - section */}
                                <div className="col-12 col-md-6">
                                    {
                                        familyList.length !== 0 ? (
                                            <div className="d-flex flex-column  flex-lg-column gap-4">
                                                {
                                                    familyList.map((member, index) => (
                                                        <FamilyCard key={index} member={member} />
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            <EmptyScreen img={NoFamilyMembers}
                                                type={2}
                                                title="No family members added !"
                                                description="It seems you didn't added any family members. Start adding your first family member." />
                                        )
                                    }

                                </div>

                                {/* Add new family member section */}
                                <div className="col-12 col-md-6">
                                    <div id="manage-add-family"
                                        className="d-flex flex-column flex-lg-row gap-2 mt-4 mt-md-0 justify-content-start justify-content-lg-between align-items-start align-items-lg-center padding-32px-lr padding-45px-tb padding-24px-all-res e-border-radius-16 ">
                                        <div>
                                            <h2 className="e-font-16-res e-text-charleston-green e-alt-font-poppins e-font-18 e-font-weight-600 line-height-28px mb-0 mb-sm-1">
                                                Add family member
                                            </h2>
                                            <p className="e-text-onyx e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px mb-0">
                                                Add your family member, in simple steps.
                                            </p>
                                        </div>
                                        <PrimaryButton name="Add"
                                            className="e-border-radius-32 line-height-20px-res e-font-12-res e-font-14 py-2 padding-56px-lr e-font-16 "
                                            onPress={() => setShowAddFamilyModal(true)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
            <Footer />

            {/* Modals */}
            {/* Add family member modal */}
            {
                showAddFamilyModal &&
                <AddFamilyModal onClose={() => setShowAddFamilyModal(false)}
                    onSuccess={_handleAddFamilyMemberSuccess} />
            }

            {/* Add family member success modal */}
            {
                showAddFamilySuccessModal &&
                <AddFamilyMemberStatusModal
                    onClose={(() => setShowAddFamilySuccessModal(false))} />
            }
        </div>
    )
}

export default ManageFamily;