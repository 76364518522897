/*
 *   File : retirement.js
 *   Author : https://evoqins.com
 *   Description : Retirement detail page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

//custom component
import { CustomAmountInput, CustomSlider } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import { Footer } from "../../Component/Footer";
import GoalSummary from "./goal-summary";
import { _setGoal } from "../../Helper";
import { BreadCrumbs } from "../../Component/Other";
import { APIService } from "../../Service";
import { CalculateCorpusModal, SelectionSuggestModal } from "../../Component/Modal";
import { Icon } from "../../Component/Icon";

//assets
import Active from "../../Assets/Images/circle-active.svg";
import Inactive from "../../Assets/Images/circle-inactive.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";

// Color
import Color from "../../Style/color.scss";



const CONFIRM = [
    {
        id: 1,
        label: "Yes",
    },

    {
        id: 2,
        label: "No",
    },
];

const LUMPSUM_AMOUNT = [
    {
        id: 1,
        amount: 2000,
        label: "₹2K"
    },

    {
        id: 2,
        amount: 5000,
        label: "₹5K"
    },

    {
        id: 3,
        amount: 10000,
        label: "₹10K"
    },
    {
        id: 4,
        amount: 25000,
        label: "₹25K"
    },
    {
        id: 5,
        amount: 100000,
        label: "₹1L"
    },
];

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Life goals",
        path: "/goals"
    },
];

// Demo Data

const INVESTMENT_FOR = [
    {
        id: 1,
        display_name: "Myself"
    },
    {
        id: 2,
        display_name: "Ann Jayden"
    },
    {
        id: 3,
        display_name: "Jaince Jayden"
    }
];

const Retirement = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [goalId, setGoalId] = useState("");
    const [target, setTarget] = useState('');
    const [targetAmtErr, setTargetAmtErr] = useState("");
    const [retirementTenure, setRetirementTenure] = useState(1);
    const [lumpsumSelect, setLumpsumSelect] = useState(2);
    const [lumpsum, setLumpsum] = useState('');
    const [lumpsumErr, setLumpsumErr] = useState("");
    const [inflationSelect, setInflationSelect] = useState(1);
    const [goalSummary, setGoalSummary] = useState(false);
    const [goalDetail, setGoalDetail] = useState({});
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [setGoalLoading, setSetGoalLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showCorpusModal, setShowCorpusModal] = useState(false);

    const [selectedInvestMember, setSelectedInvestMember] = useState(INVESTMENT_FOR[0]);
    const [showInvestmentSelectModal, setShowInvestmentSelectModal] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

        if (location.state !== null) {
            setGoalId(location.state.goal_id);
        } else {
            navigate("/goals");
        };

    }, [location]);

    useEffect(() => {
        if (goalId !== "") {
            _getGoalDetails();
        }
    }, [goalId]);

    useEffect(() => {
        if (goalSummary === true) {

            navigate(`${location.pathname}#configure`, {
                state: {
                    goal_id: goalId,
                }
            });
        }
    }, [goalSummary]);

    useEffect(() => {

        if (loading === false) {
            setBreadCrumbs([...BREADCRUMBS, { label: goalDetail.admin_configured.name }]);
        };

    }, [loading]);

    useEffect(() => {

        if (showCorpusModal === true) {
            let corpus_modal = new Modal(document.getElementById('calculate-corpus-modal'));
            corpus_modal.show();
        };

    }, [showCorpusModal]);

    useEffect(() => {

        if (showInvestmentSelectModal === true) {
            let invest_for = new Modal(document.getElementById('choose-investment-for-modal'));
            invest_for.show();
        };

    }, [showInvestmentSelectModal]);

    useEffect(() => {
        const _handleOnEnter = (e) => {
            if (e.key === 'Enter' && goalSummary === false) {
                _handleCalculate();
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };

    }, [target, retirementTenure, lumpsum, lumpsumSelect, goalSummary]);

    //handle cost 
    const _handleTargetChange = (value) => {
        setTarget(value);
        setTargetAmtErr("");
    };

    //handle tenure slider
    const _handleTenureSlider = (value) => {
        setRetirementTenure(value);
    };

    //handle lumpsum select
    const _handleLumpsumSelect = (id) => {
        setLumpsumSelect(id);
    };

    //handle lumpsum value
    const _handleLumpsum = (value) => {
        setLumpsum(value);
        setLumpsumErr('');
    };

    //handle inflation
    const _handleInflation = (id) => {
        setInflationSelect(id);
    };

    //handle calculate corpus modal
    const _handleCalculateCorpus = () => {
        setShowCorpusModal(true);
    };

    const _handleSetCorpusAmount = (calculated_amt) => {
        setShowCorpusModal(false);
        if (calculated_amt > 0) {
            setTarget(calculated_amt);
        };
    };

    const _handleSelectInvestmentFor = (selected) => {
        setSelectedInvestMember(selected);
    };

    //handle calculate 
    const _handleCalculate = () => {

        let validation = true;


        if (target == 0) {
            setTargetAmtErr("Please specify the details of your retirement fund");
            validation = false;
        };

        if (lumpsumSelect == 1 && lumpsum == 0) {
            setLumpsumErr("Please provide the lumpsum amount to proceed");
            validation = false;
        };

        if (lumpsumSelect == 1 && target < lumpsum) {
            toast.dismiss();
            toast.error("Retirement corpus should be greater than lumpsum amount",
                { className: "e-toast" });
            validation = false;
        };

        if (validation) {

            // disabling calculate btn 
            setDisabled(true);

            const params = {
                "goal_id": goalId,
                "target": target,
                "lumpsum": lumpsum,
                "sip_tenure": retirementTenure,
                "lumpsum_select": lumpsumSelect,
                "inflation_select": inflationSelect == 1 ? true : false
            };

            _setGoal(params).then(status => {
                if (status.status_code === 200) {
                    setGoalSummary(true);
                    toast.dismiss();
                    toast.success("Goal saved", {
                        type: "success",
                        className: "e-toast",

                    });
                } else {
                    toast.dismiss();
                    toast.error(status.message, {
                        type: "error",
                        className: "e-toast",

                    });
                }
                // enabling calculate btn 
                setDisabled(false);
            });

        }
    }

    //API- Post goal detail 
    const _getGoalDetails = () => {
        let is_post = true;
        let url = 'goal/get';
        let data = {
            "goal_id": goalId,
            "is_invested": false
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setGoalDetail(response.data);

                if (response.data.user_configured["target_amount"]) {
                    setTarget(response.data.user_configured.target_amount);
                } else {
                    setTarget(100000);
                }

                if (response.data.user_configured["lumpsum"]) {
                    setLumpsum(response.data.user_configured.lumpsum);
                } else {
                    setLumpsum(2000);
                };

                if (response.data.user_configured["tenure"]) {
                    setRetirementTenure(response.data.user_configured.tenure);
                };

                // Preserving current step if user reloads page
                if (location.hash) {
                    setGoalSummary(true);
                };

            } else {
                setGoalDetail({});
            }
            setLoading(false);
        });
    };

    return (
        <>
            {
                goalSummary !== true ?
                    <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">

                        {loading ?
                            <div className="min-h-100vh text-center mt-5" >
                                <img src={Loader}
                                    draggable="false"
                                    alt="loader" />
                            </div>
                            :
                            <div className="col-lg-11 p-0 pb-4 pb-sm-5">
                                {/* breadcrumbs */}
                                <BreadCrumbs data={breadcrumbs} />

                                {/* flow of invest */}
                                <div className="padding-26px-top px-0 mb-4">
                                    <h4 className="e-font-16-res line-height-22px-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-500 letter-spacing-neg-2 line-height-30px">
                                        You are few steps away from creating {goalDetail.admin_configured.short_name} goal
                                    </h4>
                                    <div className="col-12 col-sm-10 col-md-7 d-flex  justify-content-between align-items-center padding-28px-top">
                                        <img src={require("../../Assets/Images/LifeGoals/details.svg").default}
                                            draggable="false"
                                            className="e-padding-30px-left-res padding-36px-left"
                                            alt="detail" />
                                        <div className="horizontal-rule"></div>
                                        <img src={require("../../Assets/Images/LifeGoals/suggestion.svg").default}
                                            draggable="false"
                                            alt="suggestion" />
                                        <div className="horizontal-rule"></div>
                                        <img src={require("../../Assets/Images/LifeGoals/invest.svg").default}
                                            draggable="false"
                                            alt="invest" />
                                    </div>

                                    <div className="col-12 col-sm-10 col-md-7  d-flex justify-content-between">
                                        <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your details
                                        </p>
                                        <p className="e-goal-step margin-28px-neg-left mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            SIP Suggestion
                                        </p>
                                        <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Invest
                                        </p>
                                    </div>
                                </div>

                                <div className="px-0 mb-4">
                                    <div className="col-12">
                                        <div className='e-bg-beige px-3 py-xl-4 py-lg-4 py-md-4 py-3 e-border-radius-16'>
                                            <div className="e-display-flex justify-content-space-between e-flex-wrap gap-2 ">
                                                <div className="d-flex gap-0-8 mb-1">
                                                    <img src={require("../../Assets/Images/LifeGoals/calculate-note.svg").default}
                                                        draggable={false}
                                                        alt="icon" />
                                                    <p className="e-text-citron mb-0 e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px">
                                                        Want to calculate your retirement corpus
                                                    </p>
                                                </div>

                                                <PrimaryButton name="Calculate"
                                                    className='py-xl-2 py-lg-2 py-md-2 py-sm-2 py-1 px-3 e-bg-dark-lemon-lime text-end'
                                                    onPress={_handleCalculateCorpus} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* detail section */}
                                <div className="p-3 p-sm-4 e-border-radius-16 e-bg-cultured ">
                                    <div className="e-inline-flex e-gap-0-12px">
                                        <img src={goalDetail.admin_configured.icon}
                                            draggable={false}
                                            className="e-border-radius-8"
                                            width={48}
                                            alt="house" />
                                        <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                            Life goal: {goalDetail.admin_configured.short_name}
                                        </p>
                                    </div>

                                    {/* Investment Select */}
                                    <div className="d-flex align-items-center mt-4">
                                        <span className="me-2 mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                            Investment is for:
                                        </span>
                                        <span className="d-flex gap-0-6px cursor-pointer align-items-center e-text-egyptian-blue e-font-18 e-alt-font-poppins e-font-weight-500 line-height-24px"
                                            onClick={() => setShowInvestmentSelectModal(true)}>
                                            {selectedInvestMember.display_name}
                                            <Icon icon="down-arrow" size={"2rem"} color={Color.charleston_green} />
                                        </span>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-lg-6">

                                            {/* target amount input */}
                                            <CustomAmountInput label='Enter your retirement corpus*'
                                                inputClassName="py-1"
                                                value={target}
                                                type={2}
                                                errorMsg={targetAmtErr}
                                                maxLength={8}
                                                handleChange={(value) => { _handleTargetChange(value) }} />
                                            <div className="">
                                                <p className="e-font-14-res line-height-22px-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-30px">
                                                    Do you wish to invest a lumpsum amount now?
                                                </p>
                                                <div className="d-flex e-gap-0-16">
                                                    {CONFIRM.map((data, key) => (
                                                        <div key={key} className="e-display-flex gap-1 gap-sm-2 cursor-pointer"
                                                            onClick={() => _handleLumpsumSelect(data.id)}>

                                                            <img src={lumpsumSelect === data.id ? Active : Inactive}
                                                                draggable="false"
                                                                className="width-24px width-20px-res"
                                                                alt="radio" />
                                                            <p className={` ${lumpsumSelect === data.id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} mb-0 e-font-14-res e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                {data.label}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* lumpsum amount input  */}
                                                {lumpsumSelect === 1 &&
                                                    <>
                                                        <div className="pt-3">
                                                            <CustomAmountInput label='Lumpsum amount'
                                                                value={lumpsum}
                                                                maxLength={8}
                                                                errorMsg={lumpsumErr}
                                                                handleChange={(value) => _handleLumpsum(value)} />
                                                        </div>

                                                        <div className="d-flex gap-3 flex-wrap padding-8px-top">
                                                            {LUMPSUM_AMOUNT.map((data, key) => (
                                                                <p key={key} className={`${lumpsum === data.amount ? 'e-border-otp-active e-text-egyptian-blue' : 'cursor-pointer e-text-lavender-gray e-border-1px-lavender-gray'} e-font-14-res mb-0 px-2 px-sm-3 py-sm-1 e-border-radius-8 e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                                    onClick={() => {
                                                                        setLumpsum(data.amount);
                                                                        setLumpsumErr('');
                                                                    }}>
                                                                    {data.label}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {/* tenure slider */}
                                        <div className="col-lg-6">
                                            <div className="mt-4">
                                                <div className="e-display-flex justify-content-space-between">
                                                    <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                                        Tenure
                                                    </h4>
                                                    <p className="e-font-14-res mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                        {retirementTenure} {retirementTenure === 1 ? 'Yr' : 'Yrs'}
                                                    </p>
                                                </div>
                                                {/* tenure slider  */}
                                                <div className="padding-30px-top">
                                                    <CustomSlider min={1}
                                                        max={goalDetail.admin_configured.meta_data.current_age.max_age - goalDetail.admin_configured.meta_data.current_age.min_age}
                                                        step={1}
                                                        defaultValue={retirementTenure}
                                                        handleChange={(value) => _handleTenureSlider(value)} />
                                                    <div className="d-flex justify-content-space-between mt-4">
                                                        <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                            1 Yr
                                                        </p>
                                                        <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                            {goalDetail.admin_configured.meta_data.current_age.max_age - goalDetail.admin_configured.meta_data.current_age.min_age} Yrs
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className="pt-4">
                                                    <p className="pt-2 e-font-14-res line-height-22px-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-30px">
                                                        Inflation-adjusted amount(in {goalDetail.admin_configured.meta_data.inflation_rate}%)
                                                    </p>

                                                    {/* inflation select  */}
                                                    <div className="d-flex e-gap-0-16">
                                                        {CONFIRM.map((data, key) => (
                                                            <div key={key} className="e-display-flex gap-1 gap-sm-2 cursor-pointer"
                                                                onClick={() => _handleInflation(data.id)}>

                                                                <img src={inflationSelect === data.id ? Active : Inactive}
                                                                    draggable="false"
                                                                    className="width-24px width-20px-res"
                                                                    alt="radio" />
                                                                <p className={` ${inflationSelect === data.id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} e-font-14-res mb-0 e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                    {data.label}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="padding-40px-top col-lg-4">
                                            <PrimaryButton name="Continue"
                                                loading={disabled === true ? true : false}
                                                disabled={disabled}
                                                className="w-100 padding-12px-top padding-12px-bottom"
                                                onPress={() => _handleCalculate()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div >
                    :

                    // goal summary page 
                    <GoalSummary goal_name={goalDetail.admin_configured.short_name}
                        isLumpsum={lumpsumSelect == 1 ? true : false}
                        tenure={{ min: 1, max: goalDetail.admin_configured.meta_data.current_age.max_age - goalDetail.admin_configured.meta_data.current_age.min_age }}
                        inflationSelect={inflationSelect == 1 ? true : false} />
            }

            {/* calculate corpus modal */}
            {
                showCorpusModal &&
                <CalculateCorpusModal
                    data={goalDetail}
                    onClose={_handleSetCorpusAmount}
                />
            }

            {
                showInvestmentSelectModal &&
                <SelectionSuggestModal title="Investment is for*"
                    data={INVESTMENT_FOR}
                    type={1}
                    id={`choose-investment-for-modal`}
                    selectedOption={selectedInvestMember}
                    onSelectOption={_handleSelectInvestmentFor}
                    onModalClose={() => setShowInvestmentSelectModal(false)} />
            }

            {/* footer  */}
            <Footer />

        </>
    )
}
export default Retirement;