/*
 *   File : find-ifsc.js
 *   Author : https://evoqins.com
 *   Description : Find ifsc modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//Custom components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { CustomTextInput } from '../Form-Elements';

//Assets
import Close from "../../Assets/Images/close-btn.svg";

const FindIFSCModal = (props) => {

    const [selectedBank, setSelectedBank] = useState({});
    const [bankError, setBankError] = useState("");

    const [selectedBranch, setSelectedBranch] = useState({});
    const [branchError, setBranchError] = useState("");

    useEffect(() => {
        if (Object.keys(props.selectedBank).length !== 0) {
            setSelectedBank(props.selectedBank);
        }
    }, [props.selectedBank]);

    useEffect(() => {
        if (Object.keys(props.selectedBranch).length !== 0) {
            setSelectedBranch(props.selectedBranch);
        }
    }, [props.selectedBranch]);

    useEffect(() => {
        setBankError("");
    }, [selectedBank]);

    useEffect(() => {
        setBranchError("");
    }, [selectedBranch]);

    // handle close modal 
    const _handleModalClose = () => {
        var button = document.getElementById("find-ifsc-modal-close");
        button.click();
        setBankError("");
        setBranchError("");
        props.onModalClose();
    };

    // handle find ifsc 
    const _handleFind = () => {

        let is_valid = true;

        if (Object.keys(selectedBank).length === 0) {
            setBankError("Please select bank");
            is_valid = false;
        }

        if (Object.keys(selectedBranch).length === 0) {
            setBranchError("Please select branch");
            is_valid = false;
        }

        if (is_valid === true) {
            props.handleFind(selectedBranch);
            _handleModalClose();
        }
    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="find-ifsc-modal"
            data-bs-backdrop={props.type === "modal" ? "false" : "static"}
            tabIndex="-1"
            aria-labelledby="findIfscModalLabel"
            aria-hidden="true">
            <div className={` modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
                <div className={`e-bg-lotion modal-content e-border-radius-24 e-how-works-box-shadow `}>
                    <div className="modal-header border-0 justify-content-space-between pt-4 pb-2 px-3 px-sm-4 ">
                        <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                            Find IFSC code
                        </h6>
                        <img src={Close}
                            id='find-ifsc-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={_handleModalClose}
                        />
                    </div>
                    {/* Body */}
                    <div className="modal-body pb-3 px-3 px-sm-4 pt-3">

                        <div className='position-relative mb-2'>
                            <CustomTextInput label="Bank name*"
                                placeholder="Select"
                                height="h-90"
                                dropdown={true}
                                type="text"
                                value={selectedBank.name}
                                errorMsg={bankError}
                                readOnly
                                className="w-100 px-3 pe-5 cursor-pointer"
                                dropDownIcon="top-auto"
                                labelStyle="e-font-weight-500"
                                onClick={props.bankSearchModal} />
                        </div>

                        <div className='pt-2 position-relative'>
                            <CustomTextInput label="Branch*"
                                placeholder="Select"
                                height="h-90"
                                dropdown={true}
                                type="text"
                                value={selectedBranch.name}
                                errorMsg={branchError}
                                readOnly
                                disabled={Object.keys(selectedBank).length === 0 ? true : false}
                                className="w-100 px-3 pe-5 cursor-pointer"
                                dropDownIcon="top-auto"
                                labelStyle="e-font-weight-500"
                                onClick={props.branchSearchModal} />
                        </div>

                    </div>

                    {/* Custom button */}
                    <div className="modal-footer border-0 p-3 p-sm-4">
                        <div className='flex-fill'>
                            <SecondaryButton name="Cancel"
                                className="m-0 w-100 padding-12px-top padding-12px-bottom e-border-bright-gray"
                                onPress={_handleModalClose} />

                        </div>
                        <div className='flex-fill'>
                            <PrimaryButton name="Find"
                                className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                onPress={_handleFind} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FindIFSCModal;
