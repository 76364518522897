/*
 *   File : basket.js
 *   Author : https://evoqins.com
 *   Description : Basket card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Component
import { Icon } from "../Icon";

//custom styles
import styles from "../../Style/Component/card.module.scss";

// Color
import Colors from "../../Style/color.scss";

//assets
import RightArrow from "../../Assets/Images/right-icon.svg";

const BasketCard = (props) => {

    const style = {
        "--background": props.data.color,
    };

    return (
        <>
            {props.type === "modal" ?
                <div style={style}
                    className={`${styles.e_basket_modal_card} transition e-border-radius-16 px-3 px-sm-4 py-3 cursor-pointer`}
                    onClick={props.handleSelect} >
                    <div className="d-flex align-items-center e-basket-card-title gap-xl-3 gap-lg-3 gap-sm-2 gap-md-1 gap-1">
                        <Icon icon={props.selected === props.data.basket_id ? 'circle-active' : 'circle-inactive'}
                            size="24px"
                            color={Colors.white} />
                        <img src={props.data.icon}
                            draggable={false}
                            height={38}
                            width={38}
                            alt="invest" />
                        <div>
                            <p className=" mb-0 e-text-white e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px w-max-content">
                                {props.data.name}
                            </p>

                            <div className="d-flex justify-content-center align-items-center e-flex-wrap">
                                <p className="mb-0 pe-1 e-text-white e-font-14 e-alt-font-poppins e-font-weight-400 line-height-20px w-max-content">
                                    {props.data.returns.tenure_text} returns :
                                </p>
                                <p className={`${props.data.returns.returns_percentage >= 0 ? "e-text-ufo-green" : "e-text-red"}  mb-0 e-text-ufo-green e-font-16 e-font-weight-600`}>
                                    {props.data.returns.returns_percentage_text}
                                </p>
                            </div>
                        </div>
                    </div>
                </div >
                :
                <div style={style}
                    className={`${styles.e_basket_card} transition e-border-radius-16 p-3 cursor-pointer `}
                    onClick={props.onClick}>
                    <div className="e-inline-flex e-basket-card-title gap-2 gap-sm-2 gap-md-1">
                        <img src={props.data.icon}
                            draggable={false}
                            className="mb-1"
                            height={48}
                            width={48}
                            alt="invest" />
                        {/* <p className="e-font-20-res mb-0 padding-12px-left e-text-white e-font-18 e-alt-font-poppins e-font-weight-600 line-height-22px"> */}
                        <p className="e-font-16-res mb-0 padding-12px-left e-text-white e-font-18 e-alt-font-poppins e-font-weight-600 line-height-22px w-max-content">
                            {props.data.name}
                        </p>
                    </div>
                    <div className="e-display-flex justify-content-space-between pt-sm-3">
                        <div className="d-flex justify-content-center align-items-center e-flex-wrap">
                            <p className="mb-0 pe-1 e-text-white e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px w-max-content">
                                {props.data.returns.tenure_text} returns :
                            </p>
                            <p className={`${props.data.returns.returns_percentage >= 0 ? "e-text-ufo-green" : "e-text-red"} e-font-14-res mb-0 e-text-ufo-green e-font-16 e-font-weight-600`}>
                                {props.data.returns.returns_percentage_text}
                            </p>
                        </div>
                        <img src={RightArrow}
                            draggable="false"
                            className={`${styles.e_right_arrow} transition`}
                            alt="arrow" />
                    </div>
                </div>
            }
        </>
    )
}
export default BasketCard;