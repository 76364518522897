/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Navigation component
 *   Integrations : NA
 *   Version : 1.0.0
*/

//custom style
import styles from "../../Style/Component/header.module.scss";

//assets
import MailIcon from "../../Assets/Images/Login/mail-icon.svg";
import CallIcon from "../../Assets/Images/Login/call-icon.svg";
import Logo from "../../Assets/Images/index-fund-logo.svg";
import { useEffect } from "react";


const Header = () => {

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: "smooth" });

    }, []);

    return (
        <div className="container-fluid">
            <div className="row mx-sm-5 ps-4 px-sm-4 padding-32px-top align-items-center gap-3 gap-md-0">
                <div className="col-3 pt-0 px-0">
                    <img src={Logo}
                        className="transform-scale-hover cursor-pointer"
                        draggable="false"
                        width={70}
                        height={64}
                        alt="logo" />
                </div>
                <div className="px-0 col-md-9 col-sm-12 col-12 text-xl-end text-md-end text-sm-start text-start">
                    <div className={`${styles.e_links_wrapper} e-inline-flex e-flex-wrap gap-3 gap-sm-3 `}>
                        {/* <a href="/"
                            className="me-5 cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Home
                        </a> */}
                        <a href="mailto:help@indexfundssahihai.com"
                            className="text-reset d-flex align-items-center flex-wrap cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            <img src={MailIcon}
                                draggable="false"
                                className="me-2"
                                alt="mail-icon" />
                            <span>
                                help@indexfundssahihai.com
                            </span>
                        </a>
                        {/* <a href="tel:+919324878598"
                            className="text-reset cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            <img src={CallIcon}
                                draggable="false"
                                className="me-2"
                                alt="call-icon" />
                            <span>
                                +91 9324878598
                            </span>
                        </a> */}
                        <a href="tel:022 4962 0364"
                            className="d-flex align-items-center flex-wrap text-reset cursor-pointer text-decoration-none e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            <img src={CallIcon}
                                draggable="false"
                                className="me-2"
                                alt="call-icon" />
                            <span>
                                022 4962 0364
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;