/*
 *   File : marketing.js
 *   Author : https://evoqins.com
 *   Description : Banner component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from "react";
import { useNavigate } from "react-router-dom";

//custom component
import { PrimaryButton } from "../Buttons";

//style
import styles from "../../Style/Component/banner.module.scss";

const MarketingBanner = (props) => {

    const navigate = useNavigate();

    const style = {
        "--background": props.bgColor,
    }

    return (
        <>
            {props.type === 1 ?
                <div className={`${styles.e_marketing_banner} padding-34px-left e-border-radius-16 `} style={style}>
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-8 col-8 p-0 z-index-3">
                            <h3 className={`${props.className} pt-4 mb-3 e-text-white e-alt-font-poppins e-font-weight-600 line-height-48px`}>
                                {props.title}
                            </h3>
                            <div className="pb-4">
                                <PrimaryButton name="Invest now"
                                    className="py-xl-3 py-lg-3 py-md-3 py-sm-2 py-2 padding-32px-right padding-32px-left e-bg-white e-text-charleston-green e-font-16  e-alt-font-poppins e-font-weight-600 line-height-14px e-secondary-button-hover transition e-market-banner-btn"
                                    onPress={() => navigate("/explore")} />

                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-4 col-md-4 col-sm-4 col-4 text-end position-relative">
                            <div className="top-50-res top-neg-18px position-absolute right-36px e-marketing-banner-icon me-3 me-sm-0">
                                <img src={props.image}
                                    draggable="false"
                                    alt="banner" />
                            </div>
                        </div>
                    </div>

                </div>
                :
                props.type === 2 ?
                    <div className={`${styles.e_create_account_banner} padding-12px-all-res  padding-32px-left e-border-radius-16 `}
                        style={style}>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <h3 className={`${props.className} pt-4 e-text-white e-alt-font-poppins e-font-weight-600 line-height-48px`}>
                                    {props.title}
                                </h3>
                                <div className="pb-4">
                                    <PrimaryButton name="Create your account and invest now"
                                        className="py-xl-3 py-lg-3 py-md-3 py-sm-2 py-2 px-xl-4 px-lg-4 px-md-4 px-sm-3 px-3 e-bg-white e-text-charleston-green e-font-16  e-alt-font-poppins e-font-weight-600 line-height-18px e-secondary-button-hover transition"
                                        onPress={() => navigate("/kyc")} />
                                </div>
                            </div>
                            <div className="d-none d-sm-flex align-items-end justify-content-end col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <img src={props.image}
                                    draggable="false"
                                    className="pe-5"
                                    alt="banner" />
                            </div>
                        </div>
                    </div>
                    :
                    <div className={`${styles.e_banner_last} padding-32px-left e-border-radius-16 `}
                        style={style}>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <h3 className={`${props.className} pt-xl-4 pt-lg-4 pt-md-4 pt-sm-3 pt-3 e-text-white e-alt-font-poppins e-font-weight-600 line-height-48px`}>
                                    {props.title}
                                </h3>
                                <p className="padding-30px-bottom mb-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-22px e-import-portfolio">
                                    Import & manage your external portfolio in just 5 minutes.
                                </p>
                                <div className="pb-4">
                                    <PrimaryButton name="Import"
                                        className="py-xl-3 py-lg-3 py-md-3 py-sm-2 py-2  e-primary-button-hover transition padding-42-right padding-42-left e-font-16  e-alt-font-poppins e-font-weight-600 line-height-14px"
                                        onPress={props.handleImportClick} />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-block d-lg-block d-md-block d-sm-block d-none text-end position-relative">
                                <div className="top-neg-24px position-absolute right-36px">
                                    <img src={props.image}
                                        draggable="false"
                                        alt="banner" />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
export default MarketingBanner;