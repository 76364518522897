/*
 *   File : icon.js
 *   Author : https://evoqins.com
 *   Description : Icon component
 *   Integrations : icomoon-react
 *   Version : 1.0.0
*/

import { memo } from "react";

import IcomoonReact from "icomoon-react"; //{ iconList }
const iconSet = require("../../Assets/Font/selection.json");

const Icon = ({ ...props }) =>
    <span className="icon-container d-flex">
        <IcomoonReact iconSet={iconSet} {...props} />
    </span>

export default memo(Icon);