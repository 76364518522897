/*
 *   File : holdings.js
 *   Author : https://evoqins.com
 *   Description : user holdings card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


// Components
import { PrimaryButton } from "../Buttons";

// Assets
import RightBlackarrow from "../../Assets/Images/right-black-icon.svg";

//styles
import styles from "../../Style/Component/card.module.scss";

function HoldingsCard(props) {

    const navigate = useNavigate();
    const [data, setData] = useState(props.data);
    const id = props.data.type == "Fund" ? props.data.mf_id : props.data.goal_id;

    useEffect(() => {
        setData(props.data);
    }, [data]);

    const _handleOnClickHolding = (data) => {

        if (data.invested_amount > 0 && props.type !== 2) {
            let type = data.type;
            let path = type == "Fund" ? "invested-mf-detail" : type == "Goal" ? "invested-goal-detail" : "invested-basket-detail";

            navigate(path, {
                state: {
                    id
                }
            });
        }

    };

    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 cursor-pointer mb-4 e-border-radius-16"
            onClick={() => _handleOnClickHolding(props.data)}>
            <div className={`${styles.e_holdings_card} transition h-100 d-flex flex-column  gap-3 p-3 e-bg-cultured e-border-radius-16`}>
                {/* fund name */}
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2 pe-lg-2">
                        <img className="object-fit-contain me-1 e-border-radius-10 align-self-stretch e-bg-white p-1"
                            width={48}
                            height={48}
                            src={data.icon}
                            alt="holdings logo" />
                        <h2 className="e-ellipse e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-600 line-height-20px">
                            {data.name}
                        </h2>
                    </div>
                    {
                        props.type !== 2 &&
                        <img className="arrow-animation cursor-pointer"
                            src={RightBlackarrow}
                            alt="arrow right" />
                    }
                </div>

                {/* Type of holding */}
                <span className="align-self-start e-text-white e-alt-font-poppins e-font-14 e-font-weight-500 line-height-20px px-3 py-2 e-border-radius-48 e-bg-cyan-azure">
                    {data.type}
                </span>

                {/* Holdings details */}
                <div className="d-flex">
                    <div className="col-7 d-flex flex-column">
                        <span className="e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                            Current
                        </span>
                        <span className="e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                            {data.current_amount_text}
                        </span>
                    </div>
                    <div className="col-5 d-flex flex-column">
                        <span className="e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                            Invested
                        </span>
                        <span className="e-text-charleston-green e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                            {data.invested_amount_text}
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="col-7 d-flex flex-column">
                        <span className="e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                            Returns
                        </span>
                        <span className={`${data.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} e-text-ufo-green d-flex flex-wrap e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                            <span>
                                {data.returns_amount_text}
                            </span>
                            <span className="e-font-14 ps-1">
                                ({data.returns_percentage_text})
                            </span>
                        </span>
                    </div>
                    <div className="col-5 d-flex flex-column">
                        <span className="e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px">
                            XIRR
                        </span>
                        <span className={`${data.xirr >= 0 ? "e-text-ufo-green" : "e-text-red"} e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                            {data.xirr_text}
                        </span>
                    </div>
                </div>

                {/* action buttons */}
                {/* Type 2 will br family member holdings data */}
                {
                    props.type !== 2 &&
                    <div className="d-flex flex-wrap align-items-center mt-2 justify-content-between">
                        <div className="col col-sm-12  col-md-6 px-1 mb-sm-3 mb-md-0">
                            <PrimaryButton name="Redeem"
                                disabled={data.current_amount > 0 ? false : true}
                                className="w-100 e-font-14-res padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                onPress={(e) => props.onRedeem(e, id, data.type)} />
                        </div>
                        <div className="col col-sm-12  col-md-6 px-1">
                            <PrimaryButton name="Invest more"
                                className="w-100 e-font-14-res e-font-16 padding-12px-tb text-nowrap"
                                onPress={(e) => props.onInvest(e, id, data.type)} />
                        </div>


                    </div>
                }
            </div>
        </div>
    );
};

export default memo(HoldingsCard);