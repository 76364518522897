/*
 *   File : goal-invest.js
 *   Author : https://evoqins.com
 *   Description : Goal Investment modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { CustomAmountInput, CustomTextInput, OTPInput } from "../Form-Elements";
import { PrimaryButton } from "../Buttons";
import { APIService } from "../../Service";
import { Icon } from "../Icon";
import SIPDateModal from './sip-dates';
import { _getOTP } from "../../Helper";
import { _createMandate, _getNotificationCount, _goalSummary } from "../../Helper/api";
import { _formatSipDate } from "../../Helper/converter";

//  Assets
import Active from "../../Assets/Images/circle-active.svg";
import Inactive from "../../Assets/Images/circle-inactive.svg";
import UPI from "../../Assets/Images/payment_upi.svg";
import NetBanking from "../../Assets/Images/payment_net_banking.svg";
import Close from "../../Assets/Images/close-btn.svg";
import Back from "../../Assets/Images/Login/back-icon.svg";

// Colors
import Colors from "../../Style/Component/card.module.scss";

// Custom data

// SIP mandate information
const SIP_MANDATE_INFO = [
    "For first SIP order, a mandate will be created automate monthly recurring transactions",
    "The mandate is for Rs. 5 lakhs, which will be used for future rebalancing and other SIP.",
    "We won't deduct any extra money other than the SIP order amount using the mandate.",
    "Once the mandate has been created, you can proceed with the first installment."
];

const CONFIRM_PAYMENT = [
    {
        id: 1,
        label: "Yes",
        status: true,
    },

    {
        id: 2,
        label: "No",
        status: false,
    },
];

const AMOUNT_DATA = [
    {
        id: 1,
        amount: 2000,
    },

    {
        id: 2,
        amount: 5000,
    },

    {
        id: 3,
        amount: 10000,
    },
    {
        id: 4,
        amount: 25000,
    },
    {
        id: 5,
        amount: 100000,
    },
];

function GoalInvestModal(props) {

    const userData = useSelector((store) => store.Reducer.USER_DATA);
    const otpInputRef = useRef(null);

    const [goalId, setGoalId] = useState(props.goalId);
    const [focusedInput, setFocusedInput] = useState(0);
    const [goalSummary, setGoalSummary] = useState(props.summary);
    const [toggleInvest, setToggleInvest] = useState(false);
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [mandateStatus, setMandateStatus] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [isPayingNow, setIsPayingNow] = useState(true);
    const [reInvestLumpsum, setReinvestLumpsum] = useState(0);
    const [errorMsgLumpsum, setErrorMsgLumpsum] = useState("");

    // OTP
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);

    // for sip date
    const [sipActiveDates, setSipActiveDates] = useState([]);
    const [selectedSIPDate, setSelectedSIPDate] = useState("");
    const [selectedSIPDateString, setSelectedSIPDateString] = useState("");

    // For btn
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP

    useEffect(() => {
        setGoalId(props.goalId);
        setGoalSummary(props.summary);
        setMandateStatus(props.summary.mandate_status);

        // Prefilling lumpsum input for reinvest
        setReinvestLumpsum(props.summary.min_investment.min_invest);

        if (props.summary.payment_types.length !== 0) {
            let payment_types = props.summary.payment_types;
            payment_types = payment_types.map(data => {
                return data.id == 2 ? { ...data, image: UPI } : data.id == 1 ? { ...data, image: NetBanking } : data;
            });
            setPaymentMethod(payment_types);
            setSelectedPaymentType(payment_types[0].label);
        }
    }, [props.summary]);

    useEffect(() => {
        if (selectedSIPDate !== "") {
            const formatted_sip_date = _formatSipDate(selectedSIPDate);  // Formatting selected SIP date
            setSelectedSIPDateString(formatted_sip_date);
        };
    }, [selectedSIPDate]);

    // Setting default selected sip date
    useEffect(() => {

        const sip_dates = props.summary.sip_dates.split(",").map(Number);
        setSipActiveDates(sip_dates);
        const default_date = new Date().getDate() + 1;

        if (default_date > 28) {
            setSelectedSIPDate(sip_dates[0]);
        } else {
            if (sip_dates.includes(default_date)) {
                setSelectedSIPDate(default_date);
            } else {
                setSelectedSIPDate(sip_dates[0]);
            };
        };

    }, [props.summary.sip_dates]);

    // Closing Sip date modal when clicking outside
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest("#sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

        return window.removeEventListener("click", (e) => {
            if (!e.target.closest("#sip-date-modal")) {
                setToggleDateModal(false);
            }
        });
    }, []);

    //for timer
    useEffect(() => {
        if (toggleInvest === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleInvest, timer]);

    const _handleConfirmPayingNow = (status) => setIsPayingNow(status);
    const _handlePaymentMethod = (payment_type) => setSelectedPaymentType(payment_type);

    //handle amount select
    const _handleAmountSelect = (amount) => {
        // removing error msg from input
        setErrorMsgLumpsum("");
        setReinvestLumpsum(amount)
    };

    //handle SIP date select
    const _handleSIPDateSelect = (date) => {
        setSelectedSIPDate(date);
        const formatted_sip_date = _formatSipDate(date); // Formatting selected SIP date
        setSelectedSIPDateString(formatted_sip_date);
    };

    const _handleInputLumpsumChange = (value) => {
        setErrorMsgLumpsum("");
        setReinvestLumpsum(value);
    };

    const _handleCloseDateModal = () => {
        setToggleDateModal(false);
    };

    // handle toggle invest now 
    const _handleToggleInvest = () => {

        if (props.reinvest === true) {
            if (reInvestLumpsum < goalSummary.min_investment.min_invest) {
                setErrorMsgLumpsum(`Please provide the lumpsum amount to proceed`);
                return;
            }
        };

        if (goalSummary.mandate_status == false && goalSummary.sip !== null) {
            setLoading(true);

            // Call CreateMandate API
            _createMandate().then(status => {
                if (status.status_code === 200) {
                    const mandate_window = window.open(status.data.mandate_url, "", "width=500,height=500,left=400,top=100");

                    if (mandate_window === null || typeof mandate_window === 'undefined') {
                        toast.dismiss();
                        toast.error("please enable Pop-up to open mandate window", { className: "e-toast", });
                        setIsDisabled(false);
                        setLoading(false);
                        return;
                    };

                    setLoading(false);
                    setIsDisabled(true);  // Preventing from multiple request

                    let timer = setInterval(function () {
                        if (mandate_window.closed) {
                            clearInterval(timer);
                            // calling summary api again for mandate status
                            // if success change btn value to request otp
                            const param = {
                                "goal_id": goalId,
                                "type": null,
                            };

                            _goalSummary(param).then(status => {
                                if (status.status_code === 200) {
                                    setGoalSummary(status.data);
                                    if (status.data.mandate_status == true) {
                                        toast.dismiss();
                                        toast.success("Mandate created successfully", { className: "e-toast", });
                                    } else {
                                        toast.dismiss();
                                        toast.error("Mandate creation failed try again after some time", { className: "e-toast", });
                                    }
                                } else {
                                    toast.dismiss();
                                    toast.error(status.message, { className: "e-toast", });
                                }
                            });

                            setIsDisabled(false);
                        };
                    }, 1000);

                } else {
                    setLoading(false);
                    setIsDisabled(false);
                    toast.dismiss();
                    toast.error(status.message, { className: "e-toast", });
                };
            });

            return;
        };

        // API Call to send OTP
        setFocusedInput(2)
        setLoading(true);
        _sendOTP();
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleInvest(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        let resend = true;
        _sendOTP(resend);
    };

    const _handleModalClose = () => {
        setToggleInvest(false);
        setIsPayingNow(true);
        setToggleDateModal(false);
        setSelectedSIPDate("");
        setPaymentMethod();
        setReinvestLumpsum(0);

        // To change the modal open status
        props.onCloseModal();
    };

    //handle invest MF 
    const _handleInvestGoal = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
            return;
        };

        // btn loader
        setLoading(true);
        setIsDisabled(true);
        // API Call for invest in selected goal
        _investGoal();
    };

    // API - send OTP 
    const _sendOTP = (resend) => {

        // API CALL from helper function
        const param = {
            type: "Goal"
        };

        _getOTP(param).then(status => {
            if (status.status_code === 200) {

                setOtpValue("");
                setOtpError("");
                setTimer(30);

                if (resend === true) {
                    setFocusedInput(2);
                    toast.dismiss();
                    let message_medium = userData.phone.country_code == "+91" ? " mobile" : " email"
                    toast.success(`OTP sent successfully to registered ${message_medium}`, {
                        className: "e-toast",
                    });

                } else {
                    setToggleInvest(true);
                };

            } else {
                toast.dismiss();
                toast.error(status.message, {
                    className: "e-toast",

                });
            };
            setLoading(false);
            setDisableResendOTP(false);
        });
    };

    // API -  investGoal 
    const _investGoal = () => {
        let is_post = true;
        let url = 'goal/invest';
        let data = {
            "type": props.reinvest === true ? "REINVEST" : null,
            "goal_id": goalId,
            "payment_type": isPayingNow ? selectedPaymentType : false,
            "otp": Number(otpValue),
            "platform": "web"
        };

        // props.reinvest === false : first investment : Re investment;
        if (props.reinvest === false) {
            data = {
                ...data,
                "sip": goalSummary.sip === null ? null : ({
                    "amount": goalSummary.sip.amount,
                    "date": selectedSIPDate,
                    "get_first_installment": goalSummary.lumpsum === null ? isPayingNow : false,
                }),
                "lumpsum": goalSummary.lumpsum === null ? null : ({ "amount": goalSummary.lumpsum.amount }),
            };
        } else {
            data = {
                ...data,
                "lumpsum": { "amount": reInvestLumpsum },
            };
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.data.payment_url) {

                    let payment_window = window.open(response.data.payment_url, "", "width=500,height=500,left=400,top=100");

                    if (payment_window === null || typeof payment_window === 'undefined') {
                        toast.dismiss();
                        toast.error("Please enable Pop-up to open payment window", { className: "e-toast", });
                        setIsDisabled(false);
                        setLoading(false);
                        otpInputRef.current.disabled = false;
                        return;
                    }

                    setLoading(false);
                    // Prevent user from changing otp
                    otpInputRef.current.disabled = true;

                    // Calling payment status api
                    let timer = setInterval(function () {

                        if (payment_window.closed) {
                            clearInterval(timer);
                            _paymentCallback(response.data.payment_id);
                        }
                    }, 1000);

                } else {
                    props.onCloseModal("ORDER_SUCCESS");
                    setIsDisabled(false);
                    setLoading(false);
                    otpInputRef.current.disabled = false;

                    // Updating notification
                    _getNotificationCount();
                }

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
                setIsDisabled(false);
                setLoading(false);
                otpInputRef.current.disabled = false;
            };
        });
    };

    // Payment callback API for checking payment status
    const _paymentCallback = (payment_id) => {

        let is_post = true;
        let url = 'payment/check-status';
        let data = {
            "payment_id": payment_id,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                if (response.data.status === true) {
                    props.onCloseModal("PAYMENT_SUCCESS");
                } else {
                    toast.dismiss();
                    toast.error(response.data.message, { className: "e-toast", });
                }

                setIsDisabled(false);
                otpInputRef.current.disabled = false;

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
                setIsDisabled(false);
                otpInputRef.current.disabled = false;
            };

            // Updating notification
            _getNotificationCount();
        });

    };

    return (
        <div className={`modal fade e-manage-modal`}
            id="invest-goal-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="GoalInvestLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                {
                    toggleInvest === false ?
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header border-0 justify-content-space-between py-3 px-4 ">
                                <h6 className='mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Invest details
                                </h6>
                                <img src={Close}
                                    id='goal-invest-modal-close'
                                    draggable={false}
                                    className='cursor-pointer'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={_handleModalClose}
                                />
                            </div>

                            {/* Body */}
                            <div className="d-flex flex-column gap-4 modal-body pb-4 px-4 pt-0">
                                <div className='e-display-flex gap-0-12px align-item-center e-bg-cultured e-border-radius-16 p-3'>
                                    <img src={goalSummary.icon}
                                        draggable={false}
                                        className="object-fit-contain p-1 e-border-radius-10 me-1 e-bg-white"
                                        width={40}
                                        height={40}
                                        alt='goal logo' />
                                    <div>
                                        <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-22px'>
                                            Life goal : <span>{goalSummary.goal}</span>
                                        </p>
                                    </div>
                                </div>

                                {/* Investment preview */}
                                {
                                    props.reinvest === false ? (
                                        <>
                                            <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600'>
                                                Investment preview
                                            </h6>
                                            <div className="row gap-1 e-bg-lavender  e-border-radius-16 p-3 m-0">
                                                <div className="d-flex p-0">
                                                    <div className="col-6">
                                                        <span className="e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px">
                                                            SIP amount :
                                                        </span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="e-text-egyptian-blue e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                            ₹{goalSummary.sip ? goalSummary.sip.amount.toLocaleString('en-IN') : "0"}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="d-flex p-0">
                                                    <div className="col-6">
                                                        <span className="e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px">
                                                            Tenure :
                                                        </span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="e-text-egyptian-blue e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                            {goalSummary.tenure}{goalSummary.tenure < 2 ? " yr" : " yrs"}
                                                        </span>
                                                    </div>

                                                </div>
                                                <div className="d-flex p-0">
                                                    <div className="col-6">
                                                        <span className="e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px">
                                                            Lumpsum amount :
                                                        </span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="e-text-egyptian-blue e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px">
                                                            ₹{goalSummary.lumpsum !== null ? goalSummary.lumpsum.amount.toLocaleString('en-IN') : 0}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="col e-bg-cultured e-border-radius-16 p-3">
                                            <CustomAmountInput require={true}
                                                label={"One time investment amount"}
                                                value={reInvestLumpsum}
                                                maxLength={8}
                                                errorMsg={errorMsgLumpsum}
                                                placeholder={"Lumpusm amount"}
                                                handleChange={_handleInputLumpsumChange} />

                                            {/* amount select  */}
                                            <div className="d-flex flex-wrap gap-2 pt-1">
                                                {AMOUNT_DATA.map((data, key) => (
                                                    <p key={key}
                                                        className={`${(reInvestLumpsum === data.amount) ?
                                                            'e-border-otp-active e-text-egyptian-blue' : 'cursor-pointer e-text-charleston-green e-border-1px-charleston-green'}
                                                            mb-0 px-2 py-1 e-border-radius-8  e-font-14 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                        onClick={() => _handleAmountSelect(data.amount)}>
                                                        ₹{data.amount.toLocaleString("en-IN")}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                }

                                {/* Monthly SIP */}
                                {
                                    props.reinvest == false && goalSummary.sip !== null &&
                                    <div className='e-bg-cultured e-border-radius-16 p-3 '>
                                        <div className="position-relative"
                                            id='sip-date-modal'>
                                            <CustomTextInput require={true}
                                                type={2}
                                                label='Monthly SIP date '
                                                value={selectedSIPDateString}
                                                placeholder="Choose date"
                                                className="cursor-pointer w-100 e-font-14 e-alt-font-poppins e-font-weight-500 no-outline"
                                                maxLength={8}
                                                readOnly={true}
                                                onClick={() => setToggleDateModal(status => !status)}
                                            />
                                            {
                                                toggleDateModal &&
                                                <div className="position-relative" >
                                                    <SIPDateModal
                                                        selectedSIPDate={selectedSIPDate}
                                                        sipDates={sipActiveDates}
                                                        className="position-absolute start-50 top-50 mt-2 mt-sm-3 translate-middle-x"
                                                        handleSelect={_handleSIPDateSelect}
                                                        handleCloseDateModal={_handleCloseDateModal}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {
                                            goalSummary.lumpsum === null &&
                                            <div>
                                                <p className='mb-2 pt-3 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                                    Would you like to proceed with the first instalment at this time?
                                                </p>

                                                {/* confirmation radio button for paying now */}
                                                <div className="d-flex e-gap-0-16">
                                                    {CONFIRM_PAYMENT.map((data, key) => (
                                                        <div key={key} className="e-display-flex gap-0-8 cursor-pointer"
                                                            onClick={() => _handleConfirmPayingNow(data.status)}>
                                                            <img src={isPayingNow === data.status ? Active : Inactive}
                                                                draggable="false"
                                                                className="width-24px width-20px-res"
                                                                alt="radio" />
                                                            <p className={` ${isPayingNow === data.status ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} e-font-14-res mb-0 e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                {data.label}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                                {/* Payment Method */}
                                {
                                    (isPayingNow || goalSummary.lumpsum > 0) &&
                                    <div>
                                        <h6 className='mb-3 e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-24px'>
                                            Payment Method
                                        </h6>
                                        <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                            {paymentMethod.map((data, key) => (
                                                <React.Fragment key={key}>
                                                    <div className="e-display-flex justify-content-between gap-0-8 cursor-pointer"
                                                        onClick={() => _handlePaymentMethod(data.slug)}>
                                                        <p className="d-flex align-items-center gap-2 e-text-charleston-green mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-22px">
                                                            <img src={data.image}
                                                                alt={`payment method ${data.label}`}
                                                                draggable="false" />
                                                            <span>{data.label}</span>
                                                        </p>
                                                        <img src={selectedPaymentType === data.slug ? Active : Inactive}
                                                            draggable="false"
                                                            className="width-24px width-20px-res"
                                                            alt="radio" />
                                                    </div>
                                                    {key == paymentMethod.length - 2 && paymentMethod.length > 1 &&
                                                        <hr style={{ borderColor: "#D1D1D6" }} />}
                                                </React.Fragment>
                                            ))}
                                        </div >
                                    </div>
                                }

                                {/* SIP Mandate Infromation */}
                                {
                                    goalSummary.mandate_status === false && goalSummary.sip !== null &&
                                    <div className="d-flex flex-column gap-2 mt-1 e-bg-cosmic-latte  e-border-radius-16 p-3">
                                        <h6 className='d-flex gap-2 align-items-center e-text-mustard-yellow e-font-weight-600 line-height-18px'>
                                            <Icon icon="info"
                                                size="1.5rem"
                                                color={Colors.mustard_yellow} />
                                            Information
                                        </h6>
                                        <div className='d-flex flex-column gap-2'>
                                            {
                                                SIP_MANDATE_INFO.map((item, index) => (
                                                    <p className='d-flex gap-1 e-alt-font-poppins e-text-onyx mb-0 e-font-14 e-font-weight-400 line-height-24px'>
                                                        {index + 1}{"."}
                                                        <span>{item}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }

                                <div className='mt-2'>
                                    <PrimaryButton name={goalSummary.mandate_status === false && goalSummary.sip !== null ? "Create Mandate" : "Request OTP"}
                                        loading={loading}
                                        className="w-100 padding-12px-top padding-12px-bottom"
                                        disabled={isDisabled}
                                        onPress={_handleToggleInvest} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-4 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='goal-invest-modal-close'
                                    data-bs-dismiss="modal"
                                    className='d-none'
                                    aria-label="close"
                                    alt='close btn' />
                                <img src={Back}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />
                                <h6 className='e-font-14-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                    Purchase confirmation OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-4 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                    {
                                        userData.phone.country_code == "+91" ?
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered mobile number.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {userData.phone.country_code} {userData.phone.number}
                                                </span>
                                            </p>
                                            :
                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                An OTP has been sent to your registered email.
                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                    {userData.email}
                                                </span>
                                            </p>
                                    }
                                    <div className='pt-3'>
                                        <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <OTPInput ref={otpInputRef}
                                            id="otp"
                                            value={otpValue}
                                            error={otpError}
                                            spellCheck="false"
                                            focused_input={focusedInput}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onChange={_handleChangeOTP} />

                                        <div className='e-inline-flex'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                disabled={disableResendOTP}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                {/* Mandate success msg for SIP */}
                                {
                                    mandateStatus == false && goalSummary.sip !== null &&
                                    <div className='d-flex gap-2 p-3 e-bg-nyanza-green e-border-radius-16 mt-3'>
                                        <Icon icon="mandate-success"
                                            size={"1.5rem"} />
                                        <p className='e-text-go-green e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px letter-spacing-3 mb-0'>
                                            Your mandate has been successfully created. Now, you can proceed with the first instalment
                                        </p>
                                    </div>
                                }
                                <div className='padding-36px-top'>
                                    <PrimaryButton name="Invest now"
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                        onPress={_handleInvestGoal} />

                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default memo(GoalInvestModal);