/*
 *   File : order.js
 *   Author : https://evoqins.com
 *   Description : Order card component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Components
import { Icon } from "../Icon";

//custom style
import styles from "../../Style/Component/card.module.scss";

const OrderCard = (props) => {

    const navigate = useNavigate();


    //handle navigate order detail 
    const _handleNavigateOrder = () => {
        navigate("order-detail", {
            state: {
                order_id: props.data.order_id
            }
        });
    };

    return (
        <div className={`${styles.e_fund_card_wrapper} h-100 transition cursor-pointer e-bg-anti-flash-white e-border-radius-16`}
            onClick={_handleNavigateOrder} >
            <div className={`${styles.e_fund_card} d-flex gap-3 px-3 pt-3 padding-20px-bottom`}>
                <img src={props.data.icon}
                    width={48}
                    height={48}
                    className="object-fit-contain e-bg-white p-1 e-border-radius-10 "
                    draggable="false"
                    alt="fund" />
                <div className="d-flex justify-content-between w-100">
                    <h3 className={`e-ellipse pt-1 mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px`}>
                        {props.data.is_goal === true ? `Life goal: ${props.data.name}` : `${props.data.name} `}
                    </h3>
                    <Icon icon="right-black-icon"
                        size="2rem"
                        className={`${styles.e_right_arrow} transition`} />
                </div>
            </div>

            <div className='row gap-1 flex-wrap px-3 align-items-center'>
                <div className='col'>
                    <p className='d-flex gap-1 flex-wrap mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                        Date:
                        <span className='e-text-charleston-green e-font-weight-600'>
                            {props.data.order_date}
                        </span>
                    </p>
                </div>

                <div className="col">
                    <p className="mb-0 e-text-white e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px w-max-content">
                        <span className='e-border-radius-48 e-bg-cyan-azure px-xl-3 px-lg-3 px-md-3 px-sm-2 px-2 py-1 e-chip-data'>
                            {props.data.order_no}
                        </span>
                    </p>
                </div>
            </div>

            <div className="row gap-2 flex-wrap p-3 mt-1">
                <div className="col">
                    <p className="mb-1 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                        Amount
                    </p>
                    <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                        {props.data.amount_text}
                    </p>
                </div>
                <div className="col">
                    <p className="mb-1 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                        Type
                    </p>
                    <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                        {props.data.type}
                    </p>
                </div>
                {props.data.is_goal === false &&
                    <div className='col'>
                        <p className="text-nowrap mb-1 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Avg. NAV
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            {props.data.nav ? `${props.data.nav}` : '-'}
                        </p>
                    </div>
                }
            </div>
            {/* border */}
            <div className={styles.e_border_bottom}></div>

            {/* payment status */}
            <div className='row p-3'>
                <div className='col'>
                    <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px w-max-content'>
                        Payment status
                    </p>
                    <div className='e-inline-flex'>
                        <Icon icon={props.data.payment_status === "Failed" ? "failed" : props.data.payment_status === "Pending" ? "pending" : "succesfull"}
                            size={"1.25rem"} />
                        <p className={`${props.data.payment_status === "Failed" ? 'e-text-sunset-orange' : props.data.payment_status === "Pending" ? "e-text-mustard-yellow" : 'e-text-ufo-green'} ps-1 mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                            {props.data.payment_status}
                        </p>
                    </div>
                </div>
                <div className='col'>
                    <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px '>
                        Order status
                    </p>
                    <div className='e-inline-flex'>
                        <Icon icon={props.data.order_status === "Failed" ? "failed" : props.data.order_status === "Pending" ? "pending" : "succesfull"}
                            size={"1.25rem"} />
                        <p className={`${props.data.order_status === "Failed" ? 'e-text-sunset-orange' : props.data.order_status === "Pending" ? "e-text-mustard-yellow" : 'e-text-ufo-green'} ps-1 mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                            {props.data.order_status}
                        </p>
                    </div>
                </div>

                {/* error message */}
                {props.data.payment_status === "Failed" && props.data.failure_reason &&
                    <div className='pt-3'>
                        <div className='d-flex e-bg-misty-rose e-border-radius-24 py-1 padding-12px-left'>
                            <img src={require("../../Assets/Images/Orders/error-message.svg").default} />
                            <p className='mb-0 ps-2 e-text-sunset-orange e-font-12 e-alt-font-poppins e-font-weight-500 line-height-22px'>
                                {props.data.failure_reason}
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderCard;