import { memo, useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { PrimaryButton } from '../Buttons';

const PORTFOLIO_FILTER_OPTIONS = [
    {
        id: null,
        label: "All"
    },
    {
        id: 1,
        label: "Fund"
    }, {
        id: 2,
        label: "Goal"
    }, {
        id: 3,
        label: "Basket"
    }
]

function PortfolioFilterModal(props) {

    const [selectedFilter, setSelectedFilter] = useState(props.selectedFilter);

    useEffect(() => {
        setSelectedFilter(props.selectedFilter);
    }, [props.selectedFilter]);

    const _handleOnClickCancelbtn = () => {
        props.handleSelectedFilters(props.selectedFilter, false);
    };

    const _handleOnClickApplybtn = () => {
        props.handleSelectedFilters(selectedFilter, true);
    };

    const _handleFilterTypeSelect = (selected_filter) => {
        setSelectedFilter(selected_filter);
    };

    return (
        <div className={`position-absolute pt-3 z-index-99 top-100 end-0 w-350px`}
            tabIndex="-1"
            aria-labelledby="portfolio-filter-modal"
            aria-hidden="true">
            <div className={`p-4 e-bg-white e-border-radius-24 e-box-shadow-filter-modal`}>
                <div className='d-flex justify-content-between'>
                    <h6 className='e-text-charleston-green e-alt-font-poppins e-font-16 e-font-weight-600 line-height-32px'>
                        Filter
                    </h6>
                    <div onClick={props.onClick}>
                        <Icon icon="close-btn-gray" size="2rem" className="cursor-pointer" />
                    </div>
                </div>
                <p className='e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-500 line-height-24px letter-spacing-5 mb-3'>
                    Filter investment level by
                </p>
                <div className='d-flex flex-wrap gap-3 margin-32px-bottom'>
                    {
                        PORTFOLIO_FILTER_OPTIONS.map((item, key) => (
                            <span className={`${selectedFilter.id == item.id ? "e-bg-lavender e-text-egyptian-blue e-font-weight-500" : "e-bg-cultured e-text-dark-liver e-font-weight-400"} cursor-pointer  e-alt-font-poppins e-font-14  line-height-24px e-border-radius-24 py-2 px-4`}
                                key={key}
                                onClick={() => _handleFilterTypeSelect(item)}>
                                {item.label}
                            </span>
                        ))
                    }

                </div>
                <div className="d-flex align-items-center gap-2 mt-2">
                    <PrimaryButton name="Cancel" onPress={_handleOnClickCancelbtn}
                        className="w-100 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray" />
                    <PrimaryButton name="Apply" className="w-100 padding-10px-tb" onPress={_handleOnClickApplybtn} />
                </div>
            </div>
        </div>
    );
};

export default memo(PortfolioFilterModal);