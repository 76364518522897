/*
 *   File : sip-dates.js
 *   Author : https://evoqins.com
 *   Description : SIP date modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

// Component
import { PrimaryButton } from "../Buttons";

const NORMALIZED_SIP_DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

const SIPDateModal = (props) => {

    const [selectedDate, setSelectedDate] = useState(props.selectedSIPDate);
    const [sipDateString, setSipDateString] = useState(props.selectedSIPDate);
    const [sipActiveDates, setSipActiveDates] = useState(props.sipDates);

    useEffect(() => {

        document.querySelector(".sip-date-picker").scrollIntoView({
            top: 0,
            behavior: "smooth",
            block: "center"
        });

        setSelectedDate(props.selectedSIPDate);

        let selected_date = props.selectedSIPDate || sipActiveDates[0];

        switch (selected_date) {
            case 1: return setSipDateString("1st");
            case 2: return setSipDateString("2nd");
            case 3: return setSipDateString("3rd");
            case 21: return setSipDateString("21st");
            case 22: return setSipDateString("22nd");
            case 23: return setSipDateString("23rd");
            default: return setSipDateString(props.selectedSIPDate + "th");
        };



    }, [props.selectedSIPDate]);

    const _handleOnSelect = (date) => {
        setSelectedDate(date);
    };

    const _handleConfirmSIPDate = () => {
        if (selectedDate != "") {
            props.handleSelect(parseFloat(selectedDate));
            props.handleCloseDateModal();
        };
    };

    return (
        <div id={props.id}
            className={`${props.className} w-100 sip-date-picker e-bg-lotion e-border-radius-12 e-modal-box-shadow p-3 p-sm-3 mt-1 z-3`} >
            <div className="row align-items-center mb-1">
                <div className="col">
                    <h6 className='e-font-14-res mb-0 ms-sm-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px'>
                        {sipDateString} of every month
                    </h6>
                </div>
                <div className="col-auto text-end">
                    <img src={require("../../Assets/Images/close-btn.svg").default}
                        draggable={false}
                        className="width-24px width-20px-res cursor-pointer"
                        alt='close'
                        onClick={props.handleCloseDateModal} />
                </div>
            </div>
            <div className="pt-2">
                <div className="e-profile-gradient d-grid-sip-date  p-2 p-sm-3 e-border-radius-12 text-center">
                    {NORMALIZED_SIP_DATES.map((date, key) => (
                        <div className={`mb-sm-2 ${selectedDate == date ? "e-bg-egyptian-blue e-text-white" : "e-sip-date"}  
                            ${sipActiveDates.includes(date) ? "" : "pe-none user-select-none opacity-50"} cursor-pointer e-font-res-date e-font-14 p-1 e-border-radius-4 e-bg-transparent`}
                            key={key}
                            onClick={() => _handleOnSelect(date)} >
                            {date}
                        </div>
                    ))}
                </div>
                <div className='pt-2 col-8 mx-auto'>
                    <PrimaryButton name="Confirm"
                        className="e-font-12-res py-2 w-100 e-primary-button-hover transition e-font-14"
                        onPress={_handleConfirmSIPDate} />
                </div>
            </div>
        </div>
    )
}

export default memo(SIPDateModal);