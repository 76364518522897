/*
 *   File : family.js
 *   Author : https://evoqins.com
 *   Description : Added family detail card
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { TextButton } from "../Buttons";


function FamilyCard(props) {

    const navigate = useNavigate();

    const _handleNavigate = () => {
        navigate("family-profile", {
            state: {
                family_detail_id: props.member.family_detail_id,
            }
        });
    };

    return (
        <div className="col padding-16px-all-res e-bg-cultured e-border-radius-16 padding-32px-all">
            <h6 className="break-word e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                {props.member.name}
            </h6>
            <p className="e-font-14-res e-font-16 e-alt-font-poppins line-height-24px">
                <span className="pe-1 e-text-sonic-silver e-font-weight-400">Joined on:</span>
                <span className="e-text-charleston-green e-font-weight-600">{props.member.joined}</span>
            </p>
            <div className="d-flex flex-wrap row-gap-4 e-bg-lavender e-border-radius-16 p-3">
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Current
                    </p>
                    <p className='break-word e-font-14-res mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px'>
                        {props.member.current_amount_text}
                    </p>
                </div>
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Invested
                    </p>
                    <p className='break-word e-font-14-res mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px'>
                        {props.member.invested_amount_text}
                    </p>
                </div>
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        Returns
                    </p>
                    <p className={`${props.member.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} break-word e-font-14-res d-flex flex-wrap mb-0  e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px`}>
                        {props.member.returns_amount_text}
                        <span className={`${props.member.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} e-font-14-res  e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                            ({props.member.returns_percentage_text})
                        </span>
                    </p>
                </div>
                <div className="col-6">
                    <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        XIRR
                    </p>
                    <p className={`${props.member.xirr >= 0 ? "e-text-ufo-green" : "e-text-red"} break-word e-font-14-res mb-0 e-primary-font-inter e-font-16 e-font-weight-600 line-height-24px`}>
                        {props.member.xirr_text}
                    </p>
                </div>

            </div>
            <div className="text-center mt-2 mt-sm-4 pt-2">
                <TextButton name="View details"
                    onClick={_handleNavigate} />
            </div>
        </div>
    );
};

export default memo(FamilyCard);