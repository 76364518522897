/*
 *   File : transaction-manager.js
 *   Author : https://evoqins.com
 *   Description : payment redirection page
 *   Integrations : NA
 *   Version : 1.0.0
*/

function TransactionManager() {

  const search = new URLSearchParams(window.location.search);
  const next_path = search.get("next_path");

  if (next_path) {
    window.location.href = next_path;
  } else {
    window.location.href = "/"
  };

  return null;
};

export default TransactionManager;