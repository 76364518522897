/*
 *   File : welcome.js
 *   Author : https://evoqins.com
 *   Description : Welcome modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';

//custom component
import { PrimaryButton } from '../Buttons';

// Assets
import CloseBtn from "../../Assets/Images/close-btn-white.svg";

const WelcomeModal = (props) => {

    return (
        <div className="modal fade"
            id="welcomeModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="welcomeModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-lg w-welcome-modal-res">
                <div className="modal-content e-login-gradient e-border-radius-32">
                    <div className="modal-header justify-content-end pt-4 pe-4 pb-2 border-0">
                        <img src={CloseBtn}
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onCloseModal} />
                    </div>
                    <div className="modal-body pt-0 padding-64px-bottom padding-bottom-24px-res ">
                        <div className='row px-0'>
                            <div className='col-sm-6 col-7 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 mx-auto offset-3 ps-xl-4 ps-lg-4 ps-md-4 ps-sm-0'>
                                <img src={require("../../Assets/Images/welcome.webp")}
                                    draggable={false}
                                    className='w-100 '
                                    alt='welcome' />
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 padding-40px-right ps-xl-0 ps-lg-0 ps-md-0 ps-sm-5 ps-3 pt-4 mt-2'>
                                <h3 className='e-font-22-res mb-2 e-text-charleston-green e-font-32 e-alt-font-poppins e-font-weight-700 line-height-40px'>
                                    Hey! Welcome
                                </h3>
                                <p className='mb-3 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                                    Invest smarter and build a stronger financial future for your family.
                                </p>
                                <h6 className='e-font-16-res mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                                    Why choose us?
                                </h6>

                                <ol className='e-font-14-res ps-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                    <li>Intelligent asset allocation.</li>
                                    <li>Disciplined rebalancing.</li>
                                    <li>Life goal-based planning.</li>
                                </ol>
                                <div className='pt-3 mt-1'>
                                    <PrimaryButton name="Get started"
                                        className="padding-14px-top padding-14px-bottom e-font-16 line-height-20px w-100 e-primary-button-hover transition"
                                        dismiss="modal"
                                        onPress={props.onCloseModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeModal