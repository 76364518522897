/*
 *   File : data-table.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Data table component
 *   Integrations : react-data-table-component
 *   Version : 1.0.0
 */

import React from 'react';
import DataTable from 'react-data-table-component';

const DataTableComponent = (props) => {

    return (
        <>
            <DataTable columns={props.columns}
                data={props.data}
                paginationPerPage={10}
                paginationTotalRows={props.paginationTotalRows}
                pagination={props.pagination}
                selectableRows={props.selectableRows}
                paginationIconPrevious={props.paginationIconPrevious}
                onSelectedRowsChange={(row) => props.selectRows(row)}
                paginationIconNext={props.paginationIconNext}
                onRowClicked={(e) => props.rowClick ? props.rowClick(e) : null}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                paginationComponentOptions={props.paginationComponentOptions}
                paginationIconLastPage={props.paginationIconLastPage}
                paginationIconFirstPage={props.paginationIconFirstPage} />
        </>
    );
}

export default DataTableComponent;