/*
 *   File : file-picker.js
 *   Author : https://evoqins.com
 *   Description : File picker component
 *   Integrations : NA
 *    Version : 1.0.0
*/

import React, { useState } from 'react';

const FilePicker = (props) => {


    //handle upload 
    const _handleUpload = () => {
        document.getElementById(props.id).click();
    };

    return (
        <div className='h-66px'>
            <div className='cursor-pointer padding-12px-top padding-12px-bottom px-3 e-bg-anti-flash-white e-border-radius-12'
                onClick={_handleUpload}>
                <input id={props.id}
                    ref={props.ref}
                    type={props.type}
                    key={props.key}
                    // multiple={props.multiple}
                    accept={props.accept}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        props.selectFile(e);
                    }}
                    onClick={(e) => e.target.value = ""} />

                <div className='e-display-flex justify-content-center gap-0-8 '>
                    <p className='e-font-12-res no-scrollbar text-nowrap overflow-scroll mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                        {props.label}
                    </p>
                    <img src={require("../../Assets/Images/KYC/upload-icon.svg").default}
                        draggable={false}
                        alt='upload' />
                </div>
            </div>
            <p className="mb-0 e-error-message e-font-12">
                {props.error}
            </p>
        </div>

    )
}

export default FilePicker;