/*
 *   File : dream-car.js
 *   Author : https://evoqins.com
 *   Description : Dream car goal page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";



//custom component
import { CustomAmountInput, CustomSlider } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import { Footer } from "../../Component/Footer";
import GoalSummary from "./goal-summary";
import { _setGoal } from "../../Helper";
import { BreadCrumbs } from "../../Component/Other";
import { APIService } from "../../Service";
import { toast } from "react-toastify";
import { Icon } from "../../Component/Icon";
import { SelectionSuggestModal } from "../../Component/Modal";

//assets
import Active from "../../Assets/Images/circle-active.svg";
import Inactive from "../../Assets/Images/circle-inactive.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";

// Color
import Color from "../../Style/color.scss";

const CONFIRM_SELECT = [
    {
        id: 1,
        label: "Yes",
    },

    {
        id: 2,
        label: "No",
    },
];

const LUMPSUM_AMOUNT = [
    {
        id: 1,
        amount: 2000,
        label: "₹2K"
    },

    {
        id: 2,
        amount: 5000,
        label: "₹5K"
    },

    {
        id: 3,
        amount: 10000,
        label: "₹10K"
    },
    {
        id: 4,
        amount: 25000,
        label: "₹25K"
    },
    {
        id: 5,
        amount: 100000,
        label: "₹1L"
    },
];



const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Life goals",
        path: "/goals"
    },
];

// Demo Data

const INVESTMENT_FOR = [
    {
        id: 1,
        display_name: "Myself"
    },
    {
        id: 2,
        display_name: "Ann Jayden"
    },
    {
        id: 3,
        display_name: "Jaince Jayden"
    }
];


const SpecialTuitionFund = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [goalId, setGoalId] = useState("");
    const [goalDetail, setGoalDetail] = useState({});
    const [goalSummary, setGoalSummary] = useState(false);
    const [target, setTarget] = useState("");
    const [targetAmtErr, setTargetAmtErr] = useState("");
    const [dreamCarTenure, setDreamCarTenure] = useState(1);
    const [lumpsum, setLumpsum] = useState("");
    const [lumpsumErr, setLumpsumErr] = useState("");
    const [lumpsumSelect, setLumpsumSelect] = useState(2);
    const [inflationSelect, setInflationSelect] = useState(1);
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const [selectedInvestMember, setSelectedInvestMember] = useState(INVESTMENT_FOR[0]);
    const [showInvestmentSelectModal, setShowInvestmentSelectModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

        if (location.state !== null) {
            setGoalId(location.state.goal_id);
        } else {
            navigate("/goals");
        };

    }, [location.state]);

    useEffect(() => {
        if (goalId !== "") {
            _getGoalDetails();
        }
    }, [goalId]);

    useEffect(() => {
        if (goalSummary === true) {
            navigate(`${location.pathname}#configure`, {
                state: {
                    goal_id: goalId,
                }
            });
        }
    }, [goalSummary]);

    useEffect(() => {
        const _handleOnEnter = (e) => {
            if (e.key === 'Enter' && goalSummary === false) {
                _handleCalculate();
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };
    }, [target, dreamCarTenure, lumpsum, lumpsumSelect, goalSummary]);

    useEffect(() => {

        if (showInvestmentSelectModal === true) {
            let invest_for = new Modal(document.getElementById('choose-investment-for-modal'));
            invest_for.show();
        };

    }, [showInvestmentSelectModal]);

    const _handleSelectInvestmentFor = (selected) => {
        setSelectedInvestMember(selected);
    };

    //handle cost 
    const _handleGoalTargetInput = (value) => {
        setTarget(value);
        setTargetAmtErr("");
    };

    //handle tenure slider
    const _handleTenureSlider = (value) => {
        setDreamCarTenure(value);
    };

    //handle lumpsum select
    const _handleLumpsumSelect = (id) => {
        setLumpsumSelect(id);
    };

    //handle lumpsum value
    const _handleLumpsum = (value) => {
        setLumpsum(value);
        setLumpsumErr("");
    };

    //handle inflation
    const _handleInflation = (id) => {
        setInflationSelect(id);
    };

    //handle calculate 
    const _handleCalculate = () => {

        let validation = true;

        if (target == 0) {
            setTargetAmtErr("Target amount cannot be zero");
            validation = false;
        };

        if (lumpsumSelect === 1 && lumpsum === 0) {
            setLumpsumErr("Please provide the lumpsum amount to proceed");
            validation = false;
        };

        if (lumpsumSelect === 1 && target < lumpsum) {
            toast.dismiss();
            toast.error("Target amount should be greater than lumpsum amount",
                { className: "e-toast" });
            validation = false;
        };

        if (validation === false) return;

        // disabling calculate btn 
        setDisabled(true);

        const params = {
            "goal_id": goalId,
            "target": target,
            "lumpsum": lumpsum,
            "sip_tenure": dreamCarTenure,
            "lumpsum_select": lumpsumSelect,
            "inflation_select": inflationSelect == 1 ? true : false
        };
        _setGoal(params).then(status => {
            if (status.status_code === 200) {
                setGoalSummary(true);
                toast.dismiss();
                toast.success("Goal saved", {
                    type: "success",
                    className: "e-toast",

                });
            } else {
                toast.dismiss();
                toast.error(status.message, {
                    type: "error",
                    className: "e-toast",

                });
            }

            // enabling calculate btn 
            setDisabled(false);
        });
    };

    //API- Post goal detail 
    const _getGoalDetails = () => {
        let is_post = true;
        let url = 'goal/get';
        let data = {
            "goal_id": goalId,
            "is_invested": false
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setGoalDetail(response.data);

                if (response.data.user_configured["target_amount"]) {
                    setTarget(response.data.user_configured.target_amount);
                } else {
                    setTarget(100000);
                }

                if (response.data.user_configured["lumpsum"]) {
                    setLumpsum(response.data.user_configured.lumpsum);
                } else {
                    setLumpsum(2000);
                };

                if (response.data.user_configured["tenure"]) {
                    setDreamCarTenure(response.data.user_configured.tenure);
                } else {
                    setDreamCarTenure(response.data.admin_configured.meta_data.tenure.default);
                };

                setBreadCrumbs([...BREADCRUMBS, { label: response.data.admin_configured.name }]);

                // Preserving current step if user reloads page
                if (location.hash) {
                    setGoalSummary(true);
                };

            } else {
                setGoalDetail({});
            }
            setLoading(false);
        });
    };

    return (
        <>
            {goalSummary !== true ?
                <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">

                    {loading ?
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                        :
                        <div className="col-lg-11 p-0 pb-4 pb-sm-5">
                            {/* breadcrumbs */}
                            <BreadCrumbs data={breadcrumbs} />

                            {/* flow of invest */}
                            <div className="padding-26px-top px-0 padding-36px-bottom">
                                <h4 className="e-font-16-res line-height-22px-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-500 letter-spacing-neg-2 line-height-30px">
                                    You are few steps away from creating {goalDetail.admin_configured.short_name} goal
                                </h4>
                                <div className="col-12 col-sm-10 col-md-7 d-flex  justify-content-between align-items-center padding-28px-top">
                                    <img src={require("../../Assets/Images/LifeGoals/details.svg").default}
                                        draggable="false"
                                        className="e-padding-30px-left-res padding-36px-left"
                                        alt="detail" />
                                    <div className="horizontal-rule"></div>
                                    <img src={require("../../Assets/Images/LifeGoals/suggestion.svg").default}
                                        draggable="false"
                                        alt="suggestion" />
                                    <div className="horizontal-rule"></div>
                                    <img src={require("../../Assets/Images/LifeGoals/invest.svg").default}
                                        draggable="false"
                                        alt="invest" />

                                </div>

                                <div className="col-12 col-sm-10 col-md-7  d-flex justify-content-between">
                                    <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        Enter your details
                                    </p>
                                    <p className="e-goal-step margin-28px-neg-left mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        SIP Suggestion
                                    </p>
                                    <p className="e-goal-step mb-0 e-text-yankees-blue e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                        Invest
                                    </p>
                                </div>
                            </div>

                            {/* detail section */}
                            <div className="p-3 p-sm-4 e-border-radius-16 e-bg-cultured ">
                                <div className="e-inline-flex e-gap-0-12px">
                                    <img src={goalDetail.admin_configured.icon}
                                        draggable="false"
                                        className="e-border-radius-8"
                                        width={48}
                                        alt="house" />
                                    <p className="e-font-14-res mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                        Life goal: {goalDetail.admin_configured.short_name}
                                    </p>
                                </div>

                                {/* Investment Select */}
                                <div className="d-flex align-items-center mt-4">
                                    <span className="me-2 mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                        Investment is for:
                                    </span>
                                    <span className="d-flex gap-0-6px cursor-pointer  align-items-center e-text-egyptian-blue e-font-18 e-alt-font-poppins e-font-weight-500 line-height-24px"
                                        onClick={() => setShowInvestmentSelectModal(true)}>
                                        {selectedInvestMember.display_name}
                                        <Icon icon="down-arrow" size={"2rem"} color={Color.charleston_green} />
                                    </span>
                                </div>

                                <div className="row pt-4">
                                    <div className="col-lg-6">

                                        {/* target amount input */}
                                        <CustomAmountInput label='Cost of special tuition fund*'
                                            inputClassName="py-1"
                                            value={target}
                                            errorMsg={targetAmtErr}
                                            maxLength={8}
                                            handleChange={(value) => { _handleGoalTargetInput(value) }} />
                                        <div>
                                            <p className="e-font-14-res line-height-22px-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-30px">
                                                Do you wish to invest a lumpsum amount now?
                                            </p>
                                            <div className="d-flex e-gap-0-16">
                                                {CONFIRM_SELECT.map((data, key) => (
                                                    <div key={key} className="e-display-flex gap-1 gap-sm-2 cursor-pointer"
                                                        onClick={() => _handleLumpsumSelect(data.id)}>

                                                        <img src={lumpsumSelect === data.id ? Active : Inactive}
                                                            draggable="false"
                                                            className="width-24px width-20px-res"
                                                            alt="radio" />
                                                        <p className={` ${lumpsumSelect === data.id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} e-font-14-res mb-0 e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                            {data.label}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                            {/* lumpsum amount input  */}
                                            {lumpsumSelect === 1 &&
                                                <>
                                                    <div className="pt-3">
                                                        <CustomAmountInput label='Lumpsum amount'
                                                            value={lumpsum}
                                                            errorMsg={lumpsumErr}
                                                            maxLength={8}
                                                            handleChange={(value) => _handleLumpsum(value)} />
                                                    </div>

                                                    <div className="d-flex gap-3 flex-wrap padding-12px-top">
                                                        {LUMPSUM_AMOUNT.map((data, key) => (
                                                            <p key={key} className={`${lumpsum === data.amount ? 'e-border-otp-active e-text-egyptian-blue' : 'cursor-pointer e-text-lavender-gray e-border-1px-lavender-gray'} e-font-14-res mb-0 px-2 px-sm-3 py-sm-1 e-border-radius-8  e-font-16 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                                onClick={() => {
                                                                    setLumpsum(data.amount);
                                                                    setLumpsumErr('');
                                                                }}>
                                                                {data.label}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    {/* tenure slider */}
                                    <div className="col-lg-6">
                                        <div className="mt-4">
                                            <div className="e-display-flex justify-content-space-between">
                                                <h4 className="mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                                    Tenure
                                                </h4>
                                                <p className="e-font-14-res mb-0 e-text-egyptian-blue e-font-16 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                    {dreamCarTenure} {dreamCarTenure === 1 ? 'Yr' : 'Yrs'}
                                                </p>
                                            </div>
                                            {/* tenure slider  */}
                                            <div className="padding-30px-top">
                                                <CustomSlider min={goalDetail.admin_configured.meta_data.tenure.min}
                                                    max={goalDetail.admin_configured.meta_data.tenure.max}
                                                    step={1}
                                                    defaultValue={dreamCarTenure}
                                                    handleChange={(value) => _handleTenureSlider(value)} />
                                                <div className="d-flex justify-content-space-between mt-4">
                                                    <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                        {goalDetail.admin_configured.meta_data.tenure.min} Yr
                                                    </p>
                                                    <p className="mb-0 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-14px">
                                                        {goalDetail.admin_configured.meta_data.tenure.max} Yrs
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="pt-4">
                                                <p className="pt-2 e-font-14-res line-height-22px-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-30px">
                                                    Inflation-adjusted amount(in {goalDetail.admin_configured.meta_data.inflation_rate}%)
                                                </p>

                                                {/* inflation select  */}
                                                <div className="d-flex e-gap-0-16">
                                                    {CONFIRM_SELECT.map((data, key) => (
                                                        <div key={key} className="e-display-flex gap-1 gap-sm-2 cursor-pointer"
                                                            onClick={() => _handleInflation(data.id)}>

                                                            <img src={inflationSelect === data.id ? Active : Inactive}
                                                                draggable="false"
                                                                className="width-24px width-20px-res"
                                                                alt="radio" />
                                                            <p className={` ${inflationSelect === data.id ? 'e-text-egyptian-blue' : 'e-text-sonic-silver '} e-font-14-res mb-0 e-font-16 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                {data.label}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="padding-40px-top col-lg-4">
                                        <PrimaryButton name="Calculate"
                                            loading={disabled === true ? true : false}
                                            disabled={disabled}
                                            className="w-100 padding-12px-top padding-12px-bottom"
                                            onPress={() => _handleCalculate()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                // goal summary page 
                <GoalSummary goal_name={goalDetail.admin_configured.short_name}
                    isLumpsum={lumpsumSelect == 1 ? true : false}
                    tenure={goalDetail.admin_configured.meta_data.tenure}
                    inflationSelect={inflationSelect == 1 ? true : false} />
            }

            {
                showInvestmentSelectModal &&
                <SelectionSuggestModal title="Investment is for*"
                    data={INVESTMENT_FOR}
                    type={1}
                    id={`choose-investment-for-modal`}
                    selectedOption={selectedInvestMember}
                    onSelectOption={_handleSelectInvestmentFor}
                    onModalClose={() => setShowInvestmentSelectModal(false)} />
            }


            {/* footer  */}
            <Footer />
        </>
    );
}
export default SpecialTuitionFund;