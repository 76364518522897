
// custom components
import APIService from "../Service/service";
import Store from "../Store/store";

// API - Get user profile data
export async function _getProfile(token) {
    return new Promise(async (resolve, reject) => {
        let is_post = false;
        let url = 'profile/get';
        let data = null;
        try {
            let response = await APIService(is_post, url, data, token);
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'UPDATE_USER_DATA',
                    data: response.data
                });
            };
            resolve(response);

        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

//API - Set goal
export async function _setGoal(param) {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = 'goal/set';
        let data = {
            "general_data": {
                "tenure": param.sip_tenure,
                "is_inflation_enabled": param.inflation_select
            },
            "goal_id": param.goal_id,
            "target_value": param.target,
            "lumpsum": {
                "amount": param.lumpsum_select === 1 ? param.lumpsum : null,
            },
            "sip": {
                "amount": param.sip,
            },
            "additional_data": {}
        };

        try {
            let response = await APIService(is_post, url, data);
            resolve(response);
        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - Create Mandate
export async function _createMandate() {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = 'mandate/check';
        let data = {
            "create": true,
            platform: "web"
        };

        try {
            let response = await APIService(is_post, url, data);
            resolve(response);
        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - GET MF Summary
export async function _mfSummary(param) {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = "mf/summary"
        let data = {
            "mf_id": param.mf_id,
            "type": param.type,
        };

        try {
            let response = await APIService(is_post, url, data);
            resolve(response);
        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - GET Goal Summary
export async function _goalSummary(param) {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = 'goal/summary';
        let data = {
            "goal_id": param.goal_id,
            "type": param.type,
        };

        try {
            let response = await APIService(is_post, url, data);
            resolve(response);
        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - GET Basket Summary
export async function _basketSummary(param) {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = 'baskets/summary';
        let data = {
            "basket_id": param.basket_id,
        };

        try {
            let response = await APIService(is_post, url, data);
            resolve(response);

        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - Get OTP
export async function _getOTP(param) {
    return new Promise(async (resolve, reject) => {
        let is_post = true;
        let url = 'consent/generate-2fa';
        let data = {
            type: param.type,
        };
        try {
            let response = await APIService(is_post, url, data);
            resolve(response);
        } catch (err) {
            resolve({ 'status': 'error' });
        };
    });
};

// API - Get user bank data
export async function _getGeneralData() {
    return new Promise(async (resolve, reject) => {
        let is_post = false;
        let url = 'general/get';
        let data = null;
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'GENERAL_DATA',
                    data: response.data
                });
            };
            resolve(response);

        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

// API - Get user bank data
export async function _getSignupProgress() {
    return new Promise(async (resolve, reject) => {
        let is_post = false;
        let url = 'profile/signup-progress';
        let data = null;
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'SIGNUP_PROGRESS',
                    data: response.data
                });
            };
            resolve(response);

        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};


//API- Get Filter data 
// API - Get user bank data
export async function _getFilterData() {
    return new Promise(async (resolve, reject) => {
        let is_post = false;
        let url = 'scheme/filter-data';
        let data = null;
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'EXPLORE_FILTER_DATA',
                    data: response.data
                });
            };
            resolve(response);

        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};

export async function _getNotificationCount() {
    return new Promise(async (resolve, reject) => {

        let is_post = false;
        let url = 'notification/count';

        try {
            let response = await APIService(is_post, url);
            if (response.status_code === 200) {
                resolve(response);
                Store.dispatch({
                    type: 'UPDATE_UNREAD_NOTIFICATION',
                    payload: response.data.notification_count
                });
            } else {
                resolve(response);
            }
        } catch (err) {
            resolve({ 'status': 'error' });
        }
    });
};


// API - Get questions list
// export async function _getRiskQuestions(param) {
//     return new Promise(async (resolve, reject) => {
//         let is_post = false;
//         let url = 'quiz/list-questions';
//         let data = {};
//         try {
//             let response = await APIService(is_post, url, data);
//             resolve(response);
//         } catch (err) {
//             resolve({ 'status': 'error' });
//         };
//     });
// };
