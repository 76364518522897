/*
 *   File : order-detail.js
 *   Author : https://evoqins.com
 *   Description : Order detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//custom components
import { BreadCrumbs } from '../../Component/Other';
import { Footer } from '../../Component/Footer';
import { APIService } from '../../Service';

//style
import styles from "../../Style/Component/card.module.scss";

//assets
import RightIcon from "../../Assets/Images/right-black-icon.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";
import { Icon } from '../../Component/Icon';

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",

    },
    {
        label: "Orders",
        path: "/orders",
    },
]

const OrderDetail = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [orderId, setOrderId] = useState("");
    const [orderDetail, setOrderDetail] = useState({});
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (location.state !== null) {
            setOrderId(location.state.order_id);
        } else {
            navigate("/orders");
        };

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location.state]);

    useEffect(() => {
        if (orderId !== "") {
            _getOrderDetail();
        };
    }, [orderId]);

    const _handleOnFundClick = (id) => {
        let path = location.pathname === "/explore" ? "fund-details" : "/explore/fund-details";
        navigate(path, {
            state: {
                fund_id: id,
            }
        });
    };

    //API- Post order detail 
    const _getOrderDetail = () => {
        let is_post = true;
        let url = 'order/detail';
        let data = {
            "order_id": orderId,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data);
                setBreadCrumbs([...BREADCRUMBS, { label: response.data.summary.name }]);
            } else {
                setOrderDetail({});
                navigate("/orders");
            };
            setLoading(false);
        });
    };

    return (
        <>
            <div className="min-h-100vh pb-4 pb-sm-5  row flex-column e-bg-lotion ps-xl-4 ps-lg-4 ps-md-4 ps-sm-4 ps-3 e-padding-right-res padding-80px-right padding-26px-top e-border-radius-TL-BL-24px">
                {/* breadcrumbs */}
                <BreadCrumbs data={breadcrumbs} />
                {
                    loading ? (
                        <div className="min-h-100vh text-center mt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                    ) : (
                        <div className='px-0 my-4'>
                            <div className='e-bg-cultured p-md-4 p-sm-3 p-3 e-border-radius-16'>
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                        <div className="e-display-flex">
                                            <img src={orderDetail.summary.icon}
                                                draggable={false}
                                                width={47}
                                                height={47}
                                                className='object-fit-contain e-bg-white p-1 e-border-radius-10'
                                                alt='icon' />
                                            <h3 className="e-ellipse ps-2 mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                {orderDetail.summary.is_goal === true ? `Life goal: ${orderDetail.summary.name}` : `${orderDetail.summary.name} `}
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2'>
                                        <div className='d-flex e-align-item-baseline e-gap-0-16 e-res-gap-0-8 e-order-detail-heading justify-content-end '>
                                            <p className='mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                                                Date:
                                                <span className='ps-1 e-text-charleston-green e-font-weight-600'>{orderDetail.summary.order_date}</span>
                                            </p>
                                            <p className="mb-0 e-border-radius-48 e-bg-cyan-azure px-sm-4 px-3 padding-10px-tb e-text-white e-font-14 e-alt-font-poppins e-font-weight-500 line-height-20px">
                                                {/* <span className=''> */}
                                                {orderDetail.summary.order_no}
                                                {/* </span> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-sm-4 mt-2 row'>
                                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6'>
                                        <p className="mb-1 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                            Amount
                                        </p>
                                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                            {orderDetail.summary.amount_text}
                                        </p>
                                    </div>

                                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6'>
                                        <p className="mb-1 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                            Type
                                        </p>
                                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                            {orderDetail.summary.type}
                                        </p>
                                    </div>

                                    <div className='pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2 pt-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6'>
                                        <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px w-max-content'>
                                            Payment status
                                        </p>
                                        <div className='e-inline-flex'>
                                            {/* <img src={orderDetail.summary.payment_status === "Failed" ? Failed : Success}
                                                draggable={false}
                                                alt='success' /> */}
                                            <Icon icon={orderDetail.summary.payment_status === "Failed" ? "failed" : orderDetail.summary.payment_status === "Pending" ? "pending" : "succesfull"}
                                                size={"1.25rem"} />
                                            <p className={`${orderDetail.summary.payment_status === "Failed" ? 'e-text-sunset-orange' : orderDetail.summary.payment_status === "Pending" ? "e-text-mustard-yellow" : 'e-text-ufo-green'} ps-1 mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                {orderDetail.summary.payment_status}
                                            </p>
                                        </div>
                                    </div>


                                    <div className='pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2 pt-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6'>
                                        <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px '>
                                            Order status
                                        </p>
                                        <div className='e-inline-flex'>
                                                <Icon icon={orderDetail.summary.order_status === "Failed" ? "failed" : orderDetail.summary.order_status === "Pending" ? "pending" : "succesfull"}
                                                size={"1.25rem"} />
                                            <p className={`${orderDetail.summary.order_status === "Failed" ? 'e-text-sunset-orange' : orderDetail.summary.order_status === "Pending" ? "e-text-mustard-yellow" : 'e-text-ufo-green'} ps-1 mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                {orderDetail.summary.order_status}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Funds card  */}
                            {
                                orderDetail.summary.is_goal === true &&
                                <div className='padding-38px-top'>
                                    <h4 className='mb-3  e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                        {orderDetail.funds.length > 1 ? ("Funds") : ("Fund")}
                                        ({orderDetail.funds.length})
                                    </h4>

                                    {
                                        orderDetail.summary.is_goal === true &&
                                        <div className='row'>
                                            {orderDetail.funds.map((data, key) => (
                                                <div key={key}
                                                    className='col-xl-4 col-lg-6 col-md-6 col-sm-7 col-12'
                                                    onClick={() => _handleOnFundClick(data.mf_id)}>
                                                    <div className={`${styles.e_fund_card_wrapper} transition cursor-pointer e-bg-anti-flash-white e-border-radius-16 mb-3`} >
                                                        <div className={`${styles.e_fund_card} row px-3 pt-3 padding-20px-bottom`}>
                                                            <div className="col-2 pe-0">
                                                                <img src={data.icon}
                                                                    width={48}
                                                                    height={48}
                                                                    className="object-fit-contain e-bg-white p-1 e-border-radius-10 "
                                                                    draggable="false"
                                                                    alt="fund" />
                                                            </div>
                                                            <div className="col-10 ps-0">
                                                                <div className="e-display-flex justify-content-space-between">
                                                                    <h3 className="e-ellipse ms-xl-3 ms-lg-2 ms-md-2 ms-sm-4 margin-res-card-title mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                                                                        {data.fund_name}
                                                                    </h3>
                                                                    <div>
                                                                        <img src={RightIcon}
                                                                            draggable="false"
                                                                            className={`${styles.e_right_arrow} transition`}
                                                                            alt="arrow" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row px-3 mb-3'>
                                                            <div className='col-6'>
                                                                <p className='mb-0 e-text-sonic-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px'>
                                                                    Amount
                                                                </p>
                                                            </div>

                                                            <div className="col-6 text-end">
                                                                <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                                                    {data.amount_text}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* border */}
                                                        <div className={styles.e_border_bottom}></div>

                                                        <div className="row p-3 mt-1 justify-content-between">
                                                            <div className='col-auto'>
                                                                <p className="mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                                    Units
                                                                </p>
                                                                <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                                                    {data.units}
                                                                </p>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <p className="mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                                    Avg. NAV
                                                                </p>
                                                                <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                                                    {data.nav_value_text}
                                                                </p>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <p className="mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                                    Transaction ID
                                                                </p>
                                                                <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                                                                    {data.transaction_id}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {/* payment status */}
                                                        <div className='row px-3 pb-3'>
                                                            <div className='col-xl-7 col-lg-7 col-md-7 col-7' >
                                                                <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px '>
                                                                    Allocated date
                                                                </p>
                                                                <p className='mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px'>
                                                                    {data.nav_date}
                                                                </p>
                                                            </div>
                                                            <div className='col-xl-5 col-lg-5 col-md-5 col-5'>
                                                                <p className='mb-0 e-text-dark-silver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px w-max-content'>
                                                                    Order status
                                                                </p>
                                                                <div className='e-inline-flex'>
                                                                    <Icon icon={orderDetail.summary.payment_status === "Failed" ? "failed" : orderDetail.summary.payment_status === "Pending" ? "pending" : "succesfull"}
                                                                        size={"1.25rem"} />
                                                                    <p className={`${orderDetail.summary.order_status === "Failed" ? 'e-text-sunset-orange' : orderDetail.summary.order_status === "Pending" ? "e-text-mustard-yellow" : 'e-text-ufo-green'} ps-1 mb-0 e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px`}>
                                                                        {orderDetail.summary.order_status}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                            }

                        </div>
                    )
                }
            </div>
            {/* footer */}
            <Footer />
        </>
    )
}

export default OrderDetail;