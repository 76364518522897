/*
 *   File : cancelled-sip.js
 *   Author : https://evoqins.com
 *   Description : Cancelled sip card component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React from 'react';

//styles
import styles from "../../Style/Component/card.module.scss";

const CancelledSIPCard = (props) => {


    return (
        <div className={`${styles.e_sip_card_wrapper} transition cursor-pointer e-bg-anti-flash-white-light e-border-radius-16 h-100`}            >
            <div className='d-flex gap-3 p-3'>
                <div>
                    <img src={props.data.icon}
                        width={48}
                        height={48}
                        className="object-fit-contain e-border-radius-10 e-bg-white p-1"
                        draggable="false"
                        alt="fund" />
                </div>
                <div>
                    <h3 className="e-ellipse mb-1 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-600 line-height-20px">
                        {props.data.name}
                    </h3>
                </div>
            </div>

            {/* calculated */}
            <div className={styles.e_border_bottom}></div>
            <div className='p-3'>

                <div className="row">
                    <div className='col-6'>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Amount
                        </p>
                        <p className="word-break mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            ₹{props.data.amount}
                        </p>
                    </div>

                    <div className='col-6'>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Created on
                        </p>
                        <p className="mb-0 e-text-charleston-green e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            {props.data.created}
                        </p>
                    </div>
                </div>


                <div className="row pt-3">
                    <div className='col-6 pe-0'>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            SIP date
                        </p>
                        <p className="mb-0 text-wrap e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                            {props.data.sip_date}
                        </p>
                    </div>

                    <div className='col-6     '>
                        <p className="mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                            Cancelled on
                        </p>
                        <p className="mb-0 e-text-sunset-orange e-font-16 e-primary-font-inter e-font-weight-600 line-height-24px">
                            {props.data.cancelled}
                        </p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default CancelledSIPCard;