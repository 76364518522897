/*
 *   File : amount-input.js
 *   Author : https://evoqins.com
 *   Description : Amount input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

//styles
import styles from "../../Style/Component/input.module.scss";

const CustomAmountInput = (props) => {

    const [value, setValue] = useState(props.value);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const _handleChange = (event) => {
        const input_value = event.target.value;

        // Removing space and comma
        const formatted_value = Number(input_value.replaceAll(",", ''));

        const maxValue = props.maxValue || 999999999;


        if (isNaN(formatted_value) === false) {
            if (props.type == 2 && (formatted_value <= maxValue)) {
                setValue(formatted_value);
                props.handleChange(formatted_value);
            } else {
                if ((formatted_value <= maxValue)) {
                    setValue(formatted_value);
                    props.handleChange(formatted_value);
                };
            };

        } else {
            setValue('');
            props.handleChange('');
        }
    };


    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed


        if (key_code == 9 || // Tab key
            (is_ctrl_pressed && (key_code == 65 || key_code == 67 || key_code == 88 || key_code == 86))
        ) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V    
            e.preventDefault();
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            // Allow the keypress
        } else {
            e.preventDefault();
        }
    };

    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };


    return (
        <div className={`${props.className} ${styles.e_amount_input_wrapper}`}>

            {/* label */}
            <label className={`${props.labelStyle} pb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px`}
                htmlFor={props.label}>
                {props.label}
                {
                    props.require &&
                    <span className='e-venetian-red'>*</span>
                }
            </label>

            <div className={`${isInputFocused ? "e-border-1px-egyptian-blue" : "e-border-1px-charleston-green"} ${props.inputClassName} d-flex cursor-text px-2 px-sm-3  e-border-radius-12`}>
                {
                    <p className="e-font-16-res padding-8px-tb-res padding-12px-tb  mb-0 e-text-charleston-green e-font-18 e-primary-font-inter e-font-weight-500  line-height-22px">
                        ₹
                    </p>
                }
                <input required
                    value={value && value.toLocaleString('en-IN')}
                    // value={props.value && props.value.toLocaleString('en-IN')}
                    id={props.label}
                    type="text"
                    className={`e-font-14-res padding-8px-tb-res padding-12px-tb  border-0 e-bg-transparent w-100 no-outline e-text-charleston-green e-font-18 e-primary-font-inter e-font-weight-500 line-height-22px`}
                    placeholder={props.placeholder}
                    disabled={props.isDisabled}
                    autoComplete="off"
                    onChange={_handleChange}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onKeyDown={_preventDefaults}
                />
            </div>

            {/* Error text */}
            <p className={`${props.errorMsg == "" ? "opacity-0" : "opacity-100"} e-error-message e-font-12 mb-0`}>
                {props.errorMsg == "" ? "no error message" : props.errorMsg}
            </p>
        </div>
    );
};

export default memo(CustomAmountInput);