/*
 *   File : home.js
 *   Author : https://evoqins.com
 *   Description : Home page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import OneSignal from "react-onesignal";


//custom component
import { MarketingBanner } from "../../Component/Banner";
import { SectionTitle } from "../../Component/Title";
import { FundCard, GoalCard, CategoryCard, BasketCard, PortfolioCard, KYCStatusCard } from "../../Component/Cards";
import { PrimaryButton, TextButton } from "../../Component/Buttons";
import { APIService } from "../../Service";
import { Calculator } from "../../Component/Calculator";
import { Footer } from "../../Component/Footer";
import { AccountOpeningModal, EditPhoneNumberModal, ExternalFundImportModal, HowItWorksModal, IndexFundModal, WelcomeModal } from "../../Component/Modal";
import { _getProfile, _getSignupProgress, _goalDetailPath } from "../../Helper";

//style
import styles from "../../Style/Component/dashboard.module.scss";

//assets
import Invest from "../../Assets/Images/Home/invest.svg";
import Loader from "../../Assets/Images/coloured_loader.svg";


const Dashboard = () => {

    //Get ACCOUNT_OPENING_MODAL_STATUS
    const ACCOUNT_OPENING_MODAL_STATUS = useSelector((store) => store.Reducer.ACCOUNT_OPENING_MODAL_STATUS);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [homeData, setHomeData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showIndexFundModal, setShowIndexFundModal] = useState(false);
    const [showAccountOpeningModal, setShowAccountOpeningModal] = useState(false);
    const [showImportPortfolioModal, setShowImportPortfolioModal] = useState(false);

    const [howWorkModal, setHowWorkModal] = useState(false);
    const [esignLoader, setEsignLoader] = useState(false);

    useEffect(() => {

        let player_id = OneSignal.User.PushSubscription.id;

        setLoading(true);
        _getHomeDetails();

        if (player_id) {
            _saveOnesignalID();
        }

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });

    }, []);

    useEffect(() => {

        let welcome_modal_status = localStorage.getItem("welcome-modal-enabled");

        if (welcome_modal_status === null) {
            setShowWelcomeModal(true);
        } else {
            if (homeData.length !== 0 && welcome_modal_status == "true") {
                if (homeData.kyc.status === 0 && ACCOUNT_OPENING_MODAL_STATUS === false && showWelcomeModal === false) {
                    setShowAccountOpeningModal(true);
                }
            }
        }

    }, [localStorage.getItem("welcome-modal-enabled"), homeData, ACCOUNT_OPENING_MODAL_STATUS, showWelcomeModal]);

    useEffect(() => {

        if (showWelcomeModal) {
            let welcomeModal = new Modal(document.getElementById('welcomeModal'));
            welcomeModal.show();
            localStorage.setItem("welcome-modal-enabled", "true");  //set local storage
        }

    }, [showWelcomeModal]);

    useEffect(() => {

        if (showIndexFundModal) {
            let index_fund = new Modal(document.getElementById('index-fund-modal'));
            index_fund.show();
        }

    }, [showIndexFundModal]);

    useEffect(() => {
        if (howWorkModal === true) {
            let works_modal = new Modal(document.getElementById('how-it-work-modal'));
            works_modal.show();
        }
    }, [howWorkModal]);

    useEffect(() => {
        if (showAccountOpeningModal === true) {
            let account_opening_modal = new Modal(document.getElementById('account-opening-modal'));
            account_opening_modal.show();
        }
    }, [showAccountOpeningModal]);

    useEffect(() => {
        if (showImportPortfolioModal === true) {
            let import_portfolio_modal = new Modal(document.getElementById('import-portfolio-modal'));
            import_portfolio_modal.show();
        }
    }, [showImportPortfolioModal]);

    // handle open how it work modal 
    const _handleWorksModal = () => {
        setHowWorkModal(true);
    };

    const _handleOnBasketClick = (basket_id) => {
        navigate("/baskets/basket-details", {
            state: {
                "basket_id": basket_id,
            }
        });
    };

    //handle Goal cards navigate
    const _handleGoalCardClick = (id, type) => {

        let path = _goalDetailPath(type);

        navigate(`goals/${path}`, {
            state: {
                goal_id: id,
            }
        });
    };

    const _handleOnClickCategories = (data) => {
        navigate("/explore", {
            state: {
                category: data.name,
            }
        });
    };

    //handle kyc card navigation
    const _handleKycStatus = (type) => {

        if (type === 0) {
            navigate('/kyc');
        } else if (type === 1 || type === 3) {
            navigate('/kyc');
        } else if (type === 2) {
            setEsignLoader(true);
            _createEsign();
        } else if (type === 5 || type === 4) {
            navigate('/explore');
        }
    };


    // handle learn how modal
    const _handleLearn = () => {
        setShowIndexFundModal(true);
    };

    // handle close welcome modal
    const _handleCloseWelcomeModal = () => {
        setShowWelcomeModal(false);

        if (homeData.length !== 0 && homeData.kyc.status === 0 && ACCOUNT_OPENING_MODAL_STATUS === false) {
            setShowAccountOpeningModal(true);
        }

    };

    // handle close welcome modal
    const _handleCloseAccountModal = () => {
        setShowAccountOpeningModal(false);
        dispatch({
            type: 'UPDATE_ACCOUNT_OPENING_MODAL_STATUS',
            payload: true,
        });
    };

    // handle close import portfolio modal
    const _handleCloseImportPortfolioModal = () => {
        setShowImportPortfolioModal(false);
    };

    //API- Get home data 
    const _getHomeDetails = () => {
        let is_post = false;
        let url = 'home/get';
        let data = null;
        let token = null;

        APIService(is_post, url, data, token).then((response) => {
            if (response.status_code === 200) {
                setHomeData(response.data);
            } else {
                setHomeData([]);
            }
            setLoading(false);
        });
    };

    //API- create e-sign 
    const _createEsign = () => {

        let is_post = false;
        let url = 'kyc/create-esign';
        let data = null;
        let token = null;

        APIService(is_post, url, data, token).then((response) => {
            if (response.status_code === 200) {

                let e_sign_window = window.open(response.data.redirect_url, '_blank');

                let timer = setInterval(function () {
                    if (e_sign_window.closed) {
                        clearInterval(timer);
                        setEsignLoader(false);
                        window.location.reload();
                    }
                }, 1000);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }
        });
    };

    //  API - Send oneSignal ID
    const _saveOnesignalID = () => {

        // Player id
        let player_id = OneSignal.User.PushSubscription.id;

        let is_post = true;
        let url = 'notification/save-id';
        let data = {
            "player_id": player_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                // console.log("One signal connected to DB");
            } else {
                // console.log("One signal connection failed");
            }
        });
    };

    return (
        <>

            <div className="row min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0  padding-44px-top e-border-radius-TL-BL-24px">
                {loading === true ?
                    <div className="min-h-100vh text-center mt-5 pt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>
                    :
                    <div className="col-lg-11 p-0">

                        {/* banner  */}
                        {
                            homeData.signup_progress === "INVESTED" ?
                                <PortfolioCard data={homeData.investment_data ? homeData.investment_data : {}} />
                                :
                                <MarketingBanner title="The smart way to invest in index funds and gain optimal returns."
                                    className="e-font-32 e-marketing-banner-title"
                                    bgColor="linear-gradient(180deg, #156DBA 0%, #084070 100%)"
                                    type={1}
                                    image={Invest} />
                        }

                        {/* kyc status card */}
                        {/* 
                            Show KYC card for 
                            1. KYC under progress (KYC started not completed) 
                            2. E-Sign
                            3. KYC Rejection
                            4. Under Review
                            5. Account Created

                            null - Default status will be null
                            0 - Default status and will be handling using the banner below
                            6 - Invested
                        */}
                        {
                            homeData.kyc.status !== 0 &&
                            homeData.kyc.status !== null &&
                            homeData.kyc.status !== 6 &&
                            <div className="pt-4 mt-2 px-0">
                                <KYCStatusCard type={homeData.kyc.status}
                                    loader={esignLoader}
                                    onPress={_handleKycStatus} />
                            </div>
                        }

                        {/* invest in basket  */}
                        <div className="pt-4 mt-2 px-0">
                            <SectionTitle title="1 Click Investing" />
                            <p className="mt-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                Fastest way to invest in Index Funds
                            </p>
                            <div className="row row-gap-4">
                                {homeData.basket.map((data, key) => (
                                    <div key={key} className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                        <BasketCard key={key}
                                            data={data}
                                            onClick={() => _handleOnBasketClick(data.basket_id)} />
                                    </div>
                                ))}
                            </div>
                            <p className="mt-3 e-text-davys-grey e-font-12 e-alt-font-poppins e-font-weight-400 line-height-16px" >
                                {homeData.returns_text}
                            </p>
                            <div className="text-center">
                                <TextButton name="See all"
                                    onClick={() => navigate("/baskets")} />
                            </div>
                        </div>

                        <div className={`${styles.e_works_banner} e-border-radius-16 mt-4`}>
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center flex-wrap gap-3  p-md-4 p-sm-3 p-3">
                                <div className="col-auto">
                                    <div className="d-flex align-items-center e-learn-banner-wrapper">
                                        <img src={require("../../Assets/Images/Home/works-book.svg").default}
                                            className="width-40px-res align-self-start"
                                            draggable="false"
                                            alt="book" />
                                        <div className="padding-12px-left">
                                            <h3 className="pe-sm-3 mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-22px">
                                                How it works?
                                            </h3>
                                            <p className="mb-0 e-text-onyx e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                                Start your investment journey here
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* button  */}
                                <div className="">
                                    <PrimaryButton name="Learn how"
                                        className="padding-32px-lr py-2 py-sm-3 e-font-16 e-text-charleston-green e-bg-white e-alt-font-poppins e-font-weight-600 line-height-14px e-secondary-button-hover transition"
                                        onPress={_handleWorksModal} />
                                </div>
                            </div>
                        </div>

                        {/* invest in life goals  */}
                        <div className="pt-4 mt-1 px-0">
                            <SectionTitle title="Invest in smart goals." />
                            <div className="row row-cols-1 row-cols-sm-2 pt-4 mt-1">
                                {
                                    homeData.goal.map((data, key) => (
                                        <div key={key}
                                            onClick={() => _handleGoalCardClick(data.goal_id, data.short_name)}>
                                            <GoalCard key={key}
                                                data={data} />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="text-center">
                                <TextButton name="See all"
                                    onClick={() => navigate("/goals")} />
                            </div>
                        </div>

                        {/* explore top fund  */}
                        <div className="py-4 mt-sm-1 px-0">
                            <SectionTitle title="Explore funds" className="mb-sm-3" />
                            <div className="row row-gap-40px flex-nowrap flex-sm-wrap overflow-auto e-manage-scrollbar pt-sm-3 pt-4 pb-2 pb-sm-0">
                                {
                                    homeData.fund_list.map((data, key) => (
                                        <div key={key}
                                            className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                                            <FundCard key={key}
                                                data={data} />
                                        </div>
                                    ))
                                }
                            </div>
                            {/* Text button  */}
                            <div className="text-center mt-3 pt-sm-1">
                                <TextButton name="View all"
                                    onClick={() => navigate("/explore")} />
                            </div>
                        </div>

                        {/* learn how banner */}
                        <div className={`${styles.e_learn_banner} e-border-radius-16 `}>
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center flex-wrap gap-3  p-md-4 p-sm-3 p-3">
                                <div className="col-auto">
                                    <div className="d-flex align-items-center e-learn-banner-wrapper">
                                        <img src={require("../../Assets/Images/Home/learn-book.svg").default}
                                            className="width-40px-res align-self-start"
                                            draggable="false"
                                            alt="book" />
                                        <div className="padding-12px-left">
                                            <h3 className="pe-sm-3 mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-22px">
                                                Learn how an index fund works.
                                            </h3>
                                            <p className="mb-0 e-text-onyx e-alt-font-poppins e-font-14 e-font-weight-400 line-height-14px">
                                                Start your investment journey here
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* button  */}
                                <div className="">
                                    <PrimaryButton name="Learn how"
                                        className="padding-32px-lr py-2 py-sm-3 e-font-16 e-text-charleston-green e-bg-white e-alt-font-poppins e-font-weight-600 line-height-14px e-secondary-button-hover transition"
                                        onPress={_handleLearn} />
                                </div>
                            </div>
                        </div>

                        {/* index fund categories  */}
                        <div className="pt-4 mt-1  px-0">
                            <SectionTitle title="Index fund categories" />
                            <div
                                className="d-flex gap-26px gap-12-res flex-nowrap mt-1 pt-3 pt-sm-4 overflow-scroll no-scrollbar">
                                {
                                    homeData.mf_categories.map((data, key) => (
                                        <CategoryCard key={key}
                                            cardData={data}
                                            onClick={() => _handleOnClickCategories(data)} />
                                    ))
                                }
                            </div>
                        </div>

                        {/* banner  */}
                        {
                            homeData.kyc.status == 0 &&
                            <div className="px-0 pt-3 my-3">
                                <MarketingBanner title="Get various index funds that suit your investment objectives."
                                    className="e-font-24 e-marketing-banner-title"
                                    type={2}
                                    bgColor="#0D2AA6"
                                    image={require("../../Assets/Images/Home/create-account.svg").default} />
                            </div>
                        }

                        <div className="px-0">
                            <div className="py-4 mt-1">
                                <SectionTitle title="SIP/Lumpsum calculator" />
                            </div>

                            {/* SIP/Lumpsum Calculator */}
                            <Calculator />
                        </div>

                        {/* Import portfolio banner  */}

                        {
                            false &&
                            <div className="padding-70px-top padding-54px-bottom px-0">
                                <MarketingBanner
                                    title="Import portfolio"
                                    type={3}
                                    className="e-font-24 mb-2 e-text-charleston-green e-marketing-banner-title "
                                    bgColor="#D9DBFF"
                                    image={require("../../Assets/Images/Home/portfolio.svg").default}
                                    handleImportClick={() => setShowImportPortfolioModal(true)} />
                            </div>
                        }
                    </div>
                }
            </div >

            {/* footer  component */}
            < Footer />

            {/* welcome modal  */}
            {
                showWelcomeModal &&
                < WelcomeModal onCloseModal={_handleCloseWelcomeModal} />
            }

            {/* learn how modal */}
            {
                showIndexFundModal &&
                <IndexFundModal onCloseModal={() => setShowIndexFundModal(false)} />
            }

            {/* how it works modal  */}
            {
                howWorkModal === true &&
                <HowItWorksModal onCloseModal={() => setHowWorkModal(false)} />
            }

            {
                showAccountOpeningModal &&
                <AccountOpeningModal handleModalClose={_handleCloseAccountModal} />
            }

            {
                showImportPortfolioModal &&
                <ExternalFundImportModal onClose={_handleCloseImportPortfolioModal} />
            }
        </>
    )
}
export default Dashboard;