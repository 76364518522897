/*
 *   File : nominee-list.js
 *   Author : https://evoqins.com
 *   Description : Nominee detail page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom component
import { _checkUserAccess, _getProfile } from '../../Helper';
import { PrimaryButton } from '../../Component/Buttons';
import { ManageNomineeModal } from '../../Component/Modal';

// assets 
import Loader from "../../Assets/Images/coloured_loader.svg";

const NomineeList = (props) => {

    const [nomineeData, setNomineeData] = useState(props.data.nominee);
    const [showNomineeModal, setShowNomineeModal] = useState(false);
    // Edit access to user
    const [editAccess, setEditAccess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        _getProfileData();
    }, []);

    useEffect(() => {

        // Removing backdrop form UI when user goes back in history while filter modals opened
        window.addEventListener('popstate', function () {
            setShowNomineeModal(false);
        });

    }, [showNomineeModal]);

    useEffect(() => {
        setNomineeData(props.data.nominee);

        const editAccess = _checkUserAccess(); // will return boolean value
        setEditAccess(editAccess);

    }, [props.data]);

    useEffect(() => {
        if (showNomineeModal === true) {
            const nominee_modal = new Modal(document.getElementById('manage-nominee-modal'));
            nominee_modal.show();
        };
    }, [showNomineeModal]);

    //add nominee modal
    const _handleNomineeModal = () => {
        let status = props.data.signup_progress_status;
        if (status == "ACCOUNT_CREATED" || status == "INVESTED") {
            setShowNomineeModal(true);
        };
    };

    const _handleCloseModal = (status) => {
        setShowNomineeModal(false);
        if (status === "SUCCESS") {
            _getProfileData();
        };
    };

    // API - Get profile data
    const _getProfileData = () => {

        // Helper function to get profile data
        _getProfile().then(response => {
            if (response.status_code == 200) {
                setNomineeData(response.data.nominee);
            } else {
                setNomineeData([]);
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }
            setLoading(false);
        });

    };

    return (
        <>
            {
                loading ?
                    <div className="min-h-100vh text-center mt-5" >
                        <img src={Loader}
                            draggable="false"
                            alt="loader" />
                    </div>
                    :
                    editAccess === true &&
                    <div >
                        <div className='row gap-2 gap-sm-0 flex-wrap justify-content-between align-items-center'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col'>
                                <h3 className='text-nowrap mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                    Manage nominee
                                </h3>
                            </div>
                            <div className='col-auto'>
                                <PrimaryButton name="Edit or Add nominee"
                                    className="px-4 padding-12px-top padding-12px-bottom"
                                    onPress={_handleNomineeModal} />
                            </div>
                        </div>

                        <div className='mt-4 e-border-radius-16 e-bg-cornsilk p-3'>
                            <div className='d-flex gap-0-8 e-alignflex-start'>
                                <img src={require("../../Assets/Images/Profile/hint.svg").default}
                                    draggable={false}
                                    className='pt-1'
                                    alt='hint' />
                                <p className='mb-0 e-text-ochre e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px letter-spacing-3' >
                                    To comply with regulations, it is mandatory to do 2-factor authentication (2FA) when opting in or out of nominees.
                                </p>
                            </div>
                        </div>

                        {
                            nomineeData.length !== 0 ?
                                <div className='row pt-3'>
                                    {nomineeData.map((data, key) => (
                                        <div key={data.nominee_id}
                                            className='col-xl-6 col-lg-8 col-md-10 col-sm-6 col-12 '>
                                            <div key={key} className='e-bg-cultured e-border-radius-16 p-4 mb-4'>
                                                <h4 className='mb-0 e-text-dark-liver e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                    Nominee {key + 1}
                                                </h4>
                                                <div className='row pt-3'>
                                                    <div className='col-6'>
                                                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px '>
                                                            Nominee name
                                                        </p>
                                                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                            {data.name}
                                                        </p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            Relation
                                                        </p>
                                                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                            {data.relation_name}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    <div className='col-6'>
                                                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            Allocation
                                                        </p>
                                                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                            {data.allocation}%
                                                        </p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                            Date of birth
                                                        </p>
                                                        <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                            {data.dob}
                                                        </p>
                                                    </div>

                                                </div>

                                                {
                                                    data.guardian_name &&


                                                    <div className='row pt-3'>
                                                        <div className='col-6'>
                                                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                Guardian name
                                                            </p>
                                                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                                {data.guardian_name}
                                                            </p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p className='mb-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                Guardian relation
                                                            </p>
                                                            <p className='mb-0 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                                                                {data.guardian_relationship_name}
                                                            </p>
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='col-6 mt-4 e-bg-cultured p-3 e-border-radius-16'>
                                    <p className='e-font-14-res mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-500 line-height-22px letter-spacing-3' >
                                        Nominee OptIn
                                    </p>
                                    <p className='mb-0 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px letter-spacing-3' >
                                        No
                                    </p>
                                </div>
                        }
                    </div >
            }

            {/* manage nominee modal */}
            {
                showNomineeModal &&
                <ManageNomineeModal onClose={_handleCloseModal} />
            }
        </>
    )
}

export default NomineeList;