/*
 *   File : kyc-status.js
 *   Author : https://evoqins.com
 *   Description : kyc status modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React from 'react';
import { useNavigate } from 'react-router-dom';

//custom component
import { PrimaryButton } from '../Buttons';

const KycStatusModal = (props) => {

    const navigate = useNavigate();

    //handle to navigate home
    const _handleNavigate = () => {
        navigate("/");
    };

    return (
        <div className="modal fade"
            data-bs-backdrop="static"
            id="kyc-status-modal"
            tabIndex="-1"
            aria-labelledby="kycStatusModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-md">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-body text-center p-4">

                        <img src={props.image}
                            draggable={false}
                            className='padding-54px-top col-5'
                            alt='bank' />
                        <p className='mb-2 pt-4 mt-1 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            {/* Congratulations! */}
                            {props.title}
                        </p>
                        <p className='mb-0 px-xl-5 px-lg-5 px-md-5 px-sm-0 px-0 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            {props.description}
                        </p>

                        <div className='padding-34px-top'>
                            <PrimaryButton name="View Home"
                                className="w-100 padding-12px-top padding-12px-bottom"
                                dismiss="modal"
                                onPress={_handleNavigate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KycStatusModal