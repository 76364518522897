/*
 *   File : cancel-sip-confirmation.js
 *   Author : https://evoqins.com
 *   Description : sip cancel confirmation modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';

//custom component
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { CustomTextInput } from '../Form-Elements';
import SelectionSuggestModal from './select-suggestion';

const SIPConfirmationModal = (props) => {

    const [sipCancelReasons, setSipCancelReasons] = useState(props.sipCancelReasons);
    const [selectedReason, setSelectedReason] = useState([]);
    const [reasonError, setReasonError] = useState("");
    const [showReasonModal, setShowReasonModal] = useState(false);

    useEffect(() => {
        setSipCancelReasons(props.sipCancelReasons);
    }, [props]);

    useEffect(() => {

        if (showReasonModal === true) {
            let reason_modal = new Modal(document.getElementById(`select-sip-cancel-reason-modal`));
            reason_modal.show();
        };


    }, [showReasonModal]);

    // handle cancel sip 
    const _handleCancel = () => {
        if (selectedReason.length == 0) {
            setReasonError("Please select cancellation reason");
            return;
        }
        props.cancelSip(selectedReason);
    }

    // handle reason select  
    const _handleSelectReason = (value) => {
        setSelectedReason(value);
        setReasonError("");
    };


    return (
        <div className="modal fade"
            id="sip-confirmation-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="sipConfirmationModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-md ">
                <div className="modal-content min-h90vh e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0">
                        <h6 className='mb-2 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            Confirm SIP Cancellation
                        </h6>
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='sip-confirmation-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="close"
                            alt='close btn'
                            onClick={props.onClose} />
                    </div>
                    <div className="modal-body px-4 pb-4 pt-2 d-flex flex-column">
                        <div className='h-90 position-relative mb-sm-2'>
                            <CustomTextInput label="Select cancellation reason*"
                                placeholder="Select reason"
                                dropdown={true}
                                type="text"
                                value={selectedReason.name}
                                errorMsg={reasonError}
                                readOnly
                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                labelStyle="e-font-weight-500"
                                onClick={() => setShowReasonModal(true)} />
                        </div>
                        <p className='mt-2 mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Are you really want to cancel SIP? This will blowback your wealth creation journey.
                        </p>

                        <div className='text-end pt-3'>
                            <SecondaryButton name="No, Cancel"
                                id="sip-confirmation-close"
                                className="me-2 padding-10px-top padding-10px-bottom e-border-bright-gray px-4"
                                dismiss="modal"
                                onPress={props.onClose} />

                            <PrimaryButton name="Yes, I want"
                                className="padding-10px-top padding-10px-bottom px-4"
                                onPress={_handleCancel} />
                        </div>

                    </div>
                </div>
            </div>

            {
                showReasonModal &&
                <SelectionSuggestModal title="SIP cancel Reason"
                    backdrop={"false"}
                    data={sipCancelReasons}
                    type={10}
                    id={`select-sip-cancel-reason-modal`}
                    selectedOption={selectedReason}
                    onSelectOption={_handleSelectReason}
                    onModalClose={() => setShowReasonModal(false)} />
            }

        </div>
    )
}

export default SIPConfirmationModal;