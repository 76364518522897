/*
 *   File : tab.js
 *   Author : https://evoqins.com
 *   Description : Custom tab component
 *   Integrations : NA
 *   Version : 1.0.0
*/

const CustomTab = (props) => {
    return (
        <p className={`e-font-14-res mb-0 pb-2 me-4 e-font-16 e-alt-font-poppins e-font-weight-600 line-height-14px 
         ${props.tabIndex === props.tabData.id ? 'show-border e-text-egyptian-blue' : 'no e-text-dark-liver cursor-pointer'}`}
            onClick={() => props.handleChange(props.tabData.id)} >
            {props.tabData.label}
        </p>
    )
}
export default CustomTab;