/*
 *   File : kyc.js
 *   Author : https://evoqins.com
 *   Description : Kyc page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

//custom components
import { BreadCrumbs } from '../../Component/Other';
import { Footer } from '../../Component/Footer';
import { APIService } from '../../Service';
import { _getSignupProgress } from '../../Helper';
import { PAN, Summary, PersonalDetail, AddAddress, AddBank, AddNominee, Digilocker } from './Helper';

//assets
import Loader from "../../Assets/Images/coloured_loader.svg";

const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Account setup",
    },
];

const KYC = () => {

    //get progress data form redux
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [kycStep, setKycStep] = useState(0);
    const [countryData, setCountryData] = useState([]);
    // KYC verification
    const [isPanFailed, setIsPanFailed] = useState(false);
    const [progressData, setProgressData] = useState([]);

    useEffect(() => {
        _getCountry();
        _getSignupProgress().then((response) => {
            setProgressData(response.data);
            let status = response.data.kyc_data.status;
            let current_step = response.data.current_step;

            if (status === "KYC_SUBMITTED" || status === "KYC_ESIGN_REQUIRED" || status === "INVESTED" || status === "ACCOUNT_CREATED") {
                navigate("/", {
                    replace: true,
                });
                setKycStep(1);
            } else if (current_step >= 0 && current_step < 9) {

                if (current_step === 0) {
                    setKycStep(0);
                } else {
                    setKycStep(1);
                }
            } else {
                navigate("/", {
                    replace: true,
                });
                setKycStep(1);
            }
            setLoading(false);
        });
    }, []);

    //handle account setup flow
    const _handleUpdateStep = (id) => {
        setKycStep(id);

        // When we get pan error from verification follow this flow
        // Summary page => pan page => summary page => personal details page
        // To avoid looping send below data to summary page

        let is_pan_err = progressData.step_detail[0].error == true ? true : false;
        if (is_pan_err) {
            setIsPanFailed(true);
        };

    };

    //API- Get countries
    const _getCountry = () => {
        let is_post = false;
        let url = 'general/countries';
        let data = null;

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryData(response.data);
            } else {
                setCountryData([]);
            }
        });
    };

    return (
        <>
            <div className="row min-h-100vh pb-4 pb-sm-5 d-flex flex-column e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px" >
                {
                    loading === true ?
                        <div className="min-h-100vh text-center mt-5 pt-5" >
                            <img src={Loader}
                                draggable="false"
                                alt="loader" />
                        </div>
                        :
                        <>
                            {/* breadcrumbs */}
                            <BreadCrumbs data={BREADCRUMBS} />
                            <div className='col-lg-11 p-0'>
                                {kycStep === 0 ?
                                    <PAN onUpdateStep={_handleUpdateStep} />
                                    :
                                    kycStep === 1 ?
                                        <Summary onUpdateStep={_handleUpdateStep} isPanFailed={isPanFailed} />
                                        :
                                        kycStep === 2 ?
                                            <PersonalDetail onUpdateStep={_handleUpdateStep} />
                                            :
                                            kycStep === 3 ?
                                                <Digilocker onUpdateStep={_handleUpdateStep} />
                                                :
                                                kycStep === 4 ?
                                                    <AddAddress country={countryData}
                                                        onUpdateStep={_handleUpdateStep} />
                                                    :
                                                    kycStep === 5 ?
                                                        <AddBank onUpdateStep={_handleUpdateStep} />
                                                        :
                                                        kycStep === 6 ?
                                                            <AddNominee onUpdateStep={_handleUpdateStep} />
                                                            :
                                                            null
                                }
                            </div>
                        </>
                }
            </div>

            {/* footer */}
            <Footer />
        </>
    );
};

export default KYC;