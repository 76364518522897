/*
 *   File : text-area.js
 *   Author : https://evoqins.com
 *   Description : Text area component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

//custom style
import styles from "../../Style/Component/input.module.scss";

function CustomTextAreaInput(props) {

    const [inputValue, setInputValue] = useState(props.value);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.value;
        setInputValue(value);
        props.handleChange(value);
    };

    return (
        <div>
            {props.label &&
                <label className={`${props.labelStyle} e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                    {props.label}
                    {props.require && <span className='e-venetian-red'>*</span>}
                </label>
            }

            <div className={`${props.errorMsg && 'border-red-1px '} ${isInputFocused ? "e-border-1px-egyptian-blue" : "e-border-1px-charleston-green"}  d-flex align-items-center cursor-pointer e-border-radius-12`}
                onClick={props.onClick}>
                <textarea
                    name="description"
                    cols={props.col}
                    rows={props.row}
                    value={inputValue}
                    id={props.id}
                    required={props.required}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    disabled={props.disabled}

                    className={`${props.className} ${styles.text_area_placeholder} no-outline resize-none w-100 e-font-14-res e-font-16 padding-8px-all-res px-3 border-0  bg-transparent padding-12px-tb border-radius-4px e-bg-white e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-22px`}
                    onChange={_handleChange}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                >

                </textarea>
            </div>

            {/* Error text */}
            <p className={`${props.errorMsg === "" ? "opacity-0" : "opacity-100"} h-6 mb-0 e-error-message e-font-12 line-height-12px padding-2-top`}>
                {props.errorMsg}
            </p>
        </div>
    )
}

export default memo(CustomTextAreaInput);

CustomTextAreaInput.defaultProps = {
    col: "10",
    row: "10",
}