import React, { useEffect, useState } from 'react'
import { BreadCrumbs } from '../../Component/Other';
import { Footer } from '../../Component/Footer';
import { CustomDateRangePicker, CustomTextInput} from '../../Component/Form-Elements';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';
import { toast } from 'react-toastify';


// Custom Data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Report",
        path: "/report"
    },
];

function Report() {

    const [capitalDateRange, setCapitalDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [transactionDateRange, setTransactionDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [selectedCapitalRange, setSelectedCapitalRange] = useState("");
    const [selectedTransactionRange, setSelectedTransactionRange] = useState("");

    const [capitalDateError, setCapitalDateError] = useState("");
    const [transactionDateError, setTransactionDateError] = useState("");
    const [showCapitalDatePicker, setShowCapitalDatePicker] = useState(false);
    const [showTransactionDatePicker, setShowTransactionDatePicker] = useState(false);

    const [capitalLoading, setCapitalLoading] = useState(false);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [holdingsLoading, setHoldingsLoading] = useState(false);

    // Closing modal when user clicks outside
    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        const _handleDatePicker = (e) => {
            if (e.target.closest("#capital-gain-report") === null) {
                setShowCapitalDatePicker(false);
            };

            if (e.target.closest("#transaction-report") === null) {
                setShowTransactionDatePicker(false);
            };
        };

        window.addEventListener("click", (e) => _handleDatePicker(e));
        return window.removeEventListener("click", (e) => _handleDatePicker(e));

    }, []);

    const _handleUpdateRange = (range, type) => {

        const _formatDate = (date) => {
            return date.toLocaleDateString("en-GB").replaceAll("/", "-");
        };

        if (type === 1) {
            setCapitalDateRange(range);
            setSelectedCapitalRange(_formatDate(range[0].startDate) + "  -  " + _formatDate(range[0].endDate));
            setShowCapitalDatePicker(false);
            setCapitalDateError("");
        } else if (type === 2) {
            setTransactionDateRange(range);
            setSelectedTransactionRange(_formatDate(range[0].startDate) + "  -  " + _formatDate(range[0].endDate));
            setShowTransactionDatePicker(false);
            setTransactionDateError("");
        }

    };

    const _handleDownloadCapitalGain = () => {
        if (selectedCapitalRange.length === 0) {
            setCapitalDateError("Select start and end date to proceed");
            return;
        }
        setCapitalLoading(true);
        _downLoadCapitalGainReport();
    };

    const _handleDownloadTransactionReport = () => {
        if (selectedTransactionRange.length === 0) {
            setTransactionDateError("Select start and end date to proceed");
            return;
        }
        setTransactionLoading(true);
        _downLoadTransactionReport();
    };

    const _handleDownloadHoldingsReport = () => {
        setHoldingsLoading(true);
        _downLoadHoldingsReport();
    };

    const _downLoadCapitalGainReport = () => {

        let is_post = true;
        let url = 'profile/capitalgain-report';

        let data = {
            "from_date": capitalDateRange[0].startDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
            "till_date": capitalDateRange[0].endDate.toLocaleDateString('en-UK').split("/").reverse().join("-")
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Capital gain report generated successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setCapitalLoading(false);
        });
    };

    const _downLoadTransactionReport = () => {
        let is_post = true;
        let url = 'profile/transaction-report';

        let data = {
            "from_date": transactionDateRange[0].startDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
            "till_date": transactionDateRange[0].endDate.toLocaleDateString("en-Uk").split("/").reverse().join("-"),
        }

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Transaction report generated successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setTransactionLoading(false);
        });
    };
    const _downLoadHoldingsReport = () => {
        let is_post = false;
        let url = 'profile/holdings-report';

        APIService(is_post, url).then((response) => {
            if (response.status_code === 200) {

                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.dismiss();
                toast.success("Holdings report generated successfully", { className: "e-toast", });

            } else {
                toast.dismiss();
                toast.error(response.message, { className: "e-toast", });
            }

            setHoldingsLoading(false);
        });
    };

    return (
        <>
            <div className="row flex-column min-h-100vh pb-4 pb-sm-5 e-bg-lotion px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={BREADCRUMBS} />
                <div className="col-lg-11 p-0 d-flex flex-column gap-4 mt-3 mt-sm-4 mb-5">
                    <div className='col col-sm-6 col-md-4'>
                        <div id="capital-gain-report"
                            className='position-relative mb-1'>
                            <CustomTextInput
                                label="Capital Gain Report"
                                placeholder="Start date - End date"
                                type="text"
                                maxLength={1000}
                                errorMsg={capitalDateError}
                                value={selectedCapitalRange}
                                readOnly={true}
                                className="cursor-pointer w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                labelStyle="e-font-weight-500"
                                icon={"calendar"}
                                onClick={() => {
                                    setShowCapitalDatePicker((prev) => !prev);
                                    setShowTransactionDatePicker(false);
                                    setCapitalDateError("");
                                }} />
                            {
                                showCapitalDatePicker &&
                                <CustomDateRangePicker
                                    initialRange={capitalDateRange}
                                    onUpdate={(range) => _handleUpdateRange(range, 1)}
                                    cancel={() => setShowCapitalDatePicker(false)}
                                    minDate={null}
                                    maxDate={new Date()}
                                    leftAlign={true} />
                            }

                        </div>
                        <PrimaryButton name="Download report"
                            disabled={capitalLoading ? true : false}
                            loading={capitalLoading ? true : false}
                            className="w-180px e-font-14 mt-3 mt-sm-4 py-2"
                            onPress={_handleDownloadCapitalGain} />
                    </div>

                    <div className='col col-sm-6 col-md-4'>
                        <div id="transaction-report"
                            className='position-relative mb-1'>
                            <CustomTextInput
                                label="Transaction Report"
                                placeholder="Start date - End date"
                                type="text"
                                maxLength={1000}
                                value={selectedTransactionRange}
                                errorMsg={transactionDateError}
                                readOnly={true}
                                className="cursor-pointer w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                labelStyle="e-font-weight-500"
                                icon={"calendar"}
                                onClick={() => {
                                    setShowTransactionDatePicker((prev) => !prev);
                                    setShowCapitalDatePicker(false);
                                    setTransactionDateError("");
                                }}
                            />
                            {
                                showTransactionDatePicker &&
                                <CustomDateRangePicker
                                    initialRange={transactionDateRange}
                                    onUpdate={(range) => _handleUpdateRange(range, 2)}
                                    cancel={() => setShowTransactionDatePicker(false)}
                                    minDate={null}
                                    maxDate={new Date()}
                                    leftAlign={true} />
                            }
                        </div>
                        <PrimaryButton name="Download report"
                            disabled={transactionLoading ? true : false}
                            loading={transactionLoading ? true : false}
                            className="w-180px e-font-14 mt-3 mt-sm-4 py-2"
                            onPress={_handleDownloadTransactionReport} />
                    </div>

                    <div className='col col-sm-6 col-md-4'>
                        <div id="holdings-report"
                            className='position-relative mb-1'>
                            <span className={`e-text-dark-charcoal e-font-weight-500 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                                Holdings Report
                            </span>
                        </div>
                        <PrimaryButton name="Download report"
                            disabled={holdingsLoading ? true : false}
                            loading={holdingsLoading ? true : false}
                            className="w-180px e-font-14  py-2 mt-2"
                            onPress={_handleDownloadHoldingsReport} />
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default Report;