/*
 *   File : forgot-password.js
 *   Author : https://evoqins.com
 *   Description : Forgot password page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//custom components
import { OTPInput, CustomPhoneInput, RadioGroup, CustomTextInput } from "../../Component/Form-Elements";
import { PrimaryButton } from "../../Component/Buttons";
import APIService from "../../Service/service";
import { Footer } from "../../Component/Footer";
import Store from "../../Store/store";
import { Header } from "../../Component/Navigation";
import { Icon } from "../../Component/Icon";


//custom styles
import styles from "../../Style/Component/login.module.scss";
import { isValidPhoneNumber } from "react-phone-number-input";

// Custom data
const SIGNIN_METHODS = [
    {
        id: 1,
        display_name: "Phone",
        icon: "signup-phone-icon"
    },
    {
        id: 2,
        display_name: "Email",
        icon: "singup-mail-icon"
    },
];


const ForgotPassword = () => {


    // FLOW
    // Step 1 => Choose phone or email to set new PIN
    // Step 2 => OTP Verification
    // Step 3 => Set new PIN

    const inputRef = useRef(null);
    const inputConfirmPinRef = useRef(null);
    const navigate = useNavigate();
    const { state } = useLocation();

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedSignInMethod, setSelectedSignInMethod] = useState(1);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(2);
    const [timer, setTimer] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default to 91 (india)
    const [newPinValue, setNewPinValue] = useState("");
    const [confirmNewPinValue, setConfirmNewPinValue] = useState("");
    const [focusedInputConfirm, setFocusedInputConfirm] = useState(0)
    const [refreshToken, setRefreshToken] = useState(null);
    const [pinError, setPinError] = useState("");


    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    //for timer
    useEffect(() => {
        if (timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [timer]);

    useEffect(() => {
        if (state != null) {
            setPhoneNumber(state.phone_number);
        }
    }, [state]);

    // Signup when user click enter
    useEffect(() => {

        const _handleOnEnter = (e) => {
            if (e.key === 'Enter') {
                if (currentStep == 1) {
                    _handleGetOTP();
                } else if (currentStep == 2) {
                    _handleVerifyOtp();
                } else {
                    _handleVerifyPin();
                }
            }
        };

        document.addEventListener('keydown', _handleOnEnter);

        return () => {
            document.removeEventListener('keydown', _handleOnEnter);
        };

    }, [email, phoneNumber, otpValue, newPinValue, confirmNewPinValue]);


    // handle set pin method select
    const _handleSignInMethodSelect = (id) => {
        setSelectedSignInMethod(id);
        if (id == 2) {
            setPhoneNumber("");
        } else {
            setEmail("");
        }
    };

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    // handle phone number change
    const _handlePhoneNumberChange = (value, selected_country_code) => {
        setPhoneNumber(value);
        setPhoneNumberError("");
        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }
    };

    //handle back
    const _handleBack = () => {
        setCurrentStep((cur_step) => cur_step - 1);
        setTimer(0);
    };

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
    };

    // handle resend otp
    const _handleResendOTP = () => {
        let resend = true;
        setDisableResendOTP(true);
        // API call
        _getOTP(resend);
    };

    //handle OTP verification
    const _handleVerifyOtp = () => {
        if (otpValue.length !== 4) return;
        setLoading(true);
        // API call
        _verifyOTP();
    };

    //handle get otp signup
    const _handleGetOTP = () => {

        let validation = true;

        // valid phone number and email pattern 
        let valid_phone = /^[6-9]\d{9}$/;
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (selectedSignInMethod == 1) {

            // removing country code
            let phone_number = Number(phoneNumber.replace(selectedCountryCode, ""));

            // Validating phone
            if (selectedCountryCode == "+91") {
                if (!valid_phone.test(phone_number)) {
                    setPhoneNumberError("Please enter a valid 10-digit phone number");
                    validation = false;
                }
            } else {
                let is_valid_number = isValidPhoneNumber(String(phoneNumber));
                if (is_valid_number === false) {
                    setPhoneNumberError("Please enter a valid number");
                    validation = false;
                }
            }
        } else {

            // Validating email
            if (!validate_email.test(email)) {
                setEmailError("Please enter a valid email id");
                validation = false;
            }

        }

        if (validation === true) {
            setLoading(true);
            // API call
            _getOTP();
        }
    };

    //handle new pin
    const _handleNewPin = (value) => {

        setPinError("");
        setNewPinValue(value);

        if (value.length == 4) {
            setFocusedInputConfirm(2);
        }
    };

    //handle confirm new pin
    const _handleConfirmNewPin = (value) => {
        setConfirmNewPinValue(value);
        setPinError("");
    };

    // create account pin
    const _handleVerifyPin = () => {
        if (newPinValue !== confirmNewPinValue) {
            setPinError("PIN does not match");
            setLoading(false);
        } else {
            setPinError("");
            setLoading(true);
            _setPin();
        }
    };



    // ================================ //
    //============= API CALLS ========= //

    // API - get login otp 
    const _getOTP = (resend) => {

        let is_post = true;
        let url = 'auth/get-login-otp';
        let data = {
            "type": "RESET",
            "auth_type": selectedSignInMethod == 1 ? "MOBILE" : "EMAIL",
        };

        if (selectedSignInMethod == 1) {
            data = {
                ...data,
                "country_code": selectedCountryCode,
                "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
            }
        } else {
            data = {
                ...data,
                "email": email
            }
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCurrentStep(2); // OTP page
                setTimer(30);
                setOtpValue("");
                setFocusedInput(2);

                if (resend === true) {
                    toast.dismiss();
                    toast.success(`OTP sent successfully to registered ${selectedSignInMethod == 1 ? " Mobile" : " Email"}`, {
                        className: "e-toast",
                    });
                }

            } else {

                if (response.status_code === 400 && response?.data?.flow === "SIGNUP") {
                    navigate("/signup", {
                        state: {
                            phone_number: String(phoneNumber),
                            email: email
                        }
                    });
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        className: "e-toast",
                    });
                }
            }

            setDisableResendOTP(false);
            setLoading(false);
        });
    };

    // API - Verify otp
    const _verifyOTP = () => {

        let is_post = true;
        let url = 'auth/verify-otp';
        let data = {
            "type": "RESET",
        };

        if (selectedSignInMethod === 1) {
            data = {
                ...data,
                "country_code": selectedCountryCode,
                "phone_number": Number(phoneNumber.replace(selectedCountryCode, "")),
                "phone_otp": otpValue,
            }
        } else {
            data = {
                ...data,
                "email": email,
                "email_otp": otpValue,
            }
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCurrentStep(3);
                setRefreshToken(response.data.refresh_token); //update refresh token
                setFocusedInput(2);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",

                });
            }
            setLoading(false);
        });
    };

    // API - Set new pin
    const _setPin = () => {
        // let refresh_token = null;
        let is_post = true;
        let url = 'auth/set-pin';
        let data = {
            "pin": newPinValue
        };
        let token = refreshToken;
        APIService(is_post, url, data, token).then((response) => {
            if (response.status_code === 200) {

                Store.dispatch({
                    type: 'UPDATE_ACCESS_TOKEN',
                    payload: response.data.access_token
                })

                setRefreshToken(null);

                navigate("/unlock", {
                    state: {
                        refresh_token: token
                    }
                });

                toast.dismiss();
                toast.success("PIN set successfully. Enter your new PIN to continue", {
                    className: "e-toast",
                });

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            }
            setLoading(false);
        });
    };

    return (
        <>
            <section className="e-login-gradient">
                {/* header  */}
                <Header />

                <div className="container px-4">
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="e-padding-block-40px-res  padding-76px-top padding-150px-bottom position-relative">
                                <div className={`${styles.e_forget_pin_wrapper} e-border-radius-32`}>
                                    <div className="row h-100">

                                        <div className="col-md-5 me-5 d-md-block d-none mt-auto">
                                            <h3 className="margin-70px-bottom e-text-charleston-green e-font-24 e-alt-font-poppins e-font-weight-600 line-height-40px letter-spacing-neg-2per">
                                                Invest with <br />
                                                <span className="e-font-32" >
                                                    Confidence
                                                </span>
                                            </h3>
                                        </div>

                                        <div className="col-xl-6 col-md-6 col-12 pe-md-0 align-self-center">
                                            <div className={`${styles.e_forget_pin_content} gradient-vr-line ps-md-4`}>

                                                {
                                                    // step-1 =>  Set pin  method select (phone  || email)
                                                    currentStep === 1 ?
                                                        <div>
                                                            <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                Forgot PIN
                                                            </h3>

                                                            <p className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                Enter your
                                                                {selectedSignInMethod == 1 ? " phone number " : " email "}
                                                                to reset PIN
                                                            </p>
                                                            <div className="d-flex gap-3  my-3">
                                                                <RadioGroup
                                                                    type={3}
                                                                    data={SIGNIN_METHODS}
                                                                    selected={selectedSignInMethod}
                                                                    handleSelect={_handleSignInMethodSelect} />
                                                            </div>

                                                            {
                                                                selectedSignInMethod == 2 ?
                                                                    <div className='h-100px margin-3px-bottom'>
                                                                        <CustomTextInput label="Email"
                                                                            placeholder="josephthomas@gmail.com"
                                                                            type="text"
                                                                            value={email}
                                                                            require
                                                                            errorMsg={emailError}
                                                                            className="w-100 ps-3 padding-14px-top padding-14px-bottom"
                                                                            handleChange={_handleEmailChange} />
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="margin-2px-bottom">
                                                                            <CustomPhoneInput label="Phone Number"
                                                                                placeholder="9876543210"
                                                                                className={phoneNumber ? `e-number-input-padding w-100 ` : `e-number-input-padding w-100`}
                                                                                type="text"
                                                                                value={phoneNumber}
                                                                                require
                                                                                disableCountrySelect
                                                                                selectedCountryCode={selectedCountryCode}
                                                                                error={phoneNumber && phoneNumberError}
                                                                                handleChange={_handlePhoneNumberChange} />
                                                                        </div>
                                                                    </>
                                                            }

                                                            <PrimaryButton name={`Get OTP`}
                                                                loading={loading}
                                                                disabled={loading}
                                                                className={`${(email.length !== 0 || phoneNumber) ? "" : "e-bg-bright-gray e-cursor-none"} w-100 padding-12px-top padding-12px-bottom e-font-16 mt-2`}
                                                                onPress={_handleGetOTP} />
                                                        </div>
                                                        :

                                                        // OTP verification
                                                        currentStep === 2 ?
                                                            <div>
                                                                <Icon icon="back-icon"
                                                                    className="cursor-pointer mb-3"
                                                                    size={32}
                                                                    onClick={_handleBack} />

                                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                    {
                                                                        selectedSignInMethod == 1 ? " OTP Verification" : "Verify email"
                                                                    }
                                                                </h3>
                                                                {
                                                                    String(phoneNumber).startsWith("+91") ?
                                                                        <div className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px mb-4'>
                                                                            Enter the 4-digit OTP received on your {selectedSignInMethod == 1 ? " Mobile number " : " Email "}
                                                                            <span className="d-inline-flex gap-1 e-text-chinese-black e-font-weight-600">
                                                                                {selectedSignInMethod == 1 ? ` ${phoneNumber}` : ` ${email}`}
                                                                                <Icon icon="edit-data-icon"
                                                                                    onClick={() => setCurrentStep(1)}
                                                                                    size={20} />
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        <div className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px mb-4'>
                                                                            Enter the 4-digit OTP received on your Email
                                                                            {
                                                                                email &&
                                                                                <span className="d-inline-flex gap-1 e-text-chinese-black e-font-weight-600">
                                                                                    {email}
                                                                                    <Icon icon="edit-data-icon"
                                                                                        onClick={() => setCurrentStep(1)}
                                                                                        size={20} />
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                }
                                                                <OTPInput ref={inputRef}
                                                                    id="otp"
                                                                    value={otpValue}
                                                                    spellcheck="false"
                                                                    focused_input={focusedInput}
                                                                    onFocus={() => setFocusedInput(2)}
                                                                    onBlur={() => setFocusedInput(0)}
                                                                    onChange={_handleChangeOTP} />

                                                                <div className='e-inline-flex flex-wrap gap-2 pt-2 e-text-charleston-green e-font-14 e-alt-font-poppins line-height-18px'>
                                                                    <span className="e-font-weight-500 ">
                                                                        Didn't get the OTP?
                                                                    </span>
                                                                    <button className={`${timer !== 0 ? "e-disable e-text-dark-liver" : "e-text-egyptian-blue"} px-0 d-flex gap-1 border-0 e-bg-transparent e-font-weight-500`}
                                                                        disabled={disableResendOTP}
                                                                        onClick={_handleResendOTP} >
                                                                        Resend OTP
                                                                        {timer !== 0 &&
                                                                            <p className='mb-0 e-text-dark-liver e-alt-font-poppins e-font-weight-500'>
                                                                                in {timer} {timer == 1 ? "second" : "seconds"}
                                                                            </p>
                                                                        }
                                                                    </button>
                                                                </div>

                                                                <div className='mt-4'>
                                                                    {otpValue.length >= 4 ?
                                                                        <PrimaryButton
                                                                            name={"Continue"}
                                                                            loading={loading}
                                                                            disabled={loading}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-font-16"
                                                                            onPress={_handleVerifyOtp} />
                                                                        :
                                                                        <PrimaryButton
                                                                            name="Continue"
                                                                            disabled={true}
                                                                            className="w-100 padding-12px-top padding-12px-bottom e-bg-bright-gray e-font-16 e-cursor-none" />
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Icon icon="back-icon"
                                                                    className="cursor-pointer mb-3"
                                                                    size={32}
                                                                    onClick={_handleBack} />
                                                                <h3 className='e-text-dark-jungle-green e-font-20  e-alt-font-poppins e-font-weight-600 line-height-32px letter-spacing-neg-2per'>
                                                                    Setup new PIN
                                                                </h3>
                                                                <p className='e-text-onyx-80per e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                                                                    Set a new 4 digit PIN to keep our investments
                                                                    safe & secure
                                                                </p>
                                                                <>
                                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                        Enter PIN
                                                                    </label>
                                                                    <OTPInput id="newPin"
                                                                        ref={inputRef}
                                                                        value={newPinValue}
                                                                        spellcheck="false"
                                                                        focused_input={focusedInput}
                                                                        onFocus={() => setFocusedInput(2)}
                                                                        onBlur={() => setFocusedInput(0)}
                                                                        onChange={_handleNewPin} />
                                                                </>
                                                                <div className="">
                                                                    <label className="e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                                        Re-enter PIN
                                                                    </label>
                                                                    <div className="h-72">
                                                                        <OTPInput
                                                                            id="confirmNewPin"
                                                                            ref={inputConfirmPinRef}
                                                                            value={confirmNewPinValue}
                                                                            spellcheck="false"
                                                                            focused_input={focusedInputConfirm}
                                                                            error={pinError}
                                                                            onFocus={() => setFocusedInputConfirm(2)}
                                                                            onBlur={() => setFocusedInputConfirm(0)}
                                                                            onChange={_handleConfirmNewPin} />
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2">
                                                                    <PrimaryButton
                                                                        name={`Get strated`}
                                                                        loading={loading}
                                                                        disabled={loading}
                                                                        className={newPinValue.length === 4 && confirmNewPinValue.length === 4 ?
                                                                            `w-100 padding-12px-top padding-12px-bottom e-font-16` :
                                                                            `e-bg-bright-gray e-cursor-none w-100 padding-12px-top padding-12px-bottom e-font-16`}
                                                                        onPress={_handleVerifyPin}
                                                                    />
                                                                </div>
                                                            </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* footer section  */}
            <div className="e-bg-anti-flash-white">
                <Footer auth={true} />
            </div>
        </>
    )
}
export default ForgotPassword;



