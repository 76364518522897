/*
 *   File : empty-screen.js
 *   Author : https://evoqins.com
 *   Description :  chip component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";


function EmptyScreen(props) {

  return (
    <div className={`${props.type == 2 ? "col-12 col-sm-8 col-md-11" : "padding-top-40px-res padding-54px-top col-12 col-md-5 col-sm-6 "} mx-auto text-center`}>
      <img src={props.img}
        className='img-fluid col-5 col-sm-6 col-lg-5'
        draggable="false" />
      <p className='e-font-16-res my-2 e-text-charleston-green e-alt-font-poppins e-font-18 e-font-weight-500 line-height-24px'>
        {props.title}
      </p>
      <p className='col-8 col-sm-auto mx-auto e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-400 line-height-24px'>
        {props.description}
      </p>
    </div>
  )
}

export default memo(EmptyScreen);