/*
 *   File : about-us.js
 *   Author : https://evoqins.com
 *   Description :About us page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Component
import { BreadCrumbs } from "../../Component/Other";
import { SectionTitle } from "../../Component/Title";
import { Footer } from "../../Component/Footer";

// Assets
import BannerImg from "../../Assets/Images/AboutUs/about-us-banner-img.webp";
import ReferralImg from "../../Assets/Images/AboutUs/about-us-referral-img.webp"
import Instagram from "../../Assets/Images/AboutUs/ifsh-instagram-logo.webp";
import Facebook from "../../Assets/Images/AboutUs/ifsh-facebook-logo.webp";
import LinkedIn from "../../Assets/Images/AboutUs/ifsh-linkedIn-logo.webp";
import Twitter from "../../Assets/Images/AboutUs/ifsh-twitter-logo.webp";


// custom data
const BREADCRUMBS = [
    {
        label: "Home",
        path: "/",
    },
    {
        label: "About us",
    },
];

const MAIN_CONTENT = [
    {
        title: "Simplifying index fund investments for you!",
        description: `Index Funds Sahi Hai offers globally diversified portfolios using "Alpha-on-Index" methodologies. Their cutting-edge technology helps with disciplined rebalancing, intelligent asset allocation, and low-cost investment. They provide smart solutions to save funds for education, retirement, and emergencies with up to 30%* additional savings.`,
    },

    {
        title: "Our vision",
        description: `Provide exceptional and innovative investment solutions with high ethical standards. We are committed to delivering superior investment results and extraordinary client service.`,
    },

    {
        title: "Our mission",
        description: `To take a stand for all INDIANS, treat them fairly, and give them the best chance for investment success.`,
    },
];

function AboutUs() {

    const navigate = useNavigate();

    // Setting active tab in location state to show active tab in profile option menu
    useEffect(() => {

        navigate("/about-us", {
            state: {
                "optional_id": "PROFILE-TAB-05",
            },
        }, { replace: true });

    }, []);

    return (
        <>
            <div className="min-h-100vh row e-bg-lotion d-flex flex-column  px-4 px-sm-5 ps-lg-4 pe-lg-0 padding-26px-top e-border-radius-TL-BL-24px">
                <BreadCrumbs data={BREADCRUMBS} />

                <div className="col-lg-11 p-0 margin-68px-bottom">
                    {/* Banner */}
                    <div className="about-us d-flex justify-content-between p-4 margin-block-32px e-border-radius-16">
                        <div className="col-sm-7">
                            <h2 className="e-font-20-res e-text-charleston-green e-alt-font-poppins e-font-22 e-font-weight-600 line-height-32px mb-2">
                                Index Funds Sahi Hai: Your Path to Smart Investing
                            </h2>
                            <p className="e-font-14-res e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px mb-0">
                                Secure your financial future with index funds that are "Sahi Hai." Join thousands of investors who have already embraced this path to prosperity.
                            </p>
                        </div>
                        <div className="d-none d-sm-block col-4 text-end position-relative">
                            <img className="position-absolute end-0 me-4 margin-neg-18px-top"
                                src={BannerImg}
                                width={170}
                                height={200}
                                draggable="false"
                                alt="banner image" />
                        </div>
                    </div>

                    {/* Content */}
                    <div className="p-0 d-flex flex-column gap-3">
                        {MAIN_CONTENT.map((item, key) => (
                            <div key={key}>
                                <SectionTitle title={item.title}
                                    className={"line-height-32px"} />
                                <p className="e-font-14-res e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-30px mt-2 mb-0">
                                    {item.description}
                                </p>
                            </div>
                        ))
                        }
                    </div>

                    {/* Referral */}
                    <div className="mt-5 e-bg-columbia-blue e-border-radius-16 d-flex justify-content-between p-4">
                        <div className="col-sm-9 pe-sm-3 me-sm-4">
                            <h2 className="e-font-16-res e-text-charleston-green e-alt-font-poppins e-font-20 e-font-weight-600 line-height-24px mb-2">
                                Invite Friends: Grow together financially!
                            </h2>
                            <p className="e-font-14-res  pe-sm-4 pe-md-0 e-text-onyx e-alt-font-poppins e-font-16 e-font-weight-400 line-height-26px">
                                Your financial growth shouldn't be a solo journey! At "Grow Together Financially,"
                                we believe in the power of community and sharing the wealth.
                            </p>
                            <div className="d-flex gap-3 ">
                                <a href="http://app.indexfundssahihai.com/" target="_blank">
                                    <img src={Facebook}
                                        className="transform-scale-hover"
                                        draggable="false"
                                        width={24}
                                        height={24}
                                        alt="IFSH-social-link-facebook" />
                                </a>
                                <a href="http://app.indexfundssahihai.com/" target="_blank">
                                    <img src={Instagram}
                                        className="transform-scale-hover"
                                        draggable="false"
                                        width={24}
                                        height={24}
                                        alt="IFSH-social-link-instagram" />
                                </a>
                                <a href="http://app.indexfundssahihai.com/" target="_blank">
                                    <img src={LinkedIn}
                                        className="transform-scale-hover"
                                        draggable="false"
                                        width={24}
                                        height={24}
                                        alt="IFSH-social-link-linkedIn" />
                                </a>
                                <a href="http://app.indexfundssahihai.com/" target="_blank">
                                    <img src={Twitter}
                                        className="transform-scale-hover"
                                        draggable="false"
                                        width={24}
                                        height={24}
                                        alt="IFSH-social-link-twitter" />
                                </a>
                            </div>
                        </div>
                        <div className="d-none d-sm-block col-3 text-end position-relative">
                            <img className="position-relative-md position-absolute end-0 me-4 margin-top-0px-res-md margin-neg-56px-top"
                                src={ReferralImg}
                                width={170}
                                height={170}
                                draggable="false"
                                alt="banner image" />
                        </div>
                    </div>

                    <div className="col-12 text-center mt-4">
                        <p className="e-font-14-res e-text-dark-liver e-alt-font-poppins e-font-weight-500  e-font-16 line-height-24px mb-1">Send your feedback at</p>
                        <a href="mailto:feedbacks@indexfundsahai.com"
                            className="e-font-14-res e-alt-font-poppins e-font-weight-400  e-font-16 line-height-24px">
                            feedbacks@indexfundsahai.com
                        </a>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    );
};

export default AboutUs;