/*
 *   File : aws-service.js
 *   Author Author URI : https://evoqins.com
 *   Description : aws file upload service
 *   Integrations : axios
 *   Version : v1.1
 */

import axios from 'axios';

// AWS service 
const awsService = async function (params, file, url) {

    try {

        let AWS_BASE_URL = url;
        let form_data = new FormData();

        // append form data
        Object.entries(params).map(item =>  form_data.append(item[0], item[1]));
        form_data.append('file', file, file.name);
        form_data.append('Content-Type', file.type);

        //config
        var config = {
            method: 'post',
            url: AWS_BASE_URL,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': "application/json",
                'cache-control': "no-cache",
            },
            data: form_data,
            timeout: 60000, // Set a timeout of 60 seconds
            transformRequest: form_data => form_data,
        };

        // api call
        return await axios(config)
            .then(function (response) {
                return ({ status: 'ok' }); // when successful
            })
            .catch(function (error) {
                return ({
                    status: false,
                    message: 'We are facing issues with our third-party service providers. Please try later'
                });
            });

    } catch (err) {
        return ({
            status: false,
            message: 'We are facing issues with our third-party service providers. Please try later.'
        })
    }
};
export default awsService;