/*
 *   File : confirm-logout.js
 *   Author : https://evoqins.com
 *   Description : sip cancel confirmation modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";
import Cookies from "js-cookie";
// import OneSignal from "react-onesignal";

//custom component
import { PrimaryButton, SecondaryButton } from '../Buttons';
import Store from "../../Store/store";

const ConfirmLogoutModal = (props) => {

    const _handleLogout = () => {

        // Closing modal

        sessionStorage.removeItem('access_token');
        Cookies.remove("refresh_token", { path: '/', domain: process.env.REACT_APP_DOMAIN });

        Store.dispatch({
            type: 'UPDATE_ACCESS_TOKEN',
            payload: null,
        });
        Store.dispatch({
            type: 'UPDATE_REFRESH_TOKEN',
            payload: null,
        });

        Store.dispatch({
            type: 'UPDATE_USER_DATA',
            data: null
        });

        Store.dispatch({
            type: 'UPDATE_ACCOUNT_OPENING_MODAL_STATUS',
            payload: false,
        });

        var close_button = document.getElementById("logout-confirmation-modal-close");
        close_button.click();

        // OneSignal.logout();

    };

    return (
        <div className="modal fade"
            id="confirm-logout"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="Confirm Logout Modal Label"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-header justify-content-between pt-4  px-4 pb-0 border-0">
                        <h6 className='e-font-16-res mb-2 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            Confirm
                        </h6>
                        <img src={require("../../Assets/Images/close-btn.svg").default}
                            id='logout-confirmation-modal-close'
                            draggable={false}
                            className='cursor-pointer'
                            data-bs-dismiss="modal"
                            aria-label="close"
                            alt='close btn'
                            onClick={props.onClose} />
                    </div>
                    <div className="modal-body px-4 pb-4 pt-3">
                        <p className='e-font-14-res mb-2 e-text-chinese-black e-font-16 e-alt-font-poppins e-font-weight-400 line-height-24px'>
                            Are you sure you want to logout ?
                        </p>
                        <div className="d-flex gap-3 mt-4">
                            <SecondaryButton name="Cancel"
                                dismiss="modal"
                                className="flex-fill py-2 e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                onPress={props.onClose} />
                            <PrimaryButton name="Continue"
                                className="py-2 flex-fill"
                                onPress={_handleLogout} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmLogoutModal);