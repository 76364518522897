/*
 *   File : goal.js
 *   Author : https://evoqins.com
 *   Description : Goal card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";

//styles
import styles from "../../Style/Component/card.module.scss";

const GoalCard = (props) => {

    return (
        <div
            className={`${styles.e_goal_card}  cursor-pointer transition margin-32px-bottom margin-24px-bottom-res`}>
            <img src={props.data.web_image}
                draggable={false}
                alt={props.data.name}
                id="web"
                className="img-fluid h-auto object-fit-cover" />
        </div>
    )
}
export default memo(GoalCard);