/*
 *   File : text-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

// Component
import { Icon } from "../Icon";

//custom style
import styles from "../../Style/Component/input.module.scss";


const CustomTextInput = (props) => {

    const [inputValue, setInputValue] = useState(props.value);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const _handleFocus = (e) => {
        setIsInputFocused(true);
    };

    const _handleBlur = (e) => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {

        let value = e.target.value;

        if (props.style === "date") {

            const ALLOWED_VALUES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];

            if (value.length === 2 || value.length === 5) {
                value = value + "-";

            } else if (value.length === 6 || value.length === 3) {
                value = value.slice(0, -1) + "-" + value.at(-1);
            };

            if (ALLOWED_VALUES.includes(value.slice(-1)) == true) {

                setInputValue(value);
                props.handleChange(value);
            } else if ((value.at(-1) === "-" && value.at(-2) === "-") === false && value.length <= 6) {
                setInputValue(value);
                props.handleChange(value);
            }

            return;

        };

        if (props.type === "number" || props.style === "number") {
            const ALLOWED_VALUES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
            if (ALLOWED_VALUES.includes(value.slice(-1)) !== false && value.length <= props.maxLength) {
                setInputValue(value);
                props.handleChange(value);
            };
        } else {
            setInputValue(value);
            props.handleChange(value);
        };
    };

    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        let value = e.target.value;

        // prevent numbers
        if(props.style === "name") {
            if (e.key >= '0' && e.key <= '9') {
                e.preventDefault();
            }
        };

        if (key_code === 8) {

            if (props.style == "date") {
                if (value.length === 7 || value.length === 4 || value.length === 6 || value.length === 3) {
                    value = value.slice(0, -2);
                    setInputValue(value);
                    props.handleChange(value);
                    e.preventDefault();

                };
            }

        };

        if (props.type === "number") {

            if (key_code === 38 || key_code == 40 || key_code == 69) {
                e.preventDefault();
            };
        }
    };

    return (
        <div className={props.height || "h-78"}>
            {props.label &&
                <label className={`${props.labelStyle} e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                    {props.label}
                    {props.require && <span className='e-text-sunset-orange'>*</span>}
                </label>
            }

            <div
                className={`${props.disabled === true ? 'opacity-5 pe-none' : ''} ${props.errorMsg && 'border-red-1px '} ${isInputFocused ? "e-border-1px-egyptian-blue" : props.inputWrapperClassName ? props.inputWrapperClassName : "e-border-1px-charleston-green"}  d-flex align-items-center cursor-pointer ${props.inputWrapperBorderStyle || "e-border-radius-12"}`}
                onClick={props.onClick}>
                <input id={props.id}
                    className={`${styles.e_input} ${props.className} flex-fill e-font-14-res e-font-16 padding-8px-all-res pe-1 e-caret-color-egyptian-blue px-3 border-0  bg-transparent padding-12px-tb border-radius-4px e-bg-white e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-22px`}
                    ref={props.ref}
                    value={inputValue}
                    maxLength={props.maxLength}
                    type={props.type}
                    required={props.required}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    onWheel={() => document.activeElement.blur()}
                    onChange={_handleChange}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onKeyDown={_preventDefaults}
                />

                {
                    props.dropdown == true &&
                    <img src={require("../../Assets/Images/KYC/drop-down.svg").default}
                        draggable={false}
                        className={`${props.disabled && "e-pointer-events-none"} ${props.dropDownIcon ? props.dropDownIcon : 'top-50 top-45-res'} position-absolute end-0 me-2 me-sm-3 bg-white`}
                        alt='icon' />
                }

                {
                    props.icon &&
                    <Icon icon={props.icon}
                        className={`position-absolute top-50 top-45-res end-0 mt-2 pb-1 me-2 me-sm-3`}
                        size="1.8rem" />
                }

                {
                    props.type === 2 &&
                    <span className="me-sm-3 padding-8px-all-res">
                        <Icon icon="calendar"
                            size={"1.2rem"} />
                    </span>
                }

            </div>

            {/* Error text */}
            {
                props.errorMsg &&
                <p className={`${props.errorMsg === "" ? "opacity-0" : "opacity-100"} h-6 mb-0 e-error-message e-font-12 line-height-12px padding-2-top`}>
                    {props.errorMsg === "" ? "no error message" : props.errorMsg}
                </p>
            }

            {/* success text  */}
            {
                props.successMsg &&
                <p className={`${props.successMsg === "" ? "opacity-0" : "opacity-100"} mb-0 e-text-ufo-green e-font-weight-400 e-font-12`}>
                    {props.successMsg === "" ? "no error message" : props.successMsg}
                </p>
            }
        </div>
    )
}

CustomTextInput.defaultProps = {
    className: null,
    id: null,
    label: null,
    maxLength: 1000,
    handleChange: () => { },
}

export default memo(CustomTextInput);