/*
 *   File : bank-status.js
 *   Author : https://evoqins.com
 *   Description : Bank status Modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';

//custom component

const BankStatusModal = (props) => {
    return (
        <div className="modal fade"
            id="bankStatusModal"
            tabIndex="-1"
            aria-labelledby="bankStatusModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered modal-md">
                <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                    <div className="modal-body text-center p-4">

                        <img src={require("../../Assets/Images/Profile/bank-status.svg").default}
                            draggable={false}
                            className='pt-5'
                            alt='bank' />
                        <p className='mb-2 pt-4 mt-1 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-24px'>
                            Congratulations!
                        </p>
                        <p className='mb-0 px-5 e-text-dark-liver e-font-14 e-alt-font-poppins e-font-weight-400 line-height-26px'>
                            Your bank detail has successfully updated
                        </p>

                        {/* <div className='padding-34px-top'>
                            <PrimaryButton name="Create mandate"
                                onPress={props.onCloseModal}
                                className="w-100 padding-12px-top padding-12px-bottom"
                                dismiss="modal" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankStatusModal;